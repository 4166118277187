import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Button, Modal, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import * as Constants from '../../constants/constants.js';
import Loading from '../../global_components/Loading.js'

import { Divider, ListItemText, ListItem } from '@material-ui/core';


export default function CustomerSearch(props) {
    const show = props.show;
    const from = props.from
    const [loading, setLoading] = useState(false);
    const [customerList, setCustomerList] = useState({ list: [] })
    const [selected, setSelected] = useState(null)
    const [workorderToAdd, setWorkorderToAdd] = useState({
        Lastname: "",
        Firstname: "",
        LicenseNumber: "",
        Phone: "",
        Year: "",
        Make: "",
        Model: ""
    })

    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            /*  if(selected != null)
             {
                 console.log("--- ALready selected ---")
                 props.createWO(customerList.list[selected], workorderToAdd)
                 setSelected(null)
             }
             else */
            searchCustomers()
            event.preventDefault()
        }
        else if (event.key === "ArrowDown") {
            setSelected(selected + 1)
            event.preventDefault()
        }
        else if (event.key === "ArrowUp") {
            selected > 0 ? setSelected(selected - 1) : setSelected(0)
            event.preventDefault()
        }
    }

    useEffect(() => {
        document.querySelectorAll("input").forEach(b=>b.setAttribute('type','search'))
        
        inputRef.current.focus();
    }, [])

    async function searchCustomers() {
        try {
            setLoading(true)
            console.log("info search: ", workorderToAdd)
            const res_data = await axios.post(`${Constants.port}/customer/search`, workorderToAdd);
            console.log('fetching data search: ', res_data);
            setCustomerList({ list: res_data.data.customers })
            setSelected(0)
            //setListOfJobs({ jobs: res_data.data.job_list })
            setLoading(false)

        } catch (error) {
            console.log("testing CATCHING ERROR. searching customers ")
            setLoading(false)
        }
    }

    return (
        <Modal show={show} onHide={props.close} size='lg' className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title>{from === "invoice_screen" ? 'Create a new WO' : "Change Customer"}</Modal.Title>
                <Button variant="secondary" style={{ margin: 'auto' }} onClick={props.createNewCustomer.bind(this, workorderToAdd)}> New Customer </Button>
                {customerList.list.length > 0 ? <h5>Amount: {customerList.list.length}</h5> : null}
            </Modal.Header>

            {!loading ? null : <Loading />}

            <Modal.Body>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group >
                                <Row>
                                    <Col>
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control ref={inputRef} value={workorderToAdd.Lastname} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Lastname: e.target.value })} />
                                    </Col>
                                    <Col>
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control value={workorderToAdd.Firstname} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Firstname: e.target.value })} />
                                    </Col>
                                </Row>


                                <Form.Label>License Plate </Form.Label>
                                <Form.Control value={workorderToAdd.LicenseNumber} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, LicenseNumber: e.target.value })} />


                                <Form.Label>Phone</Form.Label>
                                <Form.Control value={workorderToAdd.Phone} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Phone: e.target.value })} />

                                <Row>
                                    <Col>
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control disabled value={workorderToAdd.Year} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Year: e.target.value })} />
                                    </Col>
                                    <Col>
                                        <Form.Label>Make</Form.Label>
                                        <Form.Control disabled value={workorderToAdd.Make} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Make: e.target.value })} />
                                    </Col>
                                    <Col>
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control disabled value={workorderToAdd.Model} onChange={(e) => setWorkorderToAdd({ ...workorderToAdd, Model: e.target.value })} />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <ListGroup /* activeKey={selected} */ size="small" id="customer_search_table" className="customer_search_table" >
                            {customerList.list.map((row, key) => (
                                <React.Fragment key={key}>

                                    <ListItem /* eventKey={key} */ button
                                        onClick={from === "invoice_screen" ? props.createWO.bind(this, row, workorderToAdd) : props.loadCustomerInfo.bind(this, row, workorderToAdd)}>
                                        <ListItemText /* onClick={()=>console.log("CLICK", row)} */>
                                            {row.Lastname}, {row.Firstname}
                                            <div style={{display:"inline", float:"right"}}>{row.CustomerNumber}</div>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}

                        </ListGroup>
                    </Col>

                </Row>


            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={()=>searchCustomers()}> Search </Button>
            </Modal.Footer>

        </Modal>
    )
}