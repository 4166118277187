import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Button, Modal, Row } from 'react-bootstrap';
import * as Constants from '../../../constants/constants'
import Select from 'react-select';


export default function VehicleDataModal(props) {
    const show = props.show
    const [vehicleInfo, setVehicleInfo] = useState(props.vehicle);
    const [changesMade, setChangesMade] = useState(false);
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function handleCreate(inputValue) {
        console.group('Option created');
        console.log(inputValue)
    };

    function myKeyPress(event) {
        if (event.key === "F10" ) {
            if(changesMade)
                props.submitChanges(vehicleInfo)
            else
                props.close()
            event.preventDefault()
        }
    }

    function getYear() {   
        const currentOption = Constants.years.find(option => option.label === vehicleInfo.Year);
        return currentOption
    }

    function getMakes() {
        const currentOption = Constants.makeList.find(option => option.label === vehicleInfo.Make);
        return currentOption     
    }

    function getModelOptions() {
 
        const currentOption = Constants.makeList.find(option => option.label === vehicleInfo.Make);
        if (currentOption){
            return currentOption.models;
        }
        
    }

    function getModel() {
        
        const currentOption = Constants.makeList.find(option => option.label === vehicleInfo.Make);
        if (currentOption){
            return currentOption.models.find(option => option.label === vehicleInfo.Model)
        }
        return currentOption
    
    }

    function handleChangeYear(e){
        setVehicleInfo({ ...vehicleInfo, Year: e.label, Make: "", Model: "" })
        setChangesMade(true)
    }

    function handleMakeChange(e){
        setVehicleInfo({ ...vehicleInfo, Make: e.label, Model: "" })
        setChangesMade(true)
    }

    function handleModelChange(e){
        setVehicleInfo({ ...vehicleInfo, Model: e.label })
        setChangesMade(true)
    }

    useEffect(()=>{
        document.querySelectorAll("input").forEach(b=>b.setAttribute('type','search'))
        inputRef.current.focus();
    }, [])

    return (
        <Modal
            show={show}
            onHide={props.close}
            centered
            className="invoice_item_modal"
        >

            <Modal.Header >
                <Modal.Title>Vehicle Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onChange={() => setChangesMade(true)}>


                    <Form.Group  as={Row}>
                        <Form.Label column sm={3}>
                            Year
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                value={(getYear())}
                                onChange={e =>  handleChangeYear(e) }
                                options={Constants.years} />

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Make
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                onChange={e =>  handleMakeChange(e) }
                                options={Constants.makeList}
                                value={ vehicleInfo.Make === "" ? "" : getMakes()}
                            />
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Model
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                onChange={e => handleModelChange(e) }
                                onCreateOption={handleCreate}
                                options={getModelOptions()}
                                value={ vehicleInfo.Model === "" ? "" : getModel()}
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{position:"fixed", marginTop:"-27px"}}>Engine</Form.Label>
                            <Form.Control type="EngineSize" defaultValue={vehicleInfo.EngineSize ? vehicleInfo.EngineSize : ""} onChange={(e) => { setVehicleInfo({ ...vehicleInfo, EngineSize: e.target.value.toUpperCase() }) }} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Mileage</Form.Label>
                        <Col>
                            <Form.Control ref={inputRef} type="Mileage" placeholder="Enter mileage" defaultValue={vehicleInfo.Mileage ? vehicleInfo.Mileage : ""} onChange={(e) => setVehicleInfo({ ...vehicleInfo, Mileage: e.target.value })} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>License</Form.Label>
                        <Col>
                            <Form.Control type="License" defaultValue={vehicleInfo.LicenseNumber ? vehicleInfo.LicenseNumber : ""} onChange={(e) => { setVehicleInfo({ ...vehicleInfo, LicenseNumber: e.target.value.toUpperCase() }) }} />
                        </Col>

                        <Form.Label column sm={2}>State</Form.Label>
                        <Col>
                            <Form.Control type="State" defaultValue={vehicleInfo.State} onChange={(e) => { setVehicleInfo({ ...vehicleInfo, State: e.target.value.toUpperCase() }) }} />
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>VIN</Form.Label>
                        <Col>
                            <Form.Control type="VIN" defaultValue={vehicleInfo.VIN} onChange={(e) => { setVehicleInfo({ ...vehicleInfo, VIN: e.target.value.toUpperCase() }) }} />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!changesMade} onClick={props.submitChanges.bind(this, vehicleInfo)}>Save Changes</Button>
                <Button variant="secondary" onClick={props.close}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    )
}