import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import * as Constants from '../../../constants/constants'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';


export default function VehiclesModal(props) {
    const show = props.show
    const vehicles = props.vehicles
    const [updatedVehicles, setUpdatedVehicles] = useState(props.vehicles)
    const [selectedVehicle, setSelectedVehicle] = useState();
    /* const [newFilter, setNewFilter] = useState(false) */

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit: true,
        /* selected: [selectedIndex], */
        onSelect:handleOnSelect,
    };



    function handleOnSelect(item) {
        console.log("On select Item:", item);
        setSelectedVehicle(item);
        /* setNewFilter(true) */
    }

    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        /* setNewFilter(false) */
        if (event.key === "F10") {
            if(selectedVehicle)
                props.selectVehicle(selectedVehicle)
            event.preventDefault()
        }
    }

    
/*     function afterFilter(newResult, newFilters) {
        console.log("newFilters:", newFilters, "newRes: ", newResult, "newFilter", newFilter);
        if(newResult.length > 0 && newFilter === false){
            newResult = newResult.sort((a,b) => (a.Year > b.Year) ? -1 : ((b.Year > a.Year) ? 1 : 0)); 
            console.log("newFilters22222:", newFilters, "newRes: ", newResult, "newFilter", newFilter);
            setSelectedVehicle(newResult[0])
            setSelectedIndex(newResult[0].id)
        }
    }
 */
    const defaultSorted = [{
        dataField: 'Year',
        order: 'desc'
      }];

      function afterSaveCell(oldValue, newValue, row, column, done) {
        console.log("saving change cell...");
        console.log("row:", row);
        console.log("index:", vehicles.indexOf(oldValue));
        console.log(vehicles);
        let cpy = updatedVehicles
        console.log("row before...", row);
        let upperCased = {};
        newValue = newValue.toUpperCase();

        for (var key in row) {
            console.log(key);
            if(key === "Make")
                if(row[key])
                    upperCased[key] = row[key].toUpperCase();
                else
                    upperCased[key] = ""
            else 
                upperCased[key] = row[key];
        }
        console.log("row after...", upperCased);
        /* make, modal, licensenumber, submodel, state,, vin,  */
        cpy[row.id] = upperCased
        setUpdatedVehicles(cpy)
        props.updateVehicles(cpy)
      }

    useEffect(()=>{
        document.getElementById('text-filter-column-Year').focus()
    },[])

    return (
        <Modal
            className="invoice_item_modal"
            show={show}
            onHide={props.close}
            centered
            size='xl'
        >


            <Modal.Header >
                <Modal.Title>Vehicles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tableOfVehicles">
                    <BootstrapTable striped bordered hover 
                        bootstrap4
                        condensed
                        keyField='_id'
                        defaultSorted={ defaultSorted } 
                        filter={filterFactory(/* {afterFilter} */)}
                        cellEdit={ cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell, 
                        beforeSaveCell: (oldValue, newValue, row, column) => { newValue=newValue.toUpperCase() }, }) }

                        data={vehicles}
                        columns={Constants.columns}
                        selectRow={selectRow}
                        /* rowEvents={rowEvents} */
                    />
                </div>
                <br />
                <Button   variant="secondary" onClick={props.close}>Cancel</Button>
                <Button style={{ marginLeft: "10px"  }} onClick={props.addVehicle}>Add Vehicle</Button>
                <Button style={{ marginLeft: "10px"  }} disabled={!selectedVehicle} onClick={() => props.selectVehicle(selectedVehicle) }>Select</Button>
                <Button variant="danger" style={{ marginLeft: "10px", float:"right" }}  disabled={!selectedVehicle} onClick={() => props.removeVehicle(selectedVehicle)}>Delete Vehicle</Button>
            </Modal.Body>
        </Modal>

    )
}