import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'
import axios from 'axios';

export default function BatteryModal(props) {
    const { show, employeeList } = props
    const [battery, setBattery] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })

    /* Should this be hard-coded or retrieve from canned items? */
    const [employee, setEmployee] = useState({
        employeeID: "",
        description: "INSTALL NEW INTERSTATE BATTERY",
        amount: .2, 
        rate: Constants.shop_information.LaborRate,
        total: 25
    })

    const [partsList, setPartsList] = useState({ parts: [] })
    const [error, setError] = useState("")
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.addBatteryCharge({battery,employee})
            event.preventDefault()
        }
        else if (event.key === "Escape") {
            props.close()
            event.preventDefault()
        }
    }

    async function getParts() {
        try {
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data getParts: ', res_data);
            setPartsList({ parts: res_data.data.inventory })
        } catch (error) {
            console.log("Error getting parts, BatteryModal - ahsd61ws", error)
        }
    }

    function getEmployee(checkNew) {
        if (!checkNew) {
            const currentOption = employeeList.find(option => option.value === employee.employeeID);
            return currentOption
        }
    }

    function submitAddBattery(){
        if(!battery.partNumber)
            setError("You must select a battery")
        else
            props.addBatteryCharge({battery,employee})
    }
    
    useEffect(() => {
        inputRef.current.focus();
        getParts()

    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Select Battery Info.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setEmployee({...employee, employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,    
                                        }
                                    })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Battery
                        </Form.Label>
                        <Col sm={6}>
                            <Select
                                /* value={(getPart())} */
                                onChange={e => { setBattery({ ...battery, partNumber: e.label, description: e.value, quantity: 1, cost: e.cost, list: e.list, _id: e._id }) }}
                                options={partsList.parts.filter((item) => {
                                    return (item.Description).includes("BATTERY")
                                    }).map((item)=>{
                                        return {
                                            value: item.Description,
                                            label: item.PartNumber,
                                            cost: item.Cost,
                                            list: item.List,
                                            _id: item._id
                                        }
                                    })
                                }
                            />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
             
                    <br />
                    <Button variant="primary" onClick={()=>submitAddBattery()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}