import React, {useState} from 'react';
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';
import Datetime from 'react-datetime';
export default function StatusModal(props) {
    const show = props.show
    const invoiceInfo = props.info
    const [status, setStatus] = useState(invoiceInfo.Workorder.Type.toUpperCase())
    //const payments = props.payments
    const [date, setDate] = useState(new Date())
    
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.submit(status, date)
            event.preventDefault()
        }
        else if (event.keyCode === 83) { //s
            setStatus("SPLIT")
            event.preventDefault()
        }
        else if (event.keyCode === 80) { //p
            setStatus("PAID")
            event.preventDefault()
        }
        else if (event.keyCode === 69) { //e
            setStatus("ESTIMATE")
            event.preventDefault()
        }
        else if(event.key === "Escape")
        {
            props.close()
            event.preventDefault()
        }
    }

    function updateDate(e){
        let today = new Date(e._d)
        let date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
        setDate(date)
        
    }
    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Workorder Status</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "12px" }}>
                <Form onChange={(e)=> e.target.name === "status" ? (setStatus(e.target.id)) : null}>
                    <Form.Group as={Row} style={{placeContent:"center"}} >
                        <Col style={{ fontSize: "18px" }} sm={7} >
                            <Datetime initialValue={date} onChange={(e)=>updateDate(e)} closeOnSelect dateFormat="MM/DD/YYYY" timeFormat={false} />

                            <Form.Label style={{ width: "100%", textAlign: "center", textDecorationLine: "underline" }}  > Status </Form.Label>
                            <Form.Check
                                custom
                                checked={status === "REPAIR" || status === "OPEN"}
                                onChange={()=>{}}
                                type="radio"
                                label="Open"
                                name="status"
                                id="OPEN"
                                style={{ fontSize: "large", marginBottom: "10px" }}
                            />
                            <Form.Check
                                custom
                                checked={status === "SPLIT"}
                                onChange={()=>{}}
                                type="radio"
                                label="Split"
                                name="status"
                                id="SPLIT"
                                style={{ fontSize: "large", marginBottom: "10px" }}

                            />
                            <Form.Check
                                custom
                                checked={status === "PAID"}
                                onChange={()=>{}}
                                type="radio"
                                label="Paid"
                                name="status"
                                id="PAID"
                                style={{ fontSize: "large", marginBottom: "10px" }}

                            />
                           
                            <Form.Check
                                custom
                                checked={status === "ESTIMATE"}
                                onChange={()=>{}}
                                type="radio"
                                label="Estimates"
                                name="status"
                                id="ESTIMATE"
                                style={{ fontSize: "large", marginBottom: "10px" }}
                            />

                             <Form.Check
                                 custom
                                checked={status === "ONACCOUNT"}
                                onChange={()=>{}}
                                type="radio"
                                label="On Account"
                                name="status"
                                id="ONACCOUNT"
                                style={{ fontSize: "large", marginBottom: "10px" }}
                            />
                        </Col>
                  {/*       <Col sm={5} style={{ fontSize: "18px" }}>
                            <Form.Label style={{ width: "100%", textAlign: "center", textDecorationLine: "underline" }}  > Date </Form.Label>

                            <Form.Control style={{ marginBottom: "5px" }} size="sm" defaultValue={Constants.dateFormatter(invoiceInfo.Totals.DateOpen)} disabled={status !== "OPEN"} />
                            <Form.Control style={{ marginBottom: "5px" }} size="sm" disabled={status !== "SPLIT"} value={status ==="SPLIT" ? date : ""} />
                            <Datetime initialValue={date} onChange={(e)=>updateDate(e)} closeOnSelect dateFormat="MM/DD/YYYY" timeFormat={false} />

                            <Form.Control style={{ marginBottom: "5px" }} size="sm" disabled={status !== "ESTIMATE"}  onChange={()=>{}} value={status === "ESTIMATE" ? date : ""}/>
                        </Col>
                        */}
                        <Col sm={12} style={{ textAlign: "center", marginTop: "10px" }}>
                            <Button variant="success" style={{ width: "75px" }} onClick={props.submit.bind(this,status, date)}> Save </Button>
                            <Form.Label column sm={4} />

                            <Button variant="danger" style={{ width: "75px" }} onClick={()=>props.close()}> Cancel </Button>
                        </Col>

                    </Form.Group>


                </Form>
            </Modal.Body>
        </Modal>
    )

}