import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';

export default function AddJobModal(props) {
    const show = props.show;
    const inputRef = useRef();
    const [jobToAdd, setJobToAdd] = useState({
        Lastname: "",
        Firstname: "",
        Phone: "",
        Vehicle: "",
        Description: "",
        License: "",
        State: "",
        ScheduledDate: new Date(),
        Type: props.scheduled ? "APPOINTMENT" : "",
        Waiting: false
    })

    function myKeyPress(event) {

        if (event.key === "F10") {
            props.add(jobToAdd)
            event.preventDefault()
        }
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    useEventListener('keydown', myKeyPress);

    return (
        <Modal show={show} onHide={props.close} onKeyPress={(event) => myKeyPress(event)} className="invoice_item_modal">
            <Modal.Header closeButton>
                <Modal.Title>Add a job</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            {jobToAdd.Type === "APPOINTMENT" ?
                   <>
                    <Form.Label>Date</Form.Label>
                    <br/>
                   <DateTimePicker className="testt" disableClock onChange={(e) => setJobToAdd({...jobToAdd, ScheduledDate: e})} value={jobToAdd.ScheduledDate} />
                    </>
                    : null}
                <Form  >


                    <Form.Row>
             
                        <Form.Group as={Col} >
                            <Form.Label>Last name</Form.Label>
                            <Form.Control autoComplete="new-password" ref={inputRef} value={jobToAdd.Lastname} onChange={(e) => setJobToAdd({ ...jobToAdd, Lastname: e.target.value })} />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>First name</Form.Label>
                            <Form.Control autoComplete="new-password" value={jobToAdd.Firstname} onChange={(e) => setJobToAdd({ ...jobToAdd, Firstname: e.target.value })} />
                        </Form.Group>

                        {jobToAdd.Type !== "APPOINTMENT" ? 
                        <Form.Group as={Col} xs={2}>
                        <Form.Label>Waiting</Form.Label>
                        <br/>
                        <input checked={jobToAdd.Waiting} onChange={(e)=>setJobToAdd({...jobToAdd, Waiting: e.target.checked})} type='checkbox' style={{height:"25px", width:"20px", margin:"auto"}} />
                    </Form.Group>
                    : null}
                    </Form.Row>

                    <Form.Label>Phone</Form.Label>
                    <Form.Control autoComplete="new-password"  value={jobToAdd.Phone} onChange={(e) => setJobToAdd({ ...jobToAdd, Phone: e.target.value })} />
                    <Form.Label>Vehicle</Form.Label>
                    <Form.Control value={jobToAdd.Vehicle} onChange={(e) => setJobToAdd({ ...jobToAdd, Vehicle: e.target.value })} />

                    <Form.Label>Description</Form.Label>
                    <Form.Control autoComplete="new-password" as="textarea" rows="5" value={jobToAdd.description} onChange={(e) => setJobToAdd({ ...jobToAdd, Description: e.target.value })} />
                 
                   

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={props.add.bind(this, jobToAdd)}> Save Changes </Button>
            </Modal.Footer>

        </Modal>
    )
}