import React/* , { useState, useEffect } */ from 'react';
import { Spinner } from 'react-bootstrap';


export default function Loading(props) {
    const cmp = props.component
    if (cmp == null)
        return (
            <div className="spinnerpadding">
                <div className="overlay">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </div>

        );
    else if (cmp === "JobsScreen")
        return (
            <div className="spinnerpadding">
                <div className="overlay">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </div>
        )
}