import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import ReactToPrint from 'react-to-print';
import { Col, Row, Container, Button, Form } from 'react-bootstrap'
/* import ChargesList_Print from './ChargesList_Print.js' */
import CHARGESLIST_PRINT from '../components/invoice_screen/ChargesList_Print.js'
import axios from 'axios';
//import * as Constants from './../../constants/constants.js';
import * as Constants from '../constants/constants.js'
/* class ComponentToPrint extends React.Component  */


export default function Print(props) {
    const componentRef = useRef();
    const [invoiceInfo,setInvoiceInfo] = useState(props.invoiceInfo
        /* Workorder: {
        },
        Totals: {
        },
        Customer: { City:""
        },
        Vehicle: {
        },
        Parts: [],
        Labor: [],
        Notes: [],
        Sublets: [],
        Credits: [],
    } */)
    //const payments = props.payments
    const path = useLocation().pathname
    const searching = props.searching

    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            document.getElementById('print-button').click();
            //props.submitSplitPayment(paymentInfo)
            event.preventDefault()
        }
        else if (event.keyCode === 78) {
            props.close()
            props.submit()
            //document.getElementById('print-button').click();
            //props.submitSplitPayment(paymentInfo)
            event.preventDefault()
        }
    }
    


    async function getInvoiceData() {
        var id = path.substring(1)
        id = id.substring(id.indexOf('/')+1,id.length)
        console.log("id:", id)
        try {

            const res_data = await axios.get(`${Constants.port}/invoices/${id}/getInvoiceByID`);
            console.log('fetching data getInvoiceData: ', res_data);
            if(!res_data.data.invoice.Vehicle)
                setInvoiceInfo({...res_data.data.invoice, Vehicle:{}})
            else
                setInvoiceInfo(res_data.data.invoice)
            console.log("set data")
            //getJobData(res_data.data.invoice.Workorder.WorkorderNumber)
            //calculatePayments(res_data.data.invoice)
            return res_data
        } catch (error) {
            console.log("No user found for provided id:",id, "\nErrNo: ASJDF81")
            //props.history.push("/invoices")
            props.setError({ show: true, message: "No user found for provided id:"+id+"\nErrNo: ASJDF81" })
            return -1;
        }
    }

    useEffect(()=>{
        console.log("path: ", path, "searching:", searching)
        if(searching){
            props.close()
        /*     info.Totals.DateOpen = Constants.dateFormatter(info.Totals.DateOpen)
            info.Totals.DateClosed = Constants.dateFormatter(info.Totals.DateClosed)
             */
            
            document.getElementById('print-button').click();
        }

        if(path.includes("testprint"))
            getInvoiceData()
        else
            setInvoiceInfo(props.invoiceInfo)
 // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    class ComponentToPrint extends React.Component {
        render() {
            return (
                    <Container className="page" /* style={{ border: "inset" }} */>
                        {/* HEADER */}
                        <Row>
                            <Col style={{ fontSize: "30px", fontWeight: "500" }}>DOMINICK'S AUTO REPAIR</Col>
                            <Col style={{ textAlign: "right" }}>1120 N. BROADWAY</Col>
                        </Row>
                        <Row style={{marginTop:"-10px"}}>
                            <Col>TRINGALI ENTERPRISES LTD.</Col>
                            <Col style={{ textAlign: "right", marginTop:"-10px" }}>N. MASSAPEQUA, NY 11758</Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center", fontWeight: "500" }}>(516)752-9769</Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "center"/* , fontWeight: "700" */ }}>State Reg. # 7036038</Col>
                        </Row>
                        {/* END OF HEADER */}

                        {/* CUSTOMER INFO */}
                        <Container style={{ border: "solid", borderWidth: "thin"/* , fontWeight:"700" */ }}>
                            <Row>
                                
                                <Col> {invoiceInfo.Customer.Lastname}, {invoiceInfo.Customer.Firstname} </Col>
                                <Col style={{ textAlign: "center" }}>{invoiceInfo.Vehicle.Year} {invoiceInfo.Vehicle.Make} {invoiceInfo.Vehicle.Model} {invoiceInfo.Vehicle.EngineSize}</Col>
                                <Col style={{ textAlign: "right" }}>{invoiceInfo.Workorder.WorkorderNumber}</Col>
                            </Row>
                            <Row>
                                <Col>  {Constants.phoneFormatter(invoiceInfo.Customer.Phone1)} </Col>
                                <Col style={{ textAlign: "center" }}>{invoiceInfo.Vehicle.LicenseNumber ? `License: ${invoiceInfo.Vehicle.LicenseNumber}` : ""}</Col>
                                <Col style={{ textAlign: "right" }}>{invoiceInfo.Workorder.Pricing}</Col>
                            </Row>
                            <Row>
                                <Col> {Constants.phoneFormatter(invoiceInfo.Customer.Phone2)} </Col>
                                <Col style={{ textAlign: "center" }}>{invoiceInfo.Vehicle.Mileage ? `Mileage: ${invoiceInfo.Vehicle.Mileage}` : ''}</Col>
                                <Col style={{ textAlign: "right" }}> Opened: {Constants.dateFormatter(invoiceInfo.Totals.DateOpen)}</Col>
                            </Row>
                            <Row>
                                <Col> {invoiceInfo.Customer.Address} </Col>
                                <Col style={{ textAlign: "center" }}> {invoiceInfo.Vehicle.VIN}</Col>

                                <Col style={{ textAlign: "right" }}>Status: {invoiceInfo.Workorder.Status}</Col>
                            </Row>
                            <Row>
                                <Col> {invoiceInfo.Customer.City ? `${invoiceInfo.Customer.City},` : ''} {invoiceInfo.Customer.State} {invoiceInfo.Customer.Zip} </Col>
                                <Col style={{ textAlign: "right" }}>{/* {invoiceInfo.Customer.Salesperson} */}</Col>
                                <Col style={{ textAlign: "right" }}>{invoiceInfo.Totals.DateClosed ? `Closed: ${Constants.dateFormatter(invoiceInfo.Totals.DateClosed)}` : ''}</Col>
                            </Row>
                            
                         
                        </Container>


                        {/* Workorder Contents */}
                        <br/>
                        <div /* style={{fontWeight:"500"}} */>
                            {<CHARGESLIST_PRINT
                                payments={props.payments}
                                waste={invoiceInfo.Waste}
                                sublets={invoiceInfo.Sublets}
                                totals={invoiceInfo.Totals}
                                list={invoiceInfo.Labor}
                                credits={invoiceInfo.Credits}
                                notes={invoiceInfo.Notes}
                                parts={invoiceInfo.Parts}
                            />
                            }
                        </div>
                       <p style={{ fontSize: "20px"}}> Have a nice day! </p>

                        <div style={{position:"absolute",marginTop:"25px", bottom:0/* , fontWeight:"700" */}}>
                            <Container style={{ border: "solid", borderWidth: "thin" }}>
                                <Row>
                                    <Col>Labor</Col>
                                    <Col>Parts</Col>
                                    <Col>Sublet</Col>
                                    <Col>Waste</Col>
                                    <Col>Tax</Col>
                                    <Col>Total</Col>
                                    <Col>Balance</Col>
                                </Row>
                                <Row>
                                    <Col>{invoiceInfo.Totals.LaborSubtotal ? invoiceInfo.Totals.LaborSubtotal.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.PartsSubtotal ? invoiceInfo.Totals.PartsSubtotal.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.SubletSubtotal ? invoiceInfo.Totals.SubletSubtotal.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.WasteSubtotal ? invoiceInfo.Totals.WasteSubtotal.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.WorkorderTax ? invoiceInfo.Totals.WorkorderTax.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.WorkorderTotal ? invoiceInfo.Totals.WorkorderTotal.toFixed(2) : "0.00"}</Col>
                                    <Col>{invoiceInfo.Totals.Balance ? invoiceInfo.Totals.Balance.toFixed(2) : "0.00"}</Col>
                                </Row>
                            </Container>
                            <br/>
                            <p style={{fontSize:"11px", fontWeight:"300"}}>
                                I hereby authorize the above repair work to be done along with necessary materials. You and your employees
                                may operate above vehicle for purposes of testing, inspection, or delivery at my risk. An express mechanics lien
                                is acknowledged on above vehicle to secure the amount of the repairs. Any warranties on the products sold
                                hereby are those made by the manufacturer. The seller hereby expressly disclaims all warranties, either express
                                or implied, including any implied warranty of merchant-ability or fitness for a particular purpose, and neither
                                assumes nor authorises any other person to assume for it any liability in connection with the sale of said products.
                            </p>
                            <Row style={{margin:0, padding:0}}>
                                <Col>
                                    Authorized by :
                                </Col>
                                <Col>
                                    Received by : 
                                </Col>

                            </Row>
                            <br/>
                        </div>
               
               
               
                    </Container>
               


            );
        }
    }
    return (
        <div hidden={searching} >
            <Form onSubmit={props.submit}>
            <ReactToPrint
                style={{fontVariantCaps:"all-small-caps"}}
                documentTitle={invoiceInfo.Workorder.WorkorderNumber}
                trigger={() => 
                    <Button type="submit" id="print-button" style={{ marginLeft: "10px" }}>
                        {searching ? "Print" : "Yes"}
                    </Button>}
                content={() => componentRef.current}
            />
            {searching ? null : 
                <Button type="submit" style={{ marginLeft: "10px" }} variant="secondary" >
                    No
                </Button> 
            }
            <ComponentToPrint   ref={componentRef} />
            </Form>
        </div>
    );


}
