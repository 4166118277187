import React/* , { useState, useEffect } */ from 'react';
import { Alert, Modal } from 'react-bootstrap';


export default function Error(props) {
    const error = props.error
    const show = props.show

    return (
        <Modal show={show} animation={false} onHide={props.close}>
            <div className="spinnerpadding">
                <div className="overlay">
                <Alert variant="danger" onClose={props.close} dismissible>
                    <Alert.Heading>{error}</Alert.Heading>
                </Alert>
                </div>
            </div>
        </Modal>

        
    );
}