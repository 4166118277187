import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants.js';
import { MDBIcon } from 'mdbreact'

export default function SplitPaymentModal(props) {
    const { show, payments, totals, workorder } = props
    const [newPayments, setNewPayments] = useState([...payments])
    var today = new Date()
    const [paymentInfo, setPaymentInfo] = useState({
        list: [{
            Value: 0,
            Amount: 0,
            Type: "",
            Date: today
        }],
        sum: 0
    })
    const [count, setCount] = useState(0)
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            workorder.Status === "PAID" ? props.updatePayments(newPayments) : props.submitSplitPayment(paymentInfo, newPayments)
            event.preventDefault()
        }
    }

    function addSplitPaymentOption() {
        let cpy = [...paymentInfo.list]
        cpy.push({ Amount: null, type: "", Date: today })
        setPaymentInfo({ ...paymentInfo, list: cpy })
    }

    function updateAmount(e, item, key) {
        let sum = 0
        item.Amount = parseFloat(e.target.value)

        paymentInfo.list.map((item) =>
            sum += item.Amount ? parseFloat(item.Amount) : 0
        )
        setPaymentInfo({ ...paymentInfo, sum: sum })
    }

    function getMethod(key) {
        //console.log(payments[key]);
        const currentOption = Constants.paymentMethods.find(option => option.label === payments[key].Type);
        return currentOption

    }

    function updatePaymentType(key, e) {
        newPayments[key].Type = e.label
        setCount(count + 1)
    }

    function updatePaymentAmount(e, key) {
        newPayments[key].Amount = parseFloat(e.target.value)
        setCount(count + 1)
    }

    function confirmDelete(key) {
        if (window.confirm(`Are you sure you want to delete this payment?\n\nAmount: ${newPayments[key].Amount}\nType: ${newPayments[key].Type}\nDate: ${Constants.dateFormatter(newPayments[key].Date)}`)) {
            props.deletePayment(newPayments[key]._id)
        }
    }

    useEffect(() => {
        if (workorder.Status !== "PAID")
            inputRef.current.focus();
        if (false)
            setNewPayments([])
        console.log("new payments...", newPayments)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal_canned">
            <Modal.Header closeButton>
                <Modal.Title>{workorder.Status === "PAID" ? "Payments" : "Split Payment Amounts"}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "450px", overflowY: "scroll" }}>
                <Form>
                    { payments.map((item, key) =>
                        <Row key={key}>
                            <Col>
                                <Form.Label>Method:</Form.Label>
                                <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999, zoom:"80%" }) }}
                                    ref={inputRef}

                                    value={(getMethod(key))}
                                    onChange={(e) => updatePaymentType(key, e)}
                                    options={Constants.paymentMethods}
                                />
                                {/* <div className="control_item" > {item.Type} </div> */}
                            </Col>
                            <Col>
                                <Form.Label>Amount:</Form.Label>
                                <Form.Control style={{ textAlign: "right" }}
                                    defaultValue={item.Amount ? item.Amount.toFixed(2) : 0.00.toFixed(2)}
                                    onChange={(e) => updatePaymentAmount(e, key)}
                                ></Form.Control>
                                {/* <div className="control_item" style={{ textAlign: "right" }} > {item.Amount.toFixed(2)} </div> */}
                            </Col>
                            <Col>
                                <Form.Label>Date:</Form.Label>
                                <Form.Control disabled style={{ textAlign: "right" }} defaultValue={Constants.dateFormatter(item.Date)}/>
                            </Col>

                            <Col xs={2} style={{ margin: "auto", top: "16px" }}>
                                <Button variant="danger" onClick={() => confirmDelete(key)}><MDBIcon icon="trash" /></Button>
                            </Col>

                        </Row>
                    )}
                       
                    
                    {workorder.Status === "PAID" ? <Row style={{ margin: "25px" }}> </Row> :
                        <>
                        {paymentInfo.list.map((item, key) =>
                            <Row key={key}>
                                <Col>
                                    <Form.Label>Method:</Form.Label>
                                    <Select
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999, zoom:"80%" }) }}
                                        ref={inputRef}
                                        onChange={e => (item.Type = e.label)}
                                        options={Constants.paymentMethods}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Amount:</Form.Label>
                                    <Form.Control style={{ textAlign: "right" }}
                                        onChange={(e) => updateAmount(e, item, key)}
                                    ></Form.Control>
                                </Col>
                                <Col>
                                    <Form.Label>Date:</Form.Label>
                                    <Form.Control disabled style={{ textAlign: "right" }}
                                        /* onChange={(e) => setPaymentInfo({ ...paymentInfo, date: e.target.value })}  */
                                        defaultValue={Constants.dateFormatter(item.Date)} >
                                    </Form.Control>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col></Col>
                            <Col style={{ textAlign: "center" }}>
                                <MDBIcon onClick={() => addSplitPaymentOption()} style={{ marginTop: "20px", fontSize: "50px" }} icon="plus-square" />
                            </Col>
                            <Col></Col>
                        </Row>
                        </>

                    }

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Form inline>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label >Total:  </Form.Label>
                                <Form.Control style={{ textAlign: "right", width: "100%" }} readOnly defaultValue={totals.WorkorderTotal ? totals.WorkorderTotal.toFixed(2) : 0.00.toFixed(2)} ></Form.Control>
                                {workorder.Status === "PAID" ? null :
                                    <React.Fragment><Form.Label >Splits Total:  </Form.Label>
                                        <Form.Control style={{ textAlign: "right", width: "100%" }} readOnly
                                            value={
                                                ((totals.WorkorderTotal - totals.Balance) + paymentInfo.sum) ?
                                                    ((totals.WorkorderTotal - totals.Balance) + paymentInfo.sum).toFixed(2)
                                                    : 0.00.toFixed(2)}  ></Form.Control></React.Fragment>
                                }
                            </Col>
                            <Col>
                                <Form.Label >Remaining:  </Form.Label>
                                <Form.Control style={{ textAlign: "right", width: "100%" }} readOnly value={totals.Balance && Constants.round_and_format(totals.Balance - paymentInfo.sum) } ></Form.Control>
                            </Col>
                        </Row>


                    </Col>
                    <Button style={{ marginLeft: "10px" }} variant="primary" /* onClick={()=>console.log(paymentInfo)} */
                        onClick={workorder.Status === "PAID" ? props.updatePayments.bind(this, newPayments) : props.submitSplitPayment.bind(this, paymentInfo, newPayments)}>

                        Submit
                </Button>

                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                </Button>

                </Form>

            </Modal.Footer>
        </Modal>
    )

}