import React, { useState, useEffect , useRef } from 'react';
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import * as Constants from '../../../constants/constants'


export default function NewCustomerModal(props) {
  const show = props.show;
  const customerInfo = props.info
  const customer = props.customer ? props.customer : {
    Lastname: customerInfo.Lastname ? customerInfo.Lastname : "" ,
    Firstname: customerInfo.Firstname ? customerInfo.Firstname : ""
  }

  const status = props.invoice_status
  props.useEventListener('keydown', myKeyPress);
  const inputRef = useRef();

  function myKeyPress(event) {
      if (event.key === "F10") {
        if(status === "update")
          updateCustomer()
        else
          addCustomer()
          event.preventDefault()
      }
  }
  const [custInfo, setCustInfo] = useState(customer)
  const [error, setError] = useState({ show: false, message: "" })

  async function addCustomer() {
    if (custInfo.Lastname === "") {
      setError({ show: true, message: "Enter a last name before creating a new customer." })
      return;
    }

    try {
      const res_data = await axios.post(`${Constants.port}/customer/add`, custInfo);
      if (status === "existing") {
        console.log("addCustomer():NewCustomerModal: existing invoice")
        props.loadCustomerInfo(res_data.data.customer, null)
        return;
      }
      else if(status === "new"){
        
      }
      console.log("createWO");
      props.createWO(res_data.data.customer, null)

      //props.props.history.push(`/customers/${res_data.data.customerid}`)
    } catch (error) {
      console.log("ERROR CREATING NEW CUSTOMER, AHDS71ZZa")
      if(error.response)
        setError({show: true, message: error.response.data.error})

    }
  }

  async function updateCustomer() {
    console.log("updateCustomer():NewCustomerModal: existing invoice", custInfo)

    try {
      const res_data = await axios.post(`${Constants.port}/customer/${custInfo.CustomerNumber}/update`, custInfo);
      
      console.log("updateCustomer():NewCustomerModal: existing invoice", res_data)
      props.loadCustomerInfo(res_data.data.customer, null)
       


      //props.props.history.push(`/customers/${res_data.data.customerid}`)
    } catch (error) {
      console.log("ERROR CREATING NEW CUSTOMER, asd1ssz")
      if(error.response)
        setError({show:true,message:error.response.data.error})

    }
  }

  useEffect(()=>{
    document.querySelectorAll("input").forEach(b=>b.setAttribute('type','search'))
    inputRef.current.focus();
  },[])

  return (
    <Modal
      size="lg"
      show={show}
      onHide={props.close}
      centered
      className="invoice_item_modal">

      <Modal.Header >
        <Modal.Title>{status === "update" ? "Update Customer" : "Create New Customer"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert key="no-lastname-alert" variant='danger' show={error.show} >
          {error.message}
        </Alert>
        <Form>
          <Form.Row>
            <Form.Group key="mykey1" as={Col} >
              <Form.Label>Last Name</Form.Label>
              <Form.Control ref={inputRef} key="2" defaultValue={custInfo.Lastname} onChange={(e) => setCustInfo({ ...custInfo, Lastname: e.target.value })} type="lastName" />
            </Form.Group>

            <Form.Group key="mykey2" as={Col} >
              <Form.Label>First Name</Form.Label>
              <Form.Control defaultValue={custInfo.Firstname} onChange={(e) => setCustInfo({ ...custInfo, Firstname: e.target.value })} type="firstName" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} >
              <Form.Label>Cell</Form.Label>
              <Form.Control type="cellNumber" defaultValue={custInfo.Phone1}
                onChange={(e) => setCustInfo({ ...custInfo, Phone1: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>Home</Form.Label>
              <Form.Control defaultValue={custInfo.Phone2}
                onChange={(e) => setCustInfo({ ...custInfo, Phone2: e.target.value })}
                type="houseNumber" />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>Work</Form.Label>
              <Form.Control defaultValue={custInfo.Phone3}
                onChange={(e) => setCustInfo({ ...custInfo, Phone3: e.target.value })}
                type="workNumber" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} >
              <Form.Label>Address</Form.Label>
              <Form.Control defaultValue={custInfo.Address}
                onChange={(e) => setCustInfo({ ...custInfo, Address: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>City</Form.Label>
              <Form.Control defaultValue={custInfo.City}
                onChange={(e) => setCustInfo({ ...custInfo, City: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} xs={2} >
              <Form.Label>State</Form.Label>
              <Form.Control defaultValue={custInfo.State}
                onChange={(e) => setCustInfo({ ...custInfo, State: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col}  xs={2}>
              <Form.Label>Zip</Form.Label>
              <Form.Control defaultValue={custInfo.Zip}
                onChange={(e) => setCustInfo({ ...custInfo, Zip: e.target.value })} />
            </Form.Group>
          </Form.Row>
        </Form>
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>Cancel</Button>
        {status === "update" ?
          <Button variant="primary" onClick={() => updateCustomer()}>Update Customer</Button>
          :
          <Button variant="primary" onClick={() => addCustomer()}>Create Customer</Button>}
      </Modal.Footer>


    </Modal>
  )

}