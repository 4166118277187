import React, { useEffect, useState } from 'react';
import { Form, Col, Button, Modal, Row } from 'react-bootstrap';
import * as Constants from '../../../constants/constants'


export default function CheckoutModal(props) {
    const { show, checkoutAmounts, _id, date, from, total } = props;
    const [balance, setBalance] = useState(0)

    function addSlipOption() {
        let cpy = checkoutAmounts.slips
        cpy.push({ Amount: null, Description: "", id: Date.now() })
        props.updateCheckoutAmounts({ ...checkoutAmounts, slips: cpy })
    }

    function updateAmount(e, item, key) {
        const updatedSlips = checkoutAmounts.slips.map((slip) => {
            if (slip.id === item.id) {
                return { ...slip, Amount: parseFloat(e.target.value) || 0 };
            }
            return slip;
        });
        
        props.updateCheckoutAmounts({ ...checkoutAmounts, slips: updatedSlips });
    }

    function calculateSumSlips(){
        let r = checkoutAmounts.slips.reduce( function(a, b){
            return a + (parseFloat(b['Amount']) || 0);
        }, 0);

        return r
    }

    function updateDescription(e, item, key) {
        item.Description = e.target.value
    }

    function confirmDelete() {
        if (window.confirm(`Are you sure you want to delete this checkout?\n\nDate: ${Constants.dateFormatter(date)}`)) {
            props.removeCheckout(_id)
        }
    }

    function calculateBalance() {
        let sumSlips = calculateSumSlips()
        const box_difference = (checkoutAmounts.boxStart - checkoutAmounts.boxEnd) || 0
        const total_charges = checkoutAmounts.cash + checkoutAmounts.card + checkoutAmounts.check - sumSlips - box_difference
        setBalance( Constants.round_and_format(total - total_charges) )
    }


    useEffect(()=>{
        calculateBalance()
        // eslint-disable-next-line 
    },[checkoutAmounts])

    return (
        <Modal show={show} size="lg" onHide={props.close} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title>Calculate Checkout</Modal.Title>
            </Modal.Header>

            {/* {!loading ? null : <Loading />} */}

            <Modal.Body style={{ maxHeight: "450px", overflowY: "scroll" }}>
                <Form>
                <Form.Group as={Row} >
                        <Form.Label column sm="3">
                            Box Start
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control value={checkoutAmounts.boxStart}
                                type='number' disabled={from !== 'report'}
                                onChange={(e) => props.updateCheckoutAmounts({ ...checkoutAmounts, boxStart: parseFloat(e.target.value) || 0 })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="3">
                            Box End
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control type='number' disabled={from !== 'report'} value={checkoutAmounts.boxEnd}
                                onChange={(e) => props.updateCheckoutAmounts({ ...checkoutAmounts, boxEnd: parseFloat(e.target.value) || 0 })} />
                        </Col>
                    </Form.Group>

                    <hr />

                    <Form.Group as={Row} >
                        <Form.Label column sm="3">
                            Cash
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control type='number' disabled={from !== 'report'} value={checkoutAmounts.cash} onChange={(e) => props.updateCheckoutAmounts({ ...checkoutAmounts, cash: parseFloat(e.target.value) || 0 })} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} >
                        <Form.Label column sm="3">
                            Card
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control type='number' disabled={from !== 'report'} value={checkoutAmounts.card}
                                onChange={(e) => props.updateCheckoutAmounts({ ...checkoutAmounts, card: parseFloat(e.target.value) || 0 })} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} >
                        <Form.Label column sm="3">
                            Check
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control type='number' disabled={from !== 'report'} value={checkoutAmounts.check} 
                                onChange={(e) => props.updateCheckoutAmounts({ ...checkoutAmounts, check: parseFloat(e.target.value) || 0 })} />
                        </Col>
                    </Form.Group>

                 
                    { checkoutAmounts.slips ? checkoutAmounts.slips.map((item, key) =>
                <Form.Group as={Row} key={key}>
                    <Form.Label column sm="2">
                        Amount
                    </Form.Label>
                    <Col sm="3">
                        <Form.Control type='number' disabled={from !== 'report'}
                            defaultValue={item.Amount}
                            onChange={(e) => updateAmount(e, item, key)}
                        />
                    </Col>
                    <Form.Label column sm="3">
                        Description
                    </Form.Label>
                    <Col sm="4">
                        <Form.Control disabled={from !== 'report'} 
                        defaultValue={item.Description}
                        onChange={(e) => updateDescription(e, item, key)} 
                        />
                    </Col>
                </Form.Group>
            )
                : null
            }
                   

                    <Form.Group as={Row} >
                        <Col style={{ textAlign: "center" }}>
                            <Button disabled={from === 'admin'} onClick={() => addSlipOption()} variant="primary"> Add Slip </Button>
                        </Col>
                    </Form.Group>

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Form.Group as={Row} style={{ width: "100%" }} >
                    <Form.Label style={{ paddingLeft: "0px" }} column sm="2">
                        Remaining
                        </Form.Label>
                    <Col sm="4">
                        
                        <Form.Control type='number' disabled style={balance === "0.00" ? {backgroundColor:"#90EE90"} : {backgroundColor:"#FF7F7F"}} readOnly value={balance} />
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        {from === 'report' ?
                        <Button variant="primary"  onClick={props.submit.bind(this, checkoutAmounts)}  > Submit </Button>
                        : 
                        <Button variant="danger" onClick={()=>confirmDelete()}  > Remove Checkout</Button>
                        }
                    </Col>
                </Form.Group>
            </Modal.Footer>

        </Modal>
    )
}