import { textFilter } from 'react-bootstrap-table2-filter';
export const MAX_SAFE_INTEGER = 9999999
export const port = process.env.REACT_APP_SERVER_BASE_URL;

export const customerColumns = [{
      dataField: "CustomerName",
      text: 'Customer',
      /* filter: textFilter() */
      }, {
          dataField: 'licenses',
          text: 'Licese',
          /* filter: textFilter() */
      },
      {
          dataField: 'CustomerNumber',
          text: 'Customer Number'
      }
  ]

  export const shop_information = {
    Title: "Dominicks Auto Repair",
    Company: String,
    Registration: Number,
    Address: String, 
    State: String, 
    Zip: Number, 
    Phone: String,
    Fax: String,
    LaborRate:125.00,
  }

export const years = [{
    label: "1940",
    value: 0
    }, {
      label: "1941",
      value: 1
    }, {
      label: "1942",
      value: 2
    }, {
      label: "1943",
      value: 3
    }, {
      label: "1944",
      value: 4
    }, {
      label: "1945",
      value: 5
    }, {
      label: "1946",
      value: 6
    }, {
      label: "1947",
      value: 7
    }, {
      label: "1948",
      value: 8
    }, {
      label: "1949",
      value: 9
    }, {
      label: "1950",
      value: 10
    }, {
      label: "1951",
      value: 11
    }, {
      label: "1952",
      value: 12
    }, {
      label: "1953",
      value: 13
    }, {
      label: "1954",
      value: 14
    }, {
      label: "1955",
      value: 15
    }, {
      label: "1956",
      value: 16
    }, {
      label: "1957",
      value: 17
    }, {
      label: "1958",
      value: 18
    }, {
      label: "1959",
      value: 19
    }, {
      label: "1960",
      value: 20
    }, {
      label: "1961",
      value: 21
    }, {
      label: "1962",
      value: 22
    }, {
      label: "1963",
      value: 23
    }, {
      label: "1964",
      value: 24
    }, {
      label: "1965",
      value: 25
    }, {
      label: "1966",
      value: 26
    }, {
      label: "1967",
      value: 27
    }, {
      label: "1968",
      value: 28
    }, {
      label: "1969",
      value: 29
    }, {
      label: "1970",
      value: 30
    }, {
      label: "1971",
      value: 31
    }, {
      label: "1972",
      value: 32
    }, {
      label: "1973",
      value: 33
    }, {
      label: "1974",
      value: 34
    }, {
      label: "1975",
      value: 35
    }, {
      label: "1976",
      value: 36
    }, {
      label: "1977",
      value: 37
    }, {
      label: "1978",
      value: 38
    }, {
      label: "1979",
      value: 39
    }, {
      label: "1980",
      value: 40
    }, {
      label: "1981",
      value: 41
    }, {
      label: "1982",
      value: 42
    }, {
      label: "1983",
      value: 43
    }, {
      label: "1984",
      value: 44
    }, {
      label: "1985",
      value: 45
    }, {
      label: "1986",
      value: 46
    }, {
      label: "1987",
      value: 47
    }, {
      label: "1988",
      value: 48
    }, {
      label: "1989",
      value: 49
    }, {
      label: "1990",
      value: 50
    }, {
      label: "1991",
      value: 51
    }, {
      label: "1992",
      value: 52
    }, {
      label: "1993",
      value: 53
    }, {
      label: "1994",
      value: 54
    }, {
      label: "1995",
      value: 55
    }, {
      label: "1996",
      value: 56
    }, {
      label: "1997",
      value: 57
    }, {
      label: "1998",
      value: 58
    }, {
      label: "1999",
      value: 59
    }, {
      label: "2000",
      value: 60
    }, {
      label: "2001",
      value: 61
    }, {
      label: "2002",
      value: 62
    }, {
      label: "2003",
      value: 63
    }, {
      label: "2004",
      value: 64
    }, {
      label: "2005",
      value: 65
    }, {
      label: "2006",
      value: 66
    }, {
      label: "2007",
      value: 67
    }, {
      label: "2008",
      value: 68
    }, {
      label: "2009",
      value: 69
    }, {
      label: "2010",
      value: 70
    }, {
      label: "2011",
      value: 71
    }, {
      label: "2012",
      value: 72
    }, {
      label: "2013",
      value: 73
    }, {
      label: "2014",
      value: 74
    }, {
      label: "2015",
      value: 75
    }, {
      label: "2016",
      value: 76
    }, {
      label: "2017",
      value: 77
    }, {
      label: "2018",
      value: 78
    }, {
      label: "2019",
      value: 79
    }, {
      label: "2020",
      value: 80
    }, {
      label: "2021",
      value: 81
    },
     {
      label: "2022",
      value: 82
    },
    {
      label: "2023",
      value: 83
    },
    {
      label: "2024",
      value: 84
    }
]

export const makeList = [
        {
          label: "ABARTH",
          models: [{
              label: "1000",
              value: 0
            }, {
              label: "1000 BIALBERO",
              value: 1
            }, {
              label: "1000 GT",
              value: 2
            }, {
              label: "1000 TC CORSA",
              value: 3
            }, {
              label: "103 GT",
              value: 4
            }, {
              label: "124",
              value: 5
            }, {
              label: "1300",
              value: 6
            }, {
              label: "1500",
              value: 7
            }, {
              label: "1600",
              value: 8
            }, {
              label: "2000",
              value: 9
            }, {
              label: "205",
              value: 10
            }, {
              label: "207",
              value: 11
            }, {
              label: "208",
              value: 12
            }, {
              label: "209",
              value: 13
            }, {
              label: "210",
              value: 14
            }, {
              label: "2200",
              value: 15
            }, {
              label: "2400",
              value: 16
            }, {
              label: "500",
              value: 17
            }, {
              label: "595",
              value: 18
            }, {
              label: "600",
              value: 19
            }, {
              label: "700",
              value: 20
            }, {
              label: "750",
              value: 21
            }, {
              label: "800 SCORPIONE COUPE ALLEMANO",
              value: 22
            }, {
              label: "850",
              value: 23
            }, {
              label: "A 112",
              value: 24
            }, {
              label: "BIALBERO",
              value: 25
            }, {
              label: "COUPE",
              value: 26
            }, {
              label: "GRANDE PUNTO",
              value: 27
            }, {
              label: "LANCIA 037",
              value: 28
            }, {
              label: "MONO 1000",
              value: 29
            }, {
              label: "MONOMILLE",
              value: 30
            }, {
              label: "MONOTIPO",
              value: 31
            }, {
              label: "OT",
              value: 32
            }, {
              label: "RENAULT",
              value: 33
            }, {
              label: "SIMCA",
              value: 34
            }, {
              label: "SPIDER RIVIERA",
              value: 35
            }, {
              label: "STOLA",
              value: 36
            }],
          value: 0
        }, {
          label: "ALFA ROMEO",
          models: [{
              label: "145",
              value: 0
            }, {
              label: "146",
              value: 1
            }, {
              label: "147",
              value: 2
            }, {
              label: "155",
              value: 3
            }, {
              label: "156",
              value: 4
            }, {
              label: "159",
              value: 5
            }, {
              label: "164",
              value: 6
            }, {
              label: "166",
              value: 7
            }, {
              label: "175",
              value: 8
            }, {
              label: "1750",
              value: 9
            }, {
              label: "179",
              value: 10
            }, {
              label: "1900",
              value: 11
            }, {
              label: "2000",
              value: 12
            }, {
              label: "2600",
              value: 13
            }, {
              label: "33",
              value: 14
            }, {
              label: "33 RACE",
              value: 15
            }, {
              label: "33 STRADALE",
              value: 16
            }, {
              label: "33 TT",
              value: 17
            }, {
              label: "6C",
              value: 18
            }, {
              label: "75",
              value: 19
            }, {
              label: "8C COMPETIZIONE",
              value: 20
            }, {
              label: "90",
              value: 21
            }, {
              label: "ALFA 6",
              value: 22
            }, {
              label: "ALFASUD",
              value: 23
            }, {
              label: "ALFETTA",
              value: 24
            }, {
              label: "AR 51",
              value: 25
            }, {
              label: "ARNA",
              value: 26
            }, {
              label: "BELLA",
              value: 27
            }, {
              label: "BERLINA",
              value: 28
            }, {
              label: "BRERA",
              value: 29
            }, {
              label: "CAIMANO",
              value: 30
            }, {
              label: "CARABO",
              value: 31
            }, {
              label: "CENTAURI",
              value: 32
            }, {
              label: "COUPE",
              value: 33
            }, {
              label: "CROSSWAGON",
              value: 34
            }, {
              label: "CUNEO",
              value: 35
            }, {
              label: "DARDO",
              value: 36
            }, {
              label: "DISCO VOLANTE",
              value: 37
            }, {
              label: "EAGLE",
              value: 38
            }, {
              label: "GIULIA",
              value: 39
            }, {
              label: "GIULIETTA",
              value: 40
            }, {
              label: "GP 158",
              value: 41
            }, {
              label: "GP 159",
              value: 42
            }, {
              label: "GRADUATE",
              value: 43
            }, {
              label: "GT",
              value: 44
            }, {
              label: "GTA",
              value: 45
            }, {
              label: "GTV",
              value: 46
            }, {
              label: "GTV6",
              value: 47
            }, {
              label: "JUNIOR",
              value: 48
            }, {
              label: "KAMAL",
              value: 49
            }, {
              label: "MITO",
              value: 50
            }, {
              label: "NAVAJO",
              value: 51
            }, {
              label: "NUVOLA",
              value: 52
            }, {
              label: "PROTEO",
              value: 53
            }, {
              label: "RZ",
              value: 54
            }, {
              label: "SCARABEO",
              value: 55
            }, {
              label: "SCIGHERA",
              value: 56
            }, {
              label: "SPIDER",
              value: 57
            }, {
              label: "SPORTIVA",
              value: 58
            }, {
              label: "SPORTUT",
              value: 59
            }, {
              label: "SPORTWAGON",
              value: 60
            }, {
              label: "SPRINT",
              value: 61
            }, {
              label: "SZ",
              value: 62
            }],
          value: 1
        }, {
          label: "ASTON MARTIN",
          models: [],
          value: 2
        }, {
          label: "AUDI",
          models: [{
              label: "100",
              value: 0
            }, {
              label: "200",
              value: 1
            }, {
              label: "4000",
              value: 2
            }, {
              label: "50",
              value: 3
            }, {
              label: "5000",
              value: 4
            }, {
              label: "80",
              value: 5
            }, {
              label: "90",
              value: 6
            }, {
              label: "A1",
              value: 7
            }, {
              label: "A2",
              value: 8
            }, {
              label: "A3",
              value: 9
            }, {
              label: "A3 E-TRON",
              value: 10
            }, {
              label: "A4",
              value: 11
            }, {
              label: "A5",
              value: 12
            }, {
              label: "A6",
              value: 13
            }, {
              label: "A7",
              value: 14
            }, {
              label: "A8",
              value: 15
            }, {
              label: "AD",
              value: 16
            }, {
              label: "AL2",
              value: 17
            }, {
              label: "ALLROAD",
              value: 18
            }, {
              label: "ASSO",
              value: 19
            }, {
              label: "AVANTISSIMO",
              value: 20
            }, {
              label: "AVUS",
              value: 21
            }, {
              label: "COUPE",
              value: 22
            }, {
              label: "FOX",
              value: 23
            }, {
              label: "LEMANS",
              value: 24
            }, {
              label: "NUVOLARI QUATTRO",
              value: 25
            }, {
              label: "PIKES PEAK QUATTRO",
              value: 26
            }, {
              label: "Q3",
              value: 27
            }, {
              label: "Q5",
              value: 28
            }, {
              label: "Q7",
              value: 29
            }, {
              label: "QUATTRO",
              value: 30
            }, {
              label: "R8",
              value: 31
            }, {
              label: "R8R",
              value: 32
            }, {
              label: "ROSEMEYER",
              value: 33
            }, {
              label: "RS 5",
              value: 34
            }, {
              label: "RS 7",
              value: 35
            }, {
              label: "RS2",
              value: 36
            }, {
              label: "RS3",
              value: 37
            }, {
              label: "RS4",
              value: 38
            }, {
              label: "RS5",
              value: 39
            }, {
              label: "RS6",
              value: 40
            }, {
              label: "S2",
              value: 41
            }, {
              label: "S3",
              value: 42
            }, {
              label: "S4",
              value: 43
            }, {
              label: "S5",
              value: 44
            }, {
              label: "S6",
              value: 45
            }, {
              label: "S7",
              value: 46
            }, {
              label: "S8",
              value: 47
            }, {
              label: "SPORT",
              value: 48
            }, {
              label: "SQ5",
              value: 49
            }, {
              label: "STEPPENWOLF",
              value: 50
            }, {
              label: "SUPER 90",
              value: 51
            }, {
              label: "TT",
              value: 52
            }, {
              label: "TTS",
              value: 53
            }, {
              label: "UR",
              value: 54
            }, {
              label: "V8",
              value: 55
            }, {
              label: "VARIANT",
              value: 56
            }],
          value: 3
        }, {
          label: "BENTLEY",
          models: [],
          value: 4
        }, {
          label: "BMW",
          models: [{
              label: "1 SERIES",
              value: 0
            }, {
              label: "116",
              value: 1
            }, {
              label: "118",
              value: 2
            }, {
              label: "120",
              value: 3
            }, {
              label: "123",
              value: 4
            }, {
              label: "125",
              value: 5
            }, {
              label: "128",
              value: 6
            }, {
              label: "130",
              value: 7
            }, {
              label: "135",
              value: 8
            }, {
              label: "1500",
              value: 9
            }, {
              label: "1502",
              value: 10
            }, {
              label: "1600",
              value: 11
            }, {
              label: "1602",
              value: 12
            }, {
              label: "1800",
              value: 13
            }, {
              label: "2 SERIES",
              value: 14
            }, {
              label: "2.8",
              value: 15
            }, {
              label: "2000",
              value: 16
            }, {
              label: "2002",
              value: 17
            }, {
              label: "2004",
              value: 18
            }, {
              label: "2800",
              value: 19
            }, {
              label: "3",
              value: 20
            }, {
              label: "3 SERIES",
              value: 21
            }, {
              label: "3 SERIES GRAN TURISMO",
              value: 22
            }, {
              label: "3.3",
              value: 23
            }, {
              label: "315",
              value: 24
            }, {
              label: "316",
              value: 25
            }, {
              label: "317",
              value: 26
            }, {
              label: "318",
              value: 27
            }, {
              label: "320",
              value: 28
            }, {
              label: "3200 CS",
              value: 29
            }, {
              label: "323",
              value: 30
            }, {
              label: "324",
              value: 31
            }, {
              label: "325",
              value: 32
            }, {
              label: "328",
              value: 33
            }, {
              label: "330",
              value: 34
            }, {
              label: "332",
              value: 35
            }, {
              label: "333",
              value: 36
            }, {
              label: "335",
              value: 37
            }, {
              label: "340",
              value: 38
            }, {
              label: "4 SERIES",
              value: 39
            }, {
              label: "4 SERIES GRAN COUPE",
              value: 40
            }, {
              label: "5 SERIES",
              value: 41
            }, {
              label: "5 SERIES GRAN TURISMO",
              value: 42
            }, {
              label: "5.8",
              value: 43
            }, {
              label: "501",
              value: 44
            }, {
              label: "502",
              value: 45
            }, {
              label: "503",
              value: 46
            }, {
              label: "507",
              value: 47
            }, {
              label: "518",
              value: 48
            }, {
              label: "520",
              value: 49
            }, {
              label: "523",
              value: 50
            }, {
              label: "524",
              value: 51
            }, {
              label: "525",
              value: 52
            }, {
              label: "528",
              value: 53
            }, {
              label: "529",
              value: 54
            }, {
              label: "530",
              value: 55
            }, {
              label: "535",
              value: 56
            }, {
              label: "538",
              value: 57
            }, {
              label: "540",
              value: 58
            }, {
              label: "545",
              value: 59
            }, {
              label: "550",
              value: 60
            }, {
              label: "6 SERIES",
              value: 61
            }, {
              label: "6 SERIES GRAN COUPE",
              value: 62
            }, {
              label: "600",
              value: 63
            }, {
              label: "628",
              value: 64
            }, {
              label: "630",
              value: 65
            }, {
              label: "633",
              value: 66
            }, {
              label: "635",
              value: 67
            }, {
              label: "640",
              value: 68
            }, {
              label: "645",
              value: 69
            }, {
              label: "650",
              value: 70
            }, {
              label: "7 SERIES",
              value: 71
            }, {
              label: "700",
              value: 72
            }, {
              label: "725",
              value: 73
            }, {
              label: "728",
              value: 74
            }, {
              label: "729",
              value: 75
            }, {
              label: "730",
              value: 76
            }, {
              label: "732",
              value: 77
            }, {
              label: "735",
              value: 78
            }, {
              label: "740",
              value: 79
            }, {
              label: "745",
              value: 80
            }, {
              label: "748",
              value: 81
            }, {
              label: "750",
              value: 82
            }, {
              label: "760",
              value: 83
            }, {
              label: "840",
              value: 84
            }, {
              label: "845",
              value: 85
            }, {
              label: "850",
              value: 86
            }, {
              label: "854",
              value: 87
            }, {
              label: "856",
              value: 88
            }, {
              label: "ACTIVEHYBRID 3",
              value: 89
            }, {
              label: "ACTIVEHYBRID 5",
              value: 90
            }, {
              label: "ACTIVEHYBRID 7",
              value: 91
            }, {
              label: "ALPINA",
              value: 92
            }, {
              label: "ALPINA B6 GRAN COUPE",
              value: 93
            }, {
              label: "ALPINA B7",
              value: 94
            }, {
              label: "B7",
              value: 95
            }, {
              label: "BREYTON",
              value: 96
            }, {
              label: "CLS",
              value: 97
            }, {
              label: "COMPACT",
              value: 98
            }, {
              label: "CONVERTIBLE",
              value: 99
            }, {
              label: "COUPE",
              value: 100
            }, {
              label: "DINAN",
              value: 101
            }, {
              label: "FORMULA FB02",
              value: 102
            }, {
              label: "FORMULA ONE",
              value: 103
            }, {
              label: "FW 27",
              value: 104
            }, {
              label: "ISETTA",
              value: 105
            }, {
              label: "JUST 4",
              value: 106
            }, {
              label: "KARMANN ASSO DI QUADRI",
              value: 107
            }, {
              label: "L7",
              value: 108
            }, {
              label: "LMR",
              value: 109
            }, {
              label: "M",
              value: 110
            }, {
              label: "M1",
              value: 111
            }, {
              label: "M12",
              value: 112
            }, {
              label: "M28I",
              value: 113
            }, {
              label: "M3",
              value: 114
            }, {
              label: "M4",
              value: 115
            }, {
              label: "M5",
              value: 116
            }, {
              label: "M6",
              value: 117
            }, {
              label: "M6 GRAN COUPE",
              value: 118
            }, {
              label: "MINI ACV 30",
              value: 119
            }, {
              label: "MM ROADSTER",
              value: 120
            }, {
              label: "PICKSTER",
              value: 121
            }, {
              label: "S3",
              value: 122
            }, {
              label: "TOURING",
              value: 123
            }, {
              label: "TURBO",
              value: 124
            }, {
              label: "V12",
              value: 125
            }, {
              label: "VERITAS RENNSPORTWAGEN",
              value: 126
            }, {
              label: "X ACTIVITY",
              value: 127
            }, {
              label: "X COUPE",
              value: 128
            }, {
              label: "X1",
              value: 129
            }, {
              label: "X3",
              value: 130
            }, {
              label: "X4",
              value: 131
            }, {
              label: "X5",
              value: 132
            }, {
              label: "X5 M",
              value: 133
            }, {
              label: "X6",
              value: 134
            }, {
              label: "X6 M",
              value: 135
            }, {
              label: "Z1",
              value: 136
            }, {
              label: "Z18",
              value: 137
            }, {
              label: "Z22",
              value: 138
            }, {
              label: "Z3",
              value: 139
            }, {
              label: "Z4",
              value: 140
            }, {
              label: "Z8",
              value: 141
            }, {
              label: "Z9",
              value: 142
            }, {
              label: "Z9 GRAN TURISMO CONVERTIBLE",
              value: 143
            }],
          value: 5
        }, {
          label: "BUGATTI",
          models: [],
          value: 6
        }, {
          label: "CADILLAC",
          models: [
        {
          "label": "ATS",
          "value": 0
        },
        {
          "label": "CTS",
          "value": 1
        },
        {
          "label": "ELR",
          "value": 2
        },
        {
          "label": "XTS",
          "value": 3
        },
        {
          "label": "ESCALADE",
          "value": 4
        },
        {
          "label": "SRX",
          "value": 5
        },
        {
          "label": "STS",
          "value": 6
        },
        {
          "label": "DTS",
          "value": 7
        },
        {
          "label": "XLR",
          "value": 8
        },
        {
          "label": "LIMOUSINE",
          "value": 9
        },
        {
          "label": "HEARSE",
          "value": 10
        },
        {
          "label": "ESCALADE ESV",
          "value": 11
        },
        {
          "label": "DEVILLE",
          "value": 12
        },
        {
          "label": "ELDORADO",
          "value": 13
        },
        {
          "label": "CATERA",
          "value": 14
        },
        {
          "label": "SEVILLE",
          "value": 15
        },
        {
          "label": "COMMERCIAL CHASSIS",
          "value": 16
        },
        {
          "label": "FLEETWOOD",
          "value": 17
        },
        {
          "label": "CT6",
          "value": 18
        },
        {
          "label": "XT5",
          "value": 19
        },
        {
          "label": "FUNERAL COACH",
          "value": 20
        },
        {
          "label": "ARMORED VEHICLE",
          "value": 21
        },
        {
          "label": "DEVILLE CHASSIS",
          "value": 22
        },
        {
          "label": "CT5",
          "value": 23
        }],
          value: 7
        }, { 
          label: "CHEVROLET",
          models: [
        {
          "label": "AVEO",
          "value": 0
        },
        {
          "label": "CAMARO",
          "value": 1
        },
        {
          "label": "CAPRICE POLICE VEHICLE",
          "value": 2
        },
        {
          "label": "CORVETTE",
          "value": 3
        },
        {
          "label": "CRUZE",
          "value": 4
        },
        {
          "label": "IMPALA",
          "value": 5
        },
        {
          "label": "MALIBU",
          "value": 6
        },
        {
          "label": "SONIC",
          "value": 7
        },
        {
          "label": "SPARK",
          "value": 8
        },
        {
          "label": "SS",
          "value": 9
        },
        {
          "label": "VOLT",
          "value": 10
        },
        {
          "label": "CAPTIVA SPORT",
          "value": 11
        },
        {
          "label": "EQUINOX",
          "value": 12
        },
        {
          "label": "EXPRESS",
          "value": 13
        },
        {
          "label": "ORLANDO",
          "value": 14
        },
        {
          "label": "SILVERADO",
          "value": 15
        },
        {
          "label": "SUBURBAN",
          "value": 16
        },
        {
          "label": "TAHOE",
          "value": 17
        },
        {
          "label": "TRAVERSE",
          "value": 18
        },
        {
          "label": "TRACKER",
          "value": 19
        },
        {
          "label": "CITY EXPRESS",
          "value": 20
        },
        {
          "label": "GEO PRIZM",
          "value": 21
        },
        {
          "label": "AVALANCHE",
          "value": 22
        },
        {
          "label": "MATIZ",
          "value": 23
        },
        {
          "label": "COLORADO",
          "value": 24
        },
        {
          "label": "COBALT",
          "value": 25
        },
        {
          "label": "OPTRA",
          "value": 26
        },
        {
          "label": "KALOS",
          "value": 27
        },
        {
          "label": "TRAILBLAZER",
          "value": 28
        },
        {
          "label": "HHR",
          "value": 29
        },
        {
          "label": "UPLANDER",
          "value": 30
        },
        {
          "label": "W4",
          "value": 31
        },
        {
          "label": "W5",
          "value": 32
        },
        {
          "label": "MONTE CARLO",
          "value": 33
        },
        {
          "label": "SSR",
          "value": 34
        },
        {
          "label": "EPICA",
          "value": 35
        },
        {
          "label": "CAVALIER",
          "value": 36
        },
        {
          "label": "U100",
          "value": 37
        },
        {
          "label": "3 TON",
          "value": 38
        },
        {
          "label": "ASTRO VAN",
          "value": 39
        },
        {
          "label": "VENTURE",
          "value": 40
        },
        {
          "label": "B7",
          "value": 41
        },
        {
          "label": "LUMINA",
          "value": 42
        },
        {
          "label": "ALERO",
          "value": 43
        },
        {
          "label": "CAPRICE",
          "value": 44
        },
        {
          "label": "CORSICA",
          "value": 45
        },
        {
          "label": "BERETTA",
          "value": 46
        },
        {
          "label": "HEARSE/LIMO",
          "value": 47
        },
        {
          "label": "METRO",
          "value": 48
        },
        {
          "label": "GMT-400",
          "value": 49
        },
        {
          "label": "MILITARY TRUCK",
          "value": 50
        },
        {
          "label": "FORWARD CONTROL",
          "value": 51
        },
        {
          "label": "TRAX",
          "value": 52
        },
        {
          "label": "S-10 PICKUP",
          "value": 53
        },
        {
          "label": "C/K PICKUP",
          "value": 54
        },
        {
          "label": "P - SERIES",
          "value": 55
        },
        {
          "label": "LUMINA APV",
          "value": 56
        },
        {
          "label": "BLAZER",
          "value": 57
        },
        {
          "label": "IMPALA LIMITED",
          "value": 58
        },
        {
          "label": "BOLT EV",
          "value": 59
        },
        {
          "label": "3500HD",
          "value": 60
        },
        {
          "label": "5500HD",
          "value": 61
        },
        {
          "label": "3500 / 4500",
          "value": 62
        },
        {
          "label": "4500HD",
          "value": 63
        },
        {
          "label": "4500XD",
          "value": 64
        },
        {
          "label": "5500XD",
          "value": 65
        },
        {
          "label": "T-SERIES",
          "value": 66
        },
        {
          "label": "C4",
          "value": 67
        },
        {
          "label": "C5",
          "value": 68
        },
        {
          "label": "C6",
          "value": 69
        },
        {
          "label": "C7",
          "value": 70
        },
        {
          "label": "C8",
          "value": 71
        },
        {
          "label": "W7",
          "value": 72
        },
        {
          "label": "HEARSE/LIMO COMMERCIAL CHASSIS",
          "value": 73
        },
        {
          "label": "W3",
          "value": 74
        },
        {
          "label": "W6",
          "value": 75
        },
        {
          "label": "D7",
          "value": 76
        },
        {
          "label": "P6S",
          "value": 77
        },
        {
          "label": "S-10",
          "value": 78
        },
        {
          "label": "P TRUCK FORWARD",
          "value": 79
        },
        {
          "label": "CELEBRITY",
          "value": 80
        },
        {
          "label": "GEO SPECTRUM",
          "value": 81
        },
        {
          "label": "G-SERIES",
          "value": 82
        },
        {
          "label": "CUTAWAY CHASSIS",
          "value": 83
        },
        {
          "label": "MOTORHOME CHASSIS",
          "value": 84
        },
        {
          "label": "CHEVETTE",
          "value": 85
        },
        {
          "label": "HI-CUBE",
          "value": 86
        },
        {
          "label": "CUTAWAY VAN",
          "value": 87
        },
        {
          "label": "S7",
          "value": 88
        },
        {
          "label": "GEO SPRINT",
          "value": 89
        },
        {
          "label": "NOVA",
          "value": 90
        },
        {
          "label": "S6",
          "value": 91
        },
        {
          "label": "R CONVENTIONAL",
          "value": 92
        },
        {
          "label": "V CONVENTIONAL",
          "value": 93
        },
        {
          "label": "CITATION",
          "value": 94
        },
        {
          "label": "BUS CHASSIS",
          "value": 95
        },
        {
          "label": "HEAVY CONVENTIONAL",
          "value": 96
        },
        {
          "label": "ALUMINUM TILT",
          "value": 97
        },
        {
          "label": "EL CAMINO",
          "value": 98
        },
        {
          "label": "LUV",
          "value": 99
        },
        {
          "label": "SILVERADO MEDIUM DUTY (GM515)",
          "value": 100
        },
        {
          "label": "MALIBU CLASSIC",
          "value": 101
        },
        {
          "label": "3500",
          "value": 102
        },
        {
          "label": "4500",
          "value": 103
        },
        {
          "label": "BOLT INCOMPLETE",
          "value": 104
        },
        {
          "label": "FTR / 6500XD",
          "value": 105
        },
        {
          "label": "6500XD",
          "value": 106
        },
        {
          "label": "SILVERADO HD",
          "value": 107
        },
        {
          "label": "SILVERADO LD",
          "value": 108
        },
        {
          "label": "7500XD",
          "value": 109
        },
        {
          "label": "GM51P",
          "value": 110
        },
        {
          "label": "W3500/W4500",
          "value": 111
        },
        {
          "label": "W5500/W5500HD",
          "value": 112
        },
        {
          "label": "SILVERADO MEDIUM DUTY (GM51P)",
          "value": 113
        },
        {
          "label": "ONIX",
          "value": 114
        },
        {
          "label": "3500/4500/4500HD/4500XD",
          "value": 115
        },
        {
          "label": "5500HD/5500XD",
          "value": 116
        }
        ],
            value: 8
          }, {
            label: "CHRYSLER",
            models: [
          {
            "label": "CIRRUS",
            "value": 0
          },
          {
            "label": "SEBRING",
            "value": 1
          },
          {
            "label": "300",
            "value": 2
          },
          {
            "label": "TOWN AND COUNTRY",
            "value": 3
          },
          {
            "label": "GRAND VOYAGER",
            "value": 4
          },
          {
            "label": "200",
            "value": 5
          },
          {
            "label": "PT CRUISER",
            "value": 6
          },
          {
            "label": "CROSSFIRE",
            "value": 7
          },
          {
            "label": "PACIFICA",
            "value": 8
          },
          {
            "label": "ASPEN",
            "value": 9
          },
          {
            "label": "CONCORDE",
            "value": 10
          },
          {
            "label": "INTREPID",
            "value": 11
          },
          {
            "label": "NEW YORKER",
            "value": 12
          },
          {
            "label": "LHS",
            "value": 13
          },
          {
            "label": "VISION",
            "value": 14
          },
          {
            "label": "STRATUS",
            "value": 15
          },
          {
            "label": "VIPER",
            "value": 16
          },
          {
            "label": "NEON",
            "value": 17
          },
          {
            "label": "SHADOW",
            "value": 18
          },
          {
            "label": "PROWLER",
            "value": 19
          },
          {
            "label": "CARAVAN",
            "value": 20
          },
          {
            "label": "TC",
            "value": 21
          },
          {
            "label": "LEBARON",
            "value": 22
          },
          {
            "label": "CONCORDE/LHS",
            "value": 23
          },
          {
            "label": "DAYTONA",
            "value": 24
          },
          {
            "label": "DYNASTY",
            "value": 25
          },
          {
            "label": "IMPERIAL",
            "value": 26
          },
          {
            "label": "SALON",
            "value": 27
          },
          {
            "label": "LASER",
            "value": 28
          },
          {
            "label": "NEWPORT",
            "value": 29
          },
          {
            "label": "FIFTH AVENUE",
            "value": 30
          },
          {
            "label": "CONQUEST",
            "value": 31
          },
          {
            "label": "EXECUTIVE",
            "value": 32
          },
          {
            "label": "E-CLASS",
            "value": 33
          },
          {
            "label": "CORDOBA",
            "value": 34
          },
          {
            "label": "VOYAGER",
            "value": 35
          },
          {
            "label": "300C",
            "value": 36
          },
          {
            "label": "GRAND CARAVAN",
            "value": 37
          }
        ],
            value: 9
          }, {
            label: "CITROËN",
            models: [],
            value: 10
          }, {
            label: "DACIA",
            models: [],
            value: 11
          }, {
            label: "DAEWOO",
            models: [],
            value: 12
          }, {
            label: "DAIHATSU",
            models: [],
            value: 13
          }, {
            label: "DODGE",
            models: [
        {
          "label": "AVENGER",
          "value": 0
        },
        {
          "label": "CHALLENGER",
          "value": 1
        },
        {
          "label": "CHARGER",
          "value": 2
        },
        {
          "label": "CALIBER",
          "value": 3
        },
        {
          "label": "VIPER",
          "value": 4
        },
        {
          "label": "RAM",
          "value": 5
        },
        {
          "label": "JOURNEY",
          "value": 6
        },
        {
          "label": "NITRO",
          "value": 7
        },
        {
          "label": "DAKOTA",
          "value": 8
        },
        {
          "label": "CARAVAN/GRAND CARAVAN",
          "value": 9
        },
        {
          "label": "RAM CHASSIS CAB",
          "value": 10
        },
        {
          "label": "DART",
          "value": 11
        },
        {
          "label": "SPRINTER",
          "value": 12
        },
        {
          "label": "SPRINTER",
          "value": 13
        },
        {
          "label": "DURANGO",
          "value": 14
        },
        {
          "label": "CHASSIS WITH POWERTRAIN",
          "value": 15
        },
        {
          "label": "MAGNUM",
          "value": 16
        },
        {
          "label": "DODGEN INDUSTRIES",
          "value": 17
        },
        {
          "label": "INTREPID",
          "value": 18
        },
        {
          "label": "STRATUS",
          "value": 19
        },
        {
          "label": "NEON",
          "value": 20
        },
        {
          "label": "COLT",
          "value": 21
        },
        {
          "label": "STEALTH",
          "value": 22
        },
        {
          "label": "RAMCHARGER",
          "value": 23
        },
        {
          "label": "RAM VAN",
          "value": 24
        },
        {
          "label": "RAM WAGON",
          "value": 25
        },
        {
          "label": "SPIRIT",
          "value": 26
        },
        {
          "label": "SHADOW",
          "value": 27
        },
        {
          "label": "DYNASTY",
          "value": 28
        },
        {
          "label": "DAYTONA",
          "value": 29
        },
        {
          "label": "MONACO",
          "value": 30
        },
        {
          "label": "OMNI",
          "value": 31
        },
        {
          "label": "SHELBY",
          "value": 32
        },
        {
          "label": "ARIES",
          "value": 33
        },
        {
          "label": "600",
          "value": 34
        },
        {
          "label": "DIPLOMAT",
          "value": 35
        },
        {
          "label": "LANCER",
          "value": 36
        },
        {
          "label": "CONQUEST",
          "value": 37
        },
        {
          "label": "400",
          "value": 38
        },
        {
          "label": "MIRADA",
          "value": 39
        },
        {
          "label": "ST.REGIS",
          "value": 40
        },
        {
          "label": "CARAVAN",
          "value": 41
        },
        {
          "label": "GRAND CARAVAN",
          "value": 42
        },
        {
          "label": "RAM 50",
          "value": 43
        },
        {
          "label": "D-SERIES",
          "value": 44
        },
        {
          "label": "W-SERIES",
          "value": 45
        },
        {
          "label": "RAIDER",
          "value": 46
        },
        {
          "label": "MINI RAM",
          "value": 47
        },
        {
          "label": "ROYAL MINI RAM VAN",
          "value": 48
        },
        {
          "label": "D50",
          "value": 49
        },
        {
          "label": "RAMPAGE",
          "value": 50
        },
        {
          "label": "RD200 / RD250",
          "value": 51
        },
        {
          "label": "580 SERIES",
          "value": 52
        },
        {
          "label": "480 SERIES",
          "value": 53
        },
        {
          "label": "RAM C/V",
          "value": 54
        }
      ],
                  value: 14
                }, {
                  label: "DONKERVOORT",
                  models: [],
                  value: 15
                }, {
                  label: "DS",
                  models: [],
                  value: 16
                }, {
                  label: "FERRARI",
                  models: [],
                  value: 17
                }, {
                  label: "FIAT",
                  models: [
                {
                  "label": "500L",
                  "value": 0
                },
                {
                  "label": "500",
                  "value": 1
                },
                {
                  "label": "FREEMONT",
                  "value": 2
                },
                {
                  "label": "500X",
                  "value": 3
                },
                {
                  "label": "124 SPIDER",
                  "value": 4
                },
                {
                  "label": "SPIDER 2000",
                  "value": 5
                },
                {
                  "label": "X 1/9",
                  "value": 6
                },
                {
                  "label": "BRAVA",
                  "value": 7
                },
                {
                  "label": "STRADA",
                  "value": 8
                },
                {
                  "label": "DUCATO",
                  "value": 9
                }
              ],
                  value: 18
                }, {
                  label: "FISKER",
                  models: [],
                  value: 19
                }, {
                  label: "FORD",
                  models: [
                {
                  "label": "CROWN VICTORIA",
                  "value": 0
                },
                {
                  "label": "FOCUS",
                  "value": 1
                },
                {
                  "label": "FUSION",
                  "value": 2
                },
                {
                  "label": "MUSTANG",
                  "value": 3
                },
                {
                  "label": "TAURUS",
                  "value": 4
                },
                {
                  "label": "E-150",
                  "value": 5
                },
                {
                  "label": "EDGE",
                  "value": 6
                },
                {
                  "label": "ESCAPE",
                  "value": 7
                },
                {
                  "label": "EXPEDITION",
                  "value": 8
                },
                {
                  "label": "EXPLORER",
                  "value": 9
                },
                {
                  "label": "F-150",
                  "value": 10
                },
                {
                  "label": "FLEX",
                  "value": 11
                },
                {
                  "label": "RANGER",
                  "value": 12
                },
                {
                  "label": "EXPLORER SPORT TRAC",
                  "value": 13
                },
                {
                  "label": "F-250",
                  "value": 14
                },
                {
                  "label": "F-350",
                  "value": 15
                },
                {
                  "label": "F-450",
                  "value": 16
                },
                {
                  "label": "F-550",
                  "value": 17
                },
                {
                  "label": "F-650",
                  "value": 18
                },
                {
                  "label": "F-750",
                  "value": 19
                },
                {
                  "label": "TRANSIT CONNECT",
                  "value": 20
                },
                {
                  "label": "E-250",
                  "value": 21
                },
                {
                  "label": "E-350",
                  "value": 22
                },
                {
                  "label": "E-450",
                  "value": 23
                },
                {
                  "label": "EXPEDITION MAX",
                  "value": 24
                },
                {
                  "label": "THUNDERBIRD",
                  "value": 25
                },
                {
                  "label": "GT",
                  "value": 26
                },
                {
                  "label": "FIVE HUNDRED",
                  "value": 27
                },
                {
                  "label": "EXCURSION",
                  "value": 28
                },
                {
                  "label": "FREESTYLE",
                  "value": 29
                },
                {
                  "label": "FREESTAR",
                  "value": 30
                },
                {
                  "label": "MOTORHOME CHASSIS",
                  "value": 31
                },
                {
                  "label": "TAURUS X",
                  "value": 32
                },
                {
                  "label": "FIESTA",
                  "value": 33
                },
                {
                  "label": "COMMERCIAL CHASSIS",
                  "value": 34
                },
                {
                  "label": "C-MAX",
                  "value": 35
                },
                {
                  "label": "TRANSIT",
                  "value": 36
                },
                {
                  "label": "AFFORDABLE ALUMINUM",
                  "value": 37
                },
                {
                  "label": "EAGLE FORD TANKS & TRAILERS LLC",
                  "value": 38
                },
                {
                  "label": "ESCORT",
                  "value": 39
                },
                {
                  "label": "ZX2",
                  "value": 40
                },
                {
                  "label": "WINDSTAR",
                  "value": 41
                },
                {
                  "label": "E-550",
                  "value": 42
                },
                {
                  "label": "B-750",
                  "value": 43
                },
                {
                  "label": "F-800",
                  "value": 44
                },
                {
                  "label": "EXPLORER SPORT",
                  "value": 45
                },
                {
                  "label": "MILFORD WELDING & MANUFACTURING INC.",
                  "value": 46
                },
                {
                  "label": "ASPIRE",
                  "value": 47
                },
                {
                  "label": "PROBE",
                  "value": 48
                },
                {
                  "label": "CONTOUR",
                  "value": 49
                },
                {
                  "label": "BRONCO",
                  "value": 50
                },
                {
                  "label": "AEROSTAR",
                  "value": 51
                },
                {
                  "label": "F-150 HERITAGE",
                  "value": 52
                },
                {
                  "label": "WATERFORD TANK AND FABRICATION, LTD",
                  "value": 53
                },
                {
                  "label": "MILFORD PIPE & SUPPLY",
                  "value": 54
                },
                {
                  "label": "L8501",
                  "value": 55
                },
                {
                  "label": "LT8501",
                  "value": 56
                },
                {
                  "label": "L9501",
                  "value": 57
                },
                {
                  "label": "LT9501",
                  "value": 58
                },
                {
                  "label": "L8511",
                  "value": 59
                },
                {
                  "label": "LT8511",
                  "value": 60
                },
                {
                  "label": "L9511",
                  "value": 61
                },
                {
                  "label": "LT9511",
                  "value": 62
                },
                {
                  "label": "L8513",
                  "value": 63
                },
                {
                  "label": "LT8513",
                  "value": 64
                },
                {
                  "label": "L9513",
                  "value": 65
                },
                {
                  "label": "LT9513",
                  "value": 66
                },
                {
                  "label": "L9522",
                  "value": 67
                },
                {
                  "label": "LT9522",
                  "value": 68
                },
                {
                  "label": "A8513",
                  "value": 69
                },
                {
                  "label": "AT8513",
                  "value": 70
                },
                {
                  "label": "A9513",
                  "value": 71
                },
                {
                  "label": "AT9513",
                  "value": 72
                },
                {
                  "label": "A9522",
                  "value": 73
                },
                {
                  "label": "AT9522",
                  "value": 74
                },
                {
                  "label": "B800",
                  "value": 75
                },
                {
                  "label": "F-SUPER DUTY",
                  "value": 76
                },
                {
                  "label": "F-700",
                  "value": 77
                },
                {
                  "label": "P700",
                  "value": 78
                },
                {
                  "label": "P800",
                  "value": 79
                },
                {
                  "label": "FT900",
                  "value": 80
                },
                {
                  "label": "L8000",
                  "value": 81
                },
                {
                  "label": "L9000",
                  "value": 82
                },
                {
                  "label": "LL9000",
                  "value": 83
                },
                {
                  "label": "LLA9000",
                  "value": 84
                },
                {
                  "label": "LLS9000",
                  "value": 85
                },
                {
                  "label": "LS8000",
                  "value": 86
                },
                {
                  "label": "LS9000",
                  "value": 87
                },
                {
                  "label": "LT8000",
                  "value": 88
                },
                {
                  "label": "LT9000",
                  "value": 89
                },
                {
                  "label": "LTS8000",
                  "value": 90
                },
                {
                  "label": "LTS9000",
                  "value": 91
                },
                {
                  "label": "LTL9000",
                  "value": 92
                },
                {
                  "label": "LTLA9000",
                  "value": 93
                },
                {
                  "label": "LTLS9000",
                  "value": 94
                },
                {
                  "label": "LA8000",
                  "value": 95
                },
                {
                  "label": "LA9000",
                  "value": 96
                },
                {
                  "label": "LTA9000",
                  "value": 97
                },
                {
                  "label": "LN7000",
                  "value": 98
                },
                {
                  "label": "LN8000",
                  "value": 99
                },
                {
                  "label": "LN9000",
                  "value": 100
                },
                {
                  "label": "LNT8000",
                  "value": 101
                },
                {
                  "label": "LNT9000",
                  "value": 102
                },
                {
                  "label": "CF8000",
                  "value": 103
                },
                {
                  "label": "CFT8000",
                  "value": 104
                },
                {
                  "label": "CF7000",
                  "value": 105
                },
                {
                  "label": "FORDS TRAILER SALES",
                  "value": 106
                },
                {
                  "label": "FESTIVA",
                  "value": 107
                },
                {
                  "label": "LOW CAB FORWARD",
                  "value": 108
                },
                {
                  "label": "TEMPO",
                  "value": 109
                },
                {
                  "label": "B600",
                  "value": 110
                },
                {
                  "label": "B700",
                  "value": 111
                },
                {
                  "label": "F-600",
                  "value": 112
                },
                {
                  "label": "RECREATIONAL VEHICLE",
                  "value": 113
                },
                {
                  "label": "F-590",
                  "value": 114
                },
                {
                  "label": "P600",
                  "value": 115
                },
                {
                  "label": "CL9000",
                  "value": 116
                },
                {
                  "label": "CLT9000",
                  "value": 117
                },
                {
                  "label": "BRONCO II",
                  "value": 118
                },
                {
                  "label": "FT800",
                  "value": 119
                },
                {
                  "label": "CT8000",
                  "value": 120
                },
                {
                  "label": "C800",
                  "value": 121
                },
                {
                  "label": "C8000",
                  "value": 122
                },
                {
                  "label": "LASER",
                  "value": 123
                },
                {
                  "label": "LTD",
                  "value": 124
                },
                {
                  "label": "FAIRMONT",
                  "value": 125
                },
                {
                  "label": "GRANADA",
                  "value": 126
                },
                {
                  "label": "TRAVEL PARK",
                  "value": 127
                },
                {
                  "label": "'34",
                  "value": 128
                },
                {
                  "label": "CF6000",
                  "value": 129
                },
                {
                  "label": "B7000",
                  "value": 130
                },
                {
                  "label": "C7000",
                  "value": 131
                },
                {
                  "label": "F7000",
                  "value": 132
                },
                {
                  "label": "F8000",
                  "value": 133
                },
                {
                  "label": "FT8000",
                  "value": 134
                },
                {
                  "label": "COURIER",
                  "value": 135
                },
                {
                  "label": "B6000",
                  "value": 136
                },
                {
                  "label": "C600",
                  "value": 137
                },
                {
                  "label": "C700",
                  "value": 138
                },
                {
                  "label": "F6000",
                  "value": 139
                },
                {
                  "label": "LN600",
                  "value": 140
                },
                {
                  "label": "LN700",
                  "value": 141
                },
                {
                  "label": "L800",
                  "value": 142
                },
                {
                  "label": "LT800",
                  "value": 143
                },
                {
                  "label": "LN800",
                  "value": 144
                },
                {
                  "label": "LNT800",
                  "value": 145
                },
                {
                  "label": "E-100",
                  "value": 146
                },
                {
                  "label": "F-100",
                  "value": 147
                },
                {
                  "label": "CT800",
                  "value": 148
                },
                {
                  "label": "SWINFORD MFG",
                  "value": 149
                },
                {
                  "label": "ECOSPORT",
                  "value": 150
                },
                {
                  "label": "CRANFORD RADIATOR INC.",
                  "value": 151
                },
                {
                  "label": "BRADFORD BUILT",
                  "value": 152
                },
                {
                  "label": "MALIBU SEDAN",
                  "value": 153
                },
                {
                  "label": "CORDOVA SEDAN",
                  "value": 154
                },
                {
                  "label": "CLASSIC SEDAN",
                  "value": 155
                },
                {
                  "label": "BRADFORD #1",
                  "value": 156
                },
                {
                  "label": "STAFFORD'S TRAILERS",
                  "value": 157
                },
                {
                  "label": "MEDFORD STEEL",
                  "value": 158
                },
                {
                  "label": "EXPEDITION EL",
                  "value": 159
                },
                {
                  "label": "AFFORDABLE TRAILERS",
                  "value": 160
                },
                {
                  "label": "BRONCO SPORT",
                  "value": 161
                },
                {
                  "label": "MACH-E",
                  "value": 162
                },
                {
                  "label": "CX727",
                  "value": 163
                }
              ],
                  value: 20
                }, {
                  label: "HONDA",
                  models: [
                {
                  "label": "ACCORD",
                  "value": 0
                },
                {
                  "label": "CIVIC",
                  "value": 1
                },
                {
                  "label": "PILOT",
                  "value": 2
                },
                {
                  "label": "CR-V",
                  "value": 3
                },
                {
                  "label": "RIDGELINE",
                  "value": 4
                },
                {
                  "label": "ELEMENT",
                  "value": 5
                },
                {
                  "label": "ODYSSEY",
                  "value": 6
                },
                {
                  "label": "INSIGHT",
                  "value": 7
                },
                {
                  "label": "FCX CLARITY",
                  "value": 8
                },
                {
                  "label": "FIT",
                  "value": 9
                },
                {
                  "label": "CR-Z",
                  "value": 10
                },
                {
                  "label": "S2000",
                  "value": 11
                },
                {
                  "label": "PCX150",
                  "value": 12
                },
                {
                  "label": "CB1100",
                  "value": 13
                },
                {
                  "label": "CB300F",
                  "value": 14
                },
                {
                  "label": "CBR1000RR",
                  "value": 15
                },
                {
                  "label": "CRF250",
                  "value": 16
                },
                {
                  "label": "CTX1300",
                  "value": 17
                },
                {
                  "label": "CTX700",
                  "value": 18
                },
                {
                  "label": "CTX700N",
                  "value": 19
                },
                {
                  "label": "GOLDWING",
                  "value": 20
                },
                {
                  "label": "NC700X",
                  "value": 21
                },
                {
                  "label": "METROPOLITAN",
                  "value": 22
                },
                {
                  "label": "NPS50 (RUCKUS)",
                  "value": 23
                },
                {
                  "label": "ST1300PA",
                  "value": 24
                },
                {
                  "label": "INTERCEPTOR",
                  "value": 25
                },
                {
                  "label": "ST1300",
                  "value": 26
                },
                {
                  "label": "SILVERWING",
                  "value": 27
                },
                {
                  "label": "CBR250R",
                  "value": 28
                },
                {
                  "label": "VFR1200F",
                  "value": 29
                },
                {
                  "label": "NT700V",
                  "value": 30
                },
                {
                  "label": "PCX125",
                  "value": 31
                },
                {
                  "label": "SH150I",
                  "value": 32
                },
                {
                  "label": "VTX1800F",
                  "value": 33
                },
                {
                  "label": "CB250 (NIGHTHAWK)",
                  "value": 34
                },
                {
                  "label": "ELITE80",
                  "value": 35
                },
                {
                  "label": "HELIX",
                  "value": 36
                },
                {
                  "label": "VTX1300C",
                  "value": 37
                },
                {
                  "label": "VTX1300R",
                  "value": 38
                },
                {
                  "label": "VTX1300S",
                  "value": 39
                },
                {
                  "label": "VTX1800C",
                  "value": 40
                },
                {
                  "label": "SHADOW VLX",
                  "value": 41
                },
                {
                  "label": "ST1300P",
                  "value": 42
                },
                {
                  "label": "CB600F",
                  "value": 43
                },
                {
                  "label": "NIGHTHAWK 750",
                  "value": 44
                },
                {
                  "label": "CBR1100XX",
                  "value": 45
                },
                {
                  "label": "CBR954RR",
                  "value": 46
                },
                {
                  "label": "ST1100P",
                  "value": 47
                },
                {
                  "label": "ST1100",
                  "value": 48
                },
                {
                  "label": "ELITE 50",
                  "value": 49
                },
                {
                  "label": "CBR900RR",
                  "value": 50
                },
                {
                  "label": "PACIFIC COAST",
                  "value": 51
                },
                {
                  "label": "VFR 750F",
                  "value": 52
                },
                {
                  "label": "CBR1000F",
                  "value": 53
                },
                {
                  "label": "XR250L",
                  "value": 54
                },
                {
                  "label": "SHADOW 1100",
                  "value": 55
                },
                {
                  "label": "SHADOW",
                  "value": 56
                },
                {
                  "label": "CT70",
                  "value": 57
                },
                {
                  "label": "RC 45",
                  "value": 58
                },
                {
                  "label": "HAWK GT",
                  "value": 59
                },
                {
                  "label": "CB-1",
                  "value": 60
                },
                {
                  "label": "ELITE 250",
                  "value": 61
                },
                {
                  "label": "TOURIST TROPHY",
                  "value": 62
                },
                {
                  "label": "NS50F",
                  "value": 63
                },
                {
                  "label": "NX250",
                  "value": 64
                },
                {
                  "label": "NX125",
                  "value": 65
                },
                {
                  "label": "SA50",
                  "value": 66
                },
                {
                  "label": "SB50P",
                  "value": 67
                },
                {
                  "label": "VFR750R (RC30)",
                  "value": 68
                },
                {
                  "label": "VTR",
                  "value": 69
                },
                {
                  "label": "XL600V",
                  "value": 70
                },
                {
                  "label": "CB125TT",
                  "value": 71
                },
                {
                  "label": "VT1100C",
                  "value": 72
                },
                {
                  "label": "ELITE",
                  "value": 73
                },
                {
                  "label": "TRANSALP",
                  "value": 74
                },
                {
                  "label": "NX650",
                  "value": 75
                },
                {
                  "label": "PRELUDE",
                  "value": 76
                },
                {
                  "label": "PASSPORT",
                  "value": 77
                },
                {
                  "label": "CROSSTOUR",
                  "value": 78
                },
                {
                  "label": "HR-V",
                  "value": 79
                },
                {
                  "label": "DEL SOL",
                  "value": 80
                },
                {
                  "label": "CBR650",
                  "value": 81
                },
                {
                  "label": "NC700XD",
                  "value": 82
                },
                {
                  "label": "CB500",
                  "value": 83
                },
                {
                  "label": "CRF50",
                  "value": 84
                },
                {
                  "label": "XR400R",
                  "value": 85
                },
                {
                  "label": "TRX700XX",
                  "value": 86
                },
                {
                  "label": "TRX450ER",
                  "value": 87
                },
                {
                  "label": "FOURTRAX",
                  "value": 88
                },
                {
                  "label": "TRX400X",
                  "value": 89
                },
                {
                  "label": "SPORTRAX",
                  "value": 90
                },
                {
                  "label": "TRX450R",
                  "value": 91
                },
                {
                  "label": "CRF70F",
                  "value": 92
                },
                {
                  "label": "CRF100F",
                  "value": 93
                },
                {
                  "label": "CR250R",
                  "value": 94
                },
                {
                  "label": "SA50P",
                  "value": 95
                },
                {
                  "label": "CR125R",
                  "value": 96
                },
                {
                  "label": "XR250R",
                  "value": 97
                },
                {
                  "label": "MUV700 (BIG RED)",
                  "value": 98
                },
                {
                  "label": "EV PLUS",
                  "value": 99
                },
                {
                  "label": "TRX90",
                  "value": 100
                },
                {
                  "label": "XR600R",
                  "value": 101
                },
                {
                  "label": "TRX200D",
                  "value": 102
                },
                {
                  "label": "TRX200DN",
                  "value": 103
                },
                {
                  "label": "TRX250",
                  "value": 104
                },
                {
                  "label": "TRX300",
                  "value": 105
                },
                {
                  "label": "TRX300EX",
                  "value": 106
                },
                {
                  "label": "TRX300EXN",
                  "value": 107
                },
                {
                  "label": "TRX300FW",
                  "value": 108
                },
                {
                  "label": "TRX300FWN",
                  "value": 109
                },
                {
                  "label": "TRX300N",
                  "value": 110
                },
                {
                  "label": "TRX400FW",
                  "value": 111
                },
                {
                  "label": "TRX400FWN",
                  "value": 112
                },
                {
                  "label": "TRX450S",
                  "value": 113
                },
                {
                  "label": "TRX450ES",
                  "value": 114
                },
                {
                  "label": "TRX400EX",
                  "value": 115
                },
                {
                  "label": "NC700XL (NC700X)",
                  "value": 116
                },
                {
                  "label": "NC700XD (NC700X DCT)",
                  "value": 117
                },
                {
                  "label": "NC700XDL (NC700X DCT)",
                  "value": 118
                },
                {
                  "label": "CMX250 (REBEL)",
                  "value": 119
                },
                {
                  "label": "CMX250CL (REBEL)",
                  "value": 120
                },
                {
                  "label": "CTX700NL (CTX700N)",
                  "value": 121
                },
                {
                  "label": "CTX700ND (CTX700N DCT)",
                  "value": 122
                },
                {
                  "label": "CTX700NDL (CTX700N DCT)",
                  "value": 123
                },
                {
                  "label": "NC700JD (NM4)",
                  "value": 124
                },
                {
                  "label": "VT1300 (STATELINE)",
                  "value": 125
                },
                {
                  "label": "XR650",
                  "value": 126
                },
                {
                  "label": "CTX700 (CTX700D)",
                  "value": 127
                },
                {
                  "label": "TRX420 (FOURTRAX RANCHER)",
                  "value": 128
                },
                {
                  "label": "TRX420",
                  "value": 129
                },
                {
                  "label": "TRX500 (FOURTRAX FOREMAN)",
                  "value": 130
                },
                {
                  "label": "TRX680 (RINCON)",
                  "value": 131
                },
                {
                  "label": "TRX680 (FOURTRAX RINCON)",
                  "value": 132
                },
                {
                  "label": "NCH50 (GIORNO)",
                  "value": 133
                },
                {
                  "label": "NCH50 (METROPOLITAN)",
                  "value": 134
                },
                {
                  "label": "NSS300 (FORZA)",
                  "value": 135
                },
                {
                  "label": "NSS300A (FORZA)",
                  "value": 136
                },
                {
                  "label": "CRF110",
                  "value": 137
                },
                {
                  "label": "CRF125",
                  "value": 138
                },
                {
                  "label": "CRF230",
                  "value": 139
                },
                {
                  "label": "NC750JD/NM4",
                  "value": 140
                },
                {
                  "label": "MRT260/COTA 4RT260",
                  "value": 141
                },
                {
                  "label": "CBR500",
                  "value": 142
                },
                {
                  "label": "CBR1000RA",
                  "value": 143
                },
                {
                  "label": "CBR1000R/CBR1000RR",
                  "value": 144
                },
                {
                  "label": "CBR1000RL/CBR1000RR",
                  "value": 145
                },
                {
                  "label": "CBR1000RS/CBR1000RR",
                  "value": 146
                },
                {
                  "label": "CBR1000S/CBR1000RR",
                  "value": 147
                },
                {
                  "label": "NC750X",
                  "value": 148
                },
                {
                  "label": "CB300FA",
                  "value": 149
                },
                {
                  "label": "CBR300",
                  "value": 150
                },
                {
                  "label": "NC700XD/NC700X",
                  "value": 151
                },
                {
                  "label": "NC700XDL/NC700X",
                  "value": 152
                },
                {
                  "label": "TRX250X",
                  "value": 153
                },
                {
                  "label": "TRX450R/TRX450ER",
                  "value": 154
                },
                {
                  "label": "TRX250 (FOURTRAX RECON)",
                  "value": 155
                },
                {
                  "label": "CMX250C (REBEL)",
                  "value": 156
                },
                {
                  "label": "CB1100A/CB1100",
                  "value": 157
                },
                {
                  "label": "CB1100SA/CB1100 DELUXE",
                  "value": 158
                },
                {
                  "label": "CBR125R",
                  "value": 159
                },
                {
                  "label": "CBR1000RR/CBR1000RA",
                  "value": 160
                },
                {
                  "label": "CBR1000S",
                  "value": 161
                },
                {
                  "label": "GROM125",
                  "value": 162
                },
                {
                  "label": "MSX125/GROM",
                  "value": 163
                },
                {
                  "label": "NC750SA",
                  "value": 164
                },
                {
                  "label": "VFR800F",
                  "value": 165
                },
                {
                  "label": "VFR800F (INTERCEPTOR)",
                  "value": 166
                },
                {
                  "label": "VFR1200FD/VFR1200FA",
                  "value": 167
                },
                {
                  "label": "CTX700TA/CTX700A",
                  "value": 168
                },
                {
                  "label": "CTX1300A/CT1300AE",
                  "value": 169
                },
                {
                  "label": "CTX1300D/CTX1300",
                  "value": 170
                },
                {
                  "label": "ST1300A",
                  "value": 171
                },
                {
                  "label": "VT1300 (SABRE)",
                  "value": 172
                },
                {
                  "label": "FSC600A (SILVER WING)",
                  "value": 173
                },
                {
                  "label": "CBR250RA/CBR250R",
                  "value": 174
                },
                {
                  "label": "CBR1000",
                  "value": 175
                },
                {
                  "label": "VFR1200FA",
                  "value": 176
                },
                {
                  "label": "VFR1200FD/VFR1200F",
                  "value": 177
                },
                {
                  "label": "ST1300A/ST1300",
                  "value": 178
                },
                {
                  "label": "CRF80F",
                  "value": 179
                },
                {
                  "label": "NC700SA/NC700S",
                  "value": 180
                },
                {
                  "label": "VFR1200FAD/VFR1200FD",
                  "value": 181
                },
                {
                  "label": "CBF1000A",
                  "value": 182
                },
                {
                  "label": "VT750 (SHADOW AERO)",
                  "value": 183
                },
                {
                  "label": "CB1000R/CBR1000RA",
                  "value": 184
                },
                {
                  "label": "CBF600SA",
                  "value": 185
                },
                {
                  "label": "CBR125RW",
                  "value": 186
                },
                {
                  "label": "CBR250RA",
                  "value": 187
                },
                {
                  "label": "CBR1000RRE/CBR1000RRA",
                  "value": 188
                },
                {
                  "label": "VFR1200FAD",
                  "value": 189
                },
                {
                  "label": "NT700VA/NT700V",
                  "value": 190
                },
                {
                  "label": "CBR125R/CBR125RS",
                  "value": 191
                },
                {
                  "label": "MRT260/COTA 4RT/4RT 260",
                  "value": 192
                },
                {
                  "label": "VT1300 (FURY)",
                  "value": 193
                },
                {
                  "label": "VT750 (SHADOW PHANTOM)",
                  "value": 194
                },
                {
                  "label": "VT750",
                  "value": 195
                },
                {
                  "label": "VT750 (SHADOW ACE 750)",
                  "value": 196
                },
                {
                  "label": "VT750 (SHADOW)",
                  "value": 197
                },
                {
                  "label": "VT1300",
                  "value": 198
                },
                {
                  "label": "FSC600D (SILVER WING)",
                  "value": 199
                },
                {
                  "label": "NHX110 (ELITE 110)",
                  "value": 200
                },
                {
                  "label": "SH150D/SH150I",
                  "value": 201
                },
                {
                  "label": "VT750 (AERO)",
                  "value": 202
                },
                {
                  "label": "VT1300 (INTERSTATE)",
                  "value": 203
                },
                {
                  "label": "NSA700A/DN-01",
                  "value": 204
                },
                {
                  "label": "VTX1300RL/VTX",
                  "value": 205
                },
                {
                  "label": "VTX1300R/VTX1300 (RETRO)",
                  "value": 206
                },
                {
                  "label": "VTX1300T/VTX",
                  "value": 207
                },
                {
                  "label": "VTX1300TL/VTX",
                  "value": 208
                },
                {
                  "label": "VTX1300T/VTX1300 (SPOKE)",
                  "value": 209
                },
                {
                  "label": "CBR125R/CBR125RE (SUPERSPORT)",
                  "value": 210
                },
                {
                  "label": "VFR800 (INTERCEPTOR)",
                  "value": 211
                },
                {
                  "label": "VFR800A (INTERCEPTOR)",
                  "value": 212
                },
                {
                  "label": "CBR1000RA/CBR1000R",
                  "value": 213
                },
                {
                  "label": "CBR1000A2 (SUPERSPORT)",
                  "value": 214
                },
                {
                  "label": "CBR1000RE (SUPERSPORT)",
                  "value": 215
                },
                {
                  "label": "XL1000V (VARADERO)",
                  "value": 216
                },
                {
                  "label": "CRF150",
                  "value": 217
                },
                {
                  "label": "TRX300X",
                  "value": 218
                },
                {
                  "label": "TRX450ERB",
                  "value": 219
                },
                {
                  "label": "TRX500",
                  "value": 220
                },
                {
                  "label": "CHF50 (METROPOLITAN)",
                  "value": 221
                },
                {
                  "label": "CHF50S (JAZZ)",
                  "value": 222
                },
                {
                  "label": "CHF50 (JAZZ)",
                  "value": 223
                },
                {
                  "label": "CHF50S (METROPOLITAN)",
                  "value": 224
                },
                {
                  "label": "GL1100 (GOLDWING)",
                  "value": 225
                },
                {
                  "label": "GL1200 (GOLDWING)",
                  "value": 226
                },
                {
                  "label": "VT600C (SHADOW VLX)",
                  "value": 227
                },
                {
                  "label": "VT1100C (SHADOW SPIRIT 1100)",
                  "value": 228
                },
                {
                  "label": "VT1100C2 (SHADOW SABRE)",
                  "value": 229
                },
                {
                  "label": "VT1100D2",
                  "value": 230
                },
                {
                  "label": "NRX1800 (VALKYRIE RUNE)",
                  "value": 231
                },
                {
                  "label": "VTX1800F1/VTX1800F",
                  "value": 232
                },
                {
                  "label": "VTX1800F3/VTX1800F",
                  "value": 233
                },
                {
                  "label": "VTX1800N/VTX1800 NEO RETRO",
                  "value": 234
                },
                {
                  "label": "VTX1800N1/VTX1800N",
                  "value": 235
                },
                {
                  "label": "VTX1800N3/VTX1800N",
                  "value": 236
                },
                {
                  "label": "VTX1800R/VTX1800 RETRO",
                  "value": 237
                },
                {
                  "label": "VTX1800S/VTX1800 SPOKE",
                  "value": 238
                },
                {
                  "label": "VTX1800T/VTX1800T TOURER",
                  "value": 239
                },
                {
                  "label": "VTX1800T1/VTX1800T",
                  "value": 240
                },
                {
                  "label": "CB599",
                  "value": 241
                },
                {
                  "label": "VFR800",
                  "value": 242
                },
                {
                  "label": "VFR800A",
                  "value": 243
                },
                {
                  "label": "VFR800AT",
                  "value": 244
                },
                {
                  "label": "CB900C/919",
                  "value": 245
                },
                {
                  "label": "CB900F/919",
                  "value": 246
                },
                {
                  "label": "CBR1000RE",
                  "value": 247
                },
                {
                  "label": "VTR1000S (RC51)",
                  "value": 248
                },
                {
                  "label": "XL200R",
                  "value": 249
                },
                {
                  "label": "NT650J",
                  "value": 250
                },
                {
                  "label": "CR85R",
                  "value": 251
                },
                {
                  "label": "CG150ESD",
                  "value": 252
                },
                {
                  "label": "XR200R",
                  "value": 253
                },
                {
                  "label": "CB50R",
                  "value": 254
                },
                {
                  "label": "NSR50MIN",
                  "value": 255
                },
                {
                  "label": "TRX700EX",
                  "value": 256
                },
                {
                  "label": "TRX350FE",
                  "value": 257
                },
                {
                  "label": "TRX350FM",
                  "value": 258
                },
                {
                  "label": "TRX350TE",
                  "value": 259
                },
                {
                  "label": "TRX350TM",
                  "value": 260
                },
                {
                  "label": "TRX400FA",
                  "value": 261
                },
                {
                  "label": "TRX400FG (FOURTRAX 400)",
                  "value": 262
                },
                {
                  "label": "TRX450FM",
                  "value": 263
                },
                {
                  "label": "TRX500 (FOURTRAX)",
                  "value": 264
                },
                {
                  "label": "TRX650FA (RINCON)",
                  "value": 265
                },
                {
                  "label": "TRX650FG (RINCON)",
                  "value": 266
                },
                {
                  "label": "NSS250A (REFLEX)",
                  "value": 267
                },
                {
                  "label": "PS250 (BIG RUCKUS)",
                  "value": 268
                },
                {
                  "label": "VT600CD (SHADOW VLX DELUXE)",
                  "value": 269
                },
                {
                  "label": "VT750 (A.C.E.)",
                  "value": 270
                },
                {
                  "label": "VT750 (SHADOW SPIRIT 750)",
                  "value": 271
                },
                {
                  "label": "VT750 (SHADOW ACE/AERO)",
                  "value": 272
                },
                {
                  "label": "VT1100C2D (SABRE)",
                  "value": 273
                },
                {
                  "label": "VTX1800C1/VTX1800C",
                  "value": 274
                },
                {
                  "label": "VTX1800FD",
                  "value": 275
                },
                {
                  "label": "VTX1800F2/VTX1800F",
                  "value": 276
                },
                {
                  "label": "VTX1800ND",
                  "value": 277
                },
                {
                  "label": "VTX1800N2/VTX1800N",
                  "value": 278
                },
                {
                  "label": "VTX1800R1/VTX1800R",
                  "value": 279
                },
                {
                  "label": "VTX1800R2/VTX1800R",
                  "value": 280
                },
                {
                  "label": "VTX1800R3/VTX1800R",
                  "value": 281
                },
                {
                  "label": "VTX1800TD",
                  "value": 282
                },
                {
                  "label": "VTX1800T2/VTX1800T",
                  "value": 283
                },
                {
                  "label": "VFR800AT (INTERCEPTOR ABS)",
                  "value": 284
                },
                {
                  "label": "CB900F (HORNET 919)",
                  "value": 285
                },
                {
                  "label": "CBR1000RR (SUPERSPORT)",
                  "value": 286
                },
                {
                  "label": "CR85RB (CR85R EXPERT)",
                  "value": 287
                },
                {
                  "label": "TRX250 (SPORTTRAX 250)",
                  "value": 288
                },
                {
                  "label": "TRX300EX (SPORTTRAX 300)",
                  "value": 289
                },
                {
                  "label": "TRX400EX (SPORTTRAX 400)",
                  "value": 290
                },
                {
                  "label": "TRX450ERB (SPORTTRAX 450)",
                  "value": 291
                },
                {
                  "label": "TRX450ER (SPORTTRAX 450)",
                  "value": 292
                },
                {
                  "label": "TRX250 (FOURTRAX 250)",
                  "value": 293
                },
                {
                  "label": "TRX400FA (FOURTRAX RANCHER AT)",
                  "value": 294
                },
                {
                  "label": "TRX400FGA (FOURTRAX RANCHER AT)",
                  "value": 295
                },
                {
                  "label": "CH80 (ELITE)",
                  "value": 296
                },
                {
                  "label": "CN250 (HELIX)",
                  "value": 297
                },
                {
                  "label": "NSS250AS (REFLEX SPORT)",
                  "value": 298
                },
                {
                  "label": "NSS250S (REFLEX SPORT)",
                  "value": 299
                },
                {
                  "label": "NSS250 (REFLEX)",
                  "value": 300
                },
                {
                  "label": "VT600C (VLX)",
                  "value": 301
                },
                {
                  "label": "VTX1300T",
                  "value": 302
                },
                {
                  "label": "VTX1800C2/VTX1800C",
                  "value": 303
                },
                {
                  "label": "VTX1800C3/VTX1800C",
                  "value": 304
                },
                {
                  "label": "VTX1800S1/VTX1800S",
                  "value": 305
                },
                {
                  "label": "VTX1800S2/VTX1800S",
                  "value": 306
                },
                {
                  "label": "VTX1800S3/VTX1800S",
                  "value": 307
                },
                {
                  "label": "CB600F/599",
                  "value": 308
                },
                {
                  "label": "VFR800 (SPORT TOURER)",
                  "value": 309
                },
                {
                  "label": "VFR800A (SPORT TOURER)",
                  "value": 310
                },
                {
                  "label": "CB900F (HORNET)",
                  "value": 311
                },
                {
                  "label": "RVT1000R (RC51)",
                  "value": 312
                },
                {
                  "label": "CR85R (COMPETITION)",
                  "value": 313
                },
                {
                  "label": "CR125R (COMPETITION)",
                  "value": 314
                },
                {
                  "label": "CR250R (COMPETITION)",
                  "value": 315
                },
                {
                  "label": "TRX90 (FOURTRAX 90)",
                  "value": 316
                },
                {
                  "label": "TRX350FE (FOURTRAX RANCHER)",
                  "value": 317
                },
                {
                  "label": "TRX350FM (FOURTRAX RANCHER)",
                  "value": 318
                },
                {
                  "label": "TRX350TE (FOURTRAX RANCHER)",
                  "value": 319
                },
                {
                  "label": "TRX350TM (FOURTRAX RANCHER)",
                  "value": 320
                },
                {
                  "label": "CMX250X (REBEL)",
                  "value": 321
                },
                {
                  "label": "VTR1000F (SUPERHAWK)",
                  "value": 322
                },
                {
                  "label": "VTX1300C (VTX)",
                  "value": 323
                },
                {
                  "label": "VTX1300S (VTX)",
                  "value": 324
                },
                {
                  "label": "NRX1800DA (VALKYRIE RUNE)",
                  "value": 325
                },
                {
                  "label": "NRX1800DB (VALKYRIE RUNE)",
                  "value": 326
                },
                {
                  "label": "NRX1800EA (VALKYRIE RUNE)",
                  "value": 327
                },
                {
                  "label": "NRX1800EB (VALKYRIE RUNE)",
                  "value": 328
                },
                {
                  "label": "VTX1800C1 (VTX)",
                  "value": 329
                },
                {
                  "label": "VTX1800C2 (VTX)",
                  "value": 330
                },
                {
                  "label": "VTX1800C3 (VTX)",
                  "value": 331
                },
                {
                  "label": "VTX1800F1 (VTX)",
                  "value": 332
                },
                {
                  "label": "VTX1800F2 (VTX)",
                  "value": 333
                },
                {
                  "label": "VTX1800N3 (VTX)",
                  "value": 334
                },
                {
                  "label": "VTX1800N2 (VTX)",
                  "value": 335
                },
                {
                  "label": "VTX1800N1 (VTX)",
                  "value": 336
                },
                {
                  "label": "VTX1800R3 (VTX)",
                  "value": 337
                },
                {
                  "label": "VTX1800R2 (VTX)",
                  "value": 338
                },
                {
                  "label": "VTX1800R1 (VTX)",
                  "value": 339
                },
                {
                  "label": "VTX1800S3 (VTX)",
                  "value": 340
                },
                {
                  "label": "VTX1800S2 (VTX)",
                  "value": 341
                },
                {
                  "label": "VTX1800S1 (VTX)",
                  "value": 342
                },
                {
                  "label": "VTX1800F3 (VTX)",
                  "value": 343
                },
                {
                  "label": "RC1000VS (RC213V-S)",
                  "value": 344
                },
                {
                  "label": "TRX90 (SPORTTRAX 90)",
                  "value": 345
                },
                {
                  "label": "TRX250 (RECON)",
                  "value": 346
                },
                {
                  "label": "RX250TM (RECON)",
                  "value": 347
                },
                {
                  "label": "TRX400FW (FOURTRAX FOREMAN 400)",
                  "value": 348
                },
                {
                  "label": "TRX450FE (FOURTRAX FOREMAN)",
                  "value": 349
                },
                {
                  "label": "TRX450FM (FOURTRAX FOREMAN)",
                  "value": 350
                },
                {
                  "label": "CTX700ND",
                  "value": 351
                },
                {
                  "label": "TRX650FA (FOURTRAX RINCON)",
                  "value": 352
                },
                {
                  "label": "TRX650FGA (FOURTRAX RINCON)",
                  "value": 353
                },
                {
                  "label": "CTX700D",
                  "value": 354
                },
                {
                  "label": "CRF1000",
                  "value": 355
                },
                {
                  "label": "VFR1200X",
                  "value": 356
                },
                {
                  "label": "VFR1200XD",
                  "value": 357
                },
                {
                  "label": "CHF50A (METROPOLITAN)",
                  "value": 358
                },
                {
                  "label": "CHF50P (METROPOLITAN II)",
                  "value": 359
                },
                {
                  "label": "CHF50PA (METROPOLITAN II)",
                  "value": 360
                },
                {
                  "label": "CHF50PS (METROPOLITAN II)",
                  "value": 361
                },
                {
                  "label": "NRX1800BA (VALKYRIE RUNE)",
                  "value": 362
                },
                {
                  "label": "NRX1800BB (VALKYRIE RUNE)",
                  "value": 363
                },
                {
                  "label": "VTX1800C (VTX)",
                  "value": 364
                },
                {
                  "label": "TRX400FGA",
                  "value": 365
                },
                {
                  "label": "CB750 (NIGHTHAWK 750)",
                  "value": 366
                },
                {
                  "label": "VF750C (MAGNA)",
                  "value": 367
                },
                {
                  "label": "CBR900RR/CBR954RR",
                  "value": 368
                },
                {
                  "label": "CBR900RR/CBR929RR",
                  "value": 369
                },
                {
                  "label": "CBR900RE/CBR929RR ERION EDITION",
                  "value": 370
                },
                {
                  "label": "VTX1800R (VTX)",
                  "value": 371
                },
                {
                  "label": "VTX1800S (VTX)",
                  "value": 372
                },
                {
                  "label": "GL1500CD (VALKYRIE)",
                  "value": 373
                },
                {
                  "label": "XR70R",
                  "value": 374
                },
                {
                  "label": "XR50",
                  "value": 375
                },
                {
                  "label": "CR80R",
                  "value": 376
                },
                {
                  "label": "CR85RB EXPERT",
                  "value": 377
                },
                {
                  "label": "XR80R",
                  "value": 378
                },
                {
                  "label": "XR100R",
                  "value": 379
                },
                {
                  "label": "VFR800FI (INTERCEPTOR)",
                  "value": 380
                },
                {
                  "label": "ST1100A",
                  "value": 381
                },
                {
                  "label": "VT1100C3 (SHADOW AERO)",
                  "value": 382
                },
                {
                  "label": "GL1500C (VALKYRIE)",
                  "value": 383
                },
                {
                  "label": "GL1500CD (VALKYRIE TOURER)",
                  "value": 384
                },
                {
                  "label": "GL1500CT (VALKYRIE TOURER)",
                  "value": 385
                },
                {
                  "label": "GL1500CF (VALKYRIE INTERSTATE)",
                  "value": 386
                },
                {
                  "label": "GL1500SE (GOLD WING SE)",
                  "value": 387
                },
                {
                  "label": "CR80R (CR80R EXPERT)",
                  "value": 388
                },
                {
                  "label": "CR80RB (CR80RB EXPERT)",
                  "value": 389
                },
                {
                  "label": "VT600CD2",
                  "value": 390
                },
                {
                  "label": "VT1100T (SHADOW ACE TOURER)",
                  "value": 391
                },
                {
                  "label": "CR500R",
                  "value": 392
                },
                {
                  "label": "CMX250C2 (REBEL)",
                  "value": 393
                },
                {
                  "label": "VF750C2 (MAGNA)",
                  "value": 394
                },
                {
                  "label": "GL1500A (GOLD WING ASPENCADE)",
                  "value": 395
                },
                {
                  "label": "VT1100C2 (SHADOW ACE)",
                  "value": 396
                },
                {
                  "label": "Z50R",
                  "value": 397
                },
                {
                  "label": "PC800 (PACIFIC COAST)",
                  "value": 398
                },
                {
                  "label": "XR200",
                  "value": 399
                },
                {
                  "label": "GL1500I",
                  "value": 400
                },
                {
                  "label": "EZ90",
                  "value": 401
                },
                {
                  "label": "RVF750R (RC45)",
                  "value": 402
                },
                {
                  "label": "TRX400FG",
                  "value": 403
                },
                {
                  "label": "VTX1300RL/VTX1300R",
                  "value": 404
                },
                {
                  "label": "COTA 300RR",
                  "value": 405
                },
                {
                  "label": "HONDA UTILITY VEHICLE",
                  "value": 406
                },
                {
                  "label": "CBR600",
                  "value": 407
                },
                {
                  "label": "CLARITY",
                  "value": 408
                },
                {
                  "label": "CH250",
                  "value": 409
                },
                {
                  "label": "SE50PI",
                  "value": 410
                },
                {
                  "label": "CH150",
                  "value": 411
                },
                {
                  "label": "SE50H",
                  "value": 412
                },
                {
                  "label": "SE50PH",
                  "value": 413
                },
                {
                  "label": "NQ50",
                  "value": 414
                },
                {
                  "label": "CMX450C",
                  "value": 415
                },
                {
                  "label": "VT700C",
                  "value": 416
                },
                {
                  "label": "VR700FII",
                  "value": 417
                },
                {
                  "label": "VF700C",
                  "value": 418
                },
                {
                  "label": "XL250R",
                  "value": 419
                },
                {
                  "label": "XL600R",
                  "value": 420
                },
                {
                  "label": "TLR200",
                  "value": 421
                },
                {
                  "label": "CMX250C",
                  "value": 422
                },
                {
                  "label": "CN250",
                  "value": 423
                },
                {
                  "label": "NB50",
                  "value": 424
                },
                {
                  "label": "VF1000R",
                  "value": 425
                },
                {
                  "label": "VF500F",
                  "value": 426
                },
                {
                  "label": "CB450SC",
                  "value": 427
                },
                {
                  "label": "TG50",
                  "value": 428
                },
                {
                  "label": "CB700SC",
                  "value": 429
                },
                {
                  "label": "VF1100C",
                  "value": 430
                },
                {
                  "label": "VT500C",
                  "value": 431
                },
                {
                  "label": "NB50M",
                  "value": 432
                },
                {
                  "label": "XL80",
                  "value": 433
                },
                {
                  "label": "XL100S",
                  "value": 434
                },
                {
                  "label": "CB650SC",
                  "value": 435
                },
                {
                  "label": "VF1100S",
                  "value": 436
                },
                {
                  "label": "VF1000F",
                  "value": 437
                },
                {
                  "label": "XL350R",
                  "value": 438
                },
                {
                  "label": "NH80",
                  "value": 439
                },
                {
                  "label": "CB125",
                  "value": 440
                },
                {
                  "label": "VF700",
                  "value": 441
                },
                {
                  "label": "NN50MD",
                  "value": 442
                },
                {
                  "label": "XL125",
                  "value": 443
                },
                {
                  "label": "CMX300/REBEL300",
                  "value": 444
                },
                {
                  "label": "CMX500/REBEL500",
                  "value": 445
                },
                {
                  "label": "CBR1000S2/CBR1000RR SP2",
                  "value": 446
                },
                {
                  "label": "CB1100CA",
                  "value": 447
                },
                {
                  "label": "CB1100NA",
                  "value": 448
                },
                {
                  "label": "A1",
                  "value": 449
                },
                {
                  "label": "CBR1000S1-CBR1000RR SP",
                  "value": 450
                },
                {
                  "label": "CB650F",
                  "value": 451
                },
                {
                  "label": "CB650FA",
                  "value": 452
                },
                {
                  "label": "NCW50/METROPOLITAN",
                  "value": 453
                },
                {
                  "label": "CRF1000 (AFRICA TWIN)",
                  "value": 454
                },
                {
                  "label": "CBF300",
                  "value": 455
                },
                {
                  "label": "CRF450",
                  "value": 456
                },
                {
                  "label": "Z125 (MONKEY)",
                  "value": 457
                },
                {
                  "label": "GL1800/GOLD WING",
                  "value": 458
                },
                {
                  "label": "C125 (SUPER CUB)",
                  "value": 459
                },
                {
                  "label": "CB650R",
                  "value": 460
                },
                {
                  "label": "CB650RA",
                  "value": 461
                },
                {
                  "label": "CBF300/CB300",
                  "value": 462
                },
                {
                  "label": "CLARITY ELECTRIC",
                  "value": 463
                },
                {
                  "label": "PIONEER",
                  "value": 464
                },
                {
                  "label": "CRF1100 (AFRICA TWIN)",
                  "value": 465
                },
                {
                  "label": "VT800C",
                  "value": 466
                },
                {
                  "label": "CH125",
                  "value": 467
                },
                {
                  "label": "CM200",
                  "value": 468
                },
                {
                  "label": "CM250C",
                  "value": 469
                },
                {
                  "label": "CX500C",
                  "value": 470
                },
                {
                  "label": "CX500TC",
                  "value": 471
                },
                {
                  "label": "CM450",
                  "value": 472
                },
                {
                  "label": "VF500C",
                  "value": 473
                },
                {
                  "label": "XL500S",
                  "value": 474
                },
                {
                  "label": "XR500R",
                  "value": 475
                },
                {
                  "label": "CB750",
                  "value": 476
                },
                {
                  "label": "CB650",
                  "value": 477
                },
                {
                  "label": "VF750S",
                  "value": 478
                },
                {
                  "label": "CB550",
                  "value": 479
                },
                {
                  "label": "CX650",
                  "value": 480
                },
                {
                  "label": "ACCORD CROSSTOUR",
                  "value": 481
                },
                {
                  "label": "SXS10S2 (TALON 1000)",
                  "value": 482
                },
                {
                  "label": "ADV150",
                  "value": 483
                },
                {
                  "label": "FIREBLADE",
                  "value": 484
                },
                {
                  "label": "CBX",
                  "value": 485
                },
                {
                  "label": "TRX500 (FOURTRAX FOREMAN RUBICON)",
                  "value": 486
                },
                {
                  "label": "CB1100A/CB1100 RS",
                  "value": 487
                },
                {
                  "label": "CB300F/CB300FA",
                  "value": 488
                },
                {
                  "label": "COTA 300RR/MRT300",
                  "value": 489
                },
                {
                  "label": "WW150/PCX150",
                  "value": 490
                },
                {
                  "label": "SXS1000 (PIONEER 1000)",
                  "value": 491
                },
                {
                  "label": "SXS700 (PIONEER 700)",
                  "value": 492
                },
                {
                  "label": "SXS500 (PIONEER 500)",
                  "value": 493
                },
                {
                  "label": "TRX420 (RANCHER)",
                  "value": 494
                },
                {
                  "label": "TRX500 (FOREMAN)",
                  "value": 495
                },
                {
                  "label": "TRX500 (FOREMAN RUBICON)",
                  "value": 496
                },
                {
                  "label": "TRX500 (RUBICON)",
                  "value": 497
                },
                {
                  "label": "CB300",
                  "value": 498
                },
                {
                  "label": "CB1000",
                  "value": 499
                },
                {
                  "label": "TRAIL 125",
                  "value": 500
                }
              ],
                  value: 21
                }, {
                  label: "HUMMER",
                  models: [
              {
                "label": "H2",
                "value": 0
              },
              {
                "label": "H3",
                "value": 1
              },
              {
                "label": "H1",
                "value": 2
              },
              {
                "label": "H3T",
                "value": 3
              }
            ],
                  value: 22
                }, {
                  label: "HYUNDAI",
                  models: [
                {
                  "label": "EQUUS",
                  "value": 0
                },
                {
                  "label": "SANTA FE",
                  "value": 1
                },
                {
                  "label": "TUCSON",
                  "value": 2
                },
                {
                  "label": "VELOSTER",
                  "value": 3
                },
                {
                  "label": "GENESIS COUPE",
                  "value": 4
                },
                {
                  "label": "SONATA",
                  "value": 5
                },
                {
                  "label": "ELANTRA",
                  "value": 6
                },
                {
                  "label": "ENTOURAGE",
                  "value": 7
                },
                {
                  "label": "AZERA",
                  "value": 8
                },
                {
                  "label": "VERACRUZ",
                  "value": 9
                },
                {
                  "label": "ACCENT",
                  "value": 10
                },
                {
                  "label": "GENESIS",
                  "value": 11
                },
                {
                  "label": "TIBURON",
                  "value": 12
                },
                {
                  "label": "ELANTRA TOURING",
                  "value": 13
                },
                {
                  "label": "XG 350",
                  "value": 14
                },
                {
                  "label": "EXCEL",
                  "value": 15
                },
                {
                  "label": "SCOUPE",
                  "value": 16
                },
                {
                  "label": "XG300",
                  "value": 17
                },
                {
                  "label": "ELANTRA GT",
                  "value": 18
                },
                {
                  "label": "HYUNDAI TRANSLEAD",
                  "value": 19
                },
                {
                  "label": "HYUNDAI STEEL INDUSTRIES, INC.",
                  "value": 20
                },
                {
                  "label": "PONY",
                  "value": 21
                },
                {
                  "label": "STELLAR",
                  "value": 22
                },
                {
                  "label": "SANTA FE SPORT",
                  "value": 23
                },
                {
                  "label": "IONIQ",
                  "value": 24
                },
                {
                  "label": "KONA",
                  "value": 25
                },
                {
                  "label": "SANTA FE XL",
                  "value": 26
                },
                {
                  "label": "NEXO",
                  "value": 27
                },
                {
                  "label": "PALISADE",
                  "value": 28
                },
                {
                  "label": "VENUE",
                  "value": 29
                },
                {
                  "label": "KONA ELECTRIC",
                  "value": 30
                },
                {
                  "label": "VELOSTER N",
                  "value": 31
                }
              ],
                  value: 23
                }, {
                  label: "INFINITI",
                  models: [
                {
                  "label": "QX50",
                  "value": 0
                },
                {
                  "label": "Q40",
                  "value": 1
                },
                {
                  "label": "Q50",
                  "value": 2
                },
                {
                  "label": "Q60",
                  "value": 3
                },
                {
                  "label": "Q70",
                  "value": 4
                },
                {
                  "label": "QX70",
                  "value": 5
                },
                {
                  "label": "QX80",
                  "value": 6
                },
                {
                  "label": "QX60",
                  "value": 7
                },
                {
                  "label": "Q70L",
                  "value": 8
                },
                {
                  "label": "Q45",
                  "value": 9
                },
                {
                  "label": "G35",
                  "value": 10
                },
                {
                  "label": "M35",
                  "value": 11
                },
                {
                  "label": "M45",
                  "value": 12
                },
                {
                  "label": "FX35",
                  "value": 13
                },
                {
                  "label": "FX45",
                  "value": 14
                },
                {
                  "label": "QX56",
                  "value": 15
                },
                {
                  "label": "G37",
                  "value": 16
                },
                {
                  "label": "EX35",
                  "value": 17
                },
                {
                  "label": "FX50",
                  "value": 18
                },
                {
                  "label": "M56",
                  "value": 19
                },
                {
                  "label": "M37",
                  "value": 20
                },
                {
                  "label": "G25",
                  "value": 21
                },
                {
                  "label": "M35H",
                  "value": 22
                },
                {
                  "label": "FX37",
                  "value": 23
                },
                {
                  "label": "JX35",
                  "value": 24
                },
                {
                  "label": "J30",
                  "value": 25
                },
                {
                  "label": "G20",
                  "value": 26
                },
                {
                  "label": "I35",
                  "value": 27
                },
                {
                  "label": "I30",
                  "value": 28
                },
                {
                  "label": "QX4",
                  "value": 29
                },
                {
                  "label": "M30",
                  "value": 30
                },
                {
                  "label": "QX30",
                  "value": 31
                }
              ],
                  value: 24
                }, {
                  label: "IVECO",
                  models: [],
                  value: 25
                }, {
                  label: "JAGUAR",
                  models: [
                {
                  "label": "XJ",
                  "value": 0
                },
                {
                  "label": "XF",
                  "value": 1
                },
                {
                  "label": "XK",
                  "value": 2
                },
                {
                  "label": "X-TYPE",
                  "value": 3
                },
                {
                  "label": "S-TYPE",
                  "value": 4
                },
                {
                  "label": "F-TYPE",
                  "value": 5
                },
                {
                  "label": "VANDEN PLAS",
                  "value": 6
                },
                {
                  "label": "XK8",
                  "value": 7
                },
                {
                  "label": "XJR",
                  "value": 8
                },
                {
                  "label": "XE",
                  "value": 9
                },
                {
                  "label": "F-PACE",
                  "value": 10
                },
                {
                  "label": "SUPER V8",
                  "value": 11
                },
                {
                  "label": "XJ8",
                  "value": 12
                },
                {
                  "label": "XJ6",
                  "value": 13
                },
                {
                  "label": "XJS",
                  "value": 14
                },
                {
                  "label": "E-PACE",
                  "value": 15
                },
                {
                  "label": "I-PACE",
                  "value": 16
                },
                {
                  "label": "XJ12",
                  "value": 17
                },
                {
                  "label": "XKR",
                  "value": 18
                }
              ],
                  value: 26
                }, {
                  label: "JEEP",
                  models: [
                {
                  "label": "WRANGLER",
                  "value": 0
                },
                {
                  "label": "LIBERTY",
                  "value": 1
                },
                {
                  "label": "CHEROKEE",
                  "value": 2
                },
                {
                  "label": "COMPASS",
                  "value": 3
                },
                {
                  "label": "PATRIOT",
                  "value": 4
                },
                {
                  "label": "COMMANDER",
                  "value": 5
                },
                {
                  "label": "GRAND CHEROKEE",
                  "value": 6
                },
                {
                  "label": "RENEGADE",
                  "value": 7
                },
                {
                  "label": "GRAND WAGONEER",
                  "value": 8
                },
                {
                  "label": "COMANCHE",
                  "value": 9
                },
                {
                  "label": "WAGONEER",
                  "value": 10
                },
                {
                  "label": "J-10",
                  "value": 11
                },
                {
                  "label": "J-20",
                  "value": 12
                },
                {
                  "label": "CJ-7",
                  "value": 13
                },
                {
                  "label": "CJ-8 SCRAMBLER",
                  "value": 14
                },
                {
                  "label": "CJ-5",
                  "value": 15
                },
                {
                  "label": "CJ-6",
                  "value": 16
                },
                {
                  "label": "WRANGLER JK",
                  "value": 17
                },
                {
                  "label": "GLADIATOR",
                  "value": 18
                }
              ],
                  value: 27
                }, {
                  label: "KIA",
                  models: [
                {
                  "label": "RIO",
                  "value": 0
                },
                {
                  "label": "SOUL",
                  "value": 1
                },
                {
                  "label": "BORREGO",
                  "value": 2
                },
                {
                  "label": "FORTE",
                  "value": 3
                },
                {
                  "label": "RONDO",
                  "value": 4
                },
                {
                  "label": "OPTIMA",
                  "value": 5
                },
                {
                  "label": "SEDONA",
                  "value": 6
                },
                {
                  "label": "SORENTO",
                  "value": 7
                },
                {
                  "label": "SPORTAGE",
                  "value": 8
                },
                {
                  "label": "CADENZA",
                  "value": 9
                },
                {
                  "label": "K900",
                  "value": 10
                },
                {
                  "label": "SEPHIA",
                  "value": 11
                },
                {
                  "label": "SPECTRA",
                  "value": 12
                },
                {
                  "label": "AMANTI",
                  "value": 13
                },
                {
                  "label": "SPECTRA LD",
                  "value": 14
                },
                {
                  "label": "SEPHIA II/SPECTRA",
                  "value": 15
                },
                {
                  "label": "FORTE KOUP",
                  "value": 16
                },
                {
                  "label": "SOUL EV",
                  "value": 17
                },
                {
                  "label": "MIAMI",
                  "value": 18
                },
                {
                  "label": "NIRO",
                  "value": 19
                },
                {
                  "label": "STINGER",
                  "value": 20
                },
                {
                  "label": "TELLURIDE",
                  "value": 21
                },
                {
                  "label": "SELTOS",
                  "value": 22
                },
                {
                  "label": "K5",
                  "value": 23
                }
              ],
                  value: 28
                }, {
                  label: "KTM",
                  models: [],
                  value: 29
                }, {
                  label: "LADA",
                  models: [],
                  value: 30
                }, {
                  label: "LAMBORGHINI",
                  models: [],
                  value: 31
                }, {
                  label: "LANCIA",
                  models: [],
                  value: 32
                }, {
                  label: "LAND ROVER",
                  models: [
                    {
                      "label": "DISCOVERY SPORT",
                      "value": 0
                    },
                    {
                      "label": "LR2",
                      "value": 1
                    },
                    {
                      "label": "RANGE ROVER EVOQUE",
                      "value": 2
                    },
                    {
                      "label": "LR4",
                      "value": 3
                    },
                    {
                      "label": "RANGE ROVER SPORT",
                      "value": 4
                    },
                    {
                      "label": "RANGE ROVER",
                      "value": 5
                    },
                    {
                      "label": "LR3",
                      "value": 6
                    },
                    {
                      "label": "DISCOVERY",
                      "value": 7
                    },
                    {
                      "label": "FREELANDER",
                      "value": 8
                    },
                    {
                      "label": "DEFENDER",
                      "value": 9
                    },
                    {
                      "label": "RANGE ROVER VELAR",
                      "value": 10
                    }
                  ],
                  value: 33
                }, {
                  label: "LANDWIND",
                  models: [],
                  value: 34
                }, {
                  label: "LEXUS",
                  models: [
                {
                  "label": "GS",
                  "value": 0
                },
                {
                  "label": "LS",
                  "value": 1
                },
                {
                  "label": "SC",
                  "value": 2
                },
                {
                  "label": "IS",
                  "value": 3
                },
                {
                  "label": "LX",
                  "value": 4
                },
                {
                  "label": "RX",
                  "value": 5
                },
                {
                  "label": "GX",
                  "value": 6
                },
                {
                  "label": "ES",
                  "value": 7
                },
                {
                  "label": "CT",
                  "value": 8
                },
                {
                  "label": "HS",
                  "value": 9
                },
                {
                  "label": "RC",
                  "value": 10
                },
                {
                  "label": "LFA",
                  "value": 11
                },
                {
                  "label": "NX",
                  "value": 12
                },
                {
                  "label": "LC500 / LC 500H",
                  "value": 13
                },
                {
                  "label": "LX 570",
                  "value": 14
                },
                {
                  "label": "RC F",
                  "value": 15
                },
                {
                  "label": "LC",
                  "value": 16
                },
                {
                  "label": "UX",
                  "value": 17
                },
                {
                  "label": "LS/LS HYBRID",
                  "value": 18
                },
                {
                  "label": "ES / ES HYBRID",
                  "value": 19
                }
              ],
                  value: 35
                }, {
                  label: "LOTUS",
                  models: [],
                  value: 36
                }, {
                  label: "MASERATI",
                  models: [],
                  value: 37
                }, {
                  label: "MAYBACH",
                  models: [],
                  value: 38
                }, {
                  label: "MAZDA",
                  models: [
                {
                  "label": "TRIBUTE",
                  "value": 0
                },
                {
                  "label": "MX-5",
                  "value": 1
                },
                {
                  "label": "RX-8",
                  "value": 2
                },
                {
                  "label": "MAZDA3",
                  "value": 3
                },
                {
                  "label": "MAZDA5",
                  "value": 4
                },
                {
                  "label": "CX-7",
                  "value": 5
                },
                {
                  "label": "CX-9",
                  "value": 6
                },
                {
                  "label": "MAZDA6",
                  "value": 7
                },
                {
                  "label": "MAZDA2",
                  "value": 8
                },
                {
                  "label": "MPV",
                  "value": 9
                },
                {
                  "label": "CX-5",
                  "value": 10
                },
                {
                  "label": "B-SERIES",
                  "value": 11
                },
                {
                  "label": "PROTEGE",
                  "value": 12
                },
                {
                  "label": "MILLENIA",
                  "value": 13
                },
                {
                  "label": "626",
                  "value": 14
                },
                {
                  "label": "323",
                  "value": 15
                },
                {
                  "label": "MX-3",
                  "value": 16
                },
                {
                  "label": "929",
                  "value": 17
                },
                {
                  "label": "RX-7",
                  "value": 18
                },
                {
                  "label": "MX-6",
                  "value": 19
                },
                {
                  "label": "CX-3",
                  "value": 20
                },
                {
                  "label": "GLC",
                  "value": 21
                },
                {
                  "label": "NAVAJO",
                  "value": 22
                },
                {
                  "label": "CX-30",
                  "value": 23
                }
              ],
                  value: 39
                }, {
                  label: "MCLAREN",
                  models: [],
                  value: 40
                }, {
                  label: "MERCEDES-BENZ",
                  models: [
                {
                  "label": "SPRINTER",
                  "value": 0
                },
                {
                  "label": "SL-CLASS",
                  "value": 1
                },
                {
                  "label": "SLK-CLASS",
                  "value": 2
                },
                {
                  "label": "E-CLASS",
                  "value": 3
                },
                {
                  "label": "CLS-CLASS",
                  "value": 4
                },
                {
                  "label": "CLA-CLASS",
                  "value": 5
                },
                {
                  "label": "GLA-CLASS",
                  "value": 6
                },
                {
                  "label": "C-CLASS",
                  "value": 7
                },
                {
                  "label": "S-CLASS",
                  "value": 8
                },
                {
                  "label": "SLS-CLASS",
                  "value": 9
                },
                {
                  "label": "B-CLASS",
                  "value": 10
                },
                {
                  "label": "M-CLASS",
                  "value": 11
                },
                {
                  "label": "GL-CLASS",
                  "value": 12
                },
                {
                  "label": "G-CLASS",
                  "value": 13
                },
                {
                  "label": "GLK-CLASS",
                  "value": 14
                },
                {
                  "label": "CL-CLASS",
                  "value": 15
                },
                {
                  "label": "CLK-CLASS",
                  "value": 16
                },
                {
                  "label": "SLR MCLAREN",
                  "value": 17
                },
                {
                  "label": "R-CLASS",
                  "value": 18
                },
                {
                  "label": "AMG GT",
                  "value": 19
                },
                {
                  "label": "GLC-CLASS",
                  "value": 20
                },
                {
                  "label": "GLE-CLASS",
                  "value": 21
                },
                {
                  "label": "METRIS",
                  "value": 22
                },
                {
                  "label": "SLC-CLASS",
                  "value": 23
                },
                {
                  "label": "ML-CLASS",
                  "value": 24
                },
                {
                  "label": "GLS-CLASS",
                  "value": 25
                },
                {
                  "label": "190",
                  "value": 26
                },
                {
                  "label": "300",
                  "value": 27
                },
                {
                  "label": "500",
                  "value": 28
                },
                {
                  "label": "L1013",
                  "value": 29
                },
                {
                  "label": "L1113",
                  "value": 30
                },
                {
                  "label": "L1116",
                  "value": 31
                },
                {
                  "label": "L1316",
                  "value": 32
                },
                {
                  "label": "L1418",
                  "value": 33
                },
                {
                  "label": "L1117",
                  "value": 34
                },
                {
                  "label": "L1317",
                  "value": 35
                },
                {
                  "label": "L1419",
                  "value": 36
                },
                {
                  "label": "LP1419",
                  "value": 37
                },
                {
                  "label": "LPS1525",
                  "value": 38
                },
                {
                  "label": "LP1219",
                  "value": 39
                },
                {
                  "label": "L1319",
                  "value": 40
                },
                {
                  "label": "280",
                  "value": 41
                },
                {
                  "label": "240",
                  "value": 42
                },
                {
                  "label": "380",
                  "value": 43
                },
                {
                  "label": "400",
                  "value": 44
                },
                {
                  "label": "600",
                  "value": 45
                },
                {
                  "label": "350",
                  "value": 46
                },
                {
                  "label": "420",
                  "value": 47
                },
                {
                  "label": "560",
                  "value": 48
                },
                {
                  "label": "260",
                  "value": 49
                },
                {
                  "label": "A-CLASS",
                  "value": 50
                },
                {
                  "label": "GLB-CLASS",
                  "value": 51
                },
                {
                  "label": "EQC-CLASS",
                  "value": 52
                }
              ],
                  value: 41
                }, {
                  label: "MG",
                  models: [],
                  value: 42
                }, {
                  label: "MINI",
                  models: [
              {
                "label": "COOPER",
                "value": 0
              },
              {
                "label": "COOPER S",
                "value": 1
              },
              {
                "label": "JCW HARDTOP",
                "value": 2
              },
              {
                "label": "COOPER CLUBMAN",
                "value": 3
              },
              {
                "label": "COOPER S CLUBMAN",
                "value": 4
              },
              {
                "label": "JCW CLUBMAN",
                "value": 5
              },
              {
                "label": "COOPER CONVERTIBLE",
                "value": 6
              },
              {
                "label": "COOPER S CONVERTIBLE",
                "value": 7
              },
              {
                "label": "JCW CONVERTIBLE",
                "value": 8
              },
              {
                "label": "COOPER HARDTOP",
                "value": 9
              },
              {
                "label": "COOPER COUNTRYMAN",
                "value": 10
              },
              {
                "label": "COOPER S COUNTRYMAN",
                "value": 11
              },
              {
                "label": "COOPER S COUNTRYMAN ALL4",
                "value": 12
              },
              {
                "label": "COOPER S HARDTOP",
                "value": 13
              },
              {
                "label": "COOPER COUPE",
                "value": 14
              },
              {
                "label": "COOPER COUPE",
                "value": 15
              },
              {
                "label": "COOPER S COUPE",
                "value": 16
              },
              {
                "label": "JCW COUPE",
                "value": 17
              },
              {
                "label": "COOPER ROADSTER",
                "value": 18
              },
              {
                "label": "COOPER S ROADSTER",
                "value": 19
              },
              {
                "label": "COOPER S PACEMAN",
                "value": 20
              },
              {
                "label": "COOPER PACEMAN",
                "value": 21
              },
              {
                "label": "COOPER S PACEMAN ALL4",
                "value": 22
              },
              {
                "label": "JCW ROADSTER",
                "value": 23
              },
              {
                "label": "JCW COUNTRYMAN",
                "value": 24
              },
              {
                "label": "JCW PACEMAN",
                "value": 25
              },
              {
                "label": "COOPER S JCW GP",
                "value": 26
              },
              {
                "label": "DELTA WASECA MINI",
                "value": 27
              },
              {
                "label": "CAROLINA TRIKES & MINIS",
                "value": 28
              },
              {
                "label": "MOBILE FRAC STORAGE TANK",
                "value": 29
              },
              {
                "label": "ODB TRAILER",
                "value": 30
              },
              {
                "label": "MOBILE MINI INC.",
                "value": 31
              },
              {
                "label": "MINITEARS COMPANY",
                "value": 32
              },
              {
                "label": "TRAILERS Y TANQUES DE ALUMINIO S.A. DE C.V.",
                "value": 33
              },
              {
                "label": "DOMINIGHT LLC",
                "value": 34
              },
              {
                "label": "MILLER",
                "value": 35
              },
              {
                "label": "SANTA BARBARA",
                "value": 36
              },
              {
                "label": "PETE",
                "value": 37
              },
              {
                "label": "KW",
                "value": 38
              },
              {
                "label": "FL",
                "value": 39
              },
              {
                "label": "MACK",
                "value": 40
              },
              {
                "label": "PONY",
                "value": 41
              },
              {
                "label": "MINIMIXX",
                "value": 42
              },
              {
                "label": "GEMINI AUTO & TRAILER INC",
                "value": 43
              },
              {
                "label": "DOMINION MOTORCYCLE",
                "value": 44
              },
              {
                "label": "MY MINI TRAILER LLC.",
                "value": 45
              },
              {
                "label": "LOS LOBOS MINI CHOPPERS, LLC",
                "value": 46
              },
              {
                "label": "MINIKAMP",
                "value": 47
              },
              {
                "label": "R.V. MINI MART, INC.",
                "value": 48
              },
              {
                "label": "COOPER SE COUNTRYMAN ALL4",
                "value": 49
              },
              {
                "label": "COOPER COUNTRYMAN ALL4",
                "value": 50
              },
              {
                "label": "JCW COUNTRYMAN ALL4",
                "value": 51
              },
              {
                "label": "JCW CLUBMAN ALL4",
                "value": 52
              },
              {
                "label": "COOPER S CLUBMAN ALL4",
                "value": 53
              },
              {
                "label": "ALUMINIUM FLATBED - BENSON",
                "value": 54
              }
            ],
                  value: 43
                }, {
                  label: "MITSUBISHI",
                  models: [
                {
                  "label": "RAIDER",
                  "value": 0
                },
                {
                  "label": "OUTLANDER",
                  "value": 1
                },
                {
                  "label": "LANCER",
                  "value": 2
                },
                {
                  "label": "GALANT",
                  "value": 3
                },
                {
                  "label": "ECLIPSE",
                  "value": 4
                },
                {
                  "label": "ENDEAVOR",
                  "value": 5
                },
                {
                  "label": "FE83D",
                  "value": 6
                },
                {
                  "label": "FE84D",
                  "value": 7
                },
                {
                  "label": "FE85D",
                  "value": 8
                },
                {
                  "label": "FE84DW",
                  "value": 9
                },
                {
                  "label": "FG84D",
                  "value": 10
                },
                {
                  "label": "FK61F",
                  "value": 11
                },
                {
                  "label": "FM61F",
                  "value": 12
                },
                {
                  "label": "FM64F",
                  "value": 13
                },
                {
                  "label": "FM65F",
                  "value": 14
                },
                {
                  "label": "FK62F",
                  "value": 15
                },
                {
                  "label": "FK65F",
                  "value": 16
                },
                {
                  "label": "FEC52S",
                  "value": 17
                },
                {
                  "label": "FEC72S",
                  "value": 18
                },
                {
                  "label": "FEC72W",
                  "value": 19
                },
                {
                  "label": "FEC92S",
                  "value": 20
                },
                {
                  "label": "FGB72S",
                  "value": 21
                },
                {
                  "label": "FECX2S",
                  "value": 22
                },
                {
                  "label": "MONTERO",
                  "value": 23
                },
                {
                  "label": "ECLIPSE SPYDER",
                  "value": 24
                },
                {
                  "label": "RVR",
                  "value": 25
                },
                {
                  "label": "I-MIEV",
                  "value": 26
                },
                {
                  "label": "OUTLANDER - PHEV",
                  "value": 27
                },
                {
                  "label": "MIRAGE",
                  "value": 28
                },
                {
                  "label": "FH100",
                  "value": 29
                },
                {
                  "label": "FM557",
                  "value": 30
                },
                {
                  "label": "FE439",
                  "value": 31
                },
                {
                  "label": "FE449",
                  "value": 32
                },
                {
                  "label": "FK417",
                  "value": 33
                },
                {
                  "label": "FK457",
                  "value": 34
                },
                {
                  "label": "FE639",
                  "value": 35
                },
                {
                  "label": "FE649",
                  "value": 36
                },
                {
                  "label": "FH211",
                  "value": 37
                },
                {
                  "label": "FK617",
                  "value": 38
                },
                {
                  "label": "FM617",
                  "value": 39
                },
                {
                  "label": "FM657",
                  "value": 40
                },
                {
                  "label": "EXPO",
                  "value": 41
                },
                {
                  "label": "3000 GT",
                  "value": 42
                },
                {
                  "label": "3000 GT SPYDER",
                  "value": 43
                },
                {
                  "label": "DIAMANTE",
                  "value": 44
                },
                {
                  "label": "TRUCK",
                  "value": 45
                },
                {
                  "label": "FG639",
                  "value": 46
                },
                {
                  "label": "FE640",
                  "value": 47
                },
                {
                  "label": "MONTERO SPORT",
                  "value": 48
                },
                {
                  "label": "FG649",
                  "value": 49
                },
                {
                  "label": "FH210",
                  "value": 50
                },
                {
                  "label": "LANCER SPORTBACK",
                  "value": 51
                },
                {
                  "label": "LANCER EVOLUTION",
                  "value": 52
                },
                {
                  "label": "STERLING 360",
                  "value": 53
                },
                {
                  "label": "FE640DW",
                  "value": 54
                },
                {
                  "label": "MIRAGE G4",
                  "value": 55
                },
                {
                  "label": "OUTLANDER SPORT",
                  "value": 56
                },
                {
                  "label": "EXPO LRV",
                  "value": 57
                },
                {
                  "label": "FG439",
                  "value": 58
                },
                {
                  "label": "PRECIS",
                  "value": 59
                },
                {
                  "label": "FK415",
                  "value": 60
                },
                {
                  "label": "FK455",
                  "value": 61
                },
                {
                  "label": "FM555",
                  "value": 62
                },
                {
                  "label": "FE434",
                  "value": 63
                },
                {
                  "label": "FE444",
                  "value": 64
                },
                {
                  "label": "FG434",
                  "value": 65
                },
                {
                  "label": "MIRAGE STATION WAGON",
                  "value": 66
                },
                {
                  "label": "SPACE WAGON",
                  "value": 67
                },
                {
                  "label": "WAGON",
                  "value": 68
                },
                {
                  "label": "SIGMA",
                  "value": 69
                },
                {
                  "label": "STARION",
                  "value": 70
                },
                {
                  "label": "VAN",
                  "value": 71
                },
                {
                  "label": "TREDIA",
                  "value": 72
                },
                {
                  "label": "CORDIA",
                  "value": 73
                },
                {
                  "label": "MIGHTY MAX",
                  "value": 74
                },
                {
                  "label": "SPX",
                  "value": 75
                },
                {
                  "label": "SP",
                  "value": 76
                },
                {
                  "label": "ECLIPSE CROSS",
                  "value": 77
                },
                {
                  "label": "FEC7US / ECANTER",
                  "value": 78
                },
                {
                  "label": "FE140 (FECZTS)",
                  "value": 79
                },
                {
                  "label": "FE160 (FEC7TS)",
                  "value": 80
                },
                {
                  "label": "FE180 (FEC9TS)",
                  "value": 81
                },
                {
                  "label": "FE160 (FEC7TW)",
                  "value": 82
                },
                {
                  "label": "LOW SPEED VEHICLE",
                  "value": 83
                }
              ],
                  value: 44
                }, {
                  label: "MORGAN",
                  models: [],
                  value: 45
                }, {
                  label: "NISSAN",
                  models: [
                {
                  "label": "GT-R",
                  "value": 0
                },
                {
                  "label": "VERSA",
                  "value": 1
                },
                {
                  "label": "VERSA NOTE",
                  "value": 2
                },
                {
                  "label": "ALTIMA",
                  "value": 3
                },
                {
                  "label": "LEAF",
                  "value": 4
                },
                {
                  "label": "370Z",
                  "value": 5
                },
                {
                  "label": "SENTRA",
                  "value": 6
                },
                {
                  "label": "PATHFINDER",
                  "value": 7
                },
                {
                  "label": "JUKE",
                  "value": 8
                },
                {
                  "label": "ROGUE",
                  "value": 9
                },
                {
                  "label": "MURANO",
                  "value": 10
                },
                {
                  "label": "ARMADA",
                  "value": 11
                },
                {
                  "label": "XTERRA",
                  "value": 12
                },
                {
                  "label": "QUEST",
                  "value": 13
                },
                {
                  "label": "NV200",
                  "value": 14
                },
                {
                  "label": "FRONTIER",
                  "value": 15
                },
                {
                  "label": "TITAN",
                  "value": 16
                },
                {
                  "label": "NV",
                  "value": 17
                },
                {
                  "label": "MAXIMA",
                  "value": 18
                },
                {
                  "label": "350Z",
                  "value": 19
                },
                {
                  "label": "X-TRAIL",
                  "value": 20
                },
                {
                  "label": "CUBE",
                  "value": 21
                },
                {
                  "label": "240SX",
                  "value": 22
                },
                {
                  "label": "300ZX",
                  "value": 23
                },
                {
                  "label": "PICKUP",
                  "value": 24
                },
                {
                  "label": "ALTRA-EV",
                  "value": 25
                },
                {
                  "label": "NX",
                  "value": 26
                },
                {
                  "label": "NX/SENTRA",
                  "value": 27
                },
                {
                  "label": "STANZA",
                  "value": 28
                },
                {
                  "label": "AXXESS",
                  "value": 29
                },
                {
                  "label": "PULSAR",
                  "value": 30
                },
                {
                  "label": "NV3500",
                  "value": 31
                },
                {
                  "label": "VAN",
                  "value": 32
                },
                {
                  "label": "STANZA WAGON",
                  "value": 33
                },
                {
                  "label": "200SX",
                  "value": 34
                },
                {
                  "label": "ROGUE SPORT",
                  "value": 35
                },
                {
                  "label": "SENTRA CLASSIC",
                  "value": 36
                },
                {
                  "label": "ALTRA",
                  "value": 37
                },
                {
                  "label": "MICRA",
                  "value": 38
                },
                {
                  "label": "ROGUE SELECT",
                  "value": 39
                },
                {
                  "label": "KICKS",
                  "value": 40
                }
              ],
                  value: 46
                }, {
                  label: "OPEL",
                  models: [],
                  value: 47
                }, {
                  label: "PEUGEOT",
                  models: [],
                  value: 48
                }, {
                  label: "PORSCHE",
                  models: [
                {
                  "label": "911",
                  "value": 0
                },
                {
                  "label": "BOXSTER",
                  "value": 1
                },
                {
                  "label": "CAYENNE",
                  "value": 2
                },
                {
                  "label": "CAYMAN",
                  "value": 3
                },
                {
                  "label": "PANAMERA",
                  "value": 4
                },
                {
                  "label": "918",
                  "value": 5
                },
                {
                  "label": "MACAN",
                  "value": 6
                },
                {
                  "label": "944",
                  "value": 7
                },
                {
                  "label": "928",
                  "value": 8
                },
                {
                  "label": "924",
                  "value": 9
                },
                {
                  "label": "BOXSTER S",
                  "value": 10
                },
                {
                  "label": "968",
                  "value": 11
                },
                {
                  "label": "718",
                  "value": 12
                },
                {
                  "label": "718 CAYMAN ",
                  "value": 13
                },
                {
                  "label": "718 BOXSTER",
                  "value": 14
                },
                {
                  "label": "718 SPYDER",
                  "value": 15
                },
                {
                  "label": "TAYCAN",
                  "value": 16
                }
              ],
                  value: 49
                }, {
                  label: "RENAULT",
                  models: [],
                  value: 50
                }, {
                  label: "ROLLS-ROYCE",
                  models: [],
                  value: 51
                }, {
                  label: "ROVER",
                  models: [
              {
                "label": "DISCOVERY SPORT",
                "value": 0
              },
              {
                "label": "LR2",
                "value": 1
              },
              {
                "label": "RANGE ROVER EVOQUE",
                "value": 2
              },
              {
                "label": "LR4",
                "value": 3
              },
              {
                "label": "RANGE ROVER SPORT",
                "value": 4
              },
              {
                "label": "RANGE ROVER",
                "value": 5
              },
              {
                "label": "LR3",
                "value": 6
              },
              {
                "label": "DISCOVERY",
                "value": 7
              },
              {
                "label": "FREELANDER",
                "value": 8
              },
              {
                "label": "DEFENDER",
                "value": 9
              },
              {
                "label": "RANGE ROVER VELAR",
                "value": 10
              },
              {
                "label": "LRD110-R",
                "value": 11
              },
              {
                "label": "LRD90-R",
                "value": 12
              },
              {
                "label": "ROVER",
                "value": 13
              }
            ],
                  value: 52
                }, {
                  label: "SAAB",
                  models: [
              {
                "label": "9-3",
                "value": 0
              },
              {
                "label": "9-5",
                "value": 1
              },
              {
                "label": "9-4X",
                "value": 2
              },
              {
                "label": "9-7X",
                "value": 3
              },
              {
                "label": "900",
                "value": 4
              },
              {
                "label": "9000",
                "value": 5
              },
              {
                "label": "9-2X",
                "value": 6
              }
            ],
                  value: 53
                }, {
                  label: "SEAT",
                  models: [],
                  value: 54
                }, {
                  label: "SKODA",
                  models: [],
                  value: 55
                }, {
                  label: "SMART",
                  models: [],
                  value: 56
                }, {
                  label: "SSANGYONG",
                  models: [],
                  value: 57
                }, {
                  label: "SUBARU",
                  models: [
                {
                  "label": "LEGACY",
                  "value": 0
                },
                {
                  "label": "OUTBACK",
                  "value": 1
                },
                {
                  "label": "FORESTER",
                  "value": 2
                },
                {
                  "label": "IMPREZA",
                  "value": 3
                },
                {
                  "label": "XV CROSSTREK",
                  "value": 4
                },
                {
                  "label": "WRX",
                  "value": 5
                },
                {
                  "label": "BRZ",
                  "value": 6
                },
                {
                  "label": "BAJA",
                  "value": 7
                },
                {
                  "label": "B9 TRIBECA",
                  "value": 8
                },
                {
                  "label": "SVX",
                  "value": 9
                },
                {
                  "label": "CROSSTREK",
                  "value": 10
                },
                {
                  "label": "JUSTY",
                  "value": 11
                },
                {
                  "label": "LOYALE",
                  "value": 12
                },
                {
                  "label": "BRAT",
                  "value": 13
                },
                {
                  "label": "XT",
                  "value": 14
                },
                {
                  "label": "XT6",
                  "value": 15
                },
                {
                  "label": "DL",
                  "value": 16
                },
                {
                  "label": "GL",
                  "value": 17
                },
                {
                  "label": "GL-10",
                  "value": 18
                },
                {
                  "label": "RX",
                  "value": 19
                },
                {
                  "label": "STANDARD",
                  "value": 20
                },
                {
                  "label": "GLF",
                  "value": 21
                },
                {
                  "label": "ASCENT",
                  "value": 22
                }
              ],
                  value: 58
                }, {
                  label: "SUZUKI",
                  models: [
                    {
                      "label": "DR200SE",
                      "value": 0
                    },
                    {
                      "label": "GZ250",
                      "value": 1
                    },
                    {
                      "label": "AN400 / AN400S",
                      "value": 2
                    },
                    {
                      "label": "DR-Z400SL",
                      "value": 3
                    },
                    {
                      "label": "GSX600F/GSX600FT",
                      "value": 4
                    },
                    {
                      "label": "GSX-R600L",
                      "value": 5
                    },
                    {
                      "label": "GSF650S",
                      "value": 6
                    },
                    {
                      "label": "GSF650SA",
                      "value": 7
                    },
                    {
                      "label": "DR650SEL",
                      "value": 8
                    },
                    {
                      "label": "SV650/SV650S/SV650SF",
                      "value": 9
                    },
                    {
                      "label": "AN650L",
                      "value": 10
                    },
                    {
                      "label": "AN650A",
                      "value": 11
                    },
                    {
                      "label": "LS650L",
                      "value": 12
                    },
                    {
                      "label": "DL650L",
                      "value": 13
                    },
                    {
                      "label": "GSX750F/GSX750FT",
                      "value": 14
                    },
                    {
                      "label": "GSX-R750/GSX-R750T",
                      "value": 15
                    },
                    {
                      "label": "VS800",
                      "value": 16
                    },
                    {
                      "label": "VZ800/VZ800Z",
                      "value": 17
                    },
                    {
                      "label": "VL800 / VL800T / VL800C/VL800B",
                      "value": 18
                    },
                    {
                      "label": "DL1000",
                      "value": 19
                    },
                    {
                      "label": "SV1000 / SV1000S",
                      "value": 20
                    },
                    {
                      "label": "GSX-R1000L",
                      "value": 21
                    },
                    {
                      "label": "GSF1250S",
                      "value": 22
                    },
                    {
                      "label": "GSX1300R",
                      "value": 23
                    },
                    {
                      "label": "VS1400/VS1400GL",
                      "value": 24
                    },
                    {
                      "label": "VL1500 / VL1500T",
                      "value": 25
                    },
                    {
                      "label": "VZR1800/VZR1800Z/VZR1800N",
                      "value": 26
                    },
                    {
                      "label": "LT-Z50/LT-Z50Z",
                      "value": 27
                    },
                    {
                      "label": "LT-Z250/ LT-Z250Z",
                      "value": 28
                    },
                    {
                      "label": "LT-F400F",
                      "value": 29
                    },
                    {
                      "label": "LT-A400F/KING QUAD 400AS",
                      "value": 30
                    },
                    {
                      "label": "LT-Z400L/LT-Z400ZL",
                      "value": 31
                    },
                    {
                      "label": "LT-R450/LT-R450Z",
                      "value": 32
                    },
                    {
                      "label": "LT-F500F",
                      "value": 33
                    },
                    {
                      "label": "LT-A500F",
                      "value": 34
                    },
                    {
                      "label": "LT-A700X",
                      "value": 35
                    },
                    {
                      "label": "AERIO",
                      "value": 36
                    },
                    {
                      "label": "XL7",
                      "value": 37
                    },
                    {
                      "label": "SX4",
                      "value": 38
                    },
                    {
                      "label": "EQUATOR",
                      "value": 39
                    },
                    {
                      "label": "KIZASHI",
                      "value": 40
                    },
                    {
                      "label": "DR-Z70",
                      "value": 41
                    },
                    {
                      "label": "DR-Z125L",
                      "value": 42
                    },
                    {
                      "label": "DR-Z125LL",
                      "value": 43
                    },
                    {
                      "label": "DR-Z250",
                      "value": 44
                    },
                    {
                      "label": "DR-Z400E",
                      "value": 45
                    },
                    {
                      "label": "RM85",
                      "value": 46
                    },
                    {
                      "label": "RM85L",
                      "value": 47
                    },
                    {
                      "label": "RM125T",
                      "value": 48
                    },
                    {
                      "label": "RM-Z250L",
                      "value": 49
                    },
                    {
                      "label": "RM250/ RM250T",
                      "value": 50
                    },
                    {
                      "label": "RM-Z450L",
                      "value": 51
                    },
                    {
                      "label": "LT-Z90L",
                      "value": 52
                    },
                    {
                      "label": "LT-F400",
                      "value": 53
                    },
                    {
                      "label": "LT-A400",
                      "value": 54
                    },
                    {
                      "label": "LT-A450X",
                      "value": 55
                    },
                    {
                      "label": "JR80",
                      "value": 56
                    },
                    {
                      "label": "LT-A750X",
                      "value": 57
                    },
                    {
                      "label": "AN400",
                      "value": 58
                    },
                    {
                      "label": "AN400AL",
                      "value": 59
                    },
                    {
                      "label": "SV650A/SV650SA/SV650SAF",
                      "value": 60
                    },
                    {
                      "label": "VLR1800/VLR1800T",
                      "value": 61
                    },
                    {
                      "label": "SFV650L",
                      "value": 62
                    },
                    {
                      "label": "GSX650F",
                      "value": 63
                    },
                    {
                      "label": "GSF1250SA",
                      "value": 64
                    },
                    {
                      "label": "GSX1300BK",
                      "value": 65
                    },
                    {
                      "label": "GSX1300BKA",
                      "value": 66
                    },
                    {
                      "label": "VZ1500L",
                      "value": 67
                    },
                    {
                      "label": "LT-A500XP",
                      "value": 68
                    },
                    {
                      "label": "LT-A750XP",
                      "value": 69
                    },
                    {
                      "label": "VZ800L",
                      "value": 70
                    },
                    {
                      "label": "TU250XL",
                      "value": 71
                    },
                    {
                      "label": "GSX-R1000/GSX-R1000Z",
                      "value": 72
                    },
                    {
                      "label": "VL1500L/VL1500BL/VL1500TL/VL1500BTL",
                      "value": 73
                    },
                    {
                      "label": "UH200L",
                      "value": 74
                    },
                    {
                      "label": "DL1000A/DL1000AA",
                      "value": 75
                    },
                    {
                      "label": "SWIFT",
                      "value": 76
                    },
                    {
                      "label": "FORENZA",
                      "value": 77
                    },
                    {
                      "label": "RENO",
                      "value": 78
                    },
                    {
                      "label": "ESTEEM",
                      "value": 79
                    },
                    {
                      "label": "VITARA",
                      "value": 80
                    },
                    {
                      "label": "SIDEKICK",
                      "value": 81
                    },
                    {
                      "label": "SIDEKICK SPORT",
                      "value": 82
                    },
                    {
                      "label": "X-90",
                      "value": 83
                    },
                    {
                      "label": "TL1000R",
                      "value": 84
                    },
                    {
                      "label": "GN125E/ GN125ET",
                      "value": 85
                    },
                    {
                      "label": "DR350SE",
                      "value": 86
                    },
                    {
                      "label": "GS500E/GS500ET",
                      "value": 87
                    },
                    {
                      "label": "GSF600S/GSF600ST",
                      "value": 88
                    },
                    {
                      "label": "LS650P",
                      "value": 89
                    },
                    {
                      "label": "VS800GL",
                      "value": 90
                    },
                    {
                      "label": "RF900R",
                      "value": 91
                    },
                    {
                      "label": "TL1000S",
                      "value": 92
                    },
                    {
                      "label": "GSX-R1100W",
                      "value": 93
                    },
                    {
                      "label": "GSF1200S/SA",
                      "value": 94
                    },
                    {
                      "label": "RM80T",
                      "value": 95
                    },
                    {
                      "label": "GSX-R750L",
                      "value": 96
                    },
                    {
                      "label": "VZR1800BZL",
                      "value": 97
                    },
                    {
                      "label": "VL1500BL/VL1500TL",
                      "value": 98
                    },
                    {
                      "label": "VL800L/VL800TL",
                      "value": 99
                    },
                    {
                      "label": "GSX1300RAL",
                      "value": 100
                    },
                    {
                      "label": "GSX-R1000AL",
                      "value": 101
                    },
                    {
                      "label": "DR200SL",
                      "value": 102
                    },
                    {
                      "label": "GSX-S750ZL/GSX-S750L",
                      "value": 103
                    },
                    {
                      "label": "GW250FL/GW250ZL",
                      "value": 104
                    },
                    {
                      "label": "DR-Z400SML",
                      "value": 105
                    },
                    {
                      "label": "UH200AL",
                      "value": 106
                    },
                    {
                      "label": "DL650XA/DL650A",
                      "value": 107
                    },
                    {
                      "label": "GW250/GW250F/GW250Z",
                      "value": 108
                    },
                    {
                      "label": "GSF1250SA/GSX1250SA",
                      "value": 109
                    },
                    {
                      "label": "GSX-S1000",
                      "value": 110
                    },
                    {
                      "label": "GSX-S1000A",
                      "value": 111
                    },
                    {
                      "label": "GSX-S1000F",
                      "value": 112
                    },
                    {
                      "label": "GSX-S1000FA",
                      "value": 113
                    },
                    {
                      "label": "LT-A50",
                      "value": 114
                    },
                    {
                      "label": "LT80",
                      "value": 115
                    },
                    {
                      "label": "LT-F160",
                      "value": 116
                    },
                    {
                      "label": "LT-F250L",
                      "value": 117
                    },
                    {
                      "label": "LT-F250F",
                      "value": 118
                    },
                    {
                      "label": "LT-F300F",
                      "value": 119
                    },
                    {
                      "label": "LT-Z400L",
                      "value": 120
                    },
                    {
                      "label": "GS500",
                      "value": 121
                    },
                    {
                      "label": "GSX600F",
                      "value": 122
                    },
                    {
                      "label": "GSF600S",
                      "value": 123
                    },
                    {
                      "label": "SV650/SV650S",
                      "value": 124
                    },
                    {
                      "label": "GSX750F",
                      "value": 125
                    },
                    {
                      "label": "VL800",
                      "value": 126
                    },
                    {
                      "label": "VS1400GLP",
                      "value": 127
                    },
                    {
                      "label": "VL1500",
                      "value": 128
                    },
                    {
                      "label": "JR50",
                      "value": 129
                    },
                    {
                      "label": "DR-Z400",
                      "value": 130
                    },
                    {
                      "label": "RM125",
                      "value": 131
                    },
                    {
                      "label": "RM250",
                      "value": 132
                    },
                    {
                      "label": "DR-Z110",
                      "value": 133
                    },
                    {
                      "label": "RM60",
                      "value": 134
                    },
                    {
                      "label": "RM65",
                      "value": 135
                    },
                    {
                      "label": "RM100",
                      "value": 136
                    },
                    {
                      "label": "GRAND VITARA",
                      "value": 137
                    },
                    {
                      "label": "GRAND VITARA XL-7",
                      "value": 138
                    },
                    {
                      "label": "GS500E",
                      "value": 139
                    },
                    {
                      "label": "GSF1200S",
                      "value": 140
                    },
                    {
                      "label": "SV650",
                      "value": 141
                    },
                    {
                      "label": "VERONA",
                      "value": 142
                    },
                    {
                      "label": "SAMURAI",
                      "value": 143
                    },
                    {
                      "label": "RV200L7",
                      "value": 144
                    },
                    {
                      "label": "SV650L7",
                      "value": 145
                    },
                    {
                      "label": "SV650AL7",
                      "value": 146
                    },
                    {
                      "label": "GSX-S1000AL7",
                      "value": 147
                    },
                    {
                      "label": "GSX-S1000FAL7",
                      "value": 148
                    },
                    {
                      "label": "GSX-R1000R",
                      "value": 149
                    },
                    {
                      "label": "GSX-R750L7",
                      "value": 150
                    },
                    {
                      "label": "GSX-R600L7",
                      "value": 151
                    },
                    {
                      "label": "DL650XAL7",
                      "value": 152
                    },
                    {
                      "label": "GSX-R1000L7/GSX-R1000AL7",
                      "value": 153
                    },
                    {
                      "label": "DL650XAL/DL650AAL/DL650AL",
                      "value": 154
                    },
                    {
                      "label": "DL1000AL/DL1000AAL",
                      "value": 155
                    },
                    {
                      "label": "LT-A400FCL/LT-A400FL",
                      "value": 156
                    },
                    {
                      "label": "LT-A400FCL/LT-A400FL/ LT-A400FZL",
                      "value": 157
                    },
                    {
                      "label": "LT-A500XCL/LT-A500XL",
                      "value": 158
                    },
                    {
                      "label": "LT-A500XPCL/LT-A500XPL",
                      "value": 159
                    },
                    {
                      "label": "LT-A500XPL",
                      "value": 160
                    },
                    {
                      "label": "LT-A750XL/LT-A750XCL",
                      "value": 161
                    },
                    {
                      "label": "LT-A750XPCL/LT-A750XPL/LT-A750XPZL",
                      "value": 162
                    },
                    {
                      "label": "DL650AAL/DL650AL",
                      "value": 163
                    },
                    {
                      "label": "LT-A500XPCL/LT-A500XPL/LT-A500XPZL",
                      "value": 164
                    },
                    {
                      "label": "LT-A750XL/LT-A750XCL/LT-A750XZL",
                      "value": 165
                    },
                    {
                      "label": "VZR1800BZL/VZR1800L/VZR1800ZL",
                      "value": 166
                    },
                    {
                      "label": "GSX1300RAL/GSX1300RAZL",
                      "value": 167
                    },
                    {
                      "label": "GW250L",
                      "value": 168
                    },
                    {
                      "label": "VL800TL",
                      "value": 169
                    },
                    {
                      "label": "VL800BL/VL800CL/VL800L/VL800TL",
                      "value": 170
                    },
                    {
                      "label": "LT-A400F/LT-A400FCL",
                      "value": 171
                    },
                    {
                      "label": "LT-A750X/LT-A750XCL/LT-A750XL",
                      "value": 172
                    },
                    {
                      "label": "RM85LL",
                      "value": 173
                    },
                    {
                      "label": "DR200SEL",
                      "value": 174
                    },
                    {
                      "label": "DL650AL",
                      "value": 175
                    },
                    {
                      "label": "VL800CL/VL800L/VL800TL",
                      "value": 176
                    },
                    {
                      "label": "GSX-R1000L/GSX-R1000ZL",
                      "value": 177
                    },
                    {
                      "label": "VZR1800L/VZR1800ZL",
                      "value": 178
                    },
                    {
                      "label": "LT-A400F/LT-A400FCL/LT-A400FL",
                      "value": 179
                    },
                    {
                      "label": "LT-A500X/LT-A500XCL/LT-A500XL",
                      "value": 180
                    },
                    {
                      "label": "DL1000L",
                      "value": 181
                    },
                    {
                      "label": "GSX1250FAL",
                      "value": 182
                    },
                    {
                      "label": "GSX1300RL/GSX1300RZL",
                      "value": 183
                    },
                    {
                      "label": "LT-A750XL",
                      "value": 184
                    },
                    {
                      "label": "LT-A750XPL",
                      "value": 185
                    },
                    {
                      "label": "DL650A",
                      "value": 186
                    },
                    {
                      "label": "GSX1300RL",
                      "value": 187
                    },
                    {
                      "label": "AN400L",
                      "value": 188
                    },
                    {
                      "label": "LT-A400F",
                      "value": 189
                    },
                    {
                      "label": "LT-A400FU",
                      "value": 190
                    },
                    {
                      "label": "LT-A500X",
                      "value": 191
                    },
                    {
                      "label": "LT-F250",
                      "value": 192
                    },
                    {
                      "label": "LT-F400FU",
                      "value": 193
                    },
                    {
                      "label": "LT-R450",
                      "value": 194
                    },
                    {
                      "label": "LT-R450Z",
                      "value": 195
                    },
                    {
                      "label": "LT-Z400/LT-Z400Z",
                      "value": 196
                    },
                    {
                      "label": "LT-Z90",
                      "value": 197
                    },
                    {
                      "label": "RM-Z250",
                      "value": 198
                    },
                    {
                      "label": "RM-Z450",
                      "value": 199
                    },
                    {
                      "label": "DR-Z400S",
                      "value": 200
                    },
                    {
                      "label": "DR650SE",
                      "value": 201
                    },
                    {
                      "label": "DR-Z125",
                      "value": 202
                    },
                    {
                      "label": "RMX450Z",
                      "value": 203
                    },
                    {
                      "label": "DL650",
                      "value": 204
                    },
                    {
                      "label": "GSX-R600",
                      "value": 205
                    },
                    {
                      "label": "GSX-R750",
                      "value": 206
                    },
                    {
                      "label": "GSX1250FA",
                      "value": 207
                    },
                    {
                      "label": "GS500(GS500H)/GS500F(GS500FH)",
                      "value": 208
                    },
                    {
                      "label": "GSX650FA",
                      "value": 209
                    },
                    {
                      "label": "LS650",
                      "value": 210
                    },
                    {
                      "label": "SFV650",
                      "value": 211
                    },
                    {
                      "label": "SFV650A",
                      "value": 212
                    },
                    {
                      "label": "TU250X",
                      "value": 213
                    },
                    {
                      "label": "VZ1500",
                      "value": 214
                    },
                    {
                      "label": "VL800/VL800C/VL800T",
                      "value": 215
                    },
                    {
                      "label": "VZR1800/VZR1800Z",
                      "value": 216
                    },
                    {
                      "label": "AN400A",
                      "value": 217
                    },
                    {
                      "label": "AN650",
                      "value": 218
                    },
                    {
                      "label": "AN650AL",
                      "value": 219
                    },
                    {
                      "label": "UZ125C",
                      "value": 220
                    },
                    {
                      "label": "LT-F500F/QUADRUNNER",
                      "value": 221
                    },
                    {
                      "label": "GSX-R1000",
                      "value": 222
                    },
                    {
                      "label": "VS1400",
                      "value": 223
                    },
                    {
                      "label": "LT-Z250",
                      "value": 224
                    },
                    {
                      "label": "LT-Z50",
                      "value": 225
                    },
                    {
                      "label": "VZ800/VZ800B/VZ800Z",
                      "value": 226
                    },
                    {
                      "label": "LT-A750X/LT-A750XZ/LT-A750XC",
                      "value": 227
                    },
                    {
                      "label": "SV650A/SV650SA",
                      "value": 228
                    },
                    {
                      "label": "VZ800",
                      "value": 229
                    },
                    {
                      "label": "SV1000S",
                      "value": 230
                    },
                    {
                      "label": "VZR1800",
                      "value": 231
                    },
                    {
                      "label": "LT-Z250K",
                      "value": 232
                    },
                    {
                      "label": "LT-F400F/LT-F400FC",
                      "value": 233
                    },
                    {
                      "label": "LT-A400F/LT-A400FC/LT-A400FH",
                      "value": 234
                    },
                    {
                      "label": "LT-A500F/LT-A500FC",
                      "value": 235
                    },
                    {
                      "label": "GSF1200",
                      "value": 236
                    },
                    {
                      "label": "GSX-R600/GSX-R600X",
                      "value": 237
                    },
                    {
                      "label": "GSX-R750/GSX-R750X",
                      "value": 238
                    },
                    {
                      "label": "GSF1200S/GSF1200SZ",
                      "value": 239
                    },
                    {
                      "label": "LT-A400F/LT-A400FZ",
                      "value": 240
                    },
                    {
                      "label": "LT-Z400Z",
                      "value": 241
                    },
                    {
                      "label": "QUV620F",
                      "value": 242
                    },
                    {
                      "label": "VL800/VL800T/VL800Z",
                      "value": 243
                    },
                    {
                      "label": "GSX600FK",
                      "value": 244
                    },
                    {
                      "label": "LT-V700F",
                      "value": 245
                    },
                    {
                      "label": "VZ1600",
                      "value": 246
                    },
                    {
                      "label": "GS500FK",
                      "value": 247
                    },
                    {
                      "label": "GSX-R600K",
                      "value": 248
                    },
                    {
                      "label": "GSX750FK",
                      "value": 249
                    },
                    {
                      "label": "GSX-R750K",
                      "value": 250
                    },
                    {
                      "label": "GSX-R1000K",
                      "value": 251
                    },
                    {
                      "label": "GSF1200SK",
                      "value": 252
                    },
                    {
                      "label": "GSX1300RK",
                      "value": 253
                    },
                    {
                      "label": "GZ250K",
                      "value": 254
                    },
                    {
                      "label": "LS650PK",
                      "value": 255
                    },
                    {
                      "label": "DL650K",
                      "value": 256
                    },
                    {
                      "label": "VS800GLK",
                      "value": 257
                    },
                    {
                      "label": "VZ800K",
                      "value": 258
                    },
                    {
                      "label": "VL800K",
                      "value": 259
                    },
                    {
                      "label": "DL1000K",
                      "value": 260
                    },
                    {
                      "label": "VS1400GLPK",
                      "value": 261
                    },
                    {
                      "label": "VL1500K",
                      "value": 262
                    },
                    {
                      "label": "DR200SEK",
                      "value": 263
                    },
                    {
                      "label": "DR-Z400SK",
                      "value": 264
                    },
                    {
                      "label": "DR650SEK",
                      "value": 265
                    },
                    {
                      "label": "AN400K",
                      "value": 266
                    },
                    {
                      "label": "AN650K",
                      "value": 267
                    },
                    {
                      "label": "JR80K",
                      "value": 268
                    },
                    {
                      "label": "JR50K",
                      "value": 269
                    },
                    {
                      "label": "DR-Z125K",
                      "value": 270
                    },
                    {
                      "label": "DR-Z125LK",
                      "value": 271
                    },
                    {
                      "label": "DR-Z250K",
                      "value": 272
                    },
                    {
                      "label": "DR-Z400K",
                      "value": 273
                    },
                    {
                      "label": "DR-Z400EK",
                      "value": 274
                    },
                    {
                      "label": "RM85K",
                      "value": 275
                    },
                    {
                      "label": "RM125K",
                      "value": 276
                    },
                    {
                      "label": "RM85LK",
                      "value": 277
                    },
                    {
                      "label": "RM250K",
                      "value": 278
                    },
                    {
                      "label": "LT80K",
                      "value": 279
                    },
                    {
                      "label": "LT160K",
                      "value": 280
                    },
                    {
                      "label": "LT-F250FK",
                      "value": 281
                    },
                    {
                      "label": "LT-F250K",
                      "value": 282
                    },
                    {
                      "label": "LT-F400K",
                      "value": 283
                    },
                    {
                      "label": "LT-A400K",
                      "value": 284
                    },
                    {
                      "label": "LT-F400FK",
                      "value": 285
                    },
                    {
                      "label": "LT-A400FK",
                      "value": 286
                    },
                    {
                      "label": "LT-Z400K",
                      "value": 287
                    },
                    {
                      "label": "LT-F500FK",
                      "value": 288
                    },
                    {
                      "label": "LT-A500FK",
                      "value": 289
                    },
                    {
                      "label": "RM60K",
                      "value": 290
                    },
                    {
                      "label": "RM65K",
                      "value": 291
                    },
                    {
                      "label": "RM100K",
                      "value": 292
                    },
                    {
                      "label": "DR-Z110K",
                      "value": 293
                    },
                    {
                      "label": "RM-Z250K",
                      "value": 294
                    },
                    {
                      "label": "VZ1600K",
                      "value": 295
                    },
                    {
                      "label": "GSF600SK",
                      "value": 296
                    },
                    {
                      "label": "GSF1200SK/GSF1200K",
                      "value": 297
                    },
                    {
                      "label": "SV650K/SV650SK",
                      "value": 298
                    },
                    {
                      "label": "TL1000RK",
                      "value": 299
                    },
                    {
                      "label": "SV1000K / SV1000SK",
                      "value": 300
                    },
                    {
                      "label": "GS500K",
                      "value": 301
                    },
                    {
                      "label": "RM80",
                      "value": 302
                    },
                    {
                      "label": "DS80",
                      "value": 303
                    },
                    {
                      "label": "DR350",
                      "value": 304
                    },
                    {
                      "label": "GN125E",
                      "value": 305
                    },
                    {
                      "label": "RMX250",
                      "value": 306
                    },
                    {
                      "label": "LT-F4WDX",
                      "value": 307
                    },
                    {
                      "label": "LT-F4WD",
                      "value": 308
                    },
                    {
                      "label": "RF600R",
                      "value": 309
                    },
                    {
                      "label": "DR125SE",
                      "value": 310
                    },
                    {
                      "label": "DR250SE",
                      "value": 311
                    },
                    {
                      "label": "GSX-R750W",
                      "value": 312
                    },
                    {
                      "label": "LOW SPEED VEHICLE",
                      "value": 313
                    },
                    {
                      "label": "GSX-R600W",
                      "value": 314
                    },
                    {
                      "label": "GSF400",
                      "value": 315
                    },
                    {
                      "label": "GSX1100G",
                      "value": 316
                    },
                    {
                      "label": "DR650S",
                      "value": 317
                    },
                    {
                      "label": "VX800",
                      "value": 318
                    },
                    {
                      "label": "FORSA",
                      "value": 319
                    },
                    {
                      "label": "GSX-R1100",
                      "value": 320
                    },
                    {
                      "label": "GS550L",
                      "value": 321
                    },
                    {
                      "label": "GS700E",
                      "value": 322
                    },
                    {
                      "label": "GS1150E",
                      "value": 323
                    },
                    {
                      "label": "SP600",
                      "value": 324
                    },
                    {
                      "label": "GV700GL",
                      "value": 325
                    },
                    {
                      "label": "GV1200GL",
                      "value": 326
                    },
                    {
                      "label": "BUSINESS",
                      "value": 327
                    },
                    {
                      "label": "FAMILY",
                      "value": 328
                    },
                    {
                      "label": "TOURING",
                      "value": 329
                    },
                    {
                      "label": "LEISURE",
                      "value": 330
                    },
                    {
                      "label": "ENDURO",
                      "value": 331
                    },
                    {
                      "label": "OFF ROAD PLAY",
                      "value": 332
                    },
                    {
                      "label": "MINI-LEISURE",
                      "value": 333
                    },
                    {
                      "label": "MOTO-CROSS",
                      "value": 334
                    },
                    {
                      "label": "LS650BL7",
                      "value": 335
                    },
                    {
                      "label": "GSX-R1000RAL7/GSX-R1000RAZL7",
                      "value": 336
                    },
                    {
                      "label": "BOULEVARD M109R B.O.S.S.(VZR1800BZL8)",
                      "value": 337
                    },
                    {
                      "label": "BOULEVARD M90(VZ1500L8)",
                      "value": 338
                    },
                    {
                      "label": "BOULEVARD M50(VZ800L8)",
                      "value": 339
                    },
                    {
                      "label": "BOULEVARD S40(LS650BL8 )",
                      "value": 340
                    },
                    {
                      "label": "GSX-S750ZAL8 / GSX-S750L8",
                      "value": 341
                    },
                    {
                      "label": "GSX-S750L8",
                      "value": 342
                    },
                    {
                      "label": "RV200L8",
                      "value": 343
                    },
                    {
                      "label": "TU250XL8",
                      "value": 344
                    },
                    {
                      "label": "GSX250R",
                      "value": 345
                    },
                    {
                      "label": "SV650L8",
                      "value": 346
                    },
                    {
                      "label": "SV650AL8",
                      "value": 347
                    },
                    {
                      "label": "GSX-S1000FAL8",
                      "value": 348
                    },
                    {
                      "label": "GSX-S1000AL8 / GSX-S1000ZAL8",
                      "value": 349
                    },
                    {
                      "label": "HAYABUSA(GSX1300RAL8)",
                      "value": 350
                    },
                    {
                      "label": "GSX-R1000L8",
                      "value": 351
                    },
                    {
                      "label": "GSX-R750L8",
                      "value": 352
                    },
                    {
                      "label": "GSX-R600L8",
                      "value": 353
                    },
                    {
                      "label": "GSX-R1000L8 / GSX-R1000AL8",
                      "value": 354
                    },
                    {
                      "label": "GSX-R1000RAL8 / GSX-R1000RZAL8",
                      "value": 355
                    },
                    {
                      "label": "BOULEVARD C90 B.O.S.S. (VL1500BL8)  / BOULEVARD C90T(VL1500TL8)",
                      "value": 356
                    },
                    {
                      "label": "BOULEVARD C50(VL800L8) / BOULEVARD C50T(VL800TL8)",
                      "value": 357
                    },
                    {
                      "label": "V-STROM 650XT ADVENTURE",
                      "value": 358
                    },
                    {
                      "label": "V-STROM 650XT",
                      "value": 359
                    },
                    {
                      "label": "V-STROM 1000 ABS(DL1000AL8) / V-STROM 1000 XT ABS(DL100XAL8)",
                      "value": 360
                    },
                    {
                      "label": "DR650SEL8",
                      "value": 361
                    },
                    {
                      "label": "DR-Z400SL8",
                      "value": 362
                    },
                    {
                      "label": "DR200SL8",
                      "value": 363
                    },
                    {
                      "label": "DR-Z400SML8",
                      "value": 364
                    },
                    {
                      "label": "BURGMAN 650(AN650ZL8)",
                      "value": 365
                    },
                    {
                      "label": "BURGMAN 400 ABS(AN400AL8 )",
                      "value": 366
                    },
                    {
                      "label": "BURGMAN 200 ABS(UH200AL8)",
                      "value": 367
                    },
                    {
                      "label": "BOULEVARD M109R B.O.S.S. (VZR1800BZL9)",
                      "value": 368
                    },
                    {
                      "label": "BOULEVARD M90 (VZ1500L9)",
                      "value": 369
                    },
                    {
                      "label": "BOULEVARD M50 (VZ800L9)",
                      "value": 370
                    },
                    {
                      "label": "BOULEVARD C90 B.O.S.S. (VL1500BL9) / BOULEVARD C90T (VL1500TL9)",
                      "value": 371
                    },
                    {
                      "label": "BOULEVARD C50 (VL800L9) / BOULEVARD C50T (VL800TL9)",
                      "value": 372
                    },
                    {
                      "label": "BOULEVARD S40 (LS650BL9)",
                      "value": 373
                    },
                    {
                      "label": "GSX-Z750ZAL9 (50ST) / GSX-S750YAL9 (50ST) / GSX-S750ZL9 (50ST)",
                      "value": 374
                    },
                    {
                      "label": "GSX-S750ZL9 (49ST)",
                      "value": 375
                    },
                    {
                      "label": "RV200L9",
                      "value": 376
                    },
                    {
                      "label": "TU250XL9",
                      "value": 377
                    },
                    {
                      "label": "SV650L9",
                      "value": 378
                    },
                    {
                      "label": "SV650AL9 / SV650XAL9",
                      "value": 379
                    },
                    {
                      "label": "GSX-S1000AL9/GSXS1000ZAL9/GSXS1000YAL9",
                      "value": 380
                    },
                    {
                      "label": "GSXS1000FZL9",
                      "value": 381
                    },
                    {
                      "label": "GSX1300RAL9 (HAYABUSA)",
                      "value": 382
                    },
                    {
                      "label": "GSX-R1000AL9 (49ST)",
                      "value": 383
                    },
                    {
                      "label": "GSX-R1000AL9 (50ST)",
                      "value": 384
                    },
                    {
                      "label": "GSXR1000RAL9 (49ST) / GSXR1000RZL9 (49ST)",
                      "value": 385
                    },
                    {
                      "label": "GSXR1000RAL9 (50ST) / GSXR1000RZL9 (50ST)",
                      "value": 386
                    },
                    {
                      "label": "GSX-R750L9",
                      "value": 387
                    },
                    {
                      "label": "GSX-R600L9",
                      "value": 388
                    },
                    {
                      "label": "DR650SEL9",
                      "value": 389
                    },
                    {
                      "label": "DR-Z400SL9",
                      "value": 390
                    },
                    {
                      "label": "DR200SL9",
                      "value": 391
                    },
                    {
                      "label": "DR-Z400SML9",
                      "value": 392
                    },
                    {
                      "label": "BURGMAN 400 ABS (AN400AL9)",
                      "value": 393
                    },
                    {
                      "label": "BURGMAN 200 ABS (UH200AL9)",
                      "value": 394
                    },
                    {
                      "label": "RMX450ZL",
                      "value": 395
                    },
                    {
                      "label": "LT-F400FL/KINGQUAD 400 FSI",
                      "value": 396
                    },
                    {
                      "label": "LT-A400FCL/LT-A400FL/LT-A400FZL (KINGQUAD 400 ASI)",
                      "value": 397
                    },
                    {
                      "label": "LT-A500XPCL/LT-A500XPL/LT-A500XPZL (KINGQUAD 500 AXI PWR STRG)",
                      "value": 398
                    },
                    {
                      "label": "LT-A500XCL/LT-A500XL (KINGQUAD 500 AXI)",
                      "value": 399
                    },
                    {
                      "label": "LT-A750XCL/LT-A750XL (KINGQUAD 750 AXI)",
                      "value": 400
                    },
                    {
                      "label": "LT-A750XPCL/LT-A750XPL/LT-A750XPZL (KINGQUAD 750 AXI PWR STRG)/LTD EDITION",
                      "value": 401
                    },
                    {
                      "label": "RM-Z250L/RM-Z250",
                      "value": 402
                    },
                    {
                      "label": "RM-Z450L/RM-Z450",
                      "value": 403
                    },
                    {
                      "label": "DR-Z125LL/DR-Z125L",
                      "value": 404
                    },
                    {
                      "label": "LT-Z90L/QUADSPORT Z90",
                      "value": 405
                    },
                    {
                      "label": "LT-Z50L",
                      "value": 406
                    },
                    {
                      "label": "DL650XAAL9 (V-STROM 650XT TOURING)",
                      "value": 407
                    },
                    {
                      "label": "DL1000XAAL9 (V-STROM 1000XT ADVENTURE)",
                      "value": 408
                    },
                    {
                      "label": "GSXS1000SM0",
                      "value": 409
                    },
                    {
                      "label": "SV650M0",
                      "value": 410
                    },
                    {
                      "label": "SV650AM0/SV650XAM0",
                      "value": 411
                    },
                    {
                      "label": "GSX-S750ZAM0 (50ST)/GSX-S750M0 (50ST)",
                      "value": 412
                    },
                    {
                      "label": "GSX-S750ZAM0 (49ST)",
                      "value": 413
                    },
                    {
                      "label": "GSX1300RAM0 (HAYABUSA)",
                      "value": 414
                    },
                    {
                      "label": "GSX-R1000AM0",
                      "value": 415
                    },
                    {
                      "label": "GSX-R750M0",
                      "value": 416
                    },
                    {
                      "label": "GSX-R600M0",
                      "value": 417
                    },
                    {
                      "label": "BOULEVARD C50T",
                      "value": 418
                    },
                    {
                      "label": "DL650XAAM0/DL650XAM0/DL650AM0",
                      "value": 419
                    },
                    {
                      "label": "DR650SEM0",
                      "value": 420
                    },
                    {
                      "label": "DR-Z400SM0",
                      "value": 421
                    },
                    {
                      "label": "DR200SM0",
                      "value": 422
                    },
                    {
                      "label": "DR-Z400SMM0",
                      "value": 423
                    },
                    {
                      "label": "AN400AM0 (BURGMAN 400)",
                      "value": 424
                    },
                    {
                      "label": "UH200AM0 (BURGMAN 200 ABS)",
                      "value": 425
                    },
                    {
                      "label": "GV1400",
                      "value": 426
                    },
                    {
                      "label": "VS700",
                      "value": 427
                    },
                    {
                      "label": "VS750 INTRUDER",
                      "value": 428
                    },
                    {
                      "label": "GSX1100",
                      "value": 429
                    },
                    {
                      "label": "GS750",
                      "value": 430
                    },
                    {
                      "label": "SP200",
                      "value": 431
                    },
                    {
                      "label": "GS450",
                      "value": 432
                    },
                    {
                      "label": "GSX1300 RK5",
                      "value": 433
                    },
                    {
                      "label": "DR-Z400SM",
                      "value": 434
                    },
                    {
                      "label": "DR200S",
                      "value": 435
                    },
                    {
                      "label": "DR-Z400SM0(DR-Z400S)",
                      "value": 436
                    },
                    {
                      "label": "DR650SEM0(DR650S)",
                      "value": 437
                    },
                    {
                      "label": "V-STROM 1050",
                      "value": 438
                    },
                    {
                      "label": "V-STROM 1050XT",
                      "value": 439
                    },
                    {
                      "label": "V-STROM 1050XT ADVENTURE",
                      "value": 440
                    },
                    {
                      "label": "SUZUKI BOULEVARD M109R B.O.S.S.",
                      "value": 441
                    },
                    {
                      "label": "SUZUKI BOULEVARD C50",
                      "value": 442
                    },
                    {
                      "label": "GSX250R ABS",
                      "value": 443
                    },
                    {
                      "label": "GSX-S750",
                      "value": 444
                    },
                    {
                      "label": "V-STROM 650",
                      "value": 445
                    },
                    {
                      "label": "BOULEVARD C50",
                      "value": 446
                    },
                    {
                      "label": "GSXS1000SM1",
                      "value": 447
                    }
                  ],
                  value: 59
                }, {
                  label: "TESLA",
                  models: 
                    [
                    {
                      "label": "MODEL S",
                      "value": 0
                    },
                    {
                      "label": "ROADSTER",
                      "value": 1
                    },
                    {
                      "label": "MODEL X",
                      "value": 2
                    },
                    {
                      "label": "MODEL 3",
                      "value": 3
                    },
                    {
                      "label": "MODEL Y",
                      "value": 4
                    }
                  ],
                  value: 60
                }, {
                  label: "TOYOTA",
                  models: [
                {
                  "label": "SCION XA",
                  "value": 0
                },
                {
                  "label": "SCION TC",
                  "value": 1
                },
                {
                  "label": "COROLLA",
                  "value": 2
                },
                {
                  "label": "PRIUS",
                  "value": 3
                },
                {
                  "label": "SCION XB",
                  "value": 4
                },
                {
                  "label": "LAND CRUISER",
                  "value": 5
                },
                {
                  "label": "HIGHLANDER",
                  "value": 6
                },
                {
                  "label": "4-RUNNER",
                  "value": 7
                },
                {
                  "label": "RAV4",
                  "value": 8
                },
                {
                  "label": "TACOMA",
                  "value": 9
                },
                {
                  "label": "SCION FR-S",
                  "value": 10
                },
                {
                  "label": "FJ CRUISER",
                  "value": 11
                },
                {
                  "label": "YARIS",
                  "value": 12
                },
                {
                  "label": "AVALON",
                  "value": 13
                },
                {
                  "label": "COROLLA MATRIX",
                  "value": 14
                },
                {
                  "label": "TUNDRA",
                  "value": 15
                },
                {
                  "label": "SIENNA",
                  "value": 16
                },
                {
                  "label": "CAMRY",
                  "value": 17
                },
                {
                  "label": "CAMRY SOLARA",
                  "value": 18
                },
                {
                  "label": "SEQUOIA",
                  "value": 19
                },
                {
                  "label": "SCION XD",
                  "value": 20
                },
                {
                  "label": "VENZA",
                  "value": 21
                },
                {
                  "label": "FCHV-ADV",
                  "value": 22
                },
                {
                  "label": "TERCEL",
                  "value": 23
                },
                {
                  "label": "CELICA",
                  "value": 24
                },
                {
                  "label": "SUPRA",
                  "value": 25
                },
                {
                  "label": "MR2",
                  "value": 26
                },
                {
                  "label": "PASEO",
                  "value": 27
                },
                {
                  "label": "ECHO",
                  "value": 28
                },
                {
                  "label": "CRESSIDA",
                  "value": 29
                },
                {
                  "label": "1-TON",
                  "value": 30
                },
                {
                  "label": "CARGO VAN",
                  "value": 31
                },
                {
                  "label": "PREVIA",
                  "value": 32
                },
                {
                  "label": "T100",
                  "value": 33
                },
                {
                  "label": "MIRAI",
                  "value": 34
                },
                {
                  "label": "SCION IQ",
                  "value": 35
                },
                {
                  "label": "SCION IM",
                  "value": 36
                },
                {
                  "label": "86",
                  "value": 37
                },
                {
                  "label": "SCION IA",
                  "value": 38
                },
                {
                  "label": "PRIUS V",
                  "value": 39
                },
                {
                  "label": "PRIUS C",
                  "value": 40
                },
                {
                  "label": "PRIUS PLUG-IN",
                  "value": 41
                },
                {
                  "label": "PICK-UP",
                  "value": 42
                },
                {
                  "label": "VAN",
                  "value": 43
                },
                {
                  "label": "STARLET",
                  "value": 44
                },
                {
                  "label": "CORONA",
                  "value": 45
                },
                {
                  "label": "COROLLA IM",
                  "value": 46
                },
                {
                  "label": "C-HR",
                  "value": 47
                },
                {
                  "label": "PRIUS PRIME",
                  "value": 48
                },
                {
                  "label": "YARIS IA",
                  "value": 49
                },
                {
                  "label": "RAV4 PRIME",
                  "value": 50
                }
              ],
                  value: 61
                }, {
                  label: "VOLKSWAGEN",
                  models: [
                {
                  "label": "ROUTAN",
                  "value": 0
                },
                {
                  "label": "GOLF",
                  "value": 1
                },
                {
                  "label": "PASSAT",
                  "value": 2
                },
                {
                  "label": "PHAETON",
                  "value": 3
                },
                {
                  "label": "TOUAREG",
                  "value": 4
                },
                {
                  "label": "JETTA",
                  "value": 5
                },
                {
                  "label": "GTI",
                  "value": 6
                },
                {
                  "label": "R32",
                  "value": 7
                },
                {
                  "label": "JETTA WAGON",
                  "value": 8
                },
                {
                  "label": "RABBIT",
                  "value": 9
                },
                {
                  "label": "NEW GTI",
                  "value": 10
                },
                {
                  "label": "EOS",
                  "value": 11
                },
                {
                  "label": "GOLF SPORTWAGEN",
                  "value": 12
                },
                {
                  "label": "GOLF III",
                  "value": 13
                },
                {
                  "label": "JETTA III",
                  "value": 14
                },
                {
                  "label": "CORRADO",
                  "value": 15
                },
                {
                  "label": "EURO VAN",
                  "value": 16
                },
                {
                  "label": "E-GOLF",
                  "value": 17
                },
                {
                  "label": "BEETLE",
                  "value": 18
                },
                {
                  "label": "CC",
                  "value": 19
                },
                {
                  "label": "TIGUAN",
                  "value": 20
                },
                {
                  "label": "JETTA SPORTWAGEN",
                  "value": 21
                },
                {
                  "label": "CABRIO",
                  "value": 22
                },
                {
                  "label": "NEW PASSAT",
                  "value": 23
                },
                {
                  "label": "NEW CABRIO",
                  "value": 24
                },
                {
                  "label": "NEW GOLF",
                  "value": 25
                },
                {
                  "label": "NEW JETTA",
                  "value": 26
                },
                {
                  "label": "GOLF GTI",
                  "value": 27
                },
                {
                  "label": "GOLF R",
                  "value": 28
                },
                {
                  "label": "CABRIOLET",
                  "value": 29
                },
                {
                  "label": "FOX",
                  "value": 30
                },
                {
                  "label": "QUANTUM",
                  "value": 31
                },
                {
                  "label": "SCIROCCO",
                  "value": 32
                },
                {
                  "label": "VANAGON",
                  "value": 33
                },
                {
                  "label": "MULTI-VAN",
                  "value": 34
                },
                {
                  "label": "DASHER",
                  "value": 35
                },
                {
                  "label": "KOMBI",
                  "value": 36
                },
                {
                  "label": "ATLAS",
                  "value": 37
                },
                {
                  "label": "GOLF ALLTRACK",
                  "value": 38
                },
                {
                  "label": "TIGUAN LIMITED",
                  "value": 39
                },
                {
                  "label": "ARTEON",
                  "value": 40
                },
                {
                  "label": "GLI",
                  "value": 41
                },
                {
                  "label": "ATLAS CROSS SPORT",
                  "value": 42
                }
              ],
                  value: 62
                }, {
                  label: "VOLVO",
                  models:[
                {
                  "label": "S60",
                  "value": 0
                },
                {
                  "label": "S80",
                  "value": 1
                },
                {
                  "label": "V60",
                  "value": 2
                },
                {
                  "label": "XC60",
                  "value": 3
                },
                {
                  "label": "XC70",
                  "value": 4
                },
                {
                  "label": "VHD",
                  "value": 5
                },
                {
                  "label": "VNM",
                  "value": 6
                },
                {
                  "label": "VNL",
                  "value": 7
                },
                {
                  "label": "VAH",
                  "value": 8
                },
                {
                  "label": "VNX",
                  "value": 9
                },
                {
                  "label": "VT",
                  "value": 10
                },
                {
                  "label": "FM10",
                  "value": 11
                },
                {
                  "label": "S40",
                  "value": 12
                },
                {
                  "label": "V50",
                  "value": 13
                },
                {
                  "label": "C70",
                  "value": 14
                },
                {
                  "label": "V70",
                  "value": 15
                },
                {
                  "label": "XC90",
                  "value": 16
                },
                {
                  "label": "C30",
                  "value": 17
                },
                {
                  "label": "9700",
                  "value": 18
                },
                {
                  "label": "940 SERIES",
                  "value": 19
                },
                {
                  "label": "960 SERIES",
                  "value": 20
                },
                {
                  "label": "850 SERIES",
                  "value": 21
                },
                {
                  "label": "WX",
                  "value": 22
                },
                {
                  "label": "WHL",
                  "value": 23
                },
                {
                  "label": "WXLL",
                  "value": 24
                },
                {
                  "label": "WG",
                  "value": 25
                },
                {
                  "label": "WHR",
                  "value": 26
                },
                {
                  "label": "WHLB",
                  "value": 27
                },
                {
                  "label": "WHEB",
                  "value": 28
                },
                {
                  "label": "WAH",
                  "value": 29
                },
                {
                  "label": "ACL",
                  "value": 30
                },
                {
                  "label": "WXLM",
                  "value": 31
                },
                {
                  "label": "WCA",
                  "value": 32
                },
                {
                  "label": "WIA",
                  "value": 33
                },
                {
                  "label": "CACL",
                  "value": 34
                },
                {
                  "label": "FE",
                  "value": 35
                },
                {
                  "label": "CWG",
                  "value": 36
                },
                {
                  "label": "NE64",
                  "value": 37
                },
                {
                  "label": "NR64",
                  "value": 38
                },
                {
                  "label": "VN",
                  "value": 39
                },
                {
                  "label": "NE42",
                  "value": 40
                },
                {
                  "label": "VNC",
                  "value": 41
                },
                {
                  "label": "FML",
                  "value": 42
                },
                {
                  "label": "WXR",
                  "value": 43
                },
                {
                  "label": "V60CC",
                  "value": 44
                },
                {
                  "label": "S60 CROSS COUNTRY",
                  "value": 45
                },
                {
                  "label": "S90",
                  "value": 46
                },
                {
                  "label": "V90",
                  "value": 47
                },
                {
                  "label": "V90CC",
                  "value": 48
                },
                {
                  "label": "CNE",
                  "value": 49
                },
                {
                  "label": "NE",
                  "value": 50
                },
                {
                  "label": "NR",
                  "value": 51
                },
                {
                  "label": "CNR",
                  "value": 52
                },
                {
                  "label": "240 SERIES",
                  "value": 53
                },
                {
                  "label": "740 SERIES",
                  "value": 54
                },
                {
                  "label": "780 SERIES",
                  "value": 55
                },
                {
                  "label": "760 SERIES",
                  "value": 56
                },
                {
                  "label": "B10M",
                  "value": 57
                },
                {
                  "label": "V40",
                  "value": 58
                },
                {
                  "label": "S70",
                  "value": 59
                },
                {
                  "label": "CAB OVER ENGINE HT",
                  "value": 60
                },
                {
                  "label": "CAB OVER ENGINE LT",
                  "value": 61
                },
                {
                  "label": "CAB OVER ENGINE PROD. CLASS 03",
                  "value": 62
                },
                {
                  "label": "CAB OVER ENGINE PROD. CLASS 07",
                  "value": 63
                },
                {
                  "label": "F12 W/F7 CAB",
                  "value": 64
                },
                {
                  "label": "F6 W/F7 CAB",
                  "value": 65
                },
                {
                  "label": "CAB OVER ENGINE PROD. CLASS 07 (USA)",
                  "value": 66
                },
                {
                  "label": "CAB BEHIND ENGINE",
                  "value": 67
                },
                {
                  "label": "VOLVO TRAILERS",
                  "value": 68
                },
                {
                  "label": "260 SERIES",
                  "value": 69
                },
                {
                  "label": "VNR",
                  "value": 70
                },
                {
                  "label": "XC40",
                  "value": 71
                },
                {
                  "label": "VT",
                  "value": 72
                },
                {
                  "label": "VS",
                  "value": 73
                }
              ],
                  value: 63
              },{
                label: "ACURA",
                value: 64,
                models:[
                  {
                    "label": "ZDX",
                    "value": 0
                  },
                  {
                    "label": "RDX",
                    "value": 1
                  },
                  {
                    "label": "RL",
                    "value": 2
                  },
                  {
                    "label": "TL",
                    "value": 3
                  },
                  {
                    "label": "TSX",
                    "value": 4
                  },
                  {
                    "label": "MDX",
                    "value": 5
                  },
                  {
                    "label": "RLX",
                    "value": 6
                  },
                  {
                    "label": "ILX",
                    "value": 7
                  },
                  {
                    "label": "RSX",
                    "value": 8
                  },
                  {
                    "label": "INTEGRA",
                    "value": 9
                  },
                  {
                    "label": "CL",
                    "value": 10
                  },
                  {
                    "label": "NSX",
                    "value": 11
                  },
                  {
                    "label": "LEGEND",
                    "value": 12
                  },
                  {
                    "label": "TLX",
                    "value": 13
                  },
                  {
                    "label": "SLX",
                    "value": 14
                  },
                  {
                    "label": "VIGOR",
                    "value": 15
                  }
                ]},
              {
                label: "LINCOLN",
                value: 73,
                models: [
              {
                "label": "MKZ",
                "value": 0
              },
              {
                "label": "TOWN CAR",
                "value": 1
              },
              {
                "label": "MKS",
                "value": 2
              },
              {
                "label": "MKT",
                "value": 3
              },
              {
                "label": "MKX",
                "value": 4
              },
              {
                "label": "NAVIGATOR",
                "value": 5
              },
              {
                "label": "LS",
                "value": 6
              },
              {
                "label": "AVIATOR",
                "value": 7
              },
              {
                "label": "ZEPHYR",
                "value": 8
              },
              {
                "label": "MARK LT",
                "value": 9
              },
              {
                "label": "MKC",
                "value": 10
              },
              {
                "label": "CONTINENTAL",
                "value": 11
              },
              {
                "label": "BLACKWOOD",
                "value": 12
              },
              {
                "label": "MARK",
                "value": 13
              },
              {
                "label": "NAUTILUS",
                "value": 14
              },
              {
                "label": "CORSAIR",
                "value": 15
              },
              {
                "label": "NAVIGATOR L",
                "value": 16
              }
              ]},
            {
                  label: "RAM",
                  models: [
              {
                "label": "PROMASTER CITY",
                "value": 0
              },
              {
                "label": "ENDURAMAX",
                "value": 1
              },
              {
                "label": "EASTON CUSTOM L.L.C",
                "value": 2
              },
              {
                "label": "BEST LANE ENTERPRISES DBA RAMP FREE",
                "value": 3
              },
              {
                "label": "NEXGEN FRAMING SYSTEM, LLC",
                "value": 4
              },
              {
                "label": "HOLIDAY RAMBLER",
                "value": 5
              },
              {
                "label": "PARAMOUNT CUSTOM CYCLES",
                "value": 6
              },
              {
                "label": "RAM TRANSCORP",
                "value": 7
              },
              {
                "label": "RAMSEY FABRICATION",
                "value": 8
              },
              {
                "label": "PORTAFRAME",
                "value": 9
              },
              {
                "label": "SCHRAMM, INC.",
                "value": 10
              },
              {
                "label": "RAMP CHAMP",
                "value": 11
              },
              {
                "label": "REPLICA",
                "value": 12
              },
              {
                "label": "FAITH",
                "value": 13
              },
              {
                "label": "PROTORYPE",
                "value": 14
              },
              {
                "label": "CRUISER",
                "value": 15
              },
              {
                "label": "CHOPPER",
                "value": 16
              },
              {
                "label": "TRAMONT CORPORATION",
                "value": 17
              },
              {
                "label": "TRAMONT MANUFACTURING LLC",
                "value": 18
              },
              {
                "label": "ROBERT ALLEN MOWERY RAMCO LLC",
                "value": 19
              },
              {
                "label": "RAMPLESS",
                "value": 20
              },
              {
                "label": "RAMPLESS MOTORCYCLE TRAILERS",
                "value": 21
              },
              {
                "label": "RAM ENTERPRISES, LLC",
                "value": 22
              },
              {
                "label": "1500",
                "value": 23
              },
              {
                "label": "2500",
                "value": 24
              },
              {
                "label": "3500",
                "value": 25
              },
              {
                "label": "4000",
                "value": 26
              },
              {
                "label": "4500",
                "value": 27
              },
              {
                "label": "5500",
                "value": 28
              },
              {
                "label": "PROMASTER 1500",
                "value": 29
              },
              {
                "label": "PROMASTER 2500",
                "value": 30
              },
              {
                "label": "PROMASTER 3500",
                "value": 31
              },
              {
                "label": "CARGO VAN",
                "value": 32
              },
              {
                "label": "1000",
                "value": 33
              },
              {
                "label": "2000",
                "value": 34
              },
              {
                "label": "2600",
                "value": 35
              },
              {
                "label": "3000",
                "value": 36
              },
              {
                "label": "3600",
                "value": 37
              },
              {
                "label": "HX-1000",
                "value": 38
              },
              {
                "label": "HX-2000",
                "value": 39
              },
              {
                "label": "HX-3000",
                "value": 40
              },
              {
                "label": "RAMPTEK TRAILERS",
                "value": 41
              },
              {
                "label": "BURKETT'S AUTORAMA",
                "value": 42
              },
              {
                "label": "PYRAMID MANUFACTURING CO. TRAILER",
                "value": 43
              },
              {
                "label": "RAMIKA INDUSTRIES TRAILER",
                "value": 44
              },
              {
                "label": "RAMP AGE TRAILER CO.",
                "value": 45
              },
              {
                "label": "512",
                "value": 46
              },
              {
                "label": "52",
                "value": 47
              },
              {
                "label": "725",
                "value": 48
              },
              {
                "label": "203",
                "value": 49
              },
              {
                "label": "234",
                "value": 50
              },
              {
                "label": "83",
                "value": 51
              },
              {
                "label": "288",
                "value": 52
              },
              {
                "label": "307",
                "value": 53
              },
              {
                "label": "62U",
                "value": 54
              },
              {
                "label": "825U",
                "value": 55
              },
              {
                "label": "166U",
                "value": 56
              },
              {
                "label": "83U",
                "value": 57
              },
              {
                "label": "925",
                "value": 58
              },
              {
                "label": "214",
                "value": 59
              },
              {
                "label": "266",
                "value": 60
              },
              {
                "label": "835",
                "value": 61
              },
              {
                "label": "2235",
                "value": 62
              },
              {
                "label": "3510",
                "value": 63
              },
              {
                "label": "625U",
                "value": 64
              },
              {
                "label": "1225U",
                "value": 65
              },
              {
                "label": "206U",
                "value": 66
              },
              {
                "label": "612U",
                "value": 67
              },
              {
                "label": "KTM ALUMINUM FRAME",
                "value": 68
              },
              {
                "label": "KTM STEEL FRAME",
                "value": 69
              },
              {
                "label": "RAMSHORN WELDING",
                "value": 70
              },
              {
                "label": "FATT HOGG FRAMES",
                "value": 71
              },
              {
                "label": "FRAMTRAC MANUFACTURING CO. LTD",
                "value": 72
              },
              {
                "label": "RAMIREZ TRAILER",
                "value": 73
              },
              {
                "label": "RAM FABRICATION",
                "value": 74
              },
              {
                "label": "LXR",
                "value": 75
              },
              {
                "label": "CHOPPER",
                "value": 76
              },
              {
                "label": "CRUISER",
                "value": 77
              },
              {
                "label": "JOHN INGRAM, INC.",
                "value": 78
              },
              {
                "label": "TERRAMARC INDUSTRIES",
                "value": 79
              },
              {
                "label": "TRAM POWER CAR",
                "value": 80
              },
              {
                "label": "TRAM TRAILER",
                "value": 81
              },
              {
                "label": "CP90B",
                "value": 82
              },
              {
                "label": "CP102B",
                "value": 83
              },
              {
                "label": "CP80B",
                "value": 84
              },
              {
                "label": "EZTRJ",
                "value": 85
              },
              {
                "label": "YWR",
                "value": 86
              },
              {
                "label": "YWVR",
                "value": 87
              },
              {
                "label": "WVR",
                "value": 88
              },
              {
                "label": "JSR",
                "value": 89
              },
              {
                "label": "EZR",
                "value": 90
              },
              {
                "label": "EZWR",
                "value": 91
              },
              {
                "label": "JR RAMIRO",
                "value": 92
              },
              {
                "label": "KRAMO TRAILERS",
                "value": 93
              },
              {
                "label": "URBAN",
                "value": 94
              },
              {
                "label": "DUAL SPORT",
                "value": 95
              },
              {
                "label": "SCOOTER",
                "value": 96
              },
              {
                "label": "TOURING",
                "value": 97
              },
              {
                "label": "BRAMMO STREET BIKES",
                "value": 98
              },
              {
                "label": "RAMBLER",
                "value": 99
              },
              {
                "label": "FRAME TYPE DUMP - BENSON",
                "value": 100
              },
              {
                "label": "FRAMELESS DUMP - BENSON",
                "value": 101
              },
              {
                "label": "QUARTER FRAME DUMP - BENSON",
                "value": 102
              }
            ],
                  value: 65
                },
                {
                  label: "PONTIAC",
                  value: 66,
                  models: [
              {
                "label": "VIBE",
                "value": 0
              },
              {
                "label": "G5",
                "value": 1
              },
              {
                "label": "SOLSTICE",
                "value": 2
              },
              {
                "label": "WAVE",
                "value": 3
              },
              {
                "label": "GRAND PRIX",
                "value": 4
              },
              {
                "label": "G6",
                "value": 5
              },
              {
                "label": "G8",
                "value": 6
              },
              {
                "label": "G3",
                "value": 7
              },
              {
                "label": "MONTANA/ SV6",
                "value": 8
              },
              {
                "label": "TORRENT",
                "value": 9
              },
              {
                "label": "PURSUIT",
                "value": 10
              },
              {
                "label": "GTO",
                "value": 11
              },
              {
                "label": "MATIZ",
                "value": 12
              },
              {
                "label": "BONNEVILLE",
                "value": 13
              },
              {
                "label": "SUNFIRE",
                "value": 14
              },
              {
                "label": "GRAND AM",
                "value": 15
              },
              {
                "label": "AZTEK",
                "value": 16
              },
              {
                "label": "FIREBIRD",
                "value": 17
              },
              {
                "label": "SUNBIRD",
                "value": 18
              },
              {
                "label": "TRANS SPORT",
                "value": 19
              },
              {
                "label": "FIREFLY",
                "value": 20
              },
              {
                "label": "LEMANS",
                "value": 21
              },
              {
                "label": "6000",
                "value": 22
              },
              {
                "label": "PARISIENNE",
                "value": 23
              },
              {
                "label": "FIERO",
                "value": 24
              },
              {
                "label": "T1000",
                "value": 25
              },
              {
                "label": "SUNBIRD 2000",
                "value": 26
              },
              {
                "label": "PHOENIX",
                "value": 27
              },
              {
                "label": "GRAND LEMANS",
                "value": 28
              },
              {
                "label": "CATALINA",
                "value": 29
              },
              {
                "label": "J2000",
                "value": 30
              },
              {
                "label": "SAFARI",
                "value": 31
              },
              {
                "label": "TEMPEST",
                "value": 32
              },
              {
                "label": "ACADIAN",
                "value": 33
              },
              {
                "label": "SUNBURST",
                "value": 34
              },
              {
                "label": "LAURENTIAN",
                "value": 35
              }
            ]
                },
                {
                label: "OLDSMOBILE",
                value: 67,
                models: [
              {
                "label": "BRAVADA",
                "value": 0
              },
              {
                "label": "SILHOUETTE",
                "value": 1
              },
              {
                "label": "ALERO",
                "value": 2
              },
              {
                "label": "AURORA",
                "value": 3
              },
              {
                "label": "INTRIGUE",
                "value": 4
              },
              {
                "label": "REGENCY",
                "value": 5
              },
              {
                "label": "EIGHTY EIGHT (88)",
                "value": 6
              },
              {
                "label": "LSS",
                "value": 7
              },
              {
                "label": "CUTLASS",
                "value": 8
              },
              {
                "label": "ACHIEVA",
                "value": 9
              },
              {
                "label": "CUTLASS SUPREME",
                "value": 10
              },
              {
                "label": "CIERA",
                "value": 11
              },
              {
                "label": "NINETY EIGHT (98)",
                "value": 12
              },
              {
                "label": "CUSTOM CRUISER",
                "value": 13
              },
              {
                "label": "TORONADO",
                "value": 14
              },
              {
                "label": "CUTLASS CALAIS",
                "value": 15
              },
              {
                "label": "FIRENZA",
                "value": 16
              },
              {
                "label": "OMEGA",
                "value": 17
              },
              {
                "label": "CUTLASS CIERA",
                "value": 18
              },
              {
                "label": "DELTA 88",
                "value": 19
              },
              {
                "label": "CUTLASS CRUISER",
                "value": 20
              },
              {
                "label": "CUTLASS SALON",
                "value": 21
              }
            ]
              },
              {
                label: "GENESIS",
                value: 68,
                models: [
              {
                "label": "G80",
                "value": 0
              },
              {
                "label": "G90",
                "value": 1
              },
              {
                "label": "GENESIS SUPREME",
                "value": 2
              },
              {
                "label": "ENVY",
                "value": 3
              },
              {
                "label": "VORTEX",
                "value": 4
              },
              {
                "label": "SUPREME",
                "value": 5
              },
              {
                "label": "GENESIS",
                "value": 6
              },
              {
                "label": "TAHOE",
                "value": 7
              },
              {
                "label": "GENESIS SUPREME",
                "value": 8
              },
              {
                "label": "ENVY",
                "value": 9
              },
              {
                "label": "VORTEX",
                "value": 10
              },
              {
                "label": "SUPREME",
                "value": 11
              },
              {
                "label": "GENESIS",
                "value": 12
              },
              {
                "label": "TAHOE",
                "value": 13
              },
              {
                "label": "GENESIS TRAILERS INC.",
                "value": 14
              },
              {
                "label": "G70",
                "value": 15
              },
              {
                "label": "GV80",
                "value": 16
              },
              {
                "label": "GENESIS",
                "value": 17
              }
            ]
              },
              {
                label: "BUICK",
                value: 69,
                models: [
              {
                "label": "LACROSSE",
                "value": 0
              },
              {
                "label": "REGAL",
                "value": 1
              },
              {
                "label": "VERANO",
                "value": 2
              },
              {
                "label": "ENCLAVE",
                "value": 3
              },
              {
                "label": "ENCORE",
                "value": 4
              },
              {
                "label": "LUCERNE",
                "value": 5
              },
              {
                "label": "ALLURE",
                "value": 6
              },
              {
                "label": "TERRAZA",
                "value": 7
              },
              {
                "label": "RAINIER",
                "value": 8
              },
              {
                "label": "RENDEZVOUS",
                "value": 9
              },
              {
                "label": "PARK AVENUE",
                "value": 10
              },
              {
                "label": "LESABRE",
                "value": 11
              },
              {
                "label": "CENTURY",
                "value": 12
              },
              {
                "label": "INCOMPLETE",
                "value": 13
              },
              {
                "label": "RIVIERA",
                "value": 14
              },
              {
                "label": "SKYLARK",
                "value": 15
              },
              {
                "label": "COACHBUILDER",
                "value": 16
              },
              {
                "label": "ROADMASTER",
                "value": 17
              },
              {
                "label": "CASCADA",
                "value": 18
              },
              {
                "label": "ENVISION",
                "value": 19
              },
              {
                "label": "REATTA",
                "value": 20
              },
              {
                "label": "ELECTRA",
                "value": 21
              },
              {
                "label": "SKYHAWK",
                "value": 22
              },
              {
                "label": "SOMERSET",
                "value": 23
              },
              {
                "label": "REGAL TOURX",
                "value": 24
              },
              {
                "label": "ENCORE GX",
                "value": 25
              }
            ]
              },
              {
                label: "GMC",
                value: 70,
                models: [
              {
                "label": "ACADIA",
                "value": 0
              },
              {
                "label": "SAVANA",
                "value": 1
              },
              {
                "label": "SIERRA",
                "value": 2
              },
              {
                "label": "TERRAIN",
                "value": 3
              },
              {
                "label": "YUKON",
                "value": 4
              },
              {
                "label": "CANYON",
                "value": 5
              },
              {
                "label": "ENVOY",
                "value": 6
              },
              {
                "label": "YUKON XL",
                "value": 7
              },
              {
                "label": "W4",
                "value": 8
              },
              {
                "label": "SAFARI",
                "value": 9
              },
              {
                "label": "ELECTRIC VEHICLE",
                "value": 10
              },
              {
                "label": "GMT-400",
                "value": 11
              },
              {
                "label": "SONOMA",
                "value": 12
              },
              {
                "label": "SUBURBAN",
                "value": 13
              },
              {
                "label": "C/K PICKUP",
                "value": 14
              },
              {
                "label": "JIMMY UTILITY",
                "value": 15
              },
              {
                "label": "T-SERIES",
                "value": 16
              },
              {
                "label": "C4",
                "value": 17
              },
              {
                "label": "C5",
                "value": 18
              },
              {
                "label": "C6",
                "value": 19
              },
              {
                "label": "C7",
                "value": 20
              },
              {
                "label": "C8",
                "value": 21
              },
              {
                "label": "W5",
                "value": 22
              },
              {
                "label": "W7",
                "value": 23
              },
              {
                "label": "WCS",
                "value": 24
              },
              {
                "label": "WCM",
                "value": 25
              },
              {
                "label": "WCL",
                "value": 26
              },
              {
                "label": "WHS",
                "value": 27
              },
              {
                "label": "WHM",
                "value": 28
              },
              {
                "label": "WHL",
                "value": 29
              },
              {
                "label": "WB",
                "value": 30
              },
              {
                "label": "DS",
                "value": 31
              },
              {
                "label": "ACM",
                "value": 32
              },
              {
                "label": "ACL",
                "value": 33
              },
              {
                "label": "AT",
                "value": 34
              },
              {
                "label": "DK",
                "value": 35
              },
              {
                "label": "WCA",
                "value": 36
              },
              {
                "label": "WIA",
                "value": 37
              },
              {
                "label": "WIS",
                "value": 38
              },
              {
                "label": "WIM",
                "value": 39
              },
              {
                "label": "WIL",
                "value": 40
              },
              {
                "label": "WHR",
                "value": 41
              },
              {
                "label": "WHLB",
                "value": 42
              },
              {
                "label": "CACL",
                "value": 43
              },
              {
                "label": "FE",
                "value": 44
              },
              {
                "label": "CWG",
                "value": 45
              },
              {
                "label": "NE",
                "value": 46
              },
              {
                "label": "NR",
                "value": 47
              },
              {
                "label": "CNR",
                "value": 48
              },
              {
                "label": "WHEB",
                "value": 49
              },
              {
                "label": "W3",
                "value": 50
              },
              {
                "label": "B7",
                "value": 51
              },
              {
                "label": "W6",
                "value": 52
              },
              {
                "label": "P6S",
                "value": 53
              },
              {
                "label": "D7",
                "value": 54
              },
              {
                "label": "P TRUCK FORWARD",
                "value": 55
              },
              {
                "label": "MOTORHOME CHASSIS",
                "value": 56
              },
              {
                "label": "CUTAWAY CHASSIS",
                "value": 57
              },
              {
                "label": "S15 UTILITY",
                "value": 58
              },
              {
                "label": "VANDURA",
                "value": 59
              },
              {
                "label": "CUTAWAY VAN",
                "value": 60
              },
              {
                "label": "RALLY",
                "value": 61
              },
              {
                "label": "MAGNA VAN",
                "value": 62
              },
              {
                "label": "TYPHOON",
                "value": 63
              },
              {
                "label": "VALUE VAN",
                "value": 64
              },
              {
                "label": "HI-CUBE",
                "value": 65
              },
              {
                "label": "S7",
                "value": 66
              },
              {
                "label": "GEO TRACKER",
                "value": 67
              },
              {
                "label": "S6",
                "value": 68
              },
              {
                "label": "JIMMY",
                "value": 69
              },
              {
                "label": "R CONVENTIONAL",
                "value": 70
              },
              {
                "label": "V CONVENTIONAL",
                "value": 71
              },
              {
                "label": "S15 PICKUP",
                "value": 72
              },
              {
                "label": "FORWARD CONTROL",
                "value": 73
              },
              {
                "label": "ALUMINUM TILT",
                "value": 74
              },
              {
                "label": "HEAVY CONVENTIONAL",
                "value": 75
              },
              {
                "label": "BUS CHASSIS",
                "value": 76
              },
              {
                "label": "CABALLERO",
                "value": 77
              },
              {
                "label": "TRANSIT COACH",
                "value": 78
              },
              {
                "label": "ARTICULATED COACH",
                "value": 79
              },
              {
                "label": "SIERRA HD",
                "value": 80
              },
              {
                "label": "SIERRA LIMITED",
                "value": 81
              },
              {
                "label": "W3500/W4500",
                "value": 82
              },
              {
                "label": "W5500/W5500 HD",
                "value": 83
              }
            ]
              },
              {
                label: "HINO",
                value: 71,
                models: [
              {
                "label": "CONVENTIONAL TYPE TRUCK",
                "value": 0
              },
              {
                "label": "INCOMPLETE VEHICLE",
                "value": 1
              },
              {
                "label": "XJC710  / XFC710",
                "value": 2
              },
              {
                "label": "XJC700",
                "value": 3
              },
              {
                "label": "XJC720 / XFC720",
                "value": 4
              },
              {
                "label": "XJC740 / XFC740",
                "value": 5
              },
              {
                "label": "XJC730/XFC730",
                "value": 6
              },
              {
                "label": "RHINO MOTORCYCLES",
                "value": 7
              },
              {
                "label": "RHINO TRAILERS LLC",
                "value": 8
              },
              {
                "label": "RHINO MARINE",
                "value": 9
              },
              {
                "label": "FORWARD CONTROL TYPE TRUCK",
                "value": 10
              },
              {
                "label": "COACH-TYPE REAR ENGINE BUS",
                "value": 11
              },
              {
                "label": "CONVENTIONAL /CONVENTIONAL LOW PROFILE TRUCK",
                "value": 12
              },
              {
                "label": "RHINO METALS INC",
                "value": 13
              },
              {
                "label": "RHINO POWER SPORT INDUSTRIAL",
                "value": 14
              },
              {
                "label": "RED RHINO MANUFACTURING",
                "value": 15
              },
              {
                "label": "RHINO CORPORATION TRAILER",
                "value": 16
              },
              {
                "label": "RED RHINO",
                "value": 17
              },
              {
                "label": "RHINO SERVICES LLC",
                "value": 18
              },
              {
                "label": "RHINO TRAILERS LLC",
                "value": 19
              },
              {
                "label": "HS",
                "value": 20
              },
              {
                "label": "RHINO",
                "value": 21
              },
              {
                "label": "BLACK RHINO TRAILERS",
                "value": 22
              },
              {
                "label": "RHINO, PRIME CARGO",
                "value": 23
              }
            ]
              },
              {
                label: "MERCURY",
                value: 72,
                models: [
              {
                "label": "GRAND MARQUIS",
                "value": 0
              },
              {
                "label": "MILAN",
                "value": 1
              },
              {
                "label": "MARINER",
                "value": 2
              },
              {
                "label": "MOUNTAINEER",
                "value": 3
              },
              {
                "label": "SABLE",
                "value": 4
              },
              {
                "label": "MONTEGO",
                "value": 5
              },
              {
                "label": "MONTEREY",
                "value": 6
              },
              {
                "label": "COUGAR",
                "value": 7
              },
              {
                "label": "VILLAGER",
                "value": 8
              },
              {
                "label": "TRACER",
                "value": 9
              },
              {
                "label": "MYSTIQUE",
                "value": 10
              },
              {
                "label": "CAPRI",
                "value": 11
              },
              {
                "label": "TOPAZ",
                "value": 12
              },
              {
                "label": "LYNX",
                "value": 13
              },
              {
                "label": "MARQUIS",
                "value": 14
              },
              {
                "label": "ZEPHYR",
                "value": 15
              },
              {
                "label": "MARAUDER",
                "value": 16
              }
            ]
              },
 {
    label: "SATURN",
    value: 75,
    models: [
      {
        "label": "ASTRA",
        "value": 0
      },
      {
        "label": "SKY",
        "value": 1
      },
      {
        "label": "AURA",
        "value": 2
      },
      {
        "label": "VUE",
        "value": 3
      },
      {
        "label": "OUTLOOK",
        "value": 4
      },
      {
        "label": "ION",
        "value": 5
      },
      {
        "label": "RELAY",
        "value": 6
      },
      {
        "label": "L300",
        "value": 7
      },
      {
        "label": "L200",
        "value": 8
      },
      {
        "label": "SL",
        "value": 9
      },
      {
        "label": "SL1",
        "value": 10
      },
      {
        "label": "SL2",
        "value": 11
      },
      {
        "label": "SC1",
        "value": 12
      },
      {
        "label": "SC2",
        "value": 13
      },
      {
        "label": "LS",
        "value": 14
      },
      {
        "label": "SW1",
        "value": 15
      },
      {
        "label": "SW2",
        "value": 16
      },
      {
        "label": "LS1",
        "value": 17
      },
      {
        "label": "LS2",
        "value": 18
      },
      {
        "label": "LW2",
        "value": 19
      },
      {
        "label": "LW1",
        "value": 20
      },
      {
        "label": "LW200",
        "value": 21
      },
      {
        "label": "LW300",
        "value": 22
      },
      {
        "label": "SL3",
        "value": 23
      },
      {
        "label": "SATURN2016",
        "value": 24
      },
      {
        "label": "SATURN2019",
        "value": 25
      }
    ]
  }

            ]


/* 
 {
    label: "",
    value: 75,
    models: []
  }
*/

export function sha256(ascii) {
  function rightRotate(value, amount) {
      return (value >>> amount) | (value << (32 - amount));
  };

  var mathPow = Math.pow;
  var maxWord = mathPow(2, 32);
  var lengthProperty = 'length'
  var i, j; // Used as a counter across the whole file
  var result = ''

  var words = [];
  var asciiBitLength = ascii[lengthProperty] * 8;

  //* caching results is optional - remove/add slash from front of this line to toggle
  // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
  // (we actually calculate the first 64, but extra values are just ignored)
  var hash = sha256.h = sha256.h || [];
  // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
  var k = sha256.k = sha256.k || [];
  var primeCounter = k[lengthProperty];
  /*/
  var hash = [], k = [];
  var primeCounter = 0;
  //*/

  var isComposite = {};
  for (var candidate = 2; primeCounter < 64; candidate++) {
      if (!isComposite[candidate]) {
          for (i = 0; i < 313; i += candidate) {
              isComposite[i] = candidate;
          }
          hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
          k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
      }
  }

  ascii += '\x80' // Append Ƈ' bit (plus zero padding)
  while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
  for (i = 0; i < ascii[lengthProperty]; i++) {
      j = ascii.charCodeAt(i);
      if (j >> 8) return; // ASCII check: only accept characters in range 0-255
      words[i >> 2] |= j << ((3 - i) % 4) * 8;
  }
  words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
  words[words[lengthProperty]] = (asciiBitLength)

  // process each chunk
  for (j = 0; j < words[lengthProperty];) {
      var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
      var oldHash = hash;
      // This is now the undefinedworking hash", often labelled as variables a...g
      // (we have to truncate as well, otherwise extra entries at the end accumulate
      hash = hash.slice(0, 8);

      for (i = 0; i < 64; i++) {
          //var i2 = i + j;
          // Expand the message into 64 words
          // Used below if 
          var w15 = w[i - 15], w2 = w[i - 2];

          // Iterate
          var a = hash[0], e = hash[4];
          var temp1 = hash[7]
              + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
              + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
              + k[i]
              // Expand the message schedule if needed
              + (w[i] = (i < 16) ? w[i] : (
                  w[i - 16]
                  + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                  + w[i - 7]
                  + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
              ) | 0
              );
          // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
          var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
              + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

          hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
          hash[4] = (hash[4] + temp1) | 0;
      }

      for (i = 0; i < 8; i++) {
          hash[i] = (hash[i] + oldHash[i]) | 0;
      }
  }

  for (i = 0; i < 8; i++) {
      for (j = 3; j + 1; j--) {
          var b = (hash[i] >> (j * 8)) & 255;
          result += ((b < 16) ? 0 : '') + b.toString(16);
      }
  }
  return result;
};

export const states = [
    {
        value: "Alabama",
        label: "AL"
    },
    {
        value: "Alaska",
        label: "AK"
    },
    {
        value: "American Samoa",
        label: "AS"
    },
    {
        value: "Arizona",
        label: "AZ"
    },
    {
        value: "Arkansas",
        label: "AR"
    },
    {
        value: "California",
        label: "CA"
    },
    {
        value: "Colorado",
        label: "CO"
    },
    {
        value: "Connecticut",
        label: "CT"
    },
    {
        value: "Delaware",
        label: "DE"
    },
    {
        value: "District Of Columbia",
        label: "DC"
    },
    {
        value: "Federated States Of Micronesia",
        label: "FM"
    },
    {
        value: "Florida",
        label: "FL"
    },
    {
        value: "Georgia",
        label: "GA"
    },
    {
        value: "Guam",
        label: "GU"
    },
    {
        value: "Hawaii",
        label: "HI"
    },
    {
        value: "Idaho",
        label: "ID"
    },
    {
        value: "Illinois",
        label: "IL"
    },
    {
        value: "Indiana",
        label: "IN"
    },
    {
        value: "Iowa",
        label: "IA"
    },
    {
        value: "Kansas",
        label: "KS"
    },
    {
        value: "Kentucky",
        label: "KY"
    },
    {
        value: "Louisiana",
        label: "LA"
    },
    {
        value: "Maine",
        label: "ME"
    },
    {
        value: "Marshall Islands",
        label: "MH"
    },
    {
        value: "Maryland",
        label: "MD"
    },
    {
        value: "Massachusetts",
        label: "MA"
    },
    {
        value: "Michigan",
        label: "MI"
    },
    {
        value: "Minnesota",
        label: "MN"
    },
    {
        value: "Mississippi",
        label: "MS"
    },
    {
        value: "Missouri",
        label: "MO"
    },
    {
        value: "Montana",
        label: "MT"
    },
    {
        value: "Nebraska",
        label: "NE"
    },
    {
        value: "Nevada",
        label: "NV"
    },
    {
        value: "New Hampshire",
        label: "NH"
    },
    {
        value: "New Jersey",
        label: "NJ"
    },
    {
        value: "New Mexico",
        label: "NM"
    },
    {
        value: "New York",
        label: "NY"
    },
    {
        value: "North Carolina",
        label: "NC"
    },
    {
        value: "North Dakota",
        label: "ND"
    },
    {
        value: "Northern Mariana Islands",
        label: "MP"
    },
    {
        value: "Ohio",
        label: "OH"
    },
    {
        value: "Oklahoma",
        label: "OK"
    },
    {
        value: "Oregon",
        label: "OR"
    },
    {
        value: "Palau",
        label: "PW"
    },
    {
        value: "Pennsylvania",
        label: "PA"
    },
    {
        value: "Puerto Rico",
        label: "PR"
    },
    {
        value: "Rhode Island",
        label: "RI"
    },
    {
        value: "South Carolina",
        label: "SC"
    },
    {
        value: "South Dakota",
        label: "SD"
    },
    {
        value: "Tennessee",
        label: "TN"
    },
    {
        value: "Texas",
        label: "TX"
    },
    {
        value: "Utah",
        label: "UT"
    },
    {
        value: "Vermont",
        label: "VT"
    },
    {
        value: "Virgin Islands",
        label: "VI"
    },
    {
        value: "Virginia",
        label: "VA"
    },
    {
        value: "Washington",
        label: "WA"
    },
    {
        value: "West Virginia",
        label: "WV"
    },
    {
        value: "Wisconsin",
        label: "WI"
    },
    {
        value: "Wyoming",
        label: "WY"
    }
];

export const columns = [
    {
        dataField: 'Year',
        text: 'Year',
        sort: true,
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"}  }),
        /* number validation? */
    },
    {
        dataField: 'Make',
        text: 'Make',
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"}  }),
        style:{ textTransform: "uppercase" }

    },
    {
        dataField: 'Model',
        text: 'Model',
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"}  }),
        style:{ textTransform: "uppercase" }

    },
    {
        dataField: 'LicenseNumber',
        text: 'License',
        sort: true,
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"}  }),
        style:{ textTransform: "uppercase" }

    },
    {
        dataField: 'Mileage',
        text: 'Mileage',

    }
];

export const cannedColumns = [
    {
        dataField: 'code',
        text: 'Code',
        headerStyle:  { width: "10%"/* , textAlign: 'center' */ },
        filter: textFilter({autocomplete:"off", delay: 10,  style:{zoom:"90%"}})
    },
    {
        dataField: 'description',
        text: 'Description',
        filter: textFilter({autocomplete:"off", delay: 10,  style:{zoom:"90%"}})
    },
    {
        dataField: 'hours',
        text: 'Hours',
        headerStyle:  { width: "5%"/* , textAlign: 'center' */ },
        formatter: (cell, row) => {
          const type = row.type;
          if(type === 'note')
            return '-'
          else 
            return cell
        }
    },
    {
        dataField: 'rate',
        text: 'Rate',
        headerStyle:  { width: "5%"/* , textAlign: 'center' */ },
        formatter: (cell, row) => {
          const type = row.type;
          if(type === 'note')
            return '-'
          else 
            return cell
        }

    },
    {
        dataField: 'total',
        text: 'Total',
        headerStyle:  { width: "5%"/* , textAlign: 'center' */ },
        formatter: (cell, row) => {
          const type = row.type;
          if(type === 'note')
            return '-'
          else 
            return cell
        }
    }
];

export const paymentMethods = [
  { value: 1, label: 'Cash' },
  { value: 2, label: 'Visa/MC' },
  { value: 3, label: 'Other' },
  { value: 4, label: 'Check' },
];

export const distributors = [
  { value: 1, label: 'AUTOZONE', code: "AZ" },
  { value: 2, label: 'ANYPART', code: "AP"  },
  { value: 3, label: 'PARTS AUTHORITY', code: "PA"  },
  { value: 4, label: 'TIRE TOWN', code: "TT"  },
  { value: 5, label: 'MAVIS TIRE', code: "MT"  },
  { value: 6, label: 'LAKEWOOD', code: "LKWD" },
  { value: 7, label: 'JUNKYARD', code: "JKYD" },
  { value: 8, label: 'FLAGSHIP', code: "F1" },
  { value: 9, label: 'MERRICK JDCR', code: "M JDCR" },
]

/* takes in date object and formats to mm/dd/yyyy */
export function dateFormatter(date){
    if(!date)
        return ''
    let today = new Date(date)
    let new_date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    return new_date
}

/* takes in date object and formats hh:mm AM/PM */
export function getTime(date){
  if(!date)
      return ''
  let today = new Date(date)
  var time = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return time
}

/* takes in date object and formats to  */
export function dateTimeFormatter(date){
  if(!date)
        return ''
  let today = new Date(date) /* not sure why this line is required... */
  var time = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  var formatted_date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();

  return `${time} ${formatted_date}`
}

export function dateFormatter_email(data){
  //console.log("date to format...", date);
  if(!data)
      return ''
      let date = new Date(data)
      let today = new Date()
      let f = new Date(data)
      date.setHours(0,0,0,0);
      today.setHours(0,0,0,0);
      if(date < today) 
      {}
      else if(date > today)
      {}
      else{
        let min = f.getMinutes()
        if (min < 10)  min = '0'+ min;
        if(f.getHours() > 12)
            return (f.getHours()%12+':'+min+' PM')
        else
            return (f.getHours()+':'+f.min+' AM')
      }

      let month = f.toLocaleString('default', { month: 'long' }).substring(0,3);
      const new_date = month +" "+ f.getDate()
      return new_date
}

export function phoneFormatter(phone){
  var cleaned = ('' + phone).replace(/\D/g, '')
  var match;
  if(cleaned.length === 7){
    match = cleaned.match(/^(\d{3})(\d{4})$/)
    if (match) {
      return match[1] + '-' + match[2]
    }
  }
  else if(cleaned.length === 10){
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
  }
  return phone
}

export function getDate(date){
  if(!date) 
    return ''
  var today = new Date(date)
  var formatted_date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear()
  return formatted_date
}

export function round_and_format(num) {
  let res = +(Math.round(num + "e+2") + "e-2")
  return res ? res.toFixed(2) : "0.00";
}

