import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import * as Constants from '../../../constants/constants';

export default function AddNoteModal(props) {
    const show = props.show;
    const edit = props.edit
    const [jobToAdd, setJobToAdd] = useState(edit ? props.note : {
        Description: "",
        CreatedTime: null,
        LastEditTime: null
    })

    function myKeyPress(event) {
        //console.log("keypress:",event)
        //console.log(event.which)

        if (event.key === "F10") {
            edit ? props.update(jobToAdd) : props.add(jobToAdd)
            event.preventDefault()
        }
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    useEventListener('keydown', myKeyPress);

    return (
        <Modal show={show} onHide={props.close} onKeyPress={(event) => myKeyPress(event)} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title style={{width:"100%"}}>
                    {edit ?
                        <>
                            Edit a note
                            <section style={{ fontSize: "14px", float: "right" }}>
                                Created:  {Constants.dateTimeFormatter(jobToAdd.CreatedTime)}
                                <br/>
                                {jobToAdd.LastEditTime ? 
                                <> Last Edit:  {Constants.dateTimeFormatter(jobToAdd.LastEditTime)} </>
                                : null}
                            </section>
                        </>
                        : "Add a note"}


                </Modal.Title>
            </Modal.Header>


            <Modal.Body>
                <Form>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" style={{ height: "30rem" }} defaultValue={jobToAdd.Description} onChange={(e) => setJobToAdd({ ...jobToAdd, Description: e.target.value })} />
                </Form>
            </Modal.Body>

            <Modal.Footer>
                {edit ?
                    <>
                        <Button variant="danger" style={{marginRight:"auto"}} onClick={props.remove.bind(this, jobToAdd)}> Remove </Button>
                        <Button variant="secondary" onClick={props.close}> Close </Button>
                        <Button variant="primary" onClick={props.update.bind(this, jobToAdd)}> Save Changes </Button>
                    </>
                    :
                    <>
                    <Button variant="secondary" onClick={props.close}> Close </Button>
                    <Button variant="primary" onClick={props.add.bind(this, jobToAdd)}> Save Changes </Button>
                    </>

                }
            </Modal.Footer>

        </Modal>
    )
}