import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Button, Alert, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import axios from 'axios';
import * as Constants from './../../constants/constants.js';
import Loading from '../../global_components/Loading'
import NewCustomerModal from "./Modals/NewCustomerModal.js"
import CustomerSearch from "../customers/CustomerSearch.js"
import { invalidAuth } from '../../global_components/Helpers.js';
export default function Invoices(props) {

  const [invoices, setInvoices] = useState({ open: [], closed: [], estimates: [], na: [], onaccount:[] })
  const [loading, setLoading] = useState(false)
  //const [selected, setSelected] = useState(0)
  const [index, setIndex] = useState(0)
  const [showAddNewWOModal, setShowAddNewWOModal] = useState({ show: false, info: null })
  const [modals, setModals] = useState({
    newCustomer: false,
  })
  const [alert, setAlert] = useState({selected: '', show: false, duplicates:[{Lastname: "", Firstname:"", id:'', WorkorderNumber:''}]})
  useEventListener('keydown', myKeyPress);

  const inputRef = useRef();

  const columns = [{
    dataField: 'CustomerName',
    text: 'CustomerName',
    filter: textFilter({autocomplete:"off", delay: 10, style: { zoom: "90%" } }),
    sort: true,
    footer: "",
  }, {
    dataField: 'Vehicle',
    text: 'Vehicle',
    filter: textFilter({autocomplete:"off", delay: 10, style: { zoom: "90%" } }),
    footer: ""
  }, {
    dataField: 'License',
    text: 'License',
    filter: textFilter({autocomplete:"off", delay: 10, style: { zoom: "90%" } }),
    headerStyle: { width: "15%"/* , textAlign: 'center' */ },
    footer: ""
  }, {
    dataField: 'WorkorderNumber',
    text: 'WO #',
    sort: true,
    style: { textAlign: 'center' },
    headerStyle: { width: "10%", textAlign: 'center' },
    footer: ""

  }, {
    dataField: 'Date',
    text: 'Date',
    formatter: Constants.dateFormatter,
    /* sort: true, */
    style: { textAlign: 'center' },
    headerStyle: { width: "10%", textAlign: 'center' },
    footer: ""

  }, {
    dataField: 'Total',
    text: 'Total',
    formatter: priceFormatter,
    sort: true,
    style: { textAlign: 'right' },
    headerStyle: { width: "10%"/* , textAlign: 'center' */ },
    footer: columnData => priceFormatter(columnData.reduce((acc, item) => acc + item, 0)),
    footerAlign: 'right',
  }, {
    dataField: 'Balance',
    text: 'Balance',
    formatter: priceFormatter,
    sort: true,
    style: { textAlign: 'right' },
    headerStyle: { width: "10%"/* , textAlign: 'center' */ },
    footer: columnData => priceFormatter(columnData.reduce((acc, item) => acc + item, 0)),
    footerAlign: 'right',
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.history.push(`/invoices/${row._id}`)
    },
  };

  function priceFormatter(cell, row) {
    return (
      <span>
        $ { cell ? cell.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}
      </span>
    );
  }

  function myKeyPress(event) {
    if (event.key === "Insert") {
      //props.addPart(part)
      setShowAddNewWOModal({ ...showAddNewWOModal, show: true })
      event.preventDefault()
    }
    else if (event.key === "ArrowDown") {
      //setSelected(invoices.open[index + 1]._id)
      setIndex(index + 1)
    }
    else if (event.key === "ArrowUp" && index > 0) {
      //setSelected(invoices.open[index - 1]._id)
      setIndex(index - 1)
    }
  }

  async function createWO_existing(customer, searchInfo) {
    console.log("Invoice:createWO_existing:Create new WO, Existing customer: ", customer, "searchInfo: ", searchInfo);

    try {
      const res_data = await axios.post(`${Constants.port}/invoices/create`, { customer, searchInfo });
      console.log('fetching data createWO_existing: ', res_data);
      if(res_data.data.duplicates.length > 0){
        /* duplicate invoices exist for current customer.. */
        //window.open(`/invoices/${res_data.data.newWO._id}`, "_blank")
        setAlert({selected:res_data.data.newWO._id, show: true, duplicates: res_data.data.duplicates})
        return;
      }
      //setListOfJobs({ jobs: res_data.data.list_of_all_jobs })
      if (searchInfo)
        if (searchInfo.license !== "" && !res_data.data.vehicle_identification) {
          console.log("LicenseNumber specified but unable to select. Multiple vehicles with license:", searchInfo.license)
        }
      
      console.log("new id: ", res_data.data.newWO._id)
      props.history.push(`/invoices/${res_data.data.newWO._id}`)


    } catch (error) {
      console.log("Invoices:createWO_existing():Error creating WO with existing customer. ", error)
    }

  }

  async function getPAIDinvoices(e) {
    if (e === "closed") {
      //console.log("getting paid invoices...", e);
      if(invoices.closed.length > 0)
        return
      setLoading(true)
      try {
        const res_data = await axios.get(`${Constants.port}/invoices/someClosed`);
        console.log('fetching data get paid invoices: ', res_data);
        setInvoices({
          ...invoices,
          closed: res_data.data.closed,
        })
        
        setLoading(false)
      } catch (error) {
        console.log("Error while fetching invoice-data, getPAID, asdfjh17283")
        setLoading(false)
        //props.history.push({ pathname: `/error`, state: { resp: error } })
      }
    }
  }


  function afterFilter(newResult) {
    /* if (newResult.length > 0)
      setSelected(newResult[0].id)
    else
      setSelected(-1) */
  }

  function useEventListener(eventName, handler, element = window) {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(
      () => {
        // Make sure element supports addEventListener
        // On 
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event);

        // Add event listener
        element.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] // Re-run if eventName or element changes
    );
  };
  
  async function getInvoices() {
    setLoading(true)
    try {
      const res_data = await axios.get(`${Constants.port}/invoices/open2`);
      console.log('fetching data get open invoices: ', res_data);
      setInvoices({
        ...invoices,
        open: res_data.data.list_of_open_invoices,
        estimates: res_data.data.estimates,
        na: res_data.data.na,
        onaccount: res_data.data.onaccount
        /* closed: res_data.data.closed */
      })
      //console.log("TESTING RESPONSE,", res_data.data.list_of_open_invoices.length)
      //if (res_data.data.list_of_open_invoices.length > 0)
      //setSelected(res_data.data.list_of_open_invoices[0]._id)
      setLoading(false)
    } catch (error) {
      console.log("Error while fetching invoice-data, asjx19zz")
      if(error.response)
          if(error.response.status === 403)
              invalidAuth(props)
      setLoading(false)
      //props.history.push({ pathname: `/error`, state: { resp: error } })
    }
  }

  async function openWorkorder(item) {
    console.log("open new link workorder...", item);
    window.open(`/invoices/${item}`, "_blank")

  }

  useEffect(() => {
    document.querySelectorAll("input").forEach(b=>b.setAttribute('type','search'))
    
    getInvoices();
    document.getElementById('text-filter-column-CustomerName').focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function createNewCustomer(info) {
    setShowAddNewWOModal(false)
    setModals({ ...modals, newCustomer: true })
    //setCustomerInfo(info)
  }

  function closeAlert(){
    console.log("selected alert...", alert.selected);
    props.history.push(`/invoices/${alert.selected}`)
    setAlert({...alert, show: false})
  }

  return (

    <div className="invoice-container" style={{ zIndex: 950 }}>
      <div style={{position:"absolute"}}>
            <Button /* className="invoicesButton1" */ onClick={() => setShowAddNewWOModal({ show: true })}>Create New</Button>
            {/* <Button  variant="secondary" disabled> Find by WO #</Button> */}
      </div>
      <Tabs onSelect={(e) => getPAIDinvoices(e)} defaultActiveKey="home" transition={false} id="noanim-tab-example">
  
        <Tab eventKey="home" title="Open" className="large_invoice_table">
          <BootstrapTable
            bootstrap4
            keyField='_id'
            ref={inputRef}
            data={invoices.open}
            columns={columns}
            filter={filterFactory({ afterFilter })}
            rowEvents={rowEvents}
            striped
            hover
            /* selectRow={selectRow} */

            condensed
          />
          {!loading ? null : <Loading />}
        </Tab>

        <Tab eventKey="onAccount" title="On Account" className="large_invoice_table">
          <BootstrapTable

            bootstrap4
            keyField='_id'
            ref={inputRef}
            data={invoices.onaccount}
            columns={columns}
            filter={filterFactory({ afterFilter })}
            rowEvents={rowEvents}
            striped
            hover
            condensed
          />
          {!loading ? null : <Loading />}


        </Tab>

        <Tab eventKey="estimates" title="Estimates">
          <BootstrapTable
            bootstrap4
            keyField='_id'

            data={invoices.estimates}
            columns={columns}
            filter={filterFactory({ afterFilter })}
            rowEvents={rowEvents}
            striped
            hover
            /* selectRow={selectRow} */

            condensed
          />
        </Tab>

        <Tab eventKey="closed" title="Closed" className="large_invoice_table">
          <BootstrapTable

            bootstrap4
            keyField='_id'
            ref={inputRef}
            data={invoices.closed}
            columns={columns}
            filter={filterFactory({ afterFilter })}
            rowEvents={rowEvents}
            striped
            hover


            condensed
          />
          {!loading ? null : <Loading />}


        </Tab>

      

        <Tab eventKey="na" title="N/A" className="large_invoice_table">
          <BootstrapTable

            bootstrap4
            keyField='_id'
            ref={inputRef}
            data={invoices.na}
            columns={columns}
            filter={filterFactory({ afterFilter })}
            rowEvents={rowEvents}
            striped
            hover


            condensed
          />
          {!loading ? null : <Loading />}


        </Tab>

      </Tabs>
      
     {/*  <Alert variant="primary" show={alert.show}>
        Alert
          <Alert.Link >an example link</Alert.Link>
      </Alert> */}
        <Modal show={alert.show} animation={false} onHide={()=>closeAlert()}>
            <div className="spinnerpadding">
                <div className="overlay">
                <Alert variant="primary" onClose={()=>closeAlert()} dismissible>
                    <Alert.Heading>{alert.duplicates[0].Firstname ? `${alert.duplicates[0].Firstname } ` : ''}{alert.duplicates[0].Lastname} has the following open invoices</Alert.Heading>
                    <hr/>
                    Workorder: {alert.duplicates.map((item,key)=>{ return(<Alert.Link key={key} onClick={openWorkorder.bind(this, item.id)}> {item.WorkorderNumber}</Alert.Link>) })}
                    <hr />
                    <div className="d-flex justify-content-end">
          <Button disabled variant="success" style={{marginRight:"15px"}} >
            Refresh
          </Button>
          <Button onClick={()=>closeAlert()} >
            Close
          </Button>
              </div>        
                  </Alert>
                </div>
            </div>
        </Modal>

      {modals.newCustomer ? <NewCustomerModal
        invoice_status="new"
        info={{}}
        //loadCustomerInfo={(customer, searchInfo) => loadCustomerInfo(customer, searchInfo)}
        useEventListener={useEventListener}
        setError={props.error}
        //customer={invoiceInfo.Customer}
        close={() => setModals({ ...modals, newCustomer: false })}
        show={modals.newCustomer}
        createWO={(customer, searchInfo) => createWO_existing(customer, searchInfo)}
      /> : null}

      {showAddNewWOModal.show ?
        <CustomerSearch
          from="invoice_screen"
          createNewCustomer={(info) => createNewCustomer()}
          useEventListener={useEventListener}
          show={showAddNewWOModal.show}
          close={() => setShowAddNewWOModal({ show: false, info: null })}
          createWO={(customer, searchInfo) => createWO_existing(customer, searchInfo)}
        />
        : null}

    </div>

  );
}