import React, { useState } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

export default function EmployeeModal(props) {
  const show = props.show
  const [employeeInfo, setEmployeeInfo] = useState(props.employee)
  const columns = [ {
  dataField: 'Mon',
  text: 'Mon',
  headerStyle: { width: "7%", fontWeight:"500" },
}, {
  dataField: 'Tue',
  text: 'Tue',
  headerStyle: { width: "7%", fontWeight:"500" },
}, {
  dataField: 'Wed',
  text: 'Wed',
  headerStyle: { width: "7%", fontWeight:"500" },
  
}, {
  dataField: 'Thu',
  text: 'Thu',
  headerStyle: { width: "7%", fontWeight:"500" },
}, {
  dataField: 'Fri',
  text: 'Fri',
  headerStyle: { width: "7%", fontWeight:"500" },

}, {
  dataField: 'Sat',
  text: 'Sat',
  headerStyle: { width: "9%", fontWeight:"500" },

}, {
  dataField: 'Sum',
  text: 'Week Sum',
  headerStyle: { width: "13%", fontWeight:"500" },
  editable:false,
  formatter: calculateWeekAbsence,
  style: (cell, row, rowIndex, colIndex) => {
      return {
        backgroundColor: '#D3D3D3'
      };
  }
  

}, {
  dataField: 'SumAbsentHours',
  text: 'YTD Sum',
  headerStyle: { width: "13%", fontWeight:"500" },
  formatter: calculateTotalAbsence

}];

function calculateWeekAbsence() {
  let obj = employeeInfo.Payroll
  let sum = obj.Mon + obj.Tue + obj.Wed + obj.Thu + obj.Fri + obj.Sat + obj.Sun
  return sum;
}

function calculateTotalAbsence()
{
  let obj = employeeInfo.Payroll
  let sum = obj.Mon + obj.Tue + obj.Wed + obj.Thu + obj.Fri + obj.Sat + obj.Sun
  return sum + obj.SumAbsentHours;
}


  return (
    <Modal show={show} size="lg" onHide={props.close} className="invoice_item_modal">
      <Modal.Header closeButton>
        <Modal.Title> Employee Info.</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form >
          <Form.Row>
            <Form.Group key="mykey1" as={Col} >
              <Form.Label>Last name</Form.Label>
              <Form.Control key="2" defaultValue={employeeInfo.Lastname} onChange={(e) => setEmployeeInfo({ ...employeeInfo, Lastname: e.target.value })} type="lastName" />
            </Form.Group>

            <Form.Group key="mykey2" as={Col} >
              <Form.Label>First name</Form.Label>
              <Form.Control defaultValue={employeeInfo.Firstname} onChange={(e) => setEmployeeInfo({ ...employeeInfo, Firstname: e.target.value })} type="firstName" />
            </Form.Group>

            <Form.Group as={Col} controlId="4">
              <Form.Label>Social Security</Form.Label>
              <Form.Control defaultValue={employeeInfo.SocialSecurityNumber}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, SocialSecurityNumber: e.target.value })}
                type="houseNumber" />
            </Form.Group>

            <Form.Group as={Col} xs={2} >
              <Form.Label>Employee ID</Form.Label>
              <Form.Control disabled defaultValue={employeeInfo.EmployeeID} type="EmployeeID" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="3">
              <Form.Label>Phone</Form.Label>
              <Form.Control type="cellNumber" defaultValue={employeeInfo.Phone}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, Phone: e.target.value })} />
            </Form.Group>


            <Form.Group as={Col} controlId="5">
              <Form.Label>Phone2</Form.Label>
              <Form.Control defaultValue={employeeInfo.Phone2}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, Phone2: e.target.value })}
                type="workNumber" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridAddress6">
              <Form.Label>Address</Form.Label>
              <Form.Control defaultValue={employeeInfo.Address}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, Address: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City</Form.Label>
              <Form.Control defaultValue={employeeInfo.City}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, City: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} xs={1} controlId="formGridState">
              <Form.Label>State</Form.Label>
              <Form.Control defaultValue={employeeInfo.State}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, State: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip" xs={2}>
              <Form.Label>Zip</Form.Label>
              <Form.Control defaultValue={employeeInfo.Zip}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, Zip: e.target.value })} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={2}>
              <Form.Label>Marital Status</Form.Label>
              <Form.Control defaultValue={employeeInfo.MaritalStatus}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, MaritalStatus: e.target.value })}
              />
            </Form.Group>

            <Form.Group as={Col} xs={3} >
              <Form.Label>Number Exemptions</Form.Label>
              <Form.Control defaultValue={employeeInfo.NumberExemptions}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, NumberExemptions: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} >
              <Form.Label>FICA Exempt</Form.Label>
              <Form.Control defaultValue={employeeInfo.FICAExempt}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, FICAExempt: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} xs={2} >
              <Form.Label>Date Hired</Form.Label>
              <Form.Control defaultValue={employeeInfo.DateHired}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, DateHired: e.target.value })} />
            </Form.Group>

            <Form.Group as={Col} xs={2} >
              <Form.Label>Date Terminated</Form.Label>
              <Form.Control defaultValue={employeeInfo.DateTerminated}
                onChange={(e) => setEmployeeInfo({ ...employeeInfo, DateTerminated: e.target.value })} />
            </Form.Group>
          </Form.Row>
          </Form>
          <Form inline >
         

        {employeeInfo.Payroll ? 
          <Form.Row style={{marginTop:"10px"}}>
             <BootstrapTable
              keyField="_id"/* nonexistant */
              bootstrap4
              condensed
              data={ [employeeInfo.Payroll] }
              columns={ columns }
              cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true/* , afterSaveCell */ }) }
            />
            </Form.Row>
          : null}

        </Form>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}> Close </Button>
        <Button variant="primary" onClick={props.save.bind(this, employeeInfo)}> Save Changes </Button>
      </Modal.Footer>
    </Modal>
  )

}