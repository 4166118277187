import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';


export default function EmployeeOnlyModal(props) {
    const {show, employeeList} = props
  
    const [employee, setEmployee] = useState({
        employeeID: "",
    })
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            props.selectEmployee(employee)
            event.preventDefault()
        }
    }
    useEffect(() => {
        inputRef.current.focus();
    }, [])
 
    function getEmployee(checkNew){
        if(!checkNew){
            const currentOption = employeeList.find(option => option.value === employee.employeeID);
            return currentOption
        }
    }

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal">
            <Modal.Header closeButton>
                <Modal.Title>Select Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setEmployee({ employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,    
                                        }
                                    })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>

                </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={props.selectEmployee.bind(this, employee)}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                        </Button>
            </Modal.Footer>
        </Modal>
    )

}