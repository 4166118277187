import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { Chrono } from "react-chrono";
import axios from 'axios'
import * as Constants from '../../constants/constants'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from 'react-bootstrap-table-next';
import EmailModal from './Modals/EmailModal';
export default function HomeScreen(props) {
  const [currentEmail, setCurrentEmail] = useState({})
  const [emails, setEmails] = useState([])
  const [modals, setModals] = useState({
    email: false, 
  })
  const [statistics, setStatistics] = useState({
    num_jobs: '',
    num_invoices: '',
    clocked_hours: '',
    total_collected: '',
    completed_jobs: '',
    expenses_today: [],
    expenses_YTD:[],
    current_jobs:[]
  })
  
  const changelog_items = [{
    background: "#007bff",
    /* title: "May", */
    cardTitle: <section style={{ color: "#007bff" }}> Appointments, Labor-Reordering</section>,
    cardSubtitle: "May '22 - v1.4.0 ",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Appointments section added to Jobs page\n"}
        {"  Reorder labor items on an invoice using drag-and-drop \n"}
        {"  Notes show time created & last edited\n"}
        {"  Payments now show time of day & date\n"}
        {"  Pricing screen added \n"}
      </section>
  },{
    background: "#007bff",
    /* title: "May", */
    cardTitle: <section style={{ color: "#007bff" }}> Part Number search, Labor rate increase  </section>,
    cardSubtitle: "March '22 - v1.3.2 ",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Part number searches show all bills with specified part number\n"}
        {"  Labor rate increase from 90 to 125 /hr \n"}
        {"  Added a error notice for duplicate checkouts - you can now remove a checkout from DB page\n"}
        {"  Employee missed-hours are now tracked\n"}
        {"  Automated weekly data-finalization & backups \n"}
      </section>
  },{
    background: "#007bff",
    /* title: "May", */
    cardTitle: <section style={{ color: "#007bff" }}> Inventory, Purchase Orders, Bug Fixes </section>,
    cardSubtitle: "September '21 - v1.3.1 ",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Inventory-tracking updated; Add, remove & updating accounted for\n"}
        {"  Adding purchase orders has been implemented. Inventory amounts are updated for in-stock items.\n"}
        {"  A/C Quick-add button\n"}
        {"  React-Select dropdown menu bug fix\n"}
      </section>
  },{
    background: "#007bff",
    /* title: "May", */
    cardTitle: <section style={{ color: "#007bff" }}> JWT, Save-Slips on Checkout, Search Returns, Bug Fixes </section>,
    cardSubtitle: "March '21 - v1.3.0 ",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Added JWT for authorization\n"}
        {"  When doing the Daily Checkout, if the popup is minimized, all information is stored.\n"}
        {"  Added a filter for Returns table for searching\n"}
        {"  Fix -0.00 bug on checkout values & 'null' amount on split-payments\n"}
      </section>
  }, {
    /* title: "Feb", */
    cardTitle: <section style={{ color: "#007bff" }}> Job Search, Payroll implementation </section>,
    cardSubtitle: "February '21 - v1.2.4 ",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Jobs can be searched through the Search Page\n"}
        {"  Added features for the payroll system; "}
        {"When submitting the daily checkout a editable table is supplied to store hours worked that day."}
        {" Admins are able to view employee workweeks' through the database page"}
      </section>
  }, {
    /* title: "Jan", */
    cardTitle: <section style={{ color: "#007bff" }}> Ability to update a table, Return Module, Bug Fixes </section>,
    cardSubtitle: "January '21 - v1.2.3",
    cardDetailedText:
      <section style={{ whiteSpace: "break-spaces" }}>
        {"  Tables like 'Customer Vehicle' Tables can be updated by double-clicking a value\n"}
        {"  When removing a part, the 'Remove & Return' button removes the part & adds to the returns list\n"}
        {"  Returns can be marked complete on the Database page by selecting a return and clicking the 'Mark Complete' button\n"}
        {"  Split payments are able to mark invoices paid\n"}
        {"  Open button added while searching Invoices\n"}
        {"  Update tax values with ("}<b>ctrl `</b> {")"}
      </section>
  },]
  const columns_emails = [
    {
        dataField: 'subject',
        text: 'Subject',
    },{
      dataField: 'from',
      text: 'From',
  },{
    dataField: 'date',
    text: 'Date',
    formatter: Constants.dateFormatter_email,
    headerStyle:  { width: "16%"/* , textAlign: 'center' */ },
}];
  // eslint-disable-next-line
  async function getYTDcostAnalysis() {
    try {
      const res_data = await axios.get(`${Constants.port}/invoices/calculateExpenses`);
      console.log("YTD analysis...", res_data)
      let cpy = []
      cpy.push(res_data.data.summary[0].parts)
      cpy.push(res_data.data.summary[0].waste)
      cpy.push(res_data.data.summary[0].sublet)
      //setPieDataset(cpy)
      console.log(cpy);

    } catch (error) {
      console.log("Error generating YTD cost analysis...", error)
    }
  }

  async function getStatistics() {
    try {
      const res_data = await axios.get(`${Constants.port}/analysis/reports`)
      setStatistics({
        ...statistics,
        num_old_appointments: res_data.data.num_old_appointments,
        num_appointments: res_data.data.num_appointments,
        num_old_invoices: res_data.data.num_old_invoices,
        num_jobs: res_data.data.num_jobs,
        num_invoices: res_data.data.num_open_invoices,
        completed_jobs: res_data.data.num_completed_jobs,
        expenses_today: res_data.data.expenses_today,
        expenses_YTD: res_data.data.expenses_YTD,
        current_jobs: res_data.data.current_jobs
      })
    } catch (error) {
      console.log("Error getting statistics for home page...", error);
    }
  }
  
  // eslint-disable-next-line
  async function getEmails(){
    try {
      const res_data = await axios.get(`${Constants.port}/gmail/getRecentEmails`)
      setEmails(res_data.data.message)
      console.log("getting emails...,",res_data.data.message);
    
    } catch (error) {
      console.log("Error getting statistics for home page...", error);
    }
  }

  useEffect(() => {
    //getYTDcostAnalysis()
    getStatistics()
    //getEmails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rowEvents_emails = {
    onClick: (e, row, rowIndex) => {
        console.log("CLICKED ROW");
        setCurrentEmail(row)
        setModals({ ...modals, email: true })
        //props.history.push(`/customers/${row._id}`)
    },
};
const rowStyle2 = (row, rowIndex) => {

  const style = {}
  if (row.read) {
    style.fontWeight = 'bold';
  }
  return style;

};
  function MYCARD(e) {
    return (
      <Card className="home_screen_card" onClick={() => console.log("clicking on ...", e)}>
        <Card.Body>
          <Card.Text className="home_screen_card_maintext">
            {e.item.text}
            <br />
          </Card.Text>
          <Card.Text className="home_screen_card_subtext">
            {e.item.title}
            <br />
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }
  return (

    <div style={{ background: "#f5f7fb", height: "100vh", width: "100%", overflowX: "hidden" }}>
      {/* HEADER */}
      <Row style={{ margin: "auto", padding: "15px" }}>
        <h2>Dashboard</h2>
        <div style={{margin:"auto"}}>

        </div>
        <div >
          <Button  className="home_screen_button" onClick={() => props.history.push("/invoices")}> Invoices </Button>
          <Button  className="home_screen_button" onClick={() => props.history.push("/jobs")}> Jobs </Button>
          <Button  className="home_screen_button" onClick={() => props.history.push("/vendors")}> Vendors </Button>
          <Button  className="home_screen_button" onClick={() => props.history.push("/inventory")}> Inventory </Button>
          <Button  className="home_screen_button" onClick={() => props.history.push("/report")}> Reports </Button>
          <Button  className="home_screen_button" onClick={() => props.history.push("/search")}> History </Button>
        </div>
      </Row>


      <div style={{ width: "70%", overflow: "hidden", float: "left" }}>
        <Row>
       
          <Col>
            <h3 style={{ textAlign: "center", fontWeight: 300 }}>Todays Statistics</h3>
            <hr style={{ margin: "auto", "width": "40%", marginBottom: "10px" }} />
            <Row style={{ placeContent: "center" }}>
              <MYCARD item={{ text: statistics.num_jobs || '--', title: "Jobs" }}></MYCARD>
              <MYCARD item={{ text: statistics.num_appointments || '--', title: "Appointments" }}></MYCARD>
              <MYCARD item={{ text: statistics.num_invoices || '--', title: "Open Invoices" }}></MYCARD>
              <MYCARD item={{ text: statistics.completed_jobs || '--', title: "Completed Jobs" }}></MYCARD>
            </Row>

            <Row style={{ placeContent: "center" }}>
              <MYCARD item={{ text: statistics.total_collected || '--', title: "N/A" }}></MYCARD>
              <MYCARD item={{ text: statistics.num_old_appointments || '--', title: "Appointments over 30 days old" }}></MYCARD>
              <MYCARD item={{ text: statistics.num_old_invoices || '--' , title: "Invoces over 30 days old" }}></MYCARD>
              <MYCARD item={{ text: '--', title: "N/A" }}></MYCARD>
            </Row>
          </Col>
        </Row>
    <br/>
        <Row style={{ placeContent: "center" }}>
         
          
          <Col style={{height:"390px", overflowY:"scroll", maxWidth: "80%"}}>
          <h3 style={{ textAlign: "center", fontWeight: 300 }}>Gmail</h3>
            <hr style={{ margin: "auto", "width": "15%", marginBottom: "10px" }} />
            <BootstrapTable
                bootstrap4
                keyField='_id'
                data={emails}
                columns={columns_emails}
                /*  filter={filterFactory()} */
                rowEvents={rowEvents_emails}
                striped
                hover
                condensed
                rowStyle={ rowStyle2 }
            />
          </Col>
        </Row>
      
      </div>


      <div style={{ width: "30%", overflow: "hidden", height: "90vh" }}>

        <h3 style={{ textAlign: "center", fontWeight: 300 }}>Changelog</h3>
        <hr style={{ margin: "auto", "width": "40%" }} />
        <Chrono theme={{
          primary: '#253E66',
          secondary: '#fffff',
          textColor: "#007bff",

        }} useReadMore={false} scrollable mode="VERTICAL" items={changelog_items} hideControls cardWidth={1000} itemWidth={125} />


      </div>





      {modals.email ?
                    <EmailModal
                        show={modals.email}
                        close={() => setModals({ ...modals, email: false })}
                        email={currentEmail}
                        
                    />
                    : null}

    </div>
  )

}