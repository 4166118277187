import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginScreen from './components/login_screen/LoginScreen.js';
import axios from 'axios'
import HomeScreen from './components/home_screen/HomeScreen.js';
import Navigation from './global_components/navigation/Navigation.js';
import Invoices from './components/invoice_screen/Invoices.js';
import Inventory from './components/inventory_screen/Inventory.js'
import Vendors from './components/vendor_screen/Vendors.js';
import ErrorScreen from './components/error_screen/ErrorScreen.js';
import PrivateRoute from './authentication/PrivateRoute.js';
import AdminRoute from './authentication/AdminRoute.js';
import { AuthContext } from "./authentication/auth.js";
import NewInvoiceContents from './components/invoice_screen/NewInvoiceScreen.js';
import JobsScreen from './components/jobs_screen/JobsScreen.js';
import SearchScreen from './components/search_screen/SearchScreen.js';
import ReportScreen from './components/reports_screen/ReportScreen.js';
import Error from './global_components/Error.js'
import Loading from './global_components/Loading.js'
import AdminScreen from './components/admin_screen/AdminScreen.js'
import PricingScreen from './components/pricing_screen/PricingScreen.js' 
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
/* console.log = function() {} */
 export default function App() {
  
  
  document.title = "AIMS_PT2"
// eslint-disable-next-line
  const [error, setError] = useState({ show: false, message: "" })
  const [loading, setLoading] = useState(false);

  const existingCode = localStorage.getItem("accessCode");
  const existingTokens = localStorage.getItem("accessToken");
  axios.defaults.headers.common['Authorization'] = existingTokens;
  const [authTokens, setAuthTokens] = useState({accessToken: existingTokens, accessCode: existingCode});
  const setTokens = (data) => {
    localStorage.setItem("accessCode", data.permissions);
    localStorage.setItem("accessToken", data.token);
    setAuthTokens({accessToken: data.token, accessCode: data.permissions});
  } 

  function logout() {
    console.log("signing off.")
    setTokens({accessToken:null, accessCode:0});
}
  
  return (
    <DndProvider backend={HTML5Backend}>

    <div className="App" >
      <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <Router>
          <div className="">
            <Navigation auth={authTokens} />
            <Switch>
              <PrivateRoute exact path="/" component={(props) => <HomeScreen {...props} authTokens={authTokens}  />} />
              <Route path="/login" component={(props) => <LoginScreen {...props} />} />
              <Route path="/pricing" component={(props) => <PricingScreen {...props} />} />

              <PrivateRoute exact path="/invoices" component={(props) => <Invoices {...props} authTokens={authTokens}  setLoading={(val) => setLoading(val)} setError={(e) => setError(e)} />} />
              <PrivateRoute path="/invoices/:id" component={(props) => <NewInvoiceContents {...props} setLoading={(val) => setLoading(val)} setError={(e) => setError(e)} />} />
              <PrivateRoute path="/vendors" component={Vendors} />
              <PrivateRoute path="/inventory" component={(props) => <Inventory {...props} setLoading={(val) =>setLoading(val)} setError={(e) => setError(e)}/>} />
              <PrivateRoute path="/error" component={ErrorScreen} />
              <PrivateRoute path="/jobs" component={(props) => <JobsScreen logout={()=>logout()} {...props} setLoading={setLoading(false)} setError={(e) => setError(e)} />} />
              <PrivateRoute path="/search" component={(props) => <SearchScreen {...props} setLoading={() => setLoading(false)} setError={(e) => setError(e)} />} />

              <AdminRoute path="/report" component={(props) => <ReportScreen {...props} setLoading={() => setLoading(true)} setError={(e) => setError(e)} />} />
              <AdminRoute path="/database" component={(props) => <AdminScreen {...props} setLoading={() => setLoading(true)} setError={(e) => setError(e)} />} />

              <PrivateRoute path="/:any" component={(props)=><HomeScreen {...props} authTokens={authTokens} />}  />

            </Switch>
          </div>
        </Router>
      </AuthContext.Provider>

      {error.show ?
        <Error error={error.message} show={error.show} onHide={() => setError({ message: "", show: false })} close={() => setError({ message: "", show: false })} />
        : null}
      {!loading ? null : <Loading />}

    </div>
    </DndProvider>
  );
}



