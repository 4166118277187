import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Modal,Alert } from 'react-bootstrap';

export default function NotesModal(props) {
    const {show, cannedItem} = props
    const [notes, setNotes] = useState({
        description: cannedItem?.description || ''
    })
    const [error, setError] = useState("")

    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            props.addNote(notes)
            event.preventDefault()
        }
    }

    function checkForValidation() {
        
        if (notes.description === "")
            setError("You must enter a note")
        else
            props.addNote(notes)
        
    }
    useEffect(()=>{
        //console.log(inputRef.current);
        inputRef.current.focus();
    }, [])
    
    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal">
            <Modal.Header closeButton>
                <Modal.Title>Notes Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}

                <Form >
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control ref={inputRef} as="textarea" rows="4"
                            defaultValue={notes.description}
                            onChange={(e) => setNotes({ description: e.target.value.toUpperCase() })} />
                    </Form.Group>
                    <br />
                    <Button variant="primary" onClick={()=>checkForValidation()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}