import React, { useState, useEffect, useRef } from 'react';
import {  Form, Col, Button, Modal } from 'react-bootstrap';


export default function VendorItemModal(props) {
    const show = props.show;
    const action = props.action;
    const [vendorInfo, setVendorInfo] = useState(props.vendor ? props.vendor : {
        Vendor: "",
        TaxID: "",
        Contact: "",
        Phone1: "",
        Phone2: ""
    })
    const inputRef = useRef();
    console.log(vendorInfo);
    function myKeyPress(event) {
        if (event.key === "F10") {
            if(action === "add")
                props.add(vendorInfo)
            else
                props.update(vendorInfo)
            event.preventDefault()
        }
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    useEffect(() => {
        
        //document.getElementById('partNumberTF').focus()
        inputRef.current.focus();
    }, [])

    useEventListener('keydown', myKeyPress);

    return (
        <Modal show={show} onHide={props.close} onKeyPress={(event) => myKeyPress(event)} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title>{action==='add' ? 'Add' : 'Edit'} a Vendor</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <Form.Label>Vendor</Form.Label>
                            <Form.Control defaultValue={vendorInfo.Vendor} ref={inputRef} onChange={(e) => setVendorInfo({ ...vendorInfo, Vendor: e.target.value })} />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>Contact</Form.Label>
                            <Form.Control  defaultValue={vendorInfo.Contact} onChange={(e) => setVendorInfo({ ...vendorInfo, Contact: e.target.value })} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Label>Phone1</Form.Label>
                    <Form.Control  defaultValue={vendorInfo.Phone1}  onChange={(e) => setVendorInfo({ ...vendorInfo, Phone1: e.target.value })} />
                    <Form.Label>Phone2</Form.Label>
                    <Form.Control  defaultValue={vendorInfo.Phone2}  onChange={(e) => setVendorInfo({ ...vendorInfo, Phone2: e.target.value })} />
                    <Form.Label>Tax ID</Form.Label>
                    <Form.Control  defaultValue={vendorInfo.TaxID} onChange={(e) => setVendorInfo({ ...vendorInfo, TaxID: e.target.value })} />

                </Form>
            </Modal.Body>

            <Modal.Footer>
            <Button variant="danger" style={{marginRight:"auto"}} onClick={props.remove.bind(this, vendorInfo)}> Remove </Button>

                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={action==='add'?props.add.bind(this, vendorInfo):props.update.bind(this, vendorInfo)} > Submit  </Button>
            </Modal.Footer>

        </Modal>
    )
}