import React, { /* useState , useEffect */ } from 'react';

export default function ErrorScreen(props){
    console.log(props);
    
    if(props.location.state == null)
        return(
            <div>

            <h1>Error Screen </h1>

            <h3> NOTHING?! </h3>

            
        </div>
        )
    var error = props.location.state.resp;
  /*   if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      } */

    function ErrorMSG() {
        if(error)
            if(error.response)
                return(
                    <h3>
                        {`${error.response.data} \n ${error.response.status} \n ${error.response.headers}`}
                    </h3>
                )
            else if(error.request)
                return(
                    <h3>
                        {error.request}
                    </h3>
                )
            else
                return(
                    <h3>
                        {error.message}
                    </h3>
                )
        else
            return(
                <h3>
                    {error}
                </h3>
            )
    }

    return(
        <div>

            <h1>Error Screen </h1>


            <ErrorMSG/>

            {/* <h5>Save a picture of this error screen and Contact ADAM immediately</h5> */}
            
        </div>
    );
}