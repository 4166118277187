import React, { useState, useEffect } from 'react';
import { Row, Button, Card, Container, Col, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import * as Constants from './../../constants/constants.js';
import axios from 'axios';
import JobCard from "./JobCard.js"
import AddJobModal from "./Modals/AddJobModal.js"
import EditJobModal from "./Modals/EditJobModal.js"
import socket from '../../constants/socketconfig'
import Loading from "../../global_components/Loading.js"
import NoteModal from "./Modals/NoteModal.js"
import { invalidAuth } from '../../global_components/Helpers.js';
export default function JobsScreen(props) {

    const [listOfJobs, setListOfJobs] = useState({ jobs: [] })
    const [notes, setNotes] = useState([])
    const [editItem, setEditItem] = useState({})
    const [apts, setApts] = useState({ show: false, list: [] })
    const [modals, setModals] = useState({
        addNote: false,
        editNote: false,
        addJob: false,
        addScheduledJob: false,
    })
    const [showEditJobModal, setShowEditJobModal] = useState({ show: false, job: null })
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0)
    window.onbeforeunload = () => {
        sessionStorage.clear()
    }

    async function saveJobChanges(newJob) {
        try {
            await axios.post(`${Constants.port}/jobs/${newJob._id}/update`,
                Object.assign(newJob, { createWO: false })
            );
            setShowEditJobModal({ show: false, job: null })
        } catch (error) {
            console.log("CATCHING ERROR. HANDLE THIS-62311zz")
            setShowEditJobModal({ show: false, job: null })

        }
    }

    async function moveJob(newJob) {
        try {
            await axios.post(`${Constants.port}/jobs/${newJob._id}/moveJob`);
            setShowEditJobModal({ show: false, job: null })
        } catch (error) {
            console.log("CATCHING ERROR. HANDLE THIS-62311zz")
            setShowEditJobModal({ show: false, job: null })

        }
    }

    async function addJob(jobToAdd) {
        try {
            console.log("jobToAdd: ", jobToAdd)
            await axios.post(`${Constants.port}/jobs/add`, jobToAdd);
            setModals({ ...modals, addJob: false, addScheduledJob: false })
            //setListOfJobs({ jobs: res_data.data.jobs })
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS-61", error)
        }

    }

    async function addNote(noteToAdd) {
        try {
            console.log("newNote: ", noteToAdd)
            noteToAdd.CreatedTime = new Date()
            const res_data = await axios.post(`${Constants.port}/notes/add`, noteToAdd);
            console.log('fetching data addNote: ', res_data);
            setModals({ ...modals, addNote: false })
            setNotes(res_data.data.notes)
        } catch (error) {
            console.log("Error adding new note - JobScreen.js")
        }

    }

    async function updateNote(noteToUpdate) {
        try {
            console.log("noteToUpdate: ", noteToUpdate)
            noteToUpdate.LastEditTime = new Date()
            const res_data = await axios.post(`${Constants.port}/notes/${noteToUpdate._id}/update`, noteToUpdate);
            console.log('fetching data updateNote: ', res_data);
            setModals({ ...modals, editNote: false })
            setNotes(res_data.data.notes)
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS-656")
        }

    }

    async function removeNote(noteToRemove) {
        try {
            console.log("removeNote: ", noteToRemove)
            const res_data = await axios.post(`${Constants.port}/notes/${noteToRemove._id}/remove`);
            console.log('fetching data removeNote: ', res_data);
            setModals({ ...modals, editNote: false })
            setNotes(res_data.data.notes)
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS-656")
        }

    }

    async function getJobs() {
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/jobs/getAllJobs`);
            if (sessionStorage.getItem("sortJobs") === "Status")
                setListOfJobs({ jobs: res_data.data.list_of_all_jobs.sort(compareStatus) })
            else
                setListOfJobs({ jobs: res_data.data.list_of_all_jobs.sort(compareTime) })
            setLoading(false)

        } catch (error) {
            console.log("JobsScreen: Error getting all jobs.", error)
            if (error.response)
                if (error.response.status === 403) {
                    invalidAuth(props)
                    props.history.push('/login')
                }
            setLoading(false)

        }
    }

    async function getScheduledJobs() {
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/jobs/getScheduledJobs`);
            setApts({ ...apts, list: res_data.data.apt_list.sort(compareDate)})
            setLoading(false)
        } catch (error) {
            console.log("JobsScreen: Error getting all scheduled jobs.", error)
            if (error.response)
                if (error.response.status === 403) {
                    invalidAuth(props)
                    props.history.push('/login')
                }
            setLoading(false)

        }
    }

    async function getNotes() {
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/notes/getNotes`);
            setNotes(res_data.data.notes)
            setLoading(false)

        } catch (error) {
            console.log("JobsScreen: Error getting all notes.", error)
            if (error.response)
                if (error.response.status === 403) {
                    invalidAuth(props)
                    props.history.push('/login')
                }
            setLoading(false)
            return
        }
    }

    async function openWO(job) {
        console.log(job)
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/invoices/${job.WorkorderNumber}/getInvoiceByWONumber`);
            console.log('fetching dat2a: ', res_data.data.invoice._id);
            props.history.push(`/invoices/${res_data.data.invoice._id}`)
            setLoading(false)

        } catch (error) {
            console.log("Error - aslx71", error)
            if (error.response)
                props.setError({ show: true, message: error.response.data + "\n" + error.response.status + "\n" + error.response.headers })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: "Error getting invoices/:id/getInvoiceByWONumbers, errno: as1x71" })

        }
    }

    async function createWO_blank(customer) {
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/create/blank`, { customer });
            console.log('fetching data, create blank invoice: ', res_data, "\ncustomer:", customer);
            const res_data_2 = await axios.post(`${Constants.port}/jobs/${customer._id}/update`,
                Object.assign(customer, { WorkorderNumber: res_data.data.newWO.Workorder.WorkorderNumber, createWO: true })
            );
            console.log("feting update job:", res_data_2)

            props.history.push(`/invoices/${res_data.data.newWO._id}`)

        } catch (error) {
            console.log("Error - asdz71s")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }

    }

    async function removeJob(job) {
        try {
            const res_data = await axios.post(`${Constants.port}/jobs/${job._id}/remove`);
            console.log('fetching data REMOVEJOB: ', res_data);
        } catch (error) {
            console.log("testing CATCHING ERROR. removing job by ID ")
        }
    }

    function compareStatus(a, b) {

        if(a.Status === b.Status)
            return compareTime(a,b)

        const statusOrder = {
            'STARTED': 5,
            'OPEN': 4,
            'NEEDS APPROVAL': 3,
            'NEEDS PARTS': 2,
            'FINISHED': 1,
        };
    
        const statusA = statusOrder[a.Status] || 0;
        const statusB = statusOrder[b.Status] || 0;
    
        return statusB - statusA;
        
    }

    function compareDate(a, b) {
        if(!b.ScheduledDate)
            return -1
        if (a.ScheduledDate < b.ScheduledDate) {
            return -1;
        }
        if (a.ScheduledDate > b.ScheduledDate) {
            return 1;
        }       
        return 0

    }

    function compareTime(a, b) {
        if (a.TimeIn < b.TimeIn) {
            return 1;
        }
        if (a.TimeIn > b.TimeIn) {
            return -1;
        }
        return 0;
    }

    function sortJobs(method) {
        sessionStorage.setItem("sortJobs", method)
        if (method === "Status") {
            let cpy = listOfJobs.jobs.sort(compareStatus)
            setListOfJobs({ jobs: cpy })
        }
        else if (method === "Time") {
            let cpy = listOfJobs.jobs.sort(compareTime)
            setListOfJobs({ jobs: cpy })
        }
    }

    function clickEditNote(item) {
        setModals({ ...modals, editNote: true })
        setEditItem(item)
    }

    useEffect(() => {
        getJobs();
        getNotes();
        getScheduledJobs();

        socket.on('updateJobs', message => {
            console.log('updateJob -- Updating Jobs ClientSide -', message);
            setCount((c) => c + 1)
        })
        socket.on('updateNotes', message => {
            console.log('updateNotes -- Updating Notes ClientSide -', message);
            setCount((c) => c + 1)
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getJobs();
        getNotes();
        getScheduledJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    return (
        <div style={{ display: "inline-flex", width: "100%", height: "100vh" }}>

            <div className="cardpadding_row">
                <Card style={{ overflowY: "scroll", height: "100%" }}>
                    <Card.Header style={{ fontSize: "35px", paddingRight: "10px" }}>
                        <Row style={{ margin: "auto" }}>
                            <Col>
                                <p style={{ margin: "auto" }} >Current Jobs{' '}</p>
                                <p style={{ fontSize: "18px", marginLeft: "10px", marginTop: "-8px", marginBottom: "auto"/* margin:"auto", */ }}>
                                    Total: {listOfJobs.jobs.length}
                                </p>
                            </Col>
                            <Col>
                                <Row>
                                    <Button
                                        style={{ marginTop: "10px", marginLeft: "25px", alignSelf: "center" }}
                                        onClick={() => setModals({ ...modals, addJob: true })}
                                        variant="primary">
                                        Add Job
                                    </Button>

                                    <DropdownButton /* onClick={()=>sort()} */ style={{ marginLeft: "25px", alignSelf: "center" }} title="Sort By">
                                        <Dropdown.Item onClick={() => sortJobs("Status")} >Status</Dropdown.Item>
                                        <Dropdown.Item onClick={() => sortJobs("Time")}>Time</Dropdown.Item>
                                    </DropdownButton>
                                </Row>
                            </Col>
                            {/* <Button
                                style={{ marginLeft: "25px", alignSelf: "center" }}
                                onClick={() => setShowAddNewWOModal({show: true})}
                                variant="primary">
                                Create WO
                            </Button> */}
                            <Col id="legends">
                                <Row style={{ margin: "auto" }} >
                                    <Col>
                                        <Row style={{ fontSize: "12px" }}><div className="yellowdot_legend"></div> Open</Row>
                                        <Row style={{ fontSize: "12px" }}><div className="greendot_legend"></div> Started</Row>
                                        <Row style={{ fontSize: "12px" }}><div className="bluedot_legend"></div> Finished</Row>

                                    </Col>
                                    <Col >
                                        <Row style={{ fontSize: "12px" }}><div className="purpdot_legend"></div> Needs Approval</Row>
                                        <Row style={{ fontSize: "12px" }}><div className="reddot_legend"></div> Needs Parts</Row>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                    </Card.Header>
                    <Container >
                        <Row>
                            {listOfJobs.jobs.map((job, key) =>
                                <JobCard
                                    job={job}
                                    key={key}
                                    onClick={() => setShowEditJobModal({ show: true, job: job })} />)}
                        </Row>
                    </Container>

                    {!loading ? null : <Loading component="JobsScreen" />}

                </Card>

                {modals.addJob ?
                    <AddJobModal
                        show={modals.addJob}
                        close={() => setModals({ ...modals, addJob: false, addScheduledJob: false })}
                        add={(newJob) => addJob(newJob)}
                        scheduled={modals.addScheduledJob}
                    />
                    : null}

                {showEditJobModal.show ?
                    <EditJobModal
                        from="jobscreen"
                        show={showEditJobModal.show}
                        jobInfo={showEditJobModal.job}
                        moveJob={(job) => moveJob(job)}
                        save={(newJob) => saveJobChanges(newJob)}
                        remove={(job) => { removeJob(job); setShowEditJobModal({ show: false, job: null }) }}
                        close={() => setShowEditJobModal({ show: false, job: null })}
                        createWO={(job) => createWO_blank(job)}
                        openWO={(job) => openWO(job)}
                    />
                    : null}



            </div>

            <div className="cardpadding_row">
                <Card style={{ overflowY: "scroll", height: "100%" }}>
                    <Card.Header style={{ fontSize: "35px", paddingRight: "10px" }}>
                        <Row style={{ margin: "auto" }}>
                            <p >{apts.show ? "Appointments" : "Notes"}</p>
                            {apts.show ?
                                <Button
                                    style={{ marginLeft: "25px", alignSelf: "center" }}
                                    onClick={() => setModals({ ...modals, addJob: true, addScheduledJob: true })}
                                    variant="primary">
                                    Add Apt.
                                </Button>
                                :
                                <Button
                                    style={{ marginLeft: "25px", alignSelf: "center" }}
                                    onClick={() => setModals({ ...modals, addNote: true })}
                                    variant="primary">
                                    Add Note
                                </Button>
                            }

                            <Form style={{ fontSize: "15px", display: "inline-flex", position: "absolute", right: "10px" }}>
                                <p style={{ marginRight: "1rem" }}>Notes</p>
                                <Form.Check
                                    type="switch"
                                    id="view-switch"
                                    onClick={() => setApts({ ...apts, show: !apts.show })}
                                />
                                Appointments
                            </Form>
                        </Row>

                    </Card.Header>
                    <Container >
                        <Row>

                            {apts.show ? apts.list.map((job, key) =>
                                <JobCard
                                    job={job}
                                    key={key}
                                    onClick={() => setShowEditJobModal({ show: true, job: job })}
                                />)
                                :
                                notes.map((item, key) =>
                                    <Card className="jobcard" style={{ zoom: "80%", width: '18rem', margin: "10px" }}
                                        item={item}
                                        key={key}
                                        onClick={() => clickEditNote(item)} >
                                        <Card.Body>
                                            <Card.Text>
                                                {item.Description}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>)}

                        </Row>
                    </Container>

                    {!loading ? null : <Loading component="JobsScreen" />}

                </Card>

                {modals.addNote ?
                    <NoteModal
                        edit={false}
                        show={modals.addNote}
                        close={() => setModals({ ...modals, addNote: false })}
                        add={(newNote) => addNote(newNote)}
                    />
                    : null}

                {modals.editNote ?
                    <NoteModal
                        edit={true}
                        show={modals.editNote}
                        close={() => setModals({ ...modals, editNote: false })}
                        add={(newNote) => addNote(newNote)}
                        update={(newNote) => updateNote(newNote)}
                        remove={(newNote) => removeNote(newNote)}
                        note={editItem}
                    />
                    : null}

            </div>

        </div>
    )
}