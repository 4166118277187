import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Button, Modal, InputGroup, Alert } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact'
import CreatableSelect from 'react-select/creatable'
import * as Constants from './../../../constants/constants'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

export default function AddPOModal(props) {
    const show = props.show;
    const [parts, setParts] = useState({ list: [] })
    const [error, setError] = useState("")
    const [poInfo, setpoInfo] = useState({
        Vendor: "",
        VendorId: "",
        Date: Constants.dateFormatter(new Date()),
        InvoiceNumber: "",
        Items: [
            {
                _id: '',
                PartNumber: "",
                Cost: "",
                List: "",
                Quantity: "",
                Markup: '',
                Status: "",
            }
        ],
    })

    const inputRef = useRef();
    function myKeyPress(event) {
        if (event.key === "F10") 
            event.preventDefault()
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    async function getInventory() {
        try {
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data: ', res_data);
            setParts({ list: res_data.data.inventory })

        } catch (error) {
            console.log("Error getInventory:AddPOModal.js - Unable to retrieve inventory", error)
        }
    }

    function updateVendor(newValue, actionMeta) {
        setpoInfo({...poInfo,Vendor: newValue.label})
    }

    function updatePN(e, item, key) {
        console.log("event:", e);
        //item.Description = e.target.value

        item.List = e.List
        item._id = e.value
        item.PartNumber = e.label
        /* item.Cost = "" */
        item.Markup = ""
        /* item.Quantity = "" */
        setpoInfo({ ...poInfo })
    }

    function updateQuantity(e, item, key) {
        item.Quantity = e.target.value
    }

    function updateCost(e, item, key) {
        item.Cost = e.target.value
    }

    function updateList(e, item, key) {
        item.List = e.target.value
    }

    function addItem() {
        let cpy = poInfo.Items
        cpy.push({
            _id: "",
            PartNumber: "",
            Cost: "",
            List: "",
            Quantity: "",
            Markup: "",
            Status: ""
        })
        setpoInfo({ ...poInfo, Items: cpy })
    }

    function updateMarkup(e, i, key) {
        console.log("updating markup... event:", e.target.value, "i:", i, "key:", key)
        i.Markup = Constants.round_and_format(i.List / e.target.value)
        setpoInfo({ ...poInfo })

    }

    async function submitItem(item) {
        console.log("submitItem:AddPOModal.js,", item)
        try {
            const res_data = await axios.post(`${Constants.port}/inventory/updateAmount`, item);
            console.log('fetching data, submitItem:AddPOModal.js: ', res_data);
            item.Status = "success"
            setpoInfo({ ...poInfo, Items: poInfo.Items })


        } catch (error) {
            console.log("Error submitItem:AddPOModal.js - Unable to update inventory", error)
            item.Status = "error"
        }

    }


    function validateInfo() {
        let cpy_items = poInfo.Items
        let err = 0
        if(poInfo.Vendor === "")
        {
            setError("Must select a Vendor ")
            return
        }
        for (var i = 0; i < cpy_items.length; i++) {

            if(cpy_items[i].Status === "success")
                continue

            if (!cpy_items[i].PartNumber) {
                setError("Must select a part from the dropdown menu ")
                cpy_items[i].Status = "error"
                err++
            }
            else if (!cpy_items[i].Cost) {
                setError("Must enter a cost for part " + cpy_items[i].PartNumber)
                cpy_items[i].Status = "error"
                err++
            }
            else if (!cpy_items[i].Quantity) {
                setError("Must enter a quantity for part " + cpy_items[i].PartNumber)
                cpy_items[i].Status = "error"
                err++
            }
            else {
                cpy_items[i].Status = "pending"
                submitItem(cpy_items[i])
            }
            setpoInfo({ ...poInfo, Items: cpy_items })
            if(err === 0) 
                props.close()
             
        }
    }

    useEffect(() => {
        getInventory()
        inputRef.current.focus();
    }, [])

    useEventListener('keydown', myKeyPress);

    return (
        <Modal show={show} size="lg" onHide={props.close} onKeyPress={(event) => myKeyPress(event)} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title>Add a Purchase Order</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: "80vh", overflowY: "scroll" }}>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}

                <Form>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <Form.Label>Vendor</Form.Label>

                            <CreatableSelect
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, zoom:"80%" }) }}
                                ref={inputRef}
                                onChange={updateVendor}
                                options={
                                    props.vendor_list.map((item) => {
                                        return {
                                            _id: item._id,
                                            value: item._id,
                                            label: item.Vendor

                                        }
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>Invoice Number</Form.Label>
                            <Form.Control defaultValue={poInfo.InvoiceNumber} onChange={(e) => setpoInfo({ ...poInfo, InvoiceNumber: e.target.value })} />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label>Date</Form.Label>
                            <Form.Control defaultValue={poInfo.Date} onChange={(e) => setpoInfo({ ...poInfo, Date: e.target.value })} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        {poInfo.Items.map((i, key) => {
                            return (
                                <>
                                    <Form.Row>
                                        <Form.Group as={Col} >
                                            <Form.Label>Part Number</Form.Label>
                                            <CreatableSelect
                                             menuPortalTarget={document.body}
                                             styles={{ menuPortal: base => ({ ...base, zIndex: 9999, zoom:"80%" }) }}
                                                onChange={/* handleChange_part */ (e) => updatePN(e, i, key)}
                                                options={
                                                    parts.list.map((item) => {
                                                        return {
                                                            value: item._id,
                                                            label: item.PartNumber,
                                                            List: item.List
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control defaultValue={i.Quantity} onChange={(e) => updateQuantity(e, i, key)} />
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <Form.Label>Cost</Form.Label>
                                            <Form.Control defaultValue={i.Cost} onChange={(e) => {
                                                updateCost(e, i, key);
                                                updateMarkup(e, i, key)
                                            }
                                            } />
                                        </Form.Group>
                                        <Form.Group as={Col}>

                                            <Form.Label htmlFor="inlineFormInputGroupMarkup" visuallyHidden>
                                                Markup
                                            </Form.Label>
                                            <InputGroup>
                                                <Form.Control disabled id="inlineFormInputGroupMarkup" defaultValue={i.Markup} />
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>


                                        <Form.Group as={Col} >
                                            <Form.Label>List</Form.Label>
                                            <Form.Control defaultValue={i.List} onChange={(e) => updateList(e, i, key)} />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={1} style={{ margin: "auto 0px 15px", zoom: "130%" }}>
                                            {console.log("status:", i.Status, i.PartNumber)}
                                            {i.Status === "error" ? <MDBIcon icon="times" /> : null}
                                            {i.Status === "success" ? <MDBIcon icon="check" /> : null}
                                            {i.Status === "pending" ? <Spinner style={{zoom:"49%"}} animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner> : null}
                                            {/* <MYITEM item={i} /> */}
                                        </Form.Group>
                                    </Form.Row>
                                </>
                            )
                        })}

                        <Button variant="primary" style={{ margin: "auto" }} onClick={() => addItem()}>Add Item</Button>



                    </Form.Row>

                </Form>
            </Modal.Body>

            <Modal.Footer>

                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={() => validateInfo()} > Submit  </Button>
            </Modal.Footer>

        </Modal>
    )
}