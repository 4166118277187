import React, { useState } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';

export default function TotalsModal(props) {
    const show = props.show
    const info = props.info
    const [taxInfo, setTaxInfo] = useState({
        labor: info.Totals.LaborTaxRate, 
        sublet: info.Totals.SubletTaxRate, 
        waste:info.Totals.WasteTaxRate, 
        parts:info.Totals.PartsTaxRate,
    })
    //const inputRef = useRef();
    //props.useEventListener('keydown', myKeyPress);
    // function myKeyPress(event) {
    //     if (event.key === "F10") {
    //         props.addNote(notes)
    //         event.preventDefault()
    //     }
    // }
    // useEffect(()=>{
    //     inputRef.current.focus();
    // }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title >Workorder Totals</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "12px" }}>
                <Form>

                    <Form.Row style={{ height: "25px" }}>
                        <Form.Label className="text-align-end" column sm={3} >Parts Subtotal</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={info.Totals.PartsSubtotal.toFixed(2)} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Margin</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value="--" /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Parts Discount (%)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }}  >
                        <Form.Label className="text-align-end" column sm={3} >Parts Discount ($)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Parts Total</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={info.Totals.PartsSubtotal.toFixed(2)} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Tax @</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm"
                        onChange={(e)=>setTaxInfo({...taxInfo, parts: e.target.value})} 
                        defaultValue={info.Totals.PartsTaxRate} /> </Col>

                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={(info.Totals.PartsTaxRate * info.Totals.PartsSubtotal).toFixed(2)} /> </Col>
                    </Form.Row>

                    <div style={{ borderBottom: "groove", marginBottom: "5px", marginTop: "5px" }}></div>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Labor Subtotal</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={info.Totals.LaborSubtotal.toFixed(2)} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Margin</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value="--" /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Labor Discount (%)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Hours</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value="--" /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Labor Total</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={info.Totals.LaborSubtotal.toFixed(2)} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Tax @</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" 
                        onChange={(e)=>setTaxInfo({...taxInfo, labor: e.target.value})} 
                        defaultValue={info.Totals.LaborTaxRate} /> </Col>

                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={(info.Totals.LaborTaxRate * info.Totals.LaborSubtotal).toFixed(2)} /> </Col>
                    </Form.Row>

                    <div style={{ borderBottom: "groove", marginBottom: "5px", marginTop: "5px" }}></div>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Sublet Subtotal</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} 
                        value={info.Totals.SubletSubtotal ? info.Totals.SubletSubtotal.toFixed(2) : '--'} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Margin</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value="--" /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Sublet Discount (%)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>
                    </Form.Row>


                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Sublet Total</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} 
                        value={info.Totals.SubletSubtotal ? info.Totals.SubletSubtotal.toFixed(2) : '--'} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Tax @</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" 
                        onChange={(e)=>setTaxInfo({...taxInfo, sublet: e.target.value})} 
                        defaultValue={info.Totals.SubletTaxRate} /> </Col>

                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={(info.Totals.SubletTaxRate * info.Totals.SubletSubtotal).toFixed(2)} /> </Col>
                    </Form.Row>

                    <div style={{ borderBottom: "groove", marginBottom: "5px", marginTop: "5px" }}></div>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Waste Subtotal</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} 
                        value={info.Totals.WasteSubtotal ? info.Totals.WasteSubtotal.toFixed(2) : '--'} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Margin</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value="--" /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Waste Discount (%)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Waste Total</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} 
                        value={info.Totals.WasteSubtotal ? info.Totals.WasteSubtotal.toFixed(2) : '--'} /> </Col>

                        <Form.Label className="text-align-end" column sm={1.5} >Tax @</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" 
                        onChange={(e)=>setTaxInfo({...taxInfo, waste: e.target.value})} 
                        defaultValue={info.Totals.WasteTaxRate} /> </Col>

                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}} value={(info.Totals.WasteTaxRate * info.Totals.WasteSubtotal).toFixed(2)} /> </Col>
                    </Form.Row>

                    <div style={{ borderBottom: "groove", marginBottom: "5px", marginTop: "5px" }}></div>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Subtotal before tax</Form.Label>
                        <Col sm={2}> 
                            <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}}
                                    value=
                                    {(info.Totals.PartsSubtotal +
                                    info.Totals.SubletSubtotal +
                                    info.Totals.LaborSubtotal +
                                    info.Totals.WasteSubtotal).toFixed(2)}
                                />
                        </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Tax (all)</Form.Label>
                        <Col sm={2}>
                            <Form.Control style={{ height: "25px" }} size="sm" readOnly onChange={()=>{}}
                                value=
                                {info.Totals.WorkorderTax.toFixed(2)}
                            />
                        </Col>


                        <Col sm={6} style={{ textAlign: "center" }}>
                            <Button variant="success"
                            onClick={props.updateTaxInfo.bind(this,{taxInfo, option: 0})}
                            >
                                Save
                            </Button>
                            <Button variant="danger" style={{ marginLeft: "10px" }}
                            onClick={props.close}
                            >  Cancel</Button>
                            <Button variant="info" onClick={props.updateTaxInfo.bind(this,{taxInfo, option: 1})} style={{ marginLeft: "10px" }}>Apply</Button>
                        </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Credit Subtotal (-)</Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" onChange={()=>{}} value={info.Totals.CreditSubtotal.toFixed(2)}/> </Col>
                    </Form.Row>

                    <Form.Row style={{ height: "25px" }} >
                        <Form.Label className="text-align-end" column sm={3} >Workorder Total </Form.Label>
                        <Col sm={2}> <Form.Control style={{ height: "25px" }} size="sm" 
                            onChange={()=>{}}
                            value={info.Totals.WorkorderTotal.toFixed(2)} /> </Col>

                        <Form.Label className="text-align-end" column sm={1} >Profit (%)</Form.Label>
                        <Col sm={2} > <Form.Control style={{ height: "25px" }} size="sm" value="--" onChange={()=>{}} /> </Col>
                    </Form.Row>


                </Form>

            </Modal.Body>
        </Modal>
    )

}