import React, { useState, useEffect, useRef } from 'react';

import { Row, Form, Col, Button, ButtonToolbar, Card, Dropdown, Alert, Modal } from 'react-bootstrap';
import ChargesList from "./ChargesList.js"
import LaborChargeModal from "./Modals/LaborChargeModal"
import NotesModal from "./Modals/NotesModal.js"
import EditItemModal from "./Modals/EditItemModal.js"
import CreditModal from "./Modals/CreditModal.js"
import PartsModal from "./Modals/PartsModal.js"
import SubletModal from "./Modals/SubletModal.js"
import VehiclesModal from "./Modals/VehiclesModal.js"
import VehicleDataModal from "./Modals/VehicleDataModal.js"
import NewCustomerModal from "./Modals/NewCustomerModal.js"
import EmployeeOnlyModal from "./Modals/EmployeeOnlyModal.js"
import AddVehicleModal from "./Modals/AddVehicleModal.js"
import CannedModal from "./Modals/CannedModal.js"
import PrintModal from "./Modals/PrintModal.js"
import BatteryModal from "./Modals/BatteryModal.js"
import AC_SERVICE_MODAL from './Modals/AC_ServiceModal.js';
import LOF_MODAL from "./Modals/LOF_Modal.js"
import PaymentModal from "./Modals/PaymentModal.js"
import SplitPaymentModal from "./Modals/SplitPaymentModal.js"
import axios from 'axios';
import * as Constants from './../../constants/constants.js';
import { MDBIcon } from 'mdbreact'
import SearchScreen from './SearchScreen.js';
import StatusModal from "./Modals/StatusModal.js"
import Loading from '../../global_components/Loading'
import JobCard from "../jobs_screen/JobCard.js"
import EditJobModal from "../jobs_screen/Modals/EditJobModal.js"
import TotalsModal from "./Modals/TotalsModal.js"
import caliper_img from "../../photos/rotor_caliper.png"
import BrakeJobModal from "./Modals/BrakejobModal.js"
import CustomerSearch from "../customers/CustomerSearch.js"
import ReorderModal from './Modals/ReorderModal.js';

export default function NewInvoiceContents(props) {

    const [toggleCost, setToggleCost] = useState(false)
    const id = props.match.params.id;
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({ license:false })
    const [mileageAlert, setMileageAlert] = useState(false)
    const [invoiceInfo, setInvoiceInfo] = useState({
        Workorder: {
        },
        Totals: {
        },
        Customer: {
        },
        Vehicle: {
        },
        Parts: [],
        Labor: [],
        Notes: [],
        Sublets: [],
        Credits: [],

    })
    const [jobInfo, setJobInfo] = useState([])
    const [selectedJob, setSelectedJob] = useState(-1)
    const [vehicles, setVehicles] = useState()
    const [updateItem, setUpdateItem] = useState({ oldItem: null })
    const [count, setCount] = useState(0)
    const [showEditItemModal, setShowEditItemModal] = useState(false)
    const [editItem, setEditItem] = useState({ item: null, type: null })
    const [employeeList, setEmployeeList] = useState([])
    const [modals, setModals] = useState({
        parts: false,
        sublets: false,
        credit: false,
        misc: false,
        vehicles: false,
        vehicleData: false,
        customerSearch: false,
        payment: false,
        print: false,
        customer: false,
        newCustomer: false,
        employee: false,
        LOF: false,
        waste: false,
        history: false,
        updateVehicle: false,
        canned: false,
        labor: false,
        note: false,
        status: false,
        addVehicle: false,
        job: false,
        totals: false,
        battery: false,
        brakejob: false,
        ac_service: false,
        reorder:{show: false, section: null}
    })
    const [inspectionInfo, setInspectionInfo] = useState({ type: "", employeeID: "" })
    const [profits, setProfits] = useState({})
    const [cannedItem, setCannedItem] = useState(null)
    const [modifiedDate, setModifiedDate] = useState('')
    useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        /* if(event.ctrlKey){ */

        if (event.key === "F1") {
            setModals({ ...modals, note: false, parts: false, sublets: false, credit: false, waste: false, canned: false, history: false, labor: true })
            event.preventDefault();
        }
        else if (event.key === "F2") {
            setModals({ ...modals, note: true, parts: false, sublets: false, credit: false, waste: false, canned: false, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F3") {
            setModals({ ...modals, note: false, parts: true, sublets: false, credit: false, waste: false, canned: false, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F4") {
            setModals({ ...modals, note: false, parts: false, sublets: true, credit: false, waste: false, canned: false, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F5") {
            setModals({ ...modals, note: false, parts: false, sublets: false, credit: true, waste: false, canned: false, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F6") {
            setModals({ ...modals, note: false, parts: false, sublets: false, credit: false, waste: true, canned: false, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F7") {
            setModals({ ...modals, note: false, parts: false, sublets: false, credit: false, waste: false, canned: true, history: false, labor: false })
            event.preventDefault();
        }
        else if (event.key === "F8") {
            setModals({ ...modals, note: false, parts: false, sublets: false, credit: false, waste: false, canned: false, history: true, labor: false })
            event.preventDefault();
        }
        else if (event.keyCode === 18) {
            setToggleCost(!toggleCost)
            event.preventDefault();
        }
        else if (event.keyCode === 192 && event.ctrlKey)
            setModals({ ...modals, totals: !modals.totals })
        /*  }  */
    }

    function printInvoice() {
        console.log("PRINTING INVOICE")
        props.history.push(`/invoices`)
    }

    async function addLaborCharge(newItem) {
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addLabor`, { newLabor: newItem });
            console.log('fetching data: ', res_data);
            setInvoiceInfo({ ...invoiceInfo, Totals: res_data.data.totals })
            setCount(count + 1)
        } catch (error) {
            console.log("Error - abs123a")
            // if (error.response)
            //     props.setError({ show: true, message: error.response.data.error })
            // else if (error.request)
            //     props.setError({ show: true, message: error.request })
            // else
            props.setError({ show: true, message: "Error adding labor charge" })

            //props.history.push({ pathname: `/error`, state: { resp: error } })
        }
        setCannedItem(null)
        setModals({ ...modals, LOF: false, employee: false, labor: false, battery: false, ac_service: false })
    }

    async function saveJobChanges(newJob) {
        console.log("saving job changes... ", newJob)
        try {
            /* const res_data =  */await axios.post(`${Constants.port}/jobs/${jobInfo[selectedJob]._id}/update`,
            newJob
        );
            getJobData(invoiceInfo.Workorder.WorkorderNumber)
            //console.log('fetching data, saveJobChanges: ', res_data);
            setModals({ ...modals, job: false })
            //getJobs()
        } catch (error) {
            console.log("CATCHING ERROR. HANDLE THIS-hasdhg71")
            setModals({ ...modals, job: false })

        }
    }

    function checkForAlerts(invoice) {
        /* Mileage alert */
        if (invoice.Vehicle.Mileage === invoice.Vehicle.MileageIn)
            setMileageAlert(true)
        else
            setMileageAlert(false)
    }

    function calculateProfits(invoice) {
        //console.log("calc profits, ", invoice);
        let cost = invoice.Totals.PartsCost +
            invoice.Totals.LaborCost +
            invoice.Totals.SubletCost +
            invoice.Totals.WasteCost
        let price = (invoice.Totals.SubletSubtotal +
            invoice.Totals.LaborSubtotal +
            invoice.Totals.PartsSubtotal +
            invoice.Totals.WasteSubtotal) - invoice.Totals.CreditSubtotal
        let profit = (price - cost)
        setProfits({
            Cost: cost,
            Price: price,
            Profit: profit,
            Margin: (profit / price) * 100

        })
    }

    async function getInvoiceData() {
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/invoices/${id}/getInvoiceByID`);
            console.log('fetching data getInvoiceData: ', res_data);
            if (!res_data.data.invoice.Vehicle)
                setInvoiceInfo({ ...res_data.data.invoice, Vehicle: {} })
            else
                setInvoiceInfo(res_data.data.invoice)
            getJobData(res_data.data.invoice.Workorder.WorkorderNumber)

            calculateProfits(res_data.data.invoice)
            checkForAlerts(res_data.data.invoice)
            setLoading(false)
            if (res_data.data.invoice.Vehicle)
                if (res_data.data.invoice.Vehicle.LicenseNumber !== ""
                    && res_data.data.invoice.Vehicle.Year === "" && res_data.data.invoice.Vehicle.Make === "" && res_data.data.invoice.Vehicle.Model === "") {
                    console.log("Year Make and Model are empty but License is not. Suspect failed licence selection due to Multiple plates. launch vehicle");
                }
            return res_data
        } catch (error) {
            console.log("Error getting invoice information, id:", id, "\nErrNo: ASJDF81")
            props.history.push("/invoices")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error + "\nErrNo: ASJDF81" })
            else
                props.setError({ show: true, message: "Error getting invoice information id:" + id + "\nErrNo: ASJDF81" })
            return -1;
        }


    }

    async function getJobData(WO_Number) {
        try {
            const res_data = await axios.get(`${Constants.port}/jobs/${WO_Number}/getJob`);
            setJobInfo(res_data.data.job)
            console.log("fetching job data...", res_data.data);
            return res_data
        } catch (error) {
            console.log("Error - ab17qwq")
        }


    }

    async function addNote(newNote) {
        console.log("testing newNote: ", newNote)
        let cpy = invoiceInfo.Notes;
        cpy.push(newNote);
        setInvoiceInfo({ ...invoiceInfo, Notes: cpy })

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addNote`, { newNote: newNote });
            console.log('fetching data: ', res_data);
            setCount(count + 1)
            setModals({ ...modals, note: false })

        } catch (error) {
            console.log("Error - asd1sa2")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })

        }
    }

    async function addCredit(newCredit) {
        console.log("testing newCredit: ", newCredit)
        //newCredit.amount = newCredit.amount.toFixed(2)
        let cpy = invoiceInfo.Credits;
        cpy.push(newCredit);
        setInvoiceInfo({ ...invoiceInfo, Credits: cpy })
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addCredit`, newCredit);
            console.log('fetching data: ', res_data);
            setInvoiceInfo({ ...invoiceInfo, Totals: res_data.data.totals })
            setModals({ ...modals, credit: false })
            setCount(count + 1)
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS-0a")
            props.history.push({ pathname: `/error`, state: { resp: error } })
        }
    }

    function calculateTotal(part) {
        part.quantity = parseFloat(part.quantity)
        part.list = parseFloat(part.list)
        part.cost = parseFloat(part.cost)
        part.total = part.list * part.quantity
        return part

    }

    async function addPart(newPart) {
        console.log("testing addPart: ", newPart)

        let cpy = invoiceInfo.Parts;
        cpy.push(calculateTotal(newPart));
        setInvoiceInfo({ ...invoiceInfo, Parts: cpy })

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addPart`, newPart);
            console.log('fetching data, addPart: ', res_data);
            setInvoiceInfo({ ...invoiceInfo, Totals: res_data.data.totals })
            setModals({ ...modals, parts: false, LOF: false, battery: false, ac_service: false })
            setCount(count + 1)
        } catch (error) {
            console.log("Error - a2cb1q")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }
    }

    async function addSublet(newSublet) {
        console.log("testing newSublet: ", newSublet)

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addSublet`, newSublet);
            console.log('fetching data: ', res_data);
            setInvoiceInfo({ ...invoiceInfo, Totals: res_data.data.totals })
            setModals({ ...modals, sublets: false })
            setCount(count + 1)

        } catch (error) {
            console.log("Error - a1bs1za")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }
    }

    async function addWaste(newWaste) {
        console.log("testing newWaste: ", newWaste)
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/addWaste`, newWaste);
            console.log('fetching data: ', res_data);
            setInvoiceInfo({ ...invoiceInfo, Totals: res_data.data.totals })
            setModals({ ...modals, waste: false })
            setCount(count + 1)
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS-0azz")
            props.history.push({ pathname: `/error`, state: { resp: error } })
        }
    }

    async function updateCharge(newCharge) {
        let diff = null
        if(editItem.type === "Parts")
            diff = newCharge.quantity - updateItem.oldItem.quantity
        

        console.log("Difference in quantity: ", diff);
        setUpdateItem({ ...updateItem, newItem: newCharge })
        submitUpdateItem(newCharge, diff)
    }

    async function removeCharge(charge) {
        const itemToSend = {
            newItem: charge,
            type: editItem.type
        }
        console.log("removing charge ", charge, "type:",editItem.type)
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/removeItem`, itemToSend);
            console.log('fetching data removeItem ', res_data);
            setShowEditItemModal(false)
            setCount(count + 1)
        } catch (error) {
            console.log("Error - ahbsd17", error)
            /* if (error.response)
                props.setError({ show: true, message: error.message })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else */
            props.setError({ show: true, message: "Error removing item, errno: ahbsd17" })

        }

    }

    async function removeReturnCharge(charge) {
        const itemToSend = {
            newItem: charge,
            type: editItem.type,
            date: Constants.getDate()
        }
        console.log("removing charge and adding to returns... ", charge, "type:",editItem.type)
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/removeItem`, itemToSend);
            console.log('fetching data removeItem ', res_data);
            setShowEditItemModal(false)
            setCount(count + 1)
        } catch (error) {
            console.log("Error - asdg213asd", error)
            props.setError({ show: true, message: "Error removing item, errno: asdg213asd" })
        }

        try {
            const res_data = await axios.post(`${Constants.port}/returns/addReturn`, itemToSend);
            console.log('fetching data addReturnItem ', res_data);
        } catch (error) {
            console.log("Error - asdf21e1", error)
            props.setError({ show: true, message: "Error adding return item, errno: asdf21e1" })
        }

    }

    async function launchVehicleModal(option) {

        if (invoiceInfo.Customer.CustomerNumber === -1) {
            setModals({ ...modals, customerSearch: true })
            return
        }
        if (option === "update" &&
            /* (invoiceInfo.Vehicle.Year !== "" || invoiceInfo.Vehicle.Make !== "" || invoiceInfo.Vehicle.Model !== "") */
            invoiceInfo.Vehicle._id)
            setModals({ ...modals, vehicleData: true })
        else
            getVehicles()

    }

    async function getVehicles(info) {
        console.log("GETTING VEHICLES --> ", invoiceInfo);
        if (invoiceInfo.Customer.CustomerNumber === -1 && info == null) {
            setModals({ ...modals, customerSearch: true })
            return;
        }


        try {
            let customerNumber = info ? info.Customer.CustomerNumber : invoiceInfo.Customer.CustomerNumber
            const res_data = await axios.get(`${Constants.port}/customer/${customerNumber}/getVehicles`);
            console.log('fetching data vehicles: ', res_data);
            setVehicles(res_data.data.vehicles)
            setModals({ ...modals, vehicles: true, customerSearch: false })
        } catch (error) {
            console.log("testing CATCHING ERROR. HANDLE THIS -0aasd")
            props.history.push({ pathname: `/error`, state: { resp: error } })
        }
    }

    async function loadCustomerInfo(customer, searchInfo) {
        console.log("update cust. info ", customer, "searchInfo: ", searchInfo);

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/updateCustomer`, { customer, searchInfo });
            console.log('fetching data updateCustomer: ', res_data);
            setInvoiceInfo(res_data.data.updated_invoice)
            if (modals.customerSearch)
                getVehicles(res_data.data.updated_invoice)

            setModals({ ...modals, customerSearch: false, newCustomer: false, customer: false })
            //setInvoiceInfo({ invoiceInfo: res_data.data.updated_invoice })
            //props.history.push(`/invoices/${res_data.data.newWO._id}`)

        } catch (error) {
            console.log("Error - anz71js")
            if (error.response)
                console.log(error.response.data.error);

        }

    }

    async function setVehicleInfo_history(invoice) {
        console.log("setting vehicle info, ", invoice.Vehicle)
        try {
            loadCustomerInfo(invoice.Customer, {})
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/selectVehicleByID`, {vehicle_id: invoice.Vehicle._id});
            console.log('fetching data setVehicleInfo: ', res_data);

            setInvoiceInfo(res_data.data.updated_invoice)
            checkForAlerts(res_data.data.updated_invoice)
            //props.history.push(`/invoices/${res_data.data.newWO._id}`)
            setModals({ ...modals, vehicles: false, addVehicle: false, vehicleData: true, history: false })

        } catch (error) {
            console.log("Error - abas12aaa")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })

        }
    }

    async function setVehicleInfo(row) {
        console.log("setting vehicle info, ", row)
        setAlert({...alert, license: false})
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/selectVehicle`, row);
            console.log('fetching data setVehicleInfo: ', res_data);

            setInvoiceInfo(res_data.data.updated_invoice)
            checkForAlerts(res_data.data.updated_invoice)
            //props.history.push(`/invoices/${res_data.data.newWO._id}`)
            setModals({ ...modals, vehicles: false, addVehicle: false, vehicleData: true, history: false })

        } catch (error) {
            console.log("Error - abas12aaa")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })

        }
    }

    async function removeVehicle(row) {
        console.log("removing vehicle , ", row)
        try {
            const res_data = await axios.post(`${Constants.port}/customer/${invoiceInfo.Customer.CustomerNumber}/deleteVehicle`, row);
            console.log('fetching data setVehicleInfo: ', res_data);
            setVehicles(res_data.data.vehicles)
            //props.history.push(`/invoices/${res_data.data.newWO._id}`)
            //setModals({ ...modals, vehicles: false, addVehicle: false })

        } catch (error) {
            console.log("Error - abas12a")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })

        }
    }

    async function submitUpdateItem(newItem, diff) {


        const itemToSend = {
            oldItem: updateItem.oldItem,
            newItem: editItem.type !== "Labor" ? calculateTotal(newItem) : newItem,
            type: editItem.type,
            difference: diff
        }


        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/updateItem`, itemToSend);
            console.log('fetching data submitUpdateItem ', res_data);
            setShowEditItemModal(false)
            setCount(count + 1)
        } catch (error) {
            console.log("Error - zjh1179a", error)
            if (error.response)
                props.setError({ show: true, message: error.message })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: "Error getting invoices/:id/getInvoiceByWONumbers, errno: zjh1179a" })

        }
    }

    function calculateItemType(obj) {
        setEditItem({ type: obj.type, item: obj.item })
        setUpdateItem({ ...updateItem, oldItem: obj.item })
    }

    async function markInvoicePaid(type) {

        try {
            //console.log("MARKING PAID, TYPE",type )
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/markPaid`, /* type */{ type, modifiedDate });
            console.log("fetching paid invoice data", res_data)
            setInvoiceInfo(res_data.data.invoice)
            setModals({ ...modals, payment: false, print: true })
        } catch (error) {
            console.log("Error - zha7102")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }

    }

    function addInspection(type) {
        setModals({ ...modals, employee: true })
        setInspectionInfo({ ...inspectionInfo, type: type })

    }

    function addBrakeJob(type) {
        setModals({ ...modals, brakejob: true })
        console.log("adding brake job, ", type)

        //setInspectionInfo({...inspectionInfo, type: type})

    }

    function selectEmployee(employee) {
        console.log("Adding ", inspectionInfo.type, " inspection, employeeID: ", employee)
        var itemInfo = {
            employeeID: employee.employeeID,
            amount: 0,
            rate: Constants.shop_information.LaborRate,
            total: 0,
            description: ""
        };
        if (inspectionInfo.type === "full") {
            itemInfo.amount = .3
            itemInfo.total = 37
            itemInfo.description = "NYS FULL INSPECTION"
        }
        else if (inspectionInfo.type === "safety") {
            itemInfo.amount = .12
            itemInfo.total = 10
            itemInfo.description = "NYS SAFETY INSPECTION"
        }
        else if (inspectionInfo.type === "emissions") {
            itemInfo.amount = .3
            itemInfo.total = 27
            itemInfo.description = "NYS EMISSIONS INSPECTION"
        }
        else if (inspectionInfo.type === "weight_1") {
            itemInfo.amount = .3
            itemInfo.total = 26
            itemInfo.description = "NYS WEIGHT LIMITED INSPECTION"
        }
        else if (inspectionInfo.type === "weight") {
            itemInfo.amount = .3
            itemInfo.total = 21
            itemInfo.description = "NYS WEIGHT-LIMITED INSPECTION"
        }
        else if (inspectionInfo.type === "trailer") {
            itemInfo.amount = .12
            itemInfo.total = 6
            itemInfo.description = "NYS TRAILER INSPECTION"
        }

        addLaborCharge(itemInfo)
        setModals({ ...modals, employee: false })

    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    function toggleEditCustomer(option) {
        if (option === "change" || invoiceInfo.Customer.CustomerNumber === -1)
            setModals({ ...modals, customerSearch: true })
        else {
            //setEditCustomer(!editCustomer)
            setModals({ ...modals, customer: true })
        }
    }

    function addOilChange(info) {
        console.log("adding oil change, info: ", info)
        addPart(info.filter)
        addPart(info.oil)
        addLaborCharge(info.employee)
    }

    function addBatteryCharge(info) {
        console.log("adding battery change, info: ", info)
        addPart(info.battery)
        addLaborCharge(info.employee)

    }

    function addACService(info) {
        console.log("adding AC service, info: ", info)
        if(info.dye) {
            const dye = {
                partNumber: "DYE",
                description: "LEAK TRACER DYE",
                quantity: 1,
                cost: 4.75,
                list: 20
            }
            addPart(dye)
        }
        addPart(info.freon)
        addLaborCharge(info.employee)

    }

    function toggleItem(item/* , event */) {
        console.log("toggling item: ", item)
        //console.log("event: ", event)
        /*         event.preventDefault()
                event.stopPropagation() */
        //item.preventDefault();
    }

    async function updateVehicleInfo(item) {
        console.log("UPDATE VEHICLE", item)
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/updateVehicle`, { newItem: item, oldItem: invoiceInfo.Vehicle, CustomerNumber: invoiceInfo.Customer.CustomerNumber });
            console.log('fetching data updateVehicle: ', res_data);
            setInvoiceInfo(res_data.data.updated_invoice)
            checkForAlerts(res_data.data.updated_invoice)
            //setInvoiceInfo({ invoiceInfo: res_data.data.updated_invoice })
            //props.history.push(`/invoices/${res_data.data.newWO._id}`)
            setModals({ ...modals, vehicleData: false })

        } catch (error) {
            console.log("Error - asdaq1zz")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })

        }
    }

    function addCannedItem(item) {
        if (!item) {
            setModals({ ...modals, canned: false })
            return;
        }
        console.log("adding Canned Item: - newInvoiceScreen", item)
        if (item.type === "labor") {
            setCannedItem(item)
            setModals({ ...modals, canned: false, labor: true })
            //setCannedItem(null)
        }
        if( item.type === 'note'){
            setCannedItem(item)
            setModals({...modals, canned: false, note: true})
        }
        else
            console.log("canned item type is not Labor. handle - axasx11");
    }

    function closeLaborModal() {
        setModals({ ...modals, labor: false })
        setCannedItem(null)
    }

    async function getEmployees() {
        try {
            const res_data = await axios.get(`${Constants.port}/employee/getAll_table`);
            //console.log('fetching data employeeList: ', res_data);
            setEmployeeList(res_data.data.employees)

        } catch (error) {
            console.log("Error - anzxu1s")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }
    }

    async function submitAddVehicle(vehicleInfo) {
        console.log("ADDING VEHICLE, INFO: ", vehicleInfo)

        try {
            const res_data = await axios.post(`${Constants.port}/customer/${invoiceInfo.Customer.CustomerNumber}/addVehicle`
            , vehicleInfo);
            console.log("fetching new vehicle info...", res_data)
            setVehicleInfo(res_data.data.vehicle)
            
        } catch (error) {
            console.log("ERROR ADDING VEHICLE TO CUSTOMER, as17z7zzh")

        }
    }

    async function changeStatus(status) {
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/changeStatus`, { status });
            console.log(`fetching data, changing status to ${status}:`, res_data);
            props.history.push(`/invoices`)
        } catch (error) {
            console.log("ERROR changing status to estimate azx12111")

        }
    }

    async function setInvoiceStatus(status, date) {
        console.log("NewInvoiceScreen:setInvoiceStatus(), status: ", status, date);
        //setModals({...modals, status: false, print:true }),
        setModifiedDate(date)
        if (status === "PAID")
            setModals({ ...modals, status: false, payment: true })
        else if (status === "SPLIT")
            setModals({ ...modals, status: false, split: true })
        else if (status === "ESTIMATE") {
            changeStatus("ESTIMATE")
            setModals({ ...modals, status: false })
        }
        else if (status === "OPEN") {
            changeStatus("Repair")
            setModals({ ...modals, status: false })
        }
        else if (status === "ONACCOUNT") {
            changeStatus("ONACCOUNT")
            setModals({ ...modals, status: false })
        }

    }

    async function splitPayment(paymentInfo, pmnts) {
        console.log("NewInvoiceScreen:splitPayment(), paymentInfo: ", paymentInfo);
        try {
            await updatePayments(pmnts)

            await axios.post(`${Constants.port}/invoices/${id}/addSplitPayment`, paymentInfo.list)
            
            setCount(count+1)
            setModals({ ...modals, split: false, print: true })
        } catch (error) {
            console.log("ERROR submitting split payment. 213asxZA")
            if (error.response){
                console.log(error.response.data.error)
                props.setError({ show: true, message: error.response.data.error })
            }
        }
    }

    async function updateTaxInfo(info) {
        let { taxInfo, option } = info
        console.log("NewInvoiceScreen:updateTaxInfo(): ", taxInfo, "option: ", option);
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/updateTax`, taxInfo)
            console.log("fetching data, updateTaxInfo: ", res_data);

            setCount(count + 1)
            if (option === 0)
                setModals({ ...modals, totals: false })


        } catch (error) {
            console.log("ERROR updating tax information. zxa166z")
            if (option === 0)
                setModals({ ...modals, totals: false })
            if (error.response)
                console.log(error.response.data.error)

        }

    }

    function clickPaymentButton() {
        console.log("payment info...", invoiceInfo.Workorder)
        if (invoiceInfo.Workorder.Status === "PAID" && invoiceInfo.Payments.length > 0)
            setModals({ ...modals, split: true })
        else
            setModals({ ...modals, status: true })
    }

    async function updatePayments(pmnts) {
        console.log("NewInvoiceScreen:updatePayments(): ", pmnts);
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/updatePayments`, { payments: pmnts })
            console.log("fetching data, updatePayments: ", res_data);
            await getInvoiceData()
            setModals({ ...modals, split: false })

        } catch (error) {
            console.log("ERROR updating payments. 12emnu4")

        }
    }

    async function updateVehicles(v) {
        console.log("NewInvoiceScreen:updateVehicles(): ", v);
        try {
            const res_data = await axios.post(`${Constants.port}/customer/${invoiceInfo.Customer.CustomerNumber}/updateAllVehicles`, v)
            console.log("fetching data, updateVehicles: ", res_data);

        } catch (error) {
            console.log("ERROR updating all customer vehicles, ahsd7182e")

        }
    }

    async function deletePayment(pmnt_id) {
        console.log("NewInvoiceScreen:deletePayment(): ", pmnt_id);
        try {
            const res_data = await axios.post(`${Constants.port}/invoices/${id}/deletePayment`, { pmnt_id: pmnt_id })
            console.log("fetching data, deletePayment: ", res_data);
            await getInvoiceData()
            setCount(count + 1)
            setModals({ ...modals, split: false })

        } catch (error) {
            console.log("ERROR deleting payment. dasfnc8")

        }
    }

    function selectJob(key) {
        setSelectedJob(key)
        setModals({ ...modals, job: true })
    }

    /**
     * @brief - checks for duplicate license numbers when before selecting a vehicle
     * @param {*} row - vehicle information
     */
    async function checkForDuplicates(row){
        
        let res = vehicles.filter((i)=>i.LicenseNumber===row.LicenseNumber)
        console.log("checkforduplites,",row,vehicles.filter((i)=>i.LicenseNumber===row.LicenseNumber));

        if(res.length > 1){
            setAlert({...alert,license: true, row: row})           
        }
        else
            setVehicleInfo(row)

    }

    async function reorderItems(cards) {
        try {
            let reorder_list
            if(modals.reorder.section === "labor")
                reorder_list = {section: "labor", list: cards}
            else
                reorder_list = {section:"parts", list: cards}
            const res_data = await axios.post(`${Constants.port}/invoices/${invoiceInfo._id}/reorderItems`, reorder_list);
            console.log('fetching data: ', res_data);
            setModals({...modals, reorder:false})
            setCount(count+1)
        } catch (error) {
            console.log("Error - Unable to reorder Items")
        }
    }

    function getReordersSection(){
        if(modals.reorder.section === "parts")
            return invoiceInfo.Parts
        else
            return invoiceInfo.Labor
    }


    useEffect(() => {
        getInvoiceData()
        getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    return (
        <div className="cardpadding">
            <Modal show={alert.license} animation={false} >
            <div className="spinnerpadding">
                <div className="overlay">
                <Alert 
                variant="warning"
                
            
           
            onHide={() => setAlert({...alert, license: false})} 
            close={() => setAlert({...alert, license: false})} >
           <Alert.Heading >Duplicate Information</Alert.Heading>
           <p>One or more other vehicles have the same License Number.</p>
           <hr />
        <div className="d-flex justify-content-end">
        <Button onClick={()=>setVehicleInfo(alert.row)} variant="outline-danger"  style={{marginRight:"10px"}}>
            Maybe Later
          </Button>
          <Button  onClick={() => setAlert({...alert, license: false})} variant="outline-success">
            Fix This
          </Button>
        </div>
            </Alert>
                </div>
            </div>
        </Modal>


            <Card>
                <Card.Header className="invoice_contents_header">

                    <Row /* noGutters */>
                        <Col >
                            <Row style={{ marginLeft: "1px" }}>
                                <Form.Label style={{ fontSize: "30px" }} >
                                    {invoiceInfo.Customer.Lastname ? `${invoiceInfo.Customer.Lastname},` : ""}
                                </Form.Label>
                            </Row>
                            <Row style={{ marginLeft: "10px", marginTop: "-15px" }}>
                                <Form.Label style={{ fontSize: "15px" }} >
                                    {invoiceInfo.Customer.Firstname ? invoiceInfo.Customer.Firstname : ""}
                                </Form.Label>
                            </Row>
                            <Row style={{ marginLeft: "10px", fontSize: "16px" }}>
                                WO: {invoiceInfo.Workorder.WorkorderNumber}
                            </Row>
                        </Col>
                        <Col /* xs={2} */>
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone1: {Constants.phoneFormatter(invoiceInfo.Customer.Phone1)} </Col>
                            </Row >
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone2: {Constants.phoneFormatter(invoiceInfo.Customer.Phone2)} </Col>
                            </Row >
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone3: {Constants.phoneFormatter(invoiceInfo.Customer.Phone3)} </Col>
                            </Row >
                        </Col>
                        <Col /* xs={3} */>
                            <Row style={{ fontSize: "15px" }}>{/* <MDBIcon style={{ margin: "6px" }} icon="car-alt" /> */}
                                {invoiceInfo.Vehicle ? invoiceInfo.Vehicle.Year : ""} {' '}
                                {invoiceInfo.Vehicle ? invoiceInfo.Vehicle.Make : ""} {' '}
                                {invoiceInfo.Vehicle ? invoiceInfo.Vehicle.Model : ""} {' '}
                                {invoiceInfo.Vehicle ? invoiceInfo.Vehicle.EngineSize : ""}</Row>
                            <Row style={{ fontSize: "15px" }}>
                                {mileageAlert && invoiceInfo.Vehicle.Mileage ?
                                    <MDBIcon icon="exclamation" className="red_alert" /> : null}
                                {invoiceInfo.Vehicle.Mileage ? invoiceInfo.Vehicle.Mileage.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                            </Row>
                            <Row style={{ fontSize: "15px" }}> {invoiceInfo.Vehicle.State ? `${invoiceInfo.Vehicle.State}: ` : null}{invoiceInfo.Vehicle.LicenseNumber}</Row>
                            <Row style={{ fontSize: "15px" }}> 
                                <p>{invoiceInfo.Vehicle.VIN && (invoiceInfo.Vehicle.VIN.substring(0,invoiceInfo.Vehicle.VIN.length-8))}</p> 
                                <p style={{fontWeight:"bold"}}>{invoiceInfo.Vehicle.VIN && (invoiceInfo.Vehicle.VIN.substring(invoiceInfo.Vehicle.VIN.length-8))}</p>
                            </Row>
                        </Col>
                        <Col /* xs={2} */ style={{ fontSize: "15px", whiteSpace: "pre" }}>
                            <Row >
                                <Col> Date Opened: </Col>
                                <Col> {Constants.dateFormatter(invoiceInfo.Totals.DateOpen)} </Col>
                            </Row>
                            <Row >
                                <Col> Status/Type: </Col>
                                <Col> {invoiceInfo.Workorder.Status}/{invoiceInfo.Workorder.Type} </Col>
                            </Row>
                            <Row  >
                                <Col> Balance: </Col>
                                <Col> {invoiceInfo.Totals.Balance ? invoiceInfo.Totals.Balance.toFixed(2) : "0.00"} </Col>
                            </Row>
                            <Row >
                                <Col> Tax: </Col>
                                <Col> {invoiceInfo.Totals.WorkorderTax ? invoiceInfo.Totals.WorkorderTax.toFixed(2) : "0.00"} </Col>
                            </Row>
                        </Col>
                        <Col /* xs={2} */>
                            <Dropdown >
                                <Dropdown.Toggle style={{ margin: "5px", flex: "auto", fontSize: "14px" }}>
                                    Edit Customer
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => toggleEditCustomer("update")}>Update Customer</Dropdown.Item>
                                    <Dropdown.Item onClick={() => toggleEditCustomer("change")}>Change Customer</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown >
                                <Dropdown.Toggle style={{ margin: "5px", flex: "auto", fontSize: "14px" }}>
                                    Edit Vehicle
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => launchVehicleModal("update")}>Update Vehicle</Dropdown.Item>
                                    <Dropdown.Item onClick={() => launchVehicleModal("change")}>Change Vehicle</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card.Header>

                <ButtonToolbar style={{ justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, labor: true })}>
                        <MDBIcon icon="plus" /> Labor
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, note: true })}>
                        <MDBIcon icon="plus" /> Notes
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, parts: true })} >
                        <MDBIcon icon="plus" /> Parts
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, sublets: true })} >
                        <MDBIcon icon="plus" /> Sublet
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, credit: true })} >
                        <MDBIcon icon="plus" /> Credit
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, waste: true })} >
                        <MDBIcon icon="plus" /> Waste/Misc
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, canned: true })}>
                        <MDBIcon icon="plus" /> Canned
                    </Button>
                    <Button size="lg" className="invoice-button" variant="dark" onClick={() => setModals({ ...modals, history: true })} >
                        <MDBIcon icon="plus" /> History
                    </Button>
                    <Button size="lg" className="invoice-button" variant="success" onClick={() => clickPaymentButton()} >
                        <MDBIcon icon="cash-register" /> Payment
                    </Button>
                    <Button size="lg" className="invoice-button" variant="success" onClick={() => setModals({ ...modals, print: true })} >
                        Print
                    </Button>

                </ButtonToolbar>

                <Card.Body className="invoice_screen_contents" >
                    <Row >
                        <Col /* fluid="xl" */ xs={1} >
                            <div style={{ margin: "auto", width: "50px" }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="clear" style={{ fontSize: "40px" }}>
                                        <MDBIcon icon="certificate" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => addInspection("full")}>$37-Full Inspection</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addInspection("emissions")}>$27-Emissions Inspection</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addInspection("weight_1")}>$26-Weight Limit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addInspection("weight")}>$21-Weight Limit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addInspection("safety")}>$10-Safety Inspection</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addInspection("trailer")}>$6-Trailer Inspection</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle disabled variant="clear" style={{ fontSize: "40px" }}>
                                        <img style={{ zoom: "21%" }} src={caliper_img} alt="caliper_img"></img>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => addBrakeJob("F.padsrotor")}>F. pads&rotor</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addBrakeJob("F.pads")}>F. pads</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addBrakeJob("R.padsrotor")}>R. pads&rotor</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addBrakeJob("R.pads")}>R. pads</Dropdown.Item>
                                        <Dropdown.Item onClick={() => addBrakeJob("R.drum")}>R. Drum</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button style={{ fontSize: "40px" }} variant="clear" onClick={() => setModals({ ...modals, LOF: true })}>
                                    <MDBIcon icon="oil-can" />
                                </Button>
                                <Button style={{ fontSize: "40px" }} variant="clear" onClick={() => setModals({ ...modals, battery: true })}>
                                    <i className="fas fa-car-battery"></i>
                                </Button>
                             
                                <Button style={{ fontSize: "40px" }} variant="clear" onClick={() => setModals({ ...modals, ac_service: true })}>
                                    <MDBIcon far icon="snowflake" />                            
                                </Button>

                           

                            </div>
                        </Col>
                        <Col xs={9} >
                            <div style={{ padding: "15px", marginRight: "-25px" }}>
                                <Form.Group className="invoice-notes">
                                    {<ChargesList
                                        from='invoice'
                                        reorderItems={(section)=>
                                            setModals({...modals, reorder: {show: true, section: section}})
                                        }
                                        toggleCost={toggleCost}
                                        profits={profits}
                                        payments={invoiceInfo.Payments}
                                        waste={invoiceInfo.Waste}
                                        sublets={invoiceInfo.Sublets}
                                        totals={invoiceInfo.Totals}
                                        list={invoiceInfo.Labor}
                                        credits={invoiceInfo.Credits}
                                        notes={invoiceInfo.Notes}
                                        parts={invoiceInfo.Parts}
                                        toggle={(obj, obj2) => toggleItem(obj, obj2)}
                                        onClick={(obj) => {
                                            setShowEditItemModal(true);
                                            calculateItemType(obj)
                                        }} />
                                    }
                                </Form.Group>
                            </div>
                        </Col>
                        <Col fluid="xl" style={{ marginRight: "1rem", whiteSpace: "break-spaces", overflowWrap: "normal" }}>
                            <h5 style={{ "margin": "auto", "width": "75%", "whiteSpace": "pre", "marginBottom": "10px" }}>
                                {/* <MDBIcon style={{ margin: "6px" }} icon="tasks" />Tasks */}
                            </h5>

                            {/* • {jobInfo.Description.replace(/\n/g, "\n• ")} */}
                            {jobInfo.length > 0 ?
                                jobInfo.map((item, key) => {
                                    return (
                                        <div style={{ marginBottom: "10px" }} key={key}>
                                            <JobCard
                                                from="invoice_screen"
                                                job={item}
                                                onClick={() => selectJob(key)}
                                            />
                                        </div>)
                                })

                                : null}
                        </Col>
                    </Row>
                </Card.Body>

                {modals.reorder.show ? <ReorderModal
                    reorderItems={(cards)=>reorderItems(cards)}
                    section={modals.reorder.section}
                    id={invoiceInfo._id}
                    labors={getReordersSection()}
                    useEventListener={useEventListener}
                    close={() => setModals({...modals, reorder:false})}
                    show={modals.reorder.show}
                   
                /> : null}

                {modals.labor ? <LaborChargeModal
                    useEventListener={useEventListener}
                    close={() => closeLaborModal()}
                    show={modals.labor}
                    addLaborCharge={(newItem) => addLaborCharge(newItem)}
                    cannedItem={cannedItem}
                    employeeList={employeeList}
                /> : null}

                {modals.note ? <NotesModal
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, note: false })}
                    show={modals.note}
                    cannedItem={cannedItem}
                    addNote={(newNote) => addNote(newNote)}
                /> : null}

                {modals.credit ? <CreditModal
                    type="Credit"
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, credit: false })}
                    show={modals.credit}
                    addCredit={(newCredit) => addCredit(newCredit)}
                /> : null}

                {modals.parts ? <PartsModal
                    list_stock_parts={true}
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, parts: false })}
                    show={modals.parts}
                    addPart={(newPart) => addPart(newPart)}
                /> : null}

                {modals.sublets ? <SubletModal
                    type="Sublet"
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, sublets: false })}
                    show={modals.sublets}
                    addSublet={(newSublet) => addSublet(newSublet)}
                /> : null}

                {modals.waste ? <SubletModal
                    type="Waste"
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, waste: false })}
                    show={modals.waste}
                    addWaste={(newWaste) => addWaste(newWaste)}
                /> : null}

                {showEditItemModal ? <EditItemModal
                    employeeList={employeeList}
                    useEventListener={useEventListener}
                    show={showEditItemModal}
                    close={() => setShowEditItemModal(false)}
                    saveChange={(newCharge) => updateCharge(newCharge)}
                    item={editItem.item}
                    type={editItem.type}
                    remove={(charge) => removeCharge(charge)}
                    removeReturn={(charge) => removeReturnCharge(charge)}
                />
                    : null}

                {modals.vehicles ? <VehiclesModal
                    updateVehicles={(v) => updateVehicles(v)}
                    addVehicle={() => setModals({ ...modals, vehicles: false, addVehicle: true })}
                    useEventListener={useEventListener}
                    vehicles={vehicles}
                    selectVehicle={(row) => checkForDuplicates(row)}
                    close={() => setModals({ ...modals, vehicles: false })}
                    show={modals.vehicles}
                    removeVehicle={(row) => removeVehicle(row)}
                />
                    : null}

                {modals.vehicleData ? <VehicleDataModal
                    useEventListener={useEventListener}
                    vehicle={invoiceInfo.Vehicle}
                    close={() => setModals({ ...modals, vehicleData: false })}
                    show={modals.vehicleData}
                    submitChanges={(item) => updateVehicleInfo(item)} />
                    : null}

                {modals.customerSearch ? <CustomerSearch
                    from="invoice_contents_screen"
                    useEventListener={useEventListener}
                    createNewCustomer={() => setModals({ ...modals, customerSearch: false, newCustomer: true })}
                    loadCustomerInfo={(customer, searchInfo) => loadCustomerInfo(customer, searchInfo)}
                    close={() => setModals({ ...modals, customerSearch: false })}
                    show={modals.customerSearch} /> : null}

                {modals.payment ? <PaymentModal
                    useEventListener={useEventListener}
                    submit={(type) => markInvoicePaid(type)}
                    close={() => setModals({ ...modals, payment: false })}
                    show={modals.payment}
                /> : null}

                {modals.print ? <PrintModal
                    useEventListener={useEventListener}
                    submit={() => props.history.push(`/invoices`)}
                    print={() => printInvoice()}
                    close={() => setModals({ ...modals, print: false })}
                    show={modals.print}
                    info={invoiceInfo}
                    payments={invoiceInfo.Payments}
                /> : null}

                {modals.customer ? <NewCustomerModal /* UPDATE CUSTOMER NOT NEW CUSTOMER */
                    invoice_status="update"
                    loadCustomerInfo={(customer, searchInfo) => loadCustomerInfo(customer, searchInfo)}
                    useEventListener={useEventListener}
                    setError={props.error}
                    customer={invoiceInfo.Customer}
                    close={() => setModals({ ...modals, customer: false })}
                    show={modals.customer}
                /> : null}

                {modals.newCustomer ? <NewCustomerModal
                    invoice_status="existing"
                    loadCustomerInfo={(customer, searchInfo) => loadCustomerInfo(customer, searchInfo)}
                    useEventListener={useEventListener}
                    setError={props.error}
                    customer={invoiceInfo.Customer}
                    close={() => setModals({ ...modals, newCustomer: false })}
                    show={modals.newCustomer}
                /> : null}


                {modals.employee ? <EmployeeOnlyModal
                    employeeList={employeeList}
                    useEventListener={useEventListener}
                    setError={props.error}
                    close={() => setModals({ ...modals, employee: false })}
                    show={modals.employee}
                    selectEmployee={(employee) => selectEmployee(employee)}
                /> : null}

                {modals.LOF ? < LOF_MODAL
                    employeeList={employeeList}
                    useEventListener={useEventListener}
                    setError={props.error}
                    close={() => setModals({ ...modals, LOF: false })}
                    show={modals.LOF}
                    addOilChange={(info) => { addOilChange(info) }}
                /> : null}

                {modals.battery ? <BatteryModal
                    employeeList={employeeList}
                    useEventListener={useEventListener}
                    setError={props.error}
                    close={() => setModals({ ...modals, battery: false })}
                    show={modals.battery}
                    addBatteryCharge={(info) => { addBatteryCharge(info) }}
                /> : null}

                {modals.brakejob ? <BrakeJobModal
                    employeeList={employeeList}

                    useEventListener={useEventListener}
                    setError={props.error}
                    close={() => setModals({ ...modals, brakejob: false })}
                    show={modals.brakejob}
                    addBrakejob={(info) => { addBrakeJob(info) }}
                /> : null}

                {modals.ac_service ? <AC_SERVICE_MODAL
                    employeeList={employeeList}
                    useEventListener={useEventListener}
                    setError={props.error}
                    close={() => setModals({ ...modals, ac_service: false })}
                    show={modals.ac_service}
                    addACService={(info) => { addACService(info) }}
                /> : null}

                {modals.history ? <SearchScreen
                    selectVehicleFromSearch={(invoice)=>setVehicleInfo_history(invoice)}
                    from="invoice_screen"
                    useEventListener={useEventListener}
                    setLoading={(val) => setLoading(val)}
                    info={invoiceInfo}
                    show={modals.history}
                    close={() => setModals({ ...modals, history: false })}
                /> : null}

                {modals.canned ? <CannedModal
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, canned: false })}
                    show={modals.canned}
                    addCannedItem={(item) => addCannedItem(item)}
                /> : null}

                {modals.status ? <StatusModal
                    submit={(status, date) => setInvoiceStatus(status, date)}
                    useEventListener={useEventListener}
                    info={invoiceInfo}
                    close={() => setModals({ ...modals, status: false })}
                    show={modals.status}
                /> : null}

                {modals.addVehicle ? <AddVehicleModal
                    useEventListener={useEventListener}
                    show={modals.addVehicle}
                    close={() => setModals({ ...modals, addVehicle: false })}
                    submitAddVehicle={(info) => submitAddVehicle(info)}
                /> : null}

                {modals.split ? <SplitPaymentModal
                    updatePayments={(pmnts) => updatePayments(pmnts)}
                    useEventListener={useEventListener}
                    show={modals.split}
                    close={() => setModals({ ...modals, split: false })}
                    payments={invoiceInfo.Payments}
                    totals={invoiceInfo.Totals}
                    submitSplitPayment={(paymentInfo, pmnts) => splitPayment(paymentInfo, pmnts)}
                    workorder={invoiceInfo.Workorder}
                    deletePayment={(pmnt_id) => deletePayment(pmnt_id)}
                /> : null}

                {modals.job ?
                    <EditJobModal
                        from="invoice_screen"
                        show={modals.job}
                        jobInfo={jobInfo[selectedJob]}
                        save={(newJob) => saveJobChanges(newJob)}
                        close={() => setModals({ ...modals, job: false })}
                    />
                    : null}

                {modals.totals ?
                    <TotalsModal
                        info={invoiceInfo}
                        show={modals.totals}
                        updateTaxInfo={(taxInfo) => updateTaxInfo(taxInfo)}
                        close={() => setModals({ ...modals, totals: false })}
                    />
                    : null}

            </Card>

            {!loading ? null : <Loading />}

        </div>

    )

}