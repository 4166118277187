import React, { useState, useEffect, useRef  } from 'react';
import {  Form,  Button, Modal, Alert } from 'react-bootstrap';

export default function CreditModal(props) {
    const show = props.show
    const type = props.type
    const [item, setItem] = useState({
        amount: 0, description: "", type: type
    })
    const [error, setError] = useState("")

    function checkForValidation() {
        console.log("credit item", item)
        if (item.amount === 0 || !item.amount)
            setError("You must enter an amount")
        else if (item.description === "")
            setError("You must enter a description")
        else
        {
            props.addCredit(item)
        }
    }

    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            checkForValidation()
            event.preventDefault()
        }
    }
    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal">
            <Modal.Header closeButton>
                <Modal.Title>{type} Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}

                <Form >
                        <Form.Label>Amount</Form.Label>
                        <Form.Control ref={inputRef} onChange={(e)=>setItem({...item, amount: parseFloat(e.target.value)})}/>
                        <br/>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="4"
                            defaultValue={item.description}
                            onChange={(e) => setItem({...item, description: e.target.value.toUpperCase() })} />
                    <br />
                    <Button variant="primary" onClick={()=>checkForValidation()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}