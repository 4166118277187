import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';

export default function SubletModal(props) {
    const show = props.show
    const type = props.type
    const [sublet, setSublet] = useState({
        vendor: "",
        taxable: "",
        description: "",
        invoiceNumber: "",
        cost: '',
        list: ''
    })
    const [error, setError] = useState("")

    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            type === "Sublet" ? props.addSublet(sublet) : props.addWaste(sublet)
            event.preventDefault()
        }
    }

    function checkForValidation() {
        
        if (sublet.vendor === "")
            setError("You must enter a vendor")
        else if (sublet.description === "")
            setError("You must enter a description")
        else if (sublet.cost === '')
            setError("You must enter a cost")
        else if (sublet.list === '')
            setError("You must enter a list price")
        else{
            if(type === "Sublet")
                props.addSublet(sublet)
            else    
                props.addWaste(sublet)
        }
    }
    useEffect(()=>{
        inputRef.current.focus();
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>{type} Charge Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}

                <Form >
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={4}>
                            Vendor
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                ref={inputRef}
                                defaultValue={sublet.vendor}
                                onChange={(e) => { setSublet({ ...sublet, vendor: e.target.value.toUpperCase() }) }} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={4}>
                            Vendor Invoice #
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                defaultValue={sublet.invoiceNumber}
                                onBlur={(e) => setSublet({ ...sublet, invoiceNumber: e.target.value })}
                            />                    </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>



                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={4}>
                            Cost
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                onChange={(e) => setSublet({ ...sublet, cost: e.target.value })}
                                value={sublet.cost} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={4}>
                            List
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                onChange={(e) => setSublet({ ...sublet, list: e.target.value })}
                                value={sublet.list} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="4"
                            defaultValue={sublet.description}
                            onChange={(e) => setSublet({...sublet, description: e.target.value.toUpperCase() })} />
                    </Form.Group>

                    <br />
                    <Button variant="primary" onClick={()=>checkForValidation()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}