import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from './../../constants/constants'
import { Card, Button } from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import axios from 'axios';
import Loading from '../../global_components/Loading'
import VendorItemModal from "./Modals/VendorItemModal.js"
import AddPOModal from '../PO/Modals/AddPOModal';
export default function Vendors(props) {
    const columns = [{
        dataField: 'Vendor',
        text: 'Vendor',
        filter: textFilter({autocomplete:"off", delay: 10 }),
        sort: true
    }, {
        dataField: 'Contact',
        text: 'Contact',
        filter: textFilter({autocomplete:"off", delay: 10 }),
        sort: true
    }, {
        dataField: 'Phone1',
        text: 'Phone1',
        sort: true
    }, {
        dataField: 'Phone2',
        text: 'Phone2',
        sort: true
    }, {
        dataField: 'TaxID',
        text: 'TaxID',
        sort: true

    }];

    const [vendors, setVendors] = useState([])
    const [vendorAction, setVendorAction] = useState('')
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [showModalPO, setShowModalPO] = useState(false)
    const [vendor, setVendor] = useState({
        Vendor: '',
        TaxID: '',
        Contact: '',
        Phone1: '',
        Phone2: ''
    })
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log("ROW EVENT", row)
            // setItem({ item: row })
            setVendorAction('update')
            setVendor(row)
            setShowModal(true)

        },
    };

    async function getVendors() {
        try {
            const res_data = await axios.get(`${Constants.port}/vendors/getVendors`);
            console.log('fetching data: ', res_data);
            setVendors(res_data.data.vendors)
            setLoading(false)

        } catch (error) {
            console.log("Error - azx12zzz", error)
            setLoading(false)
        }
    }

    async function addVendor(vendorInfo) {
        try {
            console.log("adding vendor, vendorInfo", vendorInfo);
            const res_data = await axios.post(`${Constants.port}/vendors/add`, vendorInfo);
            console.log('fetching data addVendor: ', res_data);
            setVendors(res_data.data.vendors)
            setLoading(false)
            setShowModal(false)


        } catch (error) {
            setShowModal(false)

            console.log("Error - azx12zzz", error)
        }
    }

    async function updateVendor(vendorInfo) {
        try {
            console.log("updating vendor, vendorInfo", vendorInfo);
            const res_data = await axios.post(`${Constants.port}/vendors/${vendorInfo._id}/update`, vendorInfo);
            console.log('fetching data updateVendor: ', res_data);
            setVendors(res_data.data.vendors)
            setLoading(false)
            setShowModal(false)
            setVendor({Vendor: '',
            TaxID: '',
            Contact: '',
            Phone1: '',
            Phone2: ''})
        } catch (error) {
            console.log("Error - azx12zzz", error)
            setShowModal(false)
        }
    }

    async function removeVendor(vendorInfo) {
        try {
            console.log("removing vendor, vendorInfo", vendorInfo);
            const res_data = await axios.post(`${Constants.port}/vendors/${vendorInfo._id}/remove`, vendorInfo);
            console.log('fetching data removeVendor: ', res_data);
            setVendors(res_data.data.vendors)
            setLoading(false)
            setShowModal(false)

        } catch (error) {
            console.log("Error - azx12zzz", error)
            setShowModal(false)
        }
    }

    function closeVendorItemModal(){
        setShowModal(false)
        setVendor({})
    }

    function clickAddVendor(){
        setShowModal(true)
        setVendorAction("add")
    }

    function clickAddPO(){
        setShowModalPO(true)
        //setVendorAction("add")
    }

    useEffect(() => {
        getVendors();
        document.getElementById('text-filter-column-Vendor').focus()

    }, [])

    return (
        <div className="cardpadding">
            {!loading ? null : <Loading />}
            {showModal ? <VendorItemModal
                action={vendorAction}
                add={(vendor) => addVendor(vendor)}
                vendor={vendor}
                show={showModal}
                close={() => closeVendorItemModal()}
                update={(vendor) => updateVendor(vendor)}
                remove={(vendor) => removeVendor(vendor)}
            
            /> : null}

        {showModalPO ? <AddPOModal
                action={vendorAction}
                add={(vendor) => addVendor(vendor)}
                vendor={vendor}
                vendor_list={vendors}
                show={showModalPO}
                close={() =>  setShowModalPO(false)}
                update={(vendor) => updateVendor(vendor)}
                remove={(vendor) => removeVendor(vendor)}
            
            /> : null}

            <Card>
                <Card.Header style={{ fontSize: "35px", paddingRight: "10px" }}>
                    Vendors
                    <Button
                        style={{ marginLeft: "25px", alignSelf: "center" }}
                        onClick={() => clickAddVendor()}
                        variant="primary"
                    >
                        Add Vendor
                </Button>

                <Button
                        style={{ marginLeft: "25px", alignSelf: "center" }}
                        onClick={() => clickAddPO()}
                        variant="primary"
                    >
                        Add P.O.
                </Button>
                </Card.Header>
                <Card.Body>
                    <div className="large_inventory_table">
                        <BootstrapTable
                            bootstrap4
                            keyField='_id'
                            data={vendors}
                            columns={columns}
                            filter={filterFactory()}
                            rowEvents={rowEvents}
                            striped
                            hover
                            condensed
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}