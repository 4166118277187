import React, { useState } from 'react';
import { Form, Col, Row, Button, Card, Container, Alert } from 'react-bootstrap'
import * as Constants from './../../constants/constants.js';
import axios from 'axios';
import { useAuth } from "../../authentication/auth.js";

export default function LoginScreen(props){
    const [alert, setAlert] = useState({ show: false, msg: "", status: 0 })
    const { setAuthTokens } = useAuth()
    const [userInfo, setUserInfo] = useState({
        username: "", password: ""
    })
    
    async function successfulLogin() {
        setAlert({ show: false, msg: "", status: 0 })
        console.log("redirectiong to home screen...");
        props.history.push('/home')
    }

    async function logIn() {
        if (userInfo.username !== "" && userInfo.password !== "") {
            console.log("logging in", userInfo)
            try {
                const res_data = await axios.post(`${Constants.port}/auth/login`, userInfo);
                if(res_data.data.admin){
                    setAuthTokens({token:res_data.data.token, permissions:2});
                }
                else
                    setAuthTokens({token:res_data.data.token, permissions:1});
                successfulLogin();
            } catch (error) {
                console.log("Error alk239Zs")
                setAlert({ show: true, msg: error.response.data.error_msg })

            }
            
        }
        else
            setAlert({ show: true, msg: "Enter a username and password" })
    }

    return (
        <div>
            <Container style={{maxWidth:"500px"}}>

                <Row>
                    <Col>
                        <Card className="login-card">
                            <Card.Header style={{ fontSize: "35px", textAlign: "center" }}>Sign in  {' '}

                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group as={Row} >
                                        <Form.Label column sm={5}>
                                            Username
                                        </Form.Label>
                                        <Col sm={7}>
                                            <Form.Control required placeholder="Username" onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formHorizontalPassword1">
                                        <Form.Label column sm={5}>
                                            Password
                                        </Form.Label>
                                        <Col sm={7}>
                                            <Form.Control type="password" required placeholder="Password" onChange={(e) => setUserInfo({ ...userInfo, password: Constants.sha256(e.target.value )})} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} style={{ placeContent: "center" }}>
                                        <Button onClick={() => { logIn() }} /* href='/home' */>Sign in</Button>
                                    </Form.Group>
                                </Form>

                                {alert.show ? <Alert variant="danger">
                                    {alert.msg}
                                </Alert>
                                    :
                                    null}
                            </Card.Body>
                        </Card>

                    </Col>
                
                </Row>
            </Container>
        </div>
    )
}
