import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'
export default function LaborChargeModal(props) {

    const inputRef = useRef();
    const cannedItem = props.cannedItem;
    const employeeList = props.employeeList

    const show = props.show;
    const [laborCharge, setLaborCharge] = useState({
        employeeID: "",
        amount: '',
        rate: Constants.shop_information.LaborRate,
        total: '',
        description: ""
    })

    function getEmployee(checkNew) {
        const currentOption = employeeList.find(option => option.value === laborCharge.employeeID);
        return currentOption
    }

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.addLaborCharge(laborCharge)
            event.preventDefault()
        }
        else if (event.key === "Escape") {
            props.close()
            event.preventDefault()
        }
    }

    useEffect(() => {
        inputRef.current.focus();
        //console.log(cannedItem, "-> canned item");
        if (cannedItem) {
            setLaborCharge({ ...laborCharge, rate: cannedItem.rate, total: cannedItem.total, amount: cannedItem.hours, description: cannedItem.description })
            console.log("canned ITEM", cannedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    props.useEventListener('keydown', myKeyPress);


    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Labor Charge Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setLaborCharge({ ...laborCharge, employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,
                                    }
                                })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Hours
                            </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                defaultValue={laborCharge.amount}
                                onChange={(e) => {
                                    setLaborCharge({
                                        ...laborCharge,
                                        amount: parseFloat(e.target.value),
                                        total: (parseFloat(laborCharge.rate) * e.target.value).toFixed(2)
                                    })
                                }} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Shop Rate
                            </Form.Label>
                        {/* <Col sm={4}>
                            <Form.Control disabled

                                value={Constants.shop_information.LaborRate.toFixed(2)}
                                onChange={(e) => { setLaborCharge({ ...laborCharge, rate: parseFloat(e.target.value), total: parseFloat(e.target.value) * laborCharge.amount }) }} />
                        </Col> */}
                           <Col sm={6}>
                            <Select
                                
                                defaultValue={{value: 125, label: '125 - Standard'}}
                                onChange={e => { setLaborCharge({ ...laborCharge, rate: parseFloat(e.value), total: parseFloat(e.value) * laborCharge.amount }) }}
                                options={
                                    [{value: 125, label: '125 - Standard'},{value: 165, label: '165 - Truck'}]
                                }
                            />
                        </Col>
                      
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Total Labor
                            </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                onChange={(e) =>
                                    setLaborCharge({
                                        ...laborCharge,
                                        total: e.target.value
                                    })}
                                /* onBlur={()=>setLaborCharge({...laborCharge, total: laborCharge.total.toFixed(2)})} */
                                value={laborCharge.total ? laborCharge.total : ""} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="4"
                            defaultValue={laborCharge.description}
                            onChange={(e) => setLaborCharge({ ...laborCharge, description: e.target.value.toUpperCase() })} />
                    </Form.Group>
                    <br />
                    <Button variant="primary" onClick={props.addLaborCharge.bind(this, laborCharge)}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                        </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}
