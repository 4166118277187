import React from 'react';
import { Modal } from 'react-bootstrap';
import { Container } from './Container'

export default function ReorderModal(props) {
    const show = props.show
    const { labors, section } = props
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            event.preventDefault()
        }
    }

    function submit(cards){
        console.log("submitting changes...", cards);
        props.reorderItems(cards)
    }
        return (
            <Modal show={show} onHide={props.close} className="invoice_item_modal" size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Reorder Items</Modal.Title>
                </Modal.Header>
                <Modal.Body >
					<Container labors={labors} submit={(cards)=>submit(cards)} close={props.close} section={section}/>
                </Modal.Body>
            </Modal>
        )

    }