import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import * as Constants from "../../constants/constants"
export default function ChargesList_Print(props) {
    const labors = props.list ? props.list : []
    const notes = props.notes ? props.notes : []
    const credits = props.credits ? props.credits : []
    const parts = props.parts ? props.parts : []
    const totals = props.totals ? props.totals : []
    const sublets = props.sublets ? props.sublets : []
    const waste = props.waste ? props.waste : []
    const payments = props.payments ? props.payments : []

    return (
        labors.length > 0 || notes.length > 0 || parts.length > 0 || credits.length > 0 || sublets.length > 0 || waste.length > 0 ?
            <Container /* style={{ height: "100%", position: "absolute", }} */ >
                {/* <Row >
                    <Col ></Col>
                    <Col xs="1" style={{textAlign:"right"}}>Hours</Col>
                    <Col xs="1" style={{textAlign:"right"}}>Rate</Col>
                    <Col xs="1" style={{textAlign:"right"}}> Amount </Col>
                </Row> */}
                {parts.length > 0 ?
                    <div>
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Parts</Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto" }}>Qty.</Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}>Price</Col>
                            {/* <Col xs="1" style={{textAlign:"right"}}>List </Col> */}
                            <Col xs="1" style={{ textAlign: "right" , margin:"auto" }}>Ext. </Col>
                        </Row>
                        {parts.map((item, key) =>
                            <Row key={key}
                                className="jobcard" 
                            >
                                <Col xs="2">{item.partNumber}</Col>
                                <Col > {item.description}  </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.quantity ? parseFloat(item.quantity).toFixed(2) : 0.00.toFixed(2)} </Col>
                                <Col xs="1" style={{ textAlign: "right" }} > {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)} </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.total ? parseFloat(item.total).toFixed(2) : 0.00.toFixed(2)}

                                </Col>
                                {/*                                 <Col xs lg="1"> <MDBIcon style={{ margin: "6px" }} icon="plus-circle" /> </Col>
 */}                                {/* <MDBIcon style={{ margin: "6px" }} icon="plus-circle" />
                                <MDBIcon style={{ margin: "6px" }} icon="minus-circle" />
                                <MDBIcon style={{ margin: "6px" }} icon="check-circle" /> */}
                            </Row>
                        )}

                        <br />
                    </div>
                    : null}
                {labors.length > 0 ?
                    <div>
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Labor</Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}>Hours</Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}>Rate</Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}> Amount </Col>
                        </Row>
                        {labors.map((item, key) =>
                            <Row key={key} className="jobcard" >
                                <Col>{("00" + item.employeeID).substr(item.employeeID.length - 1)} - {item.description}</Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.amount ? parseFloat(item.amount).toFixed(2) : 0.00.toFixed(2)}  </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.rate ? parseFloat(item.rate).toFixed(2) : 0.00.toFixed(2)} </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.total ? parseFloat(item.total).toFixed(2) : 0.00.toFixed(2)}

                                </Col>
                            </Row>
                        )}
                        <br />
                    </div>
                    : null}

                {sublets.length > 0 ?
                    <div>
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Sublets</Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}> Amount </Col>
                        </Row>
                        {sublets.map((item, key) =>
                            <Row key={key} className="jobcard" >
                                <Col xs="2">{item.vendor}</Col>
                                <Col >{item.description} </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)}

                                </Col>

                            </Row>
                        )}
                        <br />
                    </div>
                    : null}

                {waste.length > 0 ?
                    <div>
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Waste</Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right" , margin:"auto"  }}> Amount </Col>
                        </Row>
                        {waste.map((item, key) =>
                            <Row key={key} className="jobcard"  >
                                <Col xs="2">{item.vendor}</Col>
                                <Col >{item.description} </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)}

                                </Col>
                                {/* <Col xs="2"> </Col> */}
                            </Row>
                        )}
                        <br />
                    </div>
                    : null}

                {credits.length > 0 ?
                    <div>
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px"}}>Credits</Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right" }}></Col>
                            <Col xs="1" style={{ textAlign: "right", margin:"auto"  }}> Amount </Col>
                        </Row>
                        {credits.map((item, key) =>
                            <Row key={key} className="jobcard" >
                                <Col>{item.description}</Col>
                                <Col xs="2">   </Col>
                                <Col xs="2">  </Col>
                                <Col xs="1" style={{ textAlign: "right" }}>{item.amount ? parseFloat(item.amount).toFixed(2) : 0.00.toFixed}

                                </Col>
                            </Row>
                        )}
                        <br />
                    </div>
                    : null}


                    {payments.length > 0 ?
                        <div className="avoidBreak">
                            <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                                <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Payments/Charges</Col>
                            </Row>
                            {
                                payments.map((item, key) =>
                                    <Row key={key}  >
                                        <Col xs lg="6"></Col>
                
                                        <Col xs="2" style={{ textAlign: "right" }}> {Constants.dateFormatter(item.Date)}  </Col>
                                        <Col xs="1" style={{ textAlign: "right" }}> {item.Type} </Col>
                                        <Col xs="1" style={{ textAlign: "right" }}>  {item.Amount ? item.Amount.toFixed(2) : '--'} </Col>
                                    </Row>
                                )
                            }
                          {/*   <Row>
                                <Col xs lg="10"></Col>
                                <Col xs="1" style={{ textAlign: "right" }}> Balance: </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {totals.Balance ? totals.Balance.toFixed(2) : 0.00.toFixed(2)} </Col>
                            </Row> */}
                        </div>

                        :
                            totals.PaymentMethod === "" ? null : 
                        <div className="avoidBreak">

                            <Row style={{borderBottom:"groove", borderWidth:"thin"}} >
                                <Col xs lg="3" style={{ fontSize: "25px", marginLeft: "-10px" }}>Payments/Charges</Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col xs="2"> {Constants.dateFormatter(totals.DateClosed)}  </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {totals.PaymentMethod}  </Col>
                                <Col xs="1" style={{ textAlign: "right" }}> {totals.WorkorderTotal.toFixed(2)} </Col>

                            </Row>
                                {/* {totals.Balance ?  <Row>
                                    <Col xs="9"></Col>
                                    <Col xs="2" style={{ textAlign: "right" }}> Balance:  </Col>
                                    <Col xs="1" style={{ textAlign: "right" }}> {totals.Balance.toFixed(2)} </Col>

                                </Row>
                                : null} */}
                           

                        </div>
}
                 
                {notes.length > 0 ?
                    <div className="avoidBreak">
                        <Row style={{borderBottom:"groove", borderWidth:"thin"}}>
                            <Col style={{ fontSize: "25px", marginLeft: "-10px" }}>Notes</Col>
                        </Row>
                        {notes.map((item, key) =>
                            <Row key={key} className="jobcard" >
                                <Col>{item.description}</Col>
                                {/* <Col xs="2">   </Col>
                                <Col xs="2">  </Col> */}
                                <Col xs="2"> </Col>
                            </Row>
                        )}
                        <br />
                    </div>
                    : null}


                <br />
            </Container>
            :
            <Container style={{ height: "100%"/* , borderStyle: "outset" */ }} >
               
            </Container>
    )
}
