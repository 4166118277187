import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from './../../../constants/constants.js';
import filterFactory from 'react-bootstrap-table2-filter';
import axios from 'axios';

export default function CreditModal(props) {
    props.useEventListener('keydown', myKeyPress);
    const inputRef = useRef();
    const show = props.show
    const [selected, setSelected] = useState(0)
    const [newFilter, setNewFilter] = useState(false)
    const [canned_items, setCanned_items] = useState({list:[] })
    const [count,setCount] = useState(0)
    const [addItem, setAddItem] = useState(false)
    const [editItem, setEditItem] = useState(false)
    const [item, setItem] = useState({
        code: "",
        description: "",
        hours: '', 
        rate: Constants.shop_information.LaborRate,
        total: '',
        type:"labor"
    })

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: [selected],
        onSelect:handleOnSelect,
       
    };

    function afterFilter(newResult, newFilters) {
        console.log("newFilters:", newFilters, "newRes: ", newResult);
        if(newResult.length > 0 && newFilter === false){
            setSelected(newResult[0].id)
            /* setNewFilter(true) */
        }
        /* else
            setSelected(-1) */
    }

    function handleOnSelect(item, event) {
        console.log("On select Canned Item:", item);
        setSelected(item.id)
        setNewFilter(true)
    }

    function myKeyPress(event) {
        setNewFilter(false)
        if (event.key === "F10") {
            props.addCannedItem(canned_items.list.filter(item=>item.id === selected)[0])
            event.preventDefault()
        }
    }

    async function getCannedItems() {
        try {

            const res_data = await axios.get(`${Constants.port}/canned/getAll`);
            console.log('fetching data Canned-Items: ', res_data);
            setCanned_items({ list: res_data.data.canned_items })
        } catch (error) {
            console.log("ERROR GETTING CANNED ITEMS: ax1wszs")
        }

    }

    async function addCannedItem() {
        try {
            console.log("adding canned item:-cannedModal.js ", item);
            const res_data = await axios.post(`${Constants.port}/canned/add`,item);
            console.log('response, Add canned item - code: ', res_data.data.canned_item_code);
            setAddItem(false)
            setItem({
                code: "",
                description: "",
                hours: '', 
                rate: Constants.shop_information.LaborRate,
                total: '',
                type:"labor"
            })
            setCount(count+1)

            //setCanned_items({ list: res_data.data.canned_items })
        } catch (error) {
            console.log("ERROR ADDING A CANNED ITEM: ashdauzxh1")
        }
    }

    async function submitEditItem(){
        console.log("submit update item", item)
        console.log("Editing item:", canned_items.list[selected])

        try {
            const res_data = await axios.post(`${Constants.port}/canned/${canned_items.list.filter(item=>item.id === selected)[0]._id}/update`, item);
            console.log('fetching data update Canned-Item ', res_data);
            //setCanned_items({ list: res_data.data.canned_items })
            setEditItem(false)
            setCount(count+1)
        } catch (error) {
            console.log("ERROR UPDATING CANNED ITEM: axjaj1z")
        }
    }

    async function removeItem(){
        console.log("submit remove item", item)
        console.log("remving item:", canned_items.list[selected])

        try {
            const res_data = await axios.post(`${Constants.port}/canned/${canned_items.list.filter(item=>item.id === selected)[0]._id}/remove`);
            console.log('fetching data remove Canned-Item ', res_data);
            //setCanned_items({ list: res_data.data.canned_items })
            setEditItem(false)
            setCount(count+1)
        } catch (error) {
            console.log("ERROR REMOVING CANNED ITEM: axjaj1z")
        }
    }

    function showList(){
        setItem({
            code: "",
            description: "",
            hours: '', 
            rate: Constants.shop_information.LaborRate,
            total: '',
            type:"labor"
        })
        setAddItem(false)
        setEditItem(false)
    }

    function selectEditItem(){
        setEditItem(true)
        setItem(canned_items.list.filter(item=>item.id === selected)[0])
    }

    useEffect(() => {
        getCannedItems()
        document.getElementById('text-filter-column-code').focus()

        //inputRef.current.focus();
    }, [count])

    return (
        <Modal size='xl' show={show} onHide={props.close} centered className="invoice_item_modal_canned" >
            <Modal.Header closeButton>
                <Modal.Title>{addItem ? "Add Item" : editItem ? "Edit Item" : "Canned Items"}</Modal.Title>
                {addItem ? 
                <>
                <Button style={{ marginLeft: "20px" }} onClick={() => showList()}> Show List </Button>
                <Form style={{margin:"auto"}}>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="canned-switch"
                        label="Canned Note"
                        onChange={(e)=>e.target.checked ? setItem({...item, type: 'note'}) : setItem({...item, type: 'labor'})}
                    />
                </Form>
                </>
                : editItem ?
                    <Button style={{ marginLeft: "20px" }} onClick={() => showList()}> Show List </Button> :
                   <React.Fragment>
                        <Button style={{ marginLeft: "20px" }} disabled={selected < 0} onClick={() => props.addCannedItem(canned_items.list.filter(item=>item.id === selected)[0])}>Select</Button>
                        <Button style={{ marginLeft: "20px" }} onClick={() => setAddItem(true)}> Add New </Button>
                        <Button style={{ marginLeft: "20px" }} disabled={selected < 0} onClick={() => selectEditItem()}> Edit Item </Button>
                    </React.Fragment>
                }


            </Modal.Header>

            {addItem || editItem ?
                <Modal.Body className="canned_body" style={{alignSelf:"center", overflow:"hidden", width:"650px"}} >
                    <Form /* validated={validated} */ /* onSubmit={props.addPart.bind(this, part)} */ >
                        <Form.Group as={Row} bsPrefix="smallMargin" >
                            <Form.Label column sm={4}>
                                Code
                    </Form.Label>
                            <Col sm={5}>
                                <Form.Control
                                    required
                                    value={ item.code}
                                    onChange={(e) => setItem({ ...item, code: e.target.value.toUpperCase() }) }
                                />
                            </Col>
                            <Form.Label column sm={5}></Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} bsPrefix="smallMargin"  >
                            <Form.Label column sm={4}>
                                Hours
                    </Form.Label>
                            <Col sm={5}>
                                <Form.Control
                                    disabled={item.type === 'note'}
                                    required
                                    value={item.hours}
                                    onChange={(e) => setItem({ ...item, hours: e.target.value, total: parseFloat(e.target.value * item.rate).toFixed(2) })}
                                />                    </Col>
                            <Form.Label column sm={5}></Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} bsPrefix="smallMargin"  >
                            <Form.Label column sm={4}>
                                Rate
                    </Form.Label>
                            <Col sm={4}>
                                <Form.Control disabled
                                    value={item.rate.toFixed(2)}
                                    onChange={(e) => setItem({ ...item, rate: e.target.value })} />
                            </Col>
                            <Form.Label column sm={5}>

                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} bsPrefix="smallMargin"  >
                            <Form.Label column sm={4}>
                                Total
                    </Form.Label>
                            <Col sm={4}>
                                <Form.Control
                                    disabled={item.type === 'note'}
                                    onChange={(e) => setItem({ ...item, total: e.target.value })}
                                    value={item.total} />
                            </Col>
                            <Form.Label column sm={5}></Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} bsPrefix="smallMargin"  >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows="4"
                                defaultValue={item.description}
                                onChange={(e) => setItem({ ...item, description: e.target.value.toUpperCase() })} />
                        </Form.Group>


                        <br />
                        <Modal.Footer>
                        {editItem ? <Button variant="danger" style={{ marginRight:"auto" }} onClick={() => removeItem()}> Remove Item </Button> : null }
                        <Button variant="primary" onClick={()=> addItem ? addCannedItem() : submitEditItem()}>Submit form</Button>
                        <Button /* style={{ marginLeft: "10px" }} */ variant="secondary" onClick={props.close}>
                         Cancel                         
                        </Button>
                </Modal.Footer>
                    </Form>
                </Modal.Body>
                :
                <Modal.Body className="canned_body" style={{minWidth:"80%"}}>
                    <div>
                        <BootstrapTable
                            ref={inputRef}
                            bootstrap4
                            condensed
                            filter={filterFactory({ afterFilter })}
                            keyField='id'
                            //ref={inputRef}
                            data={canned_items.list}
                            columns={Constants.cannedColumns}
                            selectRow={selectRow}
                        />
                    </div>
                </Modal.Body>
            }

        </Modal>
    )

}