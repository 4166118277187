import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap'

export default function EmailModal(props) {
    const show = props.show;
    const email = props.email;
    useEffect(()=>{
        document.getElementById('email_body').innerHTML =  email.payload;
    })

    return (
        <Modal show={show} onHide={props.close}  >
{/* <input type="hidden" value="something" /> */}
            <Modal.Header closeButton>
                <Modal.Title>Email</Modal.Title>
            </Modal.Header>

            <Modal.Body >
                <div  id="email_body">B</div>
                
            </Modal.Body>

            {/* <Modal.Footer>
                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={props.add.bind(this, jobToAdd)}> Save Changes </Button>
            </Modal.Footer> */}

        </Modal>
    )
}