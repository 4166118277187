import React, { useState, useEffect, useRef } from 'react';
import { Row, Button, Card, Col, Form, Modal } from 'react-bootstrap'
import axios from 'axios';
import * as Constants from './../../constants/constants'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from 'react-bootstrap-table-next';
import EmployeeModal from "./Modals/EmployeeModal.js"
import EditJobModal from "../jobs_screen/Modals/EditJobModal"
import PartsModal from "../invoice_screen/Modals/PartsModal"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import CheckoutModal from '../reports_screen/Modals/CheckoutModal';
import ShopModal from './Modals/ShopModal';
import SearchScreen from "../search_screen/SearchScreen.js"

export function sha256(ascii) {
    function rightRotate(value, amount) {
        return (value >>> amount) | (value << (32 - amount));
    };

    var mathPow = Math.pow;
    var maxWord = mathPow(2, 32);
    var lengthProperty = 'length'
    var i, j; // Used as a counter across the whole file
    var result = ''

    var words = [];
    var asciiBitLength = ascii[lengthProperty] * 8;

    //* caching results is optional - remove/add slash from front of this line to toggle
    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
    // (we actually calculate the first 64, but extra values are just ignored)
    var hash = sha256.h = sha256.h || [];
    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
    var k = sha256.k = sha256.k || [];
    var primeCounter = k[lengthProperty];
    /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

    var isComposite = {};
    for (var candidate = 2; primeCounter < 64; candidate++) {
        if (!isComposite[candidate]) {
            for (i = 0; i < 313; i += candidate) {
                isComposite[i] = candidate;
            }
            hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
            k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
        }
    }

    ascii += '\x80' // Append Ƈ' bit (plus zero padding)
    while (ascii[lengthProperty] % 64 - 56) ascii += '\x00' // More zero padding
    for (i = 0; i < ascii[lengthProperty]; i++) {
        j = ascii.charCodeAt(i);
        if (j >> 8) return; // ASCII check: only accept characters in range 0-255
        words[i >> 2] |= j << ((3 - i) % 4) * 8;
    }
    words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
    words[words[lengthProperty]] = (asciiBitLength)

    // process each chunk
    for (j = 0; j < words[lengthProperty];) {
        var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
        var oldHash = hash;
        // This is now the undefinedworking hash", often labelled as variables a...g
        // (we have to truncate as well, otherwise extra entries at the end accumulate
        hash = hash.slice(0, 8);

        for (i = 0; i < 64; i++) {
            //var i2 = i + j;
            // Expand the message into 64 words
            // Used below if 
            var w15 = w[i - 15], w2 = w[i - 2];

            // Iterate
            var a = hash[0], e = hash[4];
            var temp1 = hash[7]
                + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                + k[i]
                // Expand the message schedule if needed
                + (w[i] = (i < 16) ? w[i] : (
                    w[i - 16]
                    + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                    + w[i - 7]
                    + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                ) | 0
                );
            // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
            var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

            hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
            hash[4] = (hash[4] + temp1) | 0;
        }

        for (i = 0; i < 8; i++) {
            hash[i] = (hash[i] + oldHash[i]) | 0;
        }
    }

    for (i = 0; i < 8; i++) {
        for (j = 3; j + 1; j--) {
            var b = (hash[i] >> (j * 8)) & 255;
            result += ((b < 16) ? 0 : '') + b.toString(16);
        }
    }
    return result;
};

export default function AdminScreen(props) {

    const [employeeList, setEmployeeList] = useState([])
    const [p, setP] = useState("")
    const [item, setItem] = useState({})
    const [partSearch, setPartSearch] = useState({ list: [], partNumber: "" })
    const [jobs, setJobs] = useState([])
    const [returns, setReturns] = useState([])
    const [shopInfo, setShopInfo] = useState({})
    const [selectedReturns, setSelectedReturns] = useState([])
    const [checkouts, setCheckouts] = useState([])
    const [count, setCount] = useState(0)
    const [invoiceInfo, setInvoiceInfo] = useState({})

    const defaultSorted = [{
        dataField: 'WorkorderNumber',
        order: 'desc'
    }];
    const [access, setAccess] = useState({
        employees: false,
        controls: false
    })

    const [modals, setModals] = useState({
        employee: false,
        job: false,
        parts: false,
        checkout: false,
        shop: false,
        invoice: false
    })
    function priceFormatter(cell, row) {
        return (
            <span>
                $ {cell ? cell.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}
            </span>
        );
    }

    const defaultSorted_returns = [{
        dataField: 'Distributor',
        order: 'desc'
    }];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        headerColumnStyle: {
            textAlign: 'center'
        }
        ,
        onSelect: (row, isSelect, rowIndex, e) => {
            let cpy = [...selectedReturns]
            let index = cpy.indexOf(row._id)
            if (index !== -1)
                cpy.splice(index, 1)
            else
                cpy.push(row._id)
            setSelectedReturns(cpy)
        }
    };

    const columns = [{
        dataField: 'EmployeeID',
        text: 'Employee ID',
        sort: true
    }, {
        dataField: 'Name',
        text: 'Name',
        formatter: nameFormatter,
        sort: true
    }];

    const columns_jobs = [
        {
            dataField: 'WorkorderNumber',
            text: 'Workorder Number',
            sort: true
        }, {
            dataField: 'Name',
            text: 'Name',
            formatter: nameFormatter,
            sort: true
        }, {
            dataField: 'Phone',
            text: 'Phone',
        }, {
            dataField: 'TimeIn',
            text: 'Time In',
            formatter:dateFormatter
        }, {
            dataField: 'TimeOut',
            text: 'Time Out',
            formatter:dateFormatter
        }];

    const columns_invoices = [
        {
            dataField: 'CustomerName',
            text: 'CustomerName',
            //formatter: nameFormatter,
        },
        {
            dataField: 'Vehicle',
            text: 'Vehicle',
        },
        {
            dataField: 'Date',
            text: 'Date',
            formatter: Constants.dateFormatter,
        },
        {
            dataField: 'WorkorderNumber',
            text: 'Workorder Number',
            sort: true
        }];

    const columns_laborclaim = [
        {
            dataField: 'WorkorderNumber',
            text: 'Workorder Number',
            sort: true
        }, {
            dataField: 'Name',
            text: 'Name',
            formatter: nameFormatter,
            sort: true
        }, {
            dataField: 'Date',
            text: 'Date',
        }];

    const columns_returns = [{
        dataField: 'Distributor',
        text: 'Distributor',
        filter: textFilter({  delay: 10, style: { zoom: "90%" } }),
        headerStyle: { width: "15%"/* , textAlign: 'center' */ },
        sort: true
    }, {
        dataField: 'PartNumber',
        text: 'Part Number',
        filter: textFilter({  delay: 10, style: { zoom: "90%" } }),
        sort: true
    }, {
        dataField: 'Description',
        text: 'Description',
        sort: true
    }, {
        dataField: 'Quantity',
        headerStyle: { width: "12%" },
        text: 'Quantity',
        sort: true,

    }, {
        dataField: 'Cost',
        text: 'Cost',
        headerStyle: { width: "10%", textAlign: 'center' },
        formatter: priceFormatter,
        sort: true,
    }, {
        dataField: 'Date',
        headerStyle: { width: "13%" },
        text: 'Init. Date',
        sort: true,
    }];

    const columns_checkouts = [
        {
            dataField: 'Date',
            text: 'Date',
            formatter: Constants.dateFormatter,
            sort: true
        }, {
            dataField: 'Total',
            text: 'Total',
            formatter: priceFormatter,
            sort: true
        }, {
            dataField: 'CashAmount',
            text: 'CashAmt',
            formatter: priceFormatter,
            sort: true
        }, {
            dataField: 'CreditAmount',
            text: 'CreditAmt',
            formatter: priceFormatter,
            sort: true
        }, {
            dataField: 'CheckAmount',
            text: 'CheckAmt',
            formatter: priceFormatter,
            sort: true
        }/*, {
            dataField: 'OtherAmount',
            text: 'OtherAmt',
            formatter: priceFormatter,
            sort: true
        }*/, {
            dataField: 'Slips',
            text: '# Slips',
            formatter: item => item.length,
            sort: true
        }];
    const rowEvents_employee = {
        onClick: (e, row, rowIndex) => {
            //console.log("ROW EVENT", row)
            setItem(row)
            setModals({ ...modals, employee: true })
            //props.history.push(`/customers/${row._id}`)
        },
    };

    const rowEvents_jobs = {
        onClick: (e, row, rowIndex) => {
            console.log("ROW EVENT", row)
            setItem(row)
            setModals({ ...modals, job: true })
            //props.history.push(`/customers/${row._id}`)
        },
    };

    const rowEvents_checkouts = {
        onClick: (e, row, rowIndex) => {
            console.log("ROW EVENT,checkout: ", row)
            setItem(row)
            setModals({ ...modals, checkout: true })
        },
    };

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    function nameFormatter(cell, row) {

        return (
            <span>
                {`${row.Firstname} ${row.Lastname}`}
            </span>
        );
    }

    function dateFormatter(cell, row) {
        var today = new Date(cell);
        var time = today.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        var date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
        return (
            <span>
                {`${time} ${date}`}
            </span>
        );
    }

    async function getEmployees() {
        try {
            const res_data = await axios.get(`${Constants.port}/employee/getAll`);
            //console.log('fetching employee data: ', res_data);
            setEmployeeList(res_data.data.employees)

        } catch (error) {
            console.log("Error - amz123z", error)


        }
    }

    async function setCustomerEmptyFields() {
        try {
            const res = axios.get(`${Constants.port}/customer/setEmptyFields`)
            console.log("fetching data, setCustomerEmptyFields ", res);
        } catch (error) {
            console.log("Error setting customer empty fields.");
        }
    }

    function submitPassword(option) {
        console.log("submit password: option:", p);
        switch (option) {
            case (1):
                if (p === "149d76556f7253abfeb6f66435bc58a3f3eba995b86b284f1629d1823002a367") {
                    getEmployees()
                    setAccess({ ...access, employees: true })
                }
                break;
            case (2):
                if (p === "252f10c83610ebca1a059c0bae8255eba2f95be4d1d7bcfa89d7248a82d9f111")
                    setAccess({ ...access, controls: true })
                break;
            default: break;
        }
    }

    function pwSection(frm) {
        return (
            <Card style={{/*  overflowY: "scroll", */ height: "100%", /* zoom: "79%"  */ }}>
                <Card.Header style={{ fontSize: "35px", paddingRight: "10px" }}>
                    {frm === 1 ? "Employees" : frm === 2 ? "Control Panel" : ''}
                </Card.Header>
                <Card.Body style={{ overflowY: "scroll", display: "flex" }}>
                    <div className="test_center">
                        <Form.Control onChange={(e) => setP(sha256(e.target.value))} type="password" placeholder="Password" />
                        <div style={{ textAlign: "center", marginTop: "10px", width: "100%" }}>
                            <Button onClick={() => submitPassword(frm)} variant="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>

                </Card.Body>
            </Card>
        )
    }

    async function getJobs() {
        try {

            const res_data = await axios.get(`${Constants.port}/jobs/getCompleted`);
            console.log('fetching data: ', res_data);
            setJobs(res_data.data.completed_jobs)


        } catch (error) {
            console.log("Error while getting completed jobs. azxy712aa")
        }
    }

    async function getReturns() {
        try {

            const res_data = await axios.get(`${Constants.port}/returns/getAll`);
            console.log('fetching data returns: ', res_data);
            setReturns(res_data.data.returns)


        } catch (error) {
            console.log("Error while getting return. ahsd16723a")
        }
    }

    async function getCheckouts() {
        try {
            const res_data = await axios.get(`${Constants.port}/checkouts/getAll`);
            console.log('fetching data: ', res_data);
            setCheckouts(res_data.data.checkouts)
        } catch (error) {
            console.log("Error while getting checkouts. j17892e", error)
        }
    }

    async function getShopInfo() {
        try {
            const res_data = await axios.get(`${Constants.port}/shop/getInfo`);
            console.log('fetching data: ', res_data);
            setShopInfo(res_data.data.shop)
        } catch (error) {
            console.log("Error while getting shop information. br1y82ue", error)
        }
    }

    async function mongodump() {

        try {

            const res_data = await axios.get(`${Constants.port}/mongodump`);
            console.log('mongodump resp: ', res_data);

        } catch (error) {
            console.log("Error mongodump. asdja7812")
        }
    }

    async function changeDate() {

        try {

            const res_data = await axios.get(`${Constants.port}/invoices/changeDateFormat`);
            console.log('changeDateFormat resp: ', res_data);

        } catch (error) {
            console.log("Error changeDate. asd123aza", error)
        }
    }

    async function changeSplitFormat() {

        try {

            const res_data = await axios.get(`${Constants.port}/invoices/changeSplitFormat`);
            console.log('changeSplitFormat resp: ', res_data);

        } catch (error) {
            console.log("Error changeSplitFormat. asd1111", error)
        }
    }

    async function goThroughAllOpen() {

        try {

            const res_data = await axios.get(`${Constants.port}/invoices/test/allOpen`);
            console.log('goThroughAllOpen resp: ', res_data);

        } catch (error) {
            console.log("Error goThroughAllOpen. asd123zzz", error)
        }
    }

    async function newPaymentMethod2() {

        try {

            const res_data = await axios.get(`${Constants.port}/invoices/newPaymentMethod2`);
            console.log('newPaymentMethod2 resp: ', res_data);

        } catch (error) {
            console.log("Error newPaymentMethod2. azxn716", error)
        }
    }

    async function removeCurrentSplit() {

        try {

            const res_data = await axios.get(`${Constants.port}/invoices/removeCurrentSplit`);
            console.log('removeCurrentSplit resp: ', res_data);

        } catch (error) {
            console.log("Error removeCurrentSplit. asd12312", error)
        }
    }

    async function calculateEmployeeHours() {
        console.log("calculating Employee hours...");
        try {

            const res_data = await axios.get(`${Constants.port}/invoices/getEmployeeHours`);
            console.log('calculateEmployeeHours resp: ', res_data);

        } catch (error) {
            console.log("Error calculateEmployeeHours. xb1t7y2e", error)
        }

    }

    async function setPayrollBlank() {
        console.log("setPayrollBlank...");
        try {

            const res_data = await axios.get(`${Constants.port}/employee/setPayrollBlank`);
            console.log('setPayrollBlank resp: ', res_data);

        } catch (error) {
            console.log("Error setPayrollBlank. asd12gt31", error)
        }

    }

    async function storeWeekAbsences() {
        console.log("setPayrollBlank...");
        try {

            const res_data = await axios.post(`${Constants.port}/employee/storeWeekAbsences`);
            console.log('setPayrollBlank resp: ', res_data);

        } catch (error) {
            console.log("Error setPayrollBlank. asd12gt31", error)
        }

    }

    async function saveEmployeeChanges(info) {
        try {
            console.log("saving...", info)
            const res_data = await axios.post(`${Constants.port}/employee/${info._id}/update`, { info });
            console.log('saveEmployeeChanges resp: ', res_data);
            setCount(count + 1)

        } catch (error) {
            console.log("Error saveEmployeeChanges. ashnd112ed", error)
        }
        setModals({ ...modals, employee: false })

    }

    async function completeSelectedReturns() {
        try {

            const res_data = await axios.post(`${Constants.port}/returns/removeReturns`, selectedReturns);
            console.log('fetching data returns: ', res_data);
            //setReturns(res_data.data.returns)
            setCount(count + 1)

        } catch (error) {
            console.log("Error while getting return. ahsd16723a")
        }
    }

    async function giveAllVehiclesID() {
        try {

            const res_data = await axios.get(`${Constants.port}/customer/giveAllVehiclesID`);
            console.log('fetching data giveAllVehiclesID: ', res_data);

        } catch (error) {
            console.log("Error while getting giveAllVehiclesID. asd12ed1")
        }
    }

    async function deleteOldInvoices() {
        try {
            let criteria = new Date(2019, 11, 31)
            console.log("criteria: ", criteria)
            const res_data = await axios.post(`${Constants.port}/invoices/cleanInvoices`, { criteria });
            console.log('fetching data cleanInvoices: ', res_data);

        } catch (error) {
            console.log("Error while getting cleanInvoices. asd12eda")
        }
    }

    async function addPart(newPart) {
        console.log("testing addPart: ", newPart)
        const itemToSend = {
            newItem: newPart,
            type: "Parts",
            date: Constants.getDate()
        }
        console.log("adding return item...", itemToSend)

        try {
            const res_data = await axios.post(`${Constants.port}/returns/addReturn`, itemToSend);
            console.log('fetching data addReturnItem ', res_data);
            setCount(count + 1)
            setModals({ ...modals, parts: false })
        } catch (error) {
            console.log("Error - asdfn718", error)
            //props.setError({ show: true, message: "Error adding return item, errno: asdf21e1" })
        }

    }

    async function removeCheckout(id) {
        console.log("Removing checkout...: ", id)


        try {
            const res_data = await axios.post(`${Constants.port}/checkouts/removeByID`, { id: id });
            console.log('fetching data removeCheckout ', res_data);
            setModals({ ...modals, checkout: false })
            setCount(count + 1)
        } catch (error) {
            console.log("Error - asdfn718", error)
            //props.setError({ show: true, message: "Error adding return item, errno: asdf21e1" })
        }

    }

    async function searchForPart() {
        console.log("searching for part...", partSearch.partNumber)

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/searchPart`, { partNumber: partSearch.partNumber });
            console.log("searching for part(2)...", res_data.data.invoices)
            setPartSearch({ list: res_data.data.invoices })
            setCount(count+1)
        } catch (error) {
            console.log("error searching for part...", error)

        }
    }

    const rowEvents_partSearch = {
        onClick: (e, row, rowIndex) => {
            console.log("click part-search item", row._id)
            setInvoiceInfo(row._id)
            setModals({ ...modals, invoice: true })
        },
    };

    useEffect(() => {
        //console.log("useEffect adminScreen,", modals);
        getJobs()
        getReturns()
        getCheckouts()
        getShopInfo()
        if (access.employees)
            getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count])

    return (

        <div style={{ height: "100vh", overflowX: "hidden", /* width:"100rem", */ padding: "10px" }}>

            <Modal
                show={modals.invoice}
                onHide={() => setModals({ ...modals, invoice: false })}
                centered
                dialogClassName="search_modal_content"
            >
                <SearchScreen from="database_screen" info={invoiceInfo} />
            </Modal>
            {modals.employee ?
                <EmployeeModal
                    employee={item}
                    show={modals.employee}
                    close={() => setModals({ ...modals, employee: false })}
                    save={(info) => saveEmployeeChanges(info)} />
                : null
            }

            {modals.checkout ?
                <CheckoutModal
                    from='admin'
                    show={modals.checkout}
                    checkoutAmounts={{ 
                        cash: item.CashAmount, card: item.CreditAmount, check: item.CheckAmount, other: item.otherAmount, slips: item.Slips,
                        boxStart: item.BoxStart, boxEnd: item.BoxEnd
                    }}
                    total={item.Total}
                    date={item.Date}
                    close={() => setModals({ ...modals, checkout: false })}
                    _id={item._id}
                    removeCheckout={(id) => removeCheckout(id)}
                />
                : null}

            {/* COMPLETED JOBS // CHECKOUTS */}
            <Row style={{ height: "48%" }}>
                <Col style={{ maxHeight: "100%" }}>
                    <Card style={{/*  overflowY: "scroll", */ height: "100%", zoom: "79%" }}>
                        <Card.Header style={{ fontSize: "35px", /* paddingRight: "10px" */ }}>
                            Completed Jobs
                            <p style={{ fontSize: "15px", float: "right" }}>Showing 50 most recent Jobs</p>
                        </Card.Header>
                        <Card.Body style={{ overflowY: "scroll" }}>
                            {modals.job ?
                                <EditJobModal
                                    from="admin_screen"
                                    show={modals.job}
                                    jobInfo={item}
                                    save={(newJob) => console.log(newJob)}
                                    close={() => setModals({ ...modals, job: false })}
                                />
                                : null}
                            <BootstrapTable
                                defaultSorted={defaultSorted}
                                bootstrap4
                                keyField='_id'
                                data={jobs}
                                columns={columns_jobs}
                                /*  filter={filterFactory()} */
                                rowEvents={rowEvents_jobs}
                                striped
                                hover
                                condensed
                            />
                        </Card.Body>
                    </Card>
                </Col>

                <Col style={{ maxHeight: "100%" }}>
                    <Card style={{ height: "100%", zoom: "79%" }}>
                        <Card.Header style={{ fontSize: "35px" }}>
                            Checkouts
                        </Card.Header>
                        <Card.Body style={{ overflowY: "scroll" }}>
                            <BootstrapTable
                                defaultSorted={defaultSorted}
                                bootstrap4
                                keyField='_id'
                                data={checkouts}
                                columns={columns_checkouts}
                                rowEvents={rowEvents_checkouts}
                                striped
                                hover
                                condensed
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />

            <Row style={{ height: "48%" }}>
                <Col style={{ maxHeight: "100%" }}>
                    <Card style={{/*  overflowY: "scroll", */ height: "100%", zoom: "79%" }}>
                        <Card.Header style={{ fontSize: "35px", /* paddingRight: "10px" */ }}>
                            Returns
                            <Button style={{ marginLeft: "10px" }} variant="success" disabled={selectedReturns.length === 0}
                                onClick={() => completeSelectedReturns()}> Mark Complete </Button>
                            <Button style={{ marginLeft: "10px" }} variant="primary"
                                onClick={() => setModals({ ...modals, parts: true })} > Add Item </Button>
                        </Card.Header>
                        <Card.Body style={{ overflowY: "scroll" }}>
                            <h4>Parts</h4>
                            <BootstrapTable
                                bootstrap4
                                keyField='_id'
                                data={returns}
                                columns={columns_returns}
                                filter={filterFactory()}
                                selectRow={selectRow}
                                striped
                                hover
                                condensed
                                defaultSorted={defaultSorted_returns}
                            />

                            <h4>Labor Claim</h4>
                            <BootstrapTable
                                bootstrap4
                                keyField='_id'
                                data={[]}
                                columns={columns_laborclaim}
                                /*  filter={filterFactory()} */
                                /* rowEvents={rowEvents_jobs} */
                                selectRow={selectRow}
                                striped
                                hover
                                condensed
                            />
                        </Card.Body>
                    </Card>
                </Col>

                <Col style={{ maxHeight: "100%" }}>
                    <Card style={{/*  overflowY: "scroll", */ height: "100%", zoom: "79%" }}>
                        <Card.Header style={{ fontSize: "35px", /* paddingRight: "10px" */display: "inline-flex" }}>
                            Part-Search
                            <Form.Control placeholder="Part Number" style={{ marginLeft: "auto", width: "fit-content", marginTop: "auto", marginBottom: "auto" }} onChange={(e) => setPartSearch({ ...partSearch, partNumber: e.target.value.toUpperCase() })} />

                            <Button style={{ marginLeft: "10px" }} variant="primary" onClick={() => searchForPart()}> Search </Button>
                        </Card.Header>
                        <Card.Body style={{ overflowY: "scroll" }}>
                            <BootstrapTable
                                defaultSorted={defaultSorted}
                                bootstrap4
                                keyField='_id'
                                data={partSearch.list}
                                columns={columns_invoices}
                                /*  filter={filterFactory()} */
                                rowEvents={rowEvents_partSearch}
                                striped
                                hover
                                condensed
                            />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br />

            {/* EMPLOYEES // CONTROL PANEL */}
            <Row style={{ height: "48%" }}>
                <Col>
                    {access.employees ?
                        <Card style={{/*  overflowY: "scroll", */ height: "100%", zoom: "79%" }}>
                            <Card.Header style={{ fontSize: "35px", /* paddingRight: "10px" */ }}>
                                Employees
                            </Card.Header>
                            <Card.Body style={{ overflowY: "scroll" }}>
                                <BootstrapTable
                                    bootstrap4
                                    keyField='_id'
                                    data={employeeList}
                                    columns={columns}
                                    /*  filter={filterFactory()} */
                                    rowEvents={rowEvents_employee}
                                    striped
                                    hover
                                    condensed
                                />
                            </Card.Body>
                        </Card>
                        : pwSection(1)}
                </Col>
                <Col>
                    {access.controls ? <Card style={{/*  overflowY: "scroll", */ height: "100%", zoom: "79%" }}>
                        <Card.Header style={{ fontSize: "35px", /* paddingRight: "10px" */ }}>
                            Control Panel
                        </Card.Header>
                        <Card.Body style={{ overflowY: "scroll" }}>
                            <Row>
                                <Col>
                                    <h4 style={{ borderBottom: 'inset' }}>Mongo</h4>
                                    <Button style={{ fontSize: "20px", margin: "10px" }}
                                        onClick={() => mongodump()} variant="primary">Mongodump</Button>
                                    <h4 style={{ borderBottom: 'inset' }}>Employees</h4>
                                    <Button style={{ fontSize: "20px", margin: "10px" }}
                                        onClick={() => calculateEmployeeHours()} variant="primary">Calc-Hours</Button>
                                    <Button style={{ fontSize: "20px", margin: "10px" }}
                                        onClick={() => setPayrollBlank()} variant="primary">Blank Payroll</Button>
                                    <Button style={{ fontSize: "20px", margin: "10px" }}
                                        onClick={() => storeWeekAbsences()} variant="primary">Store Week Absence</Button>
                                    <h4 style={{ borderBottom: 'inset' }}>Shop</h4>
                                    <Button style={{ fontSize: "20px", margin: "10px" }}
                                        onClick={() => setModals({ ...modals, shop: true })} variant="primary">View/Edit</Button>
                                </Col>
                                <Col>
                                    <h4 style={{ borderBottom: 'inset' }}>Invoices</h4>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => changeDate()} variant="danger" type="submit">
                                        Change Date (All WO)
                                    </Button>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => changeSplitFormat()} variant="danger" type="submit">
                                        ChangeSplitFormat
                                    </Button>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => removeCurrentSplit()} variant="danger" type="submit">
                                        RemoveCurrentSplitDATA
                                    </Button>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => goThroughAllOpen()} variant="danger" type="submit">
                                        goThroughAllOpen
                                    </Button>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => newPaymentMethod2()} variant="danger" type="submit"> newPaymentMethod2 </Button>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => deleteOldInvoices()} variant="danger" type="submit"> Delete-old-invoices </Button>
                                </Col>
                                <Col>
                                    <h4 style={{ borderBottom: 'inset' }}>Customers</h4>

                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => setCustomerEmptyFields()} variant="danger" type="submit">
                                        Set empty fields
                                    </Button>
                                    <Button style={{ fontSize: "20px", margin: "10px" }} onClick={() => giveAllVehiclesID()} variant="danger" type="submit">
                                        giveAllVehiclesID
                                    </Button>

                                </Col>


                            </Row>
                        </Card.Body>
                    </Card> : pwSection(2)}
                </Col>
            </Row>


            {modals.parts ?
                <PartsModal
                    list_stock_parts={false}
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, parts: false })}
                    show={modals.parts}
                    addPart={(newPart) => addPart(newPart)}
                />
                : null}

            {modals.shop ?
                <ShopModal
                    shop={shopInfo}
                    useEventListener={useEventListener}
                    close={() => setModals({ ...modals, shop: false })}
                    show={modals.shop}
                />
                : null}
        </div>
    )

}