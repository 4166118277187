import React, { useState/* , useEffect */ } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as Constants from '../../../constants/constants'

export default function EditItemModal(props) {
    const show = props.show
    const item = props.item
    const type = props.type
    const employeeList = props.employeeList
    const [itemInfo, setItemInfo] = useState(item)
    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10") {
            //console.log("f10 saving change", itemInfo)
            props.saveChange(itemInfo)
            event.preventDefault()
        }
    }

    if (item == null)
        return (<div></div>)

    function getDistributor() {
        //console.log(itemInfo);
        const currentOption = Constants.distributors.find(option => (option.code === itemInfo.distributor));
        //console.log("currentOption:", currentOption)
        if (!currentOption)
            return ({
                label: itemInfo.distributor

            })
        return currentOption


    }
    function handleChange_distributor(newValue, actionMeta) {
        console.group('-- Value Changed Distributor -- ');
        console.log(newValue);
        newValue.label = newValue.label.toUpperCase()
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue.__isNew__)
            setItemInfo({ ...itemInfo, distributor: newValue.label.toUpperCase() })
        else
            setItemInfo({ ...itemInfo, distributor: newValue.code })


    }

    function getEmployee() {

        const currentOption = employeeList.find(option => (option.EmployeeID === itemInfo.employeeID.substr(itemInfo.employeeID.length - 3)));
        if (currentOption)
            return {
                value: currentOption.employeeID,
                label: currentOption.EmployeeName
            }
    }

    var header, body

    if (type == null)
        return <div> </div>
    else if (type === 'Notes') {
        header = "Edit Note"
        body = (
            <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows="4"
                    defaultValue={itemInfo.description}
                    onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })} />
            </Form.Group>
        )
    }
    else if (type === 'Labor') {
        header = "Edit Labor";
        body = (
            <div>
                <Form.Group as={Row} >
                    <Form.Label column sm={3}>
                        Employee
                            </Form.Label>
                    <Col sm={6}>
                        <Select
                            value={(getEmployee())}
                            onChange={e => { setItemInfo({ ...itemInfo, employeeID: e.value }) }}
                            options={employeeList.map((item, key) => {
                                return {
                                    value: item.EmployeeID,
                                    label: item.EmployeeName,
                                }
                            })
                            }
                        />
                    </Col>

                    <Form.Label column sm={3}></Form.Label>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm={3}>
                        Hours
                    </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            defaultValue={itemInfo.amount}
                            onChange={(e) => { setItemInfo({ ...itemInfo, amount: parseFloat(e.target.value), total: (itemInfo.rate * parseFloat(e.target.value))?.toFixed(2) }) }} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm={3}>
                        Shop Rate
                            </Form.Label>
                    <Col sm={4}>
                        <Form.Control disabled
                            value={itemInfo.rate ? itemInfo.rate?.toFixed(2) : "125.00"}
                            onChange={(e) => { setItemInfo({ ...itemInfo, rate: parseFloat(e.target.value), total: parseFloat(e.target.value) * itemInfo.amount }) }} />
                    </Col>
                    <Form.Label column sm={5}>

                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} >
                    <Form.Label column sm={3}>
                        Total Labor
                            </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, total: e.target.value })}
                            value={itemInfo.total ? itemInfo.total : ""} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="4"
                        defaultValue={itemInfo.description}
                        onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })} />
                </Form.Group>
            </div>
        )
    }
    else if (type === 'Parts') {
        header = "Edit Part";
        body = (
            <div>
                <Form.Row>
                    <Form.Group as={Col} >
                        <Form.Label>
                            Part Number
                            </Form.Label>
                        <Form.Control defaultValue={itemInfo.partNumber}
                            onChange={(e) => { setItemInfo({ ...itemInfo, partNumber: e.target.value.toUpperCase() }) }} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={8} >
                        <Form.Label >
                            Part description
                            </Form.Label>

                        <Form.Control
                            defaultValue={itemInfo.description}
                            onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })}
                        />
                    </Form.Group>
                    <Form.Group as={Col}  >
                        <Form.Label >
                            Distributor
                    </Form.Label>
                        <CreatableSelect
                            value={(getDistributor())}
                            formatCreateLabel={(str) => "new distributor: " + str.toUpperCase()}
                            onChange={handleChange_distributor}
                            options={Constants.distributors}
                        />
                    </Form.Group>
                </Form.Row>




                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Quantity
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control required

                            value={itemInfo.quantity}
                            onChange={(e) => setItemInfo({ ...itemInfo, quantity: e.target.value })} />
                    </Col>
                    <Form.Label column sm={5}>

                    </Form.Label>
                </Form.Group>
                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Cost
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, cost: e.target.value, list: parseFloat(e.target.value * 1.95)?.toFixed(2) })}
                            value={itemInfo.cost} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        List
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, list: e.target.value })}
                            value={itemInfo.list} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>

            </div>
        )
    }
    else if (type === 'Credits') {
        header = "Edit Credit";
        body = (
            <div>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                    defaultValue={itemInfo.amount?.toFixed(2)}
                    onChange={(e) => setItemInfo({ ...itemInfo, amount: parseFloat(e.target.value) })} />
                <br />
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows="4"
                    defaultValue={itemInfo.description}
                    onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })} />
            </div>
        )
    }
    else if (type === 'Waste') {
        header = "Edit Waste";
        body = (
            <div>
                <Form.Group as={Row} bsPrefix="smallMargin" >
                    <Form.Label column sm={4}>
                        Vendor
                        </Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            /* ref={inputRef} */
                            defaultValue={itemInfo.vendor}
                            onChange={(e) => { setItemInfo({ ...itemInfo, vendor: e.target.value.toUpperCase() }) }} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>

                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Vendor Invoice #
                        </Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            defaultValue={itemInfo.invoiceNumber}
                            onChange={(e) => setItemInfo({ ...itemInfo, invoiceNumber: e.target.value })}
                        />                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>



                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Cost
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, cost: e.target.value })}
                            value={itemInfo.cost} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        List
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, list: e.target.value })}
                            value={itemInfo.list} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="4"
                        defaultValue={itemInfo.description}
                        onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })} />
                </Form.Group>
            </div>
        )
    }
    else if (type === 'Sublets') {
        header = "Edit Sublet";
        body = (
            <div>
                <Form.Group as={Row} bsPrefix="smallMargin" >
                    <Form.Label column sm={4}>
                        Vendor
                        </Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            /* ref={inputRef} */
                            defaultValue={itemInfo.vendor}
                            onChange={(e) => { setItemInfo({ ...itemInfo, vendor: e.target.value.toUpperCase() }) }} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>

                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Vendor Invoice #
                        </Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            defaultValue={itemInfo.invoiceNumber}
                            onChange={(e) => setItemInfo({ ...itemInfo, invoiceNumber: e.target.value })}
                        />                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>



                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        Cost
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, cost: e.target.value })}
                            value={itemInfo.cost} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group as={Row} bsPrefix="smallMargin"  >
                    <Form.Label column sm={4}>
                        List
                        </Form.Label>
                    <Col sm={4}>
                        <Form.Control
                            onChange={(e) => setItemInfo({ ...itemInfo, list: e.target.value })}
                            value={itemInfo.list} />
                    </Col>
                    <Form.Label column sm={5}></Form.Label>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="4"
                        defaultValue={itemInfo.description}
                        onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value.toUpperCase() })} />
                </Form.Group>
            </div>
        )
    }
    else
        console.log("unhandled type - edititem-EditItemModal.js:x18271")

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    {body}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                
                {header === "Edit Part" ?
                <>
                <Button variant="danger" onClick={props.remove.bind(this, itemInfo)}>
                    Remove
                </Button>
                    <Button style={{  marginRight: "auto" }} variant="danger" onClick={props.removeReturn.bind(this, itemInfo)}>
                        Remove & Return
                        </Button>
                </>
                    : 
                    <Button style={{ position:"absolute", left:"10px" }} variant="danger" onClick={props.remove.bind(this, itemInfo)}>
                    Remove
                </Button>
                }
                <Button variant="secondary" onClick={props.close}>
                    Cancel
                    </Button>
                <Button variant="primary" onClick={props.saveChange.bind(this, itemInfo)}>Submit form</Button>
            </Modal.Footer>
        </Modal>
    )

}
