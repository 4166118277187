import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants.js';

export default function PaymentModal(props) {
    const show = props.show
    const inputRef = useRef();

    const [method, setMethod] = useState({
        value: 0
    })


    props.useEventListener('keydown', myKeyPress);
    function myKeyPress(event) {
        if (event.key === "F10" && method.value !== 0) {
            props.submit(method)
            event.preventDefault()
        }
    }

    function getMethod(checkNew) {
        if (!checkNew) {
            var currentOption = Constants.paymentMethods.find(option => option.value === method.value);

            return currentOption
        }
    }

    useEffect(() => {
        inputRef.current.focus();
    }, [])
    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal"  >
        <Modal.Header closeButton>
            <Modal.Title>Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form >
                


            <Select
            ref={inputRef}
                value={(getMethod())}
                onChange={e => { setMethod({ type: e.label }) }}
                options={Constants.paymentMethods}
            />
               
             <br/>

             <Button style={{ marginLeft: "10px" }} disabled={method.value !== 0 ? false : true} variant="primary" onClick={props.submit.bind(this, method)}>
                    OK
                    </Button>
            
                <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                    Cancel
                </Button>
            </Form>
        </Modal.Body>
    </Modal>
    )

}