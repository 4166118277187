import React from 'react';
import { Card } from 'react-bootstrap';
import * as Constants from '../../constants/constants'
export default function JobCard(props) {
    const jobInfo = props.job;

    function MyItem() {
        let date = new Date(jobInfo.ScheduledDate)
        let today = new Date()
        date.setHours(0, 0, 0, 0)
        today.setHours(0, 0, 0, 0)
        if (date.getTime() === today.getTime()) {
            return (
                <p style={{
                    fontSize: "20px",
                    float: "right",
                    marginTop: "-10px",
                    marginRight: "-10px"
                }}>
                    {Constants.getTime(jobInfo.ScheduledDate)}
                </p>)
        }
        else {
            return (
                <>
                    <p style={{
                        fontSize: "20px",
                        float: "right",
                        marginTop: "-15px",
                        marginRight: "-10px"
                    }}>
                        {Constants.getDate(jobInfo.ScheduledDate)}<br/>
                        {Constants.getTime(jobInfo.ScheduledDate)} 
                    </p>

                </>
            )
        }

    }
    return (
        <Card key={jobInfo._id} className={props.from === "invoice_screen" ? "jobcard_2" : "jobcard_jobs"} onClick={props.onClick} style={jobInfo.Waiting ? {boxShadow:"0 0 0 0.2rem rgb(38 143 255 / 50%)"} : {}} >

            <Card.Body>
                {jobInfo.Type === "APPOINTMENT" ?
                        <MyItem />
                    :
                    jobInfo.Status === "OPEN" ? <span className="yellowdot"></span> :
                        jobInfo.Status === "STARTED" ? <span className="greendot"></span> :
                            jobInfo.Status === "FINISHED" ? <span className="bluedot"></span> :
                                jobInfo.Status === "NEEDS PARTS" ? <span className="reddot"></span> :
                                    jobInfo.Status === "NEEDS APPROVAL" ? <span className="purpdot"></span> :
                                        <span className="blackdot"></span>
                }

                <Card.Title>
                    {jobInfo.Lastname}, {jobInfo.Firstname}
                    <br />
                    {/* <i className="fas fa-phone-alt"></i>  */}  {jobInfo.Phone}
                    <br />
                    {/*  <i className="fas fa-car"></i> */}    {jobInfo.Vehicle}
                </Card.Title>
                <Card.Text style={{ whiteSpace: "pre-line" }}>
                    • {jobInfo.Description.replace(/\n/g, "\n• ")}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}