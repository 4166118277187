import React, { useState, useEffect, useRef } from 'react';
import { Row, Form, Col, Button, Card, Container, Dropdown } from 'react-bootstrap';
import ChargesList from "../invoice_screen/ChargesList.js"
import * as Constants from './../../constants/constants.js';
import axios from 'axios';
import Loading from '../../global_components/Loading'
import Print from '../../global_components/Print.js'
import JobCard from "../jobs_screen/JobCard.js"
import EditJobModal from "../jobs_screen/Modals/EditJobModal.js"

export default function SearchScreen(props) {
    const from = props.from
    const info = props.info
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const inputRef = useRef();
    const [print, setPrint] = useState(false)
    const [jobInfo, setJobInfo] = useState([])
    const [showEditJobModal, setShowEditJobModal] = useState({ show: false, job: null })

    const [search, setSearch] = useState({
        license: '',
        lastname: '',
        phone: '',
        year: '',
        make: '',
        model: '',
        vin: ''
    })
    const [searchJob, setSearchJob] = useState({
        lastname: '',
        phone: '',
    })
    const [invoiceInfo, setInvoiceInfo] = useState({
        list: [], index: 0, current:
        {
            Workorder: {
                WorkorderNumber: "",
                Status: "",
                Type: "",
                Pricing: ""
            },
            Totals: {
                DateOpen: "",
                DateClosed: '',
                DatePaid: '',
                PartsCost: '',
                PartsSubtotal: '',
                LaborSubtotal: '',
                WorkorderTotal: '',
                PaymentMethod: ''
            },
            Customer: {
                CustomerNumber: '',
                Lastname: '',
                City: '',
                State: '',
                Phone1: '',
                Phone2: '',
                Phone3: '',
                Salesperson: ''
            },
            Vehicle: {
                Year: '',
                Make: '',
                Model: '',
                LicenseNumber: '',
                State: '',
                Mileage: '',
                Date: '',
                OrigMileage: '',
                OrigDate: ''
            },
            Parts: [],
            Labor: [],
            Sublets: [],
            Waste: [],
            Credits: [],
            Notes: [],
            'Check List': [],
            parts: [],
            labors: [],
            notes: [],
            sublets: [],
            credits: []
        }
    })
    const [limit, setLimit] = useState(50)

    function myKeyPress(event) {
        if (event.key === "F10") {
            searchInvoices()
            event.preventDefault()
        }
        else if (event.key === "ArrowLeft") {
            goToPrevious()
            event.preventDefault()
        }
        else if (event.key === "ArrowRight") {
            goToNext()
            event.preventDefault()
        }
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    function goToNext() {
        if (invoiceInfo.index + 1 < invoiceInfo.list.length) {
            let i = invoiceInfo.index
            setInvoiceInfo({ ...invoiceInfo, index: (invoiceInfo.index + 1), current: (invoiceInfo.list[i + 1]) })
            setCount(count + 1)
        }

    }

    async function searchInvoices() {
        console.log("testing search_search_invoices SEARCH SCREEN: ", search, 'from:', from)
        setLoading(true)

        try {
            var res_data
            if (from === "invoice_screen" && count === 0)
                res_data = await axios.post(`${Constants.port}/invoices/search`,
                    {
                        license: info.Vehicle.LicenseNumber,
                        lastname: info.Customer.Lastname,
                        phone: '',
                        year: '',
                        make: '',
                        model: '',
                        vin: '',
                        limit: limit
                    });
            else
                res_data = await axios.post(`${Constants.port}/invoices/search`, Object.assign(search, { limit: limit }));
            console.log('fetching data: ', res_data);
            if (res_data.data.response.length > 0)
                setInvoiceInfo({ list: res_data.data.response, index: 0, current: res_data.data.response[0] })
            else
                setInvoiceInfo({
                    list: [], index: 0, current: {
                        Workorder: {
                            WorkorderNumber: "",
                            Status: "",
                            Type: "",
                            Pricing: ""
                        },
                        Totals: {
                            DateOpen: "",
                            DateClosed: '',
                            DatePaid: '',
                            PartsCost: '',
                            PartsSubtotal: '',
                            LaborSubtotal: '',
                            WorkorderTotal: '',
                            PaymentMethod: ''
                        },
                        Customer: {
                            CustomerNumber: '',
                            Lastname: '',
                            City: '',
                            State: '',
                            Phone1: '',
                            Phone2: '',
                            Phone3: '',
                            Salesperson: ''
                        },
                        Vehicle: {
                            Year: '',
                            Make: '',
                            Model: '',
                            LicenseNumber: '',
                            State: '',
                            Mileage: '',
                            Date: '',
                            OrigMileage: '',
                            OrigDate: ''
                        },
                        Parts: [],
                        Labor: [],
                        Sublets: [],
                        Waste: [],
                        Credits: [],
                        Notes: [],
                        'Check List': [],
                        parts: [],
                        labors: [],
                        notes: [],
                        sublets: [],
                        credits: []
                    }
                })
            setLoading(false)
            //console.log("testing WO: ", res_data.data.response[0])
        } catch (error) {
            setLoading(false)
            console.log("SearchScreen:searchInvoices(), Error searching invoices. ")
            if (error.response) {
                console.log(error.response.data)
                //props.setError({show: true, message: error.response.data.error})
            }

        }
        setCount(count + 1)
    }

    async function getJobData() {
        console.log("getting jobs...", searchJob)
        try {
            const res_data = await axios.post(`${Constants.port}/jobs/search`, searchJob);
            setJobInfo(res_data.data.response)
            return res_data
        } catch (error) {
            console.log("Error - xbas67q12")
        }


    }

    function goToPrevious() {
        if (invoiceInfo.index - 1 >= 0) {
            let i = invoiceInfo.index
            setInvoiceInfo({ ...invoiceInfo, index: (invoiceInfo.index - 1), current: (invoiceInfo.list[i - 1]) })
        }
    }

    async function openWO(job) {
        console.log(job)
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/invoices/${job.WorkorderNumber}/getInvoiceByWONumber`);
            console.log('fetching dat2a: ', res_data.data.invoice._id);
            props.history.push(`/invoices/${res_data.data.invoice._id}`)
            setLoading(false)

        } catch (error) {
            console.log("Error - aslx71", error)
            if (error.response)
                props.setError({ show: true, message: error.response.data + "\n" + error.response.status + "\n" + error.response.headers })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: "Error getting invoices/:id/getInvoiceByWONumbers, errno: as1x71" })

        }
    }

    function selectVehicleFromHistory(){
        props.selectVehicleFromSearch(invoiceInfo.current)
    }

    async function getInvoiceInfoByID(id){
        try {
            setLoading(true)
            const res_data = await axios.get(`${Constants.port}/invoices/${id}/getInvoiceByID`);
            console.log('fetching data getInvoiceData: ', res_data);
            setInvoiceInfo({ list: [], index: 0, current: res_data.data.invoice })
            setLoading(false)
        } catch (error) {
            console.log("Error getting invoice information, id:", id, "\nErrNo: GJHMJFDS", error)
            setLoading(false)

        }

    }

    useEffect(() => {
        if (from === "invoice_screen") {
            setSearch({
                ...search,
                license: info.Vehicle.LicenseNumber,
                lastname: info.Customer.Lastname
            });
            searchInvoices()
        }
        else if (from === "report_screen") {
            setInvoiceInfo({ list: [props.info], index: 0, current: props.info })
        }
        else if (from === "database_screen") {
            getInvoiceInfoByID(props.info)
        }
        else {
            inputRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEventListener('keydown', myKeyPress);

    return (

        <div style={{ padding: "25px" }}>
            <Card className="search_screen_card">
                {!loading ? null /* : from === "invoice_screen" ? null */ : <Loading />}

                <Card.Header style={{ fontSize: "27px", paddingRight: "10px" }}>
                    <Row /* noGutters */>
                        <Col >
                            <Row style={{ marginLeft: "1px" }}>
                                <Form.Label style={{ fontSize: "30px" }} >
                                    {invoiceInfo.current.Customer.Lastname ? `${invoiceInfo.current.Customer.Lastname},` : ""}
                                </Form.Label>
                            </Row>
                            <Row style={{ marginLeft: "10px", marginTop: "-15px" }}>
                                <Form.Label style={{ fontSize: "15px" }} >
                                    {invoiceInfo.current.Customer.Firstname ? invoiceInfo.current.Customer.Firstname : ""}
                                </Form.Label>
                            </Row>
                            <Row style={{ marginLeft: "10px", fontSize: "16px" }}>
                                WO: {invoiceInfo.current.Workorder.WorkorderNumber}
                            </Row>
                        </Col>
                        <Col /* xs={2} */>
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone1: {Constants.phoneFormatter(invoiceInfo.current.Customer.Phone1)} </Col>
                            </Row >
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone2: {Constants.phoneFormatter(invoiceInfo.current.Customer.Phone2)} </Col>
                            </Row >
                            <Row style={{ fontSize: "15px" }} noGutters>{/* <MDBIcon style={{ margin: "6px" }} icon="phone" /> */}
                                <Col style={{/* margin:"auto", */ marginRight: "-45px" }}> Phone3: {Constants.phoneFormatter(invoiceInfo.current.Customer.Phone3)} </Col>
                            </Row >
                        </Col>
                        <Col >
                            <Row style={{ fontSize: "15px" }}>{/* <MDBIcon style={{ margin: "6px" }} icon="car-alt" /> */}
                                {invoiceInfo.current.Vehicle ? invoiceInfo.current.Vehicle.Year : ""} {' '}
                                {invoiceInfo.current.Vehicle ? invoiceInfo.current.Vehicle.Make : ""} {' '}
                                {invoiceInfo.current.Vehicle ? invoiceInfo.current.Vehicle.Model : ""} {' '}
                                {invoiceInfo.current.Vehicle ? invoiceInfo.current.Vehicle.EngineSize : ""} </Row>
                            <Row style={{ fontSize: "15px" }}>{invoiceInfo.current.Vehicle ? invoiceInfo.current.Vehicle.Mileage ? invoiceInfo.current.Vehicle.Mileage.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "" : ""}</Row>
                            <Row style={{ fontSize: "15px" }}> {invoiceInfo.current.Vehicle.State ? `${invoiceInfo.current.Vehicle.State}: ` : null}{invoiceInfo.current.Vehicle.LicenseNumber}</Row>
                            <Row style={{ fontSize: "15px" }}> {invoiceInfo.current.Vehicle.VIN}</Row>
                        </Col>
                        <Col xs={2} style={{ fontSize: "15px" }}>
                            <Row noGutters>
                                <Col> Date Opened: </Col>
                                <Col> {Constants.dateFormatter(invoiceInfo.current.Totals.DateOpen)} </Col>
                            </Row>
                            <Row noGutters>
                                <Col> Status: </Col>
                                <Col> {invoiceInfo.current.Workorder.Status} </Col>
                            </Row>
                            <Row noGutters >
                                <Col> Balance: </Col>
                                <Col> {invoiceInfo.current.Totals.Balance ? invoiceInfo.current.Totals.Balance.toFixed(2) : "0.00"} </Col>
                            </Row>
                            <Row noGutters>
                                <Col> Tax: </Col>
                                <Col> {invoiceInfo.current.Totals.WorkorderTax ? invoiceInfo.current.Totals.WorkorderTax.toFixed(2) : "0.00"} </Col>
                            </Row>
                        </Col>
                        {from !== "report_screen" ?
                            <Col xs={2}>
                                <Row style={{ justifyContent: "center" }}>
                                    <Button disabled={invoiceInfo.index === 0} onClick={() => goToPrevious()}
                                        style={{ margin: "5px", }}>
                                        Previous
                            </Button>

                                    <Button disabled={invoiceInfo.index + 1 >= invoiceInfo.list.length ? true : false}
                                        onClick={() => goToNext()}
                                        style={{ margin: "5px", }}>
                                        Next
                            </Button>
                                </Row>
                                <Row style={{ justifyContent: "center" }}>

                                    <Button

                                        style={{ margin: "5px", width: "40%", fontSize: "14px" }}
                                        variant="info" onClick={() => setPrint(true)}>
                                        Print
                            </Button>
                                    {print ?
                                        <Print
                                            searching={true}
                                            close={() => setPrint(false)}
                                            useEventListener={useEventListener}
                                            submit={(e) => e.preventDefault()}
                                            invoiceInfo={invoiceInfo.current}
                                            payments={invoiceInfo.current.Payments} >
                                        </Print> : null}

                                </Row>


                            </Col>
                            : null}
                    </Row>
                </Card.Header>

                <Container style={{ minWidth: "100%", minHeight: "100%" }}>
                    {from !== "report_screen" ?
                        <Row style={{ margin: "15px" }}>
                            <Form >
                                <Row>
                                    <Col xs={1}>
                                        <Button onClick={() => searchInvoices()}> Search </Button>
                                    </Col>
                                    <Col>
                                        <Form.Control ref={inputRef}
                                            value={search.lastname ? search.lastname : ''}
                                            onChange={(e) => setSearch({ ...search, lastname: e.target.value })}
                                            placeholder="Lastname" />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={search.license ? search.license : ""}
                                            onChange={(e) => setSearch({ ...search, license: e.target.value })}
                                            placeholder="License" />
                                    </Col>
                                    <Col>
                                        <Form.Control

                                            value={search.phone ? search.phone : ""}
                                            onChange={(e) => setSearch({ ...search, phone: e.target.value })}
                                            placeholder="Phone" />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={search.year ? search.year : ""}
                                            onChange={(e) => setSearch({ ...search, year: e.target.value })}
                                            placeholder="Year" />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={search.make ? search.make : ""}
                                            onChange={(e) => setSearch({ ...search, make: e.target.value })}
                                            placeholder="Make" />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            value={search.model ? search.model : ""}
                                            onChange={(e) => setSearch({ ...search, model: e.target.value })}
                                            placeholder="Model" />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                        disabled
                                            value={search.vin ? search.vin : ""}
                                            onChange={(e) => setSearch({ ...search, vin: e.target.value })}
                                            placeholder="VIN" />
                                    </Col>
                                    {/* <div> */}
                                    <Col xs={1}>
                                        <Dropdown >
                                            {/* <Form.Label style={{marginRight:"10px"}}> Limit # of results</Form.Label> */}
                                            <Dropdown.Toggle variant="secondary">{limit !== Constants.MAX_SAFE_INTEGER ? limit : '∞'}</Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                <Dropdown.Item onClick={() => setLimit(50)} >50</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setLimit(200)}>200</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setLimit(Constants.MAX_SAFE_INTEGER)}>∞</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </Col>
                                    <Col xs={1}>
                                        <Button variant="secondary" href={`/invoices/${invoiceInfo.current._id}`} disabled={!invoiceInfo.current._id}>
                                            Open
                                        </Button>
                                    </Col>
                                    {from === "invoice_screen" ? 
                                    <Col xs={2}>
                                        <Button variant="danger" onClick={()=>selectVehicleFromHistory()}/* href={`/invoices/${invoiceInfo.current._id}`} */ disabled={!invoiceInfo.current.Vehicle._id}>
                                            Select Vehicle
                                        </Button>
                                    </Col>
                                    : null }
                                    {/* </div> */}
                                </Row>
                            </Form>
                        </Row>
                        : <div style={{ margin: "15px" }}></div>}
                    <Row style={{ marginBottom: "15px", height: "80%" }}>
                        <Col>

                            <Container className="invoice-notes" >
                                {<ChargesList
                                    from='search'
                                    disabled={true}
                                    waste={invoiceInfo.current.Waste}
                                    payments={invoiceInfo.current.Payments}
                                    sublets={invoiceInfo.current.Sublets}
                                    totals={invoiceInfo.current.Totals}
                                    list={invoiceInfo.current.Labor}
                                    credits={invoiceInfo.current.Credits}
                                    notes={invoiceInfo.current.Notes}
                                    parts={invoiceInfo.current.Parts}
                                />
                                }
                            </Container>

                        </Col>
                    </Row>
                </Container>

            </Card>
            <p style={{ position: "absolute", right: "10px" }}>Search matched {invoiceInfo.list.length} invoices (Limit {limit !== Constants.MAX_SAFE_INTEGER ? limit : '∞'})</p>
            
            {!from ?
                <div>
                    <br />
                    <h1 style={{ borderBottom: "groove" }}>Job Search</h1>

                    <Row style={{ margin: "15px" }}>
                        <Form >
                            <Row>
                                <Col >
                                    <Button disabled={searchJob.lastname === "" && searchJob.phone === ""} onClick={() => getJobData()}> Search </Button>
                                </Col>
                                <Col>
                                    <Form.Control 
                                        value={searchJob.lastname ? searchJob.lastname : ''}
                                        onChange={(e) => setSearchJob({ ...searchJob, lastname: e.target.value })}
                                        placeholder="Lastname" />
                                </Col>
                                <Col>
                                    <Form.Control

                                        value={searchJob.phone ? searchJob.phone : ""}
                                        onChange={(e) => setSearchJob({ ...searchJob, phone: e.target.value })}
                                        placeholder="Phone" />
                                </Col>

                            </Row>
                        </Form>
                    </Row>
                    <Row>
                    {jobInfo.length > 0 ?
                        jobInfo.map((item, key) => {
                            return (
                                <div style={{ marginBottom: "10px" }} key={key}>
                                    <JobCard
                                        from="search_screen"
                                        job={item}
                                        onClick={() => setShowEditJobModal({show: true, job: jobInfo[key]})}
                                    />
                                </div>)
                        })

                        : null}
                    </Row>
                </div>
                : null}
            {showEditJobModal.show ?
                <EditJobModal
                    show={showEditJobModal.show}
                    jobInfo={showEditJobModal.job}
                    from="search_screen"
                    save={() => null}
                    /* remove={(job) => { removeJob(job); setShowEditJobModal({ show: false, job: null }) }} */
                    close={() => setShowEditJobModal({ show: false, job: null })}
                    /* createWO={(job) => createWO_blank(job)} */
                    openWO={(job) => openWO(job)}
                />
                : null}
        </div>


    );

}