import React, { useState, useEffect, useRef } from 'react';
import {  Form, Col, Button, Modal } from 'react-bootstrap';


export default function EditItemModal(props) {
    const show = props.show;
    const [itemInfo, setItemInfo] = useState(props.item)

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.update(itemInfo)
            event.preventDefault()
        }
    }

    function useEventListener(eventName, handler, element = window) {
        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    useEventListener('keydown', myKeyPress);

    return (
        <Modal show={show} onHide={props.close} onKeyPress={(event) => myKeyPress(event)} className="invoice_item_modal">

            <Modal.Header closeButton>
                <Modal.Title>Edit an Item</Modal.Title>
            </Modal.Header>


            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} >
                            <Form.Label>Part Number</Form.Label>
                            {/* <CreatableSelect
                                    onChange={e=>{setName({...name, lastname: e.value})}}
                                    onCreateOption={handleCreate}
                                    options={nameList.list} 
                                    value={getLastNames()}
                                    /> */}
                            <Form.Control ref={inputRef} defaultValue={itemInfo.PartNumber} onChange={(e) => setItemInfo({ ...itemInfo, PartNumber: e.target.value })} />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Description</Form.Label>
                            <Form.Control defaultValue={itemInfo.Description }  onChange={(e) => setItemInfo({ ...itemInfo, Description: e.target.value })} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control defaultValue={itemInfo.Quantity}   onChange={(e) => setItemInfo({ ...itemInfo, Quantity: e.target.value })} />
                    <Form.Label>Cost</Form.Label>
                    <Form.Control defaultValue={itemInfo.Cost}  onChange={(e) => setItemInfo({ ...itemInfo, Cost: e.target.value })} />
                    <Form.Label>List</Form.Label>
                    <Form.Control defaultValue={itemInfo.List}  onChange={(e) => setItemInfo({ ...itemInfo, List: e.target.value })} />

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" style={{marginRight:"auto"}} onClick={props.remove.bind(this, itemInfo)}> Remove </Button>
                <Button variant="secondary" onClick={props.close}> Close </Button>
                <Button variant="primary" onClick={props.update.bind(this, itemInfo)}> Save Changes </Button>
            </Modal.Footer>

        </Modal>
    )
}