import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'
import axios from 'axios';


export default function AC_ServiceModal(props) {
    const { show, employeeList } = props
    const [partsList, setPartsList] = useState({ parts: [] })
    const [dye, setDye] = useState(true)
    const [serviceInfo, setServiceInfo] = useState({
        amtRecovered: 0, 
        amtCharged: 0, 
        difference:0, 
        comments: "" })

    const [freon, setFreon] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })

    /* Should this be hard-coded or retrieve from canned items? */
    const [laborCharge, setLaborCharge] = useState({
        employeeID: "",
        description: "EVACUATE AND RECHARGE A/C. VISUAL CHECK FOR LEAKS. ",
        amount: .5, 
        rate: Constants.shop_information.LaborRate,
        total: 62.5
    })

    const [error, setError] = useState("")
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            submitAddService()
            event.preventDefault()
        } else if (event.key === "Escape") {
            props.close()
            event.preventDefault()
        }
    }

    function getEmployee(checkNew) {
        if (!checkNew) {
            const currentOption = employeeList.find(option => option.value === laborCharge.employeeID);
            return currentOption
        }
    }

    function submitAddService(){
        console.log("recovered",serviceInfo.amtRecovered," charged:", serviceInfo.amtCharged);

        if(!freon.partNumber){
            setError("You must select a freon type")
            return
        }
        if(!serviceInfo.amtRecovered){
            setError("You must enter amount recovered")
            return
        }
        if(!serviceInfo.amtCharged){
            setError("You must enter amount charged")
            return
        }
        
        const description = laborCharge.description + "\nRECOVERED " + serviceInfo.amtRecovered + "LBS, TOTAL CAPACITY " + serviceInfo.amtCharged + "LBS."
        laborCharge.description = description
        console.log("adding service...", freon, serviceInfo)
        props.addACService({freon,employee: laborCharge, dye})
    }
    
    async function getParts() {
        try {
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data getParts: ', res_data);
            setPartsList({ parts: res_data.data.inventory })
        } catch (error) {
            console.log("Error - asdb1123a", error)
        }
    }

    useEffect(() => {
        inputRef.current.focus();
        getParts()
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Select A/C Service Info.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setLaborCharge({...laborCharge, employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,    
                                        }
                                    })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Type
                        </Form.Label>
                        <Col sm={6}>
                            <Select
                                /* value={(getPart())} */
                                onChange={e => { setFreon({ ...freon, partNumber: e.label, description: e.value, /* quantity: 0, */ cost: e.cost, list: e.list }) }}
                                options={partsList.parts.filter((item) => {
                                    return (item.Description).includes("FREON")
                                    }).map((item)=>{
                                        return {
                                            value: item.Description,
                                            label: item.PartNumber,
                                            cost: item.Cost,
                                            list: item.List
                                        }
                                    })
                                }
                            />
                        </Col>
                        <Col sm={3} style={{marginTop:"auto"}}>
                        <Form.Check
                            style={{/* marginRight:"30px" */ marginTop:"auto"}}
                            defaultChecked={dye}
                            onChange={(e)=>setDye(e.target.checked)}
                            inline
                            name="group1"
                            type="checkbox"
                            id={`inline-checkbox-1`}
                        />
                         <Form.Label   style={{marginRight:"-20px"}}>Dye</Form.Label>
                        </Col>
                        <Form.Label column sm={5}>
                            
                        </Form.Label>
                    </Form.Group>
                
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Recovered
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required
                                value={serviceInfo.amtRecovered}
                                onChange={(e) => {
                                    setServiceInfo({ ...serviceInfo, 
                                        amtRecovered: e.target.value, 
                                        difference: Constants.round_and_format(serviceInfo.amtCharged-e.target.value) });
                                    setFreon({...freon, quantity: Constants.round_and_format(serviceInfo.amtCharged-e.target.value) });
                                    }
                            }
                                 />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Charged
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required
                                value={serviceInfo.amtCharged}
                                onChange={(e) => {
                                    setServiceInfo({ ...serviceInfo, 
                                        amtCharged: e.target.value, 
                                        difference: Constants.round_and_format(e.target.value-serviceInfo.amtRecovered) });
                                    setFreon({...freon, quantity: Constants.round_and_format(e.target.value-serviceInfo.amtRecovered)})
                                }
                                    } />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Difference
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required
                                value={serviceInfo.difference}
                                onChange={(e) => {
                                    setServiceInfo({ ...serviceInfo, difference: e.target.value });
                                    setFreon({...freon, quantity: e.target.value});
                                    }} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Comments
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control as="textarea" rows="4"
                                defaultValue={laborCharge.description}
                                onChange={(e) => setLaborCharge({ ...laborCharge, description: e.target.value.toUpperCase() })} />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    
                    <br />
                    <Button variant="primary" onClick={()=>submitAddService()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
                
            </Modal.Body>
        </Modal>
    )

}