import React, { useState/* , useEffect  */ } from 'react';
import { Row, Form, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'
import CreatableSelect from 'react-select/creatable';


export default function AddVehicleModal(props) {
    const { show } = props
    props.useEventListener('keydown', myKeyPress);

    const [vehicleInfo, setVehicleInfo] = useState({ 
        LicenseNumber: "", 
        State: "", 
        VIN: "", 
        Mileage: "", 
        Transmission: "", 
        Engine: "", 
        Year: "", 
        Make: "", 
        Model: "" 
    });

    function handleCreate(inputValue) {
        console.group('Option created');
        console.log(inputValue)
    };

    function getYear(checkNew) {
        if (!checkNew) {
            const currentOption = Constants.years.find(option => option.value === vehicleInfo.Year);
            return currentOption
        }
    }

    function getMakes(checkNew) {
        if (!checkNew) {
            const currentOption = Constants.makeList.find(option => option.value === vehicleInfo.Make);
            return currentOption
        }
    }

    function getModel(checkNew) {
        if (!checkNew) {
            const currentOption = Constants.makeList.find(option => option.value === vehicleInfo.Make);
            if (currentOption)
                return currentOption.models[vehicleInfo.Model]

            return currentOption
        }
    }

    function getModelOptions(checkNew) {
        if (!checkNew) {
            const currentOption = Constants.makeList.find(option => option.label === vehicleInfo.Make);
            if (currentOption)
                return currentOption.models;
        }
    }

    function myKeyPress(event) {
        
        if (event.key === "F10") {
            props.submitAddVehicle(vehicleInfo)
            event.preventDefault()
        }
    }

    return (
        <Modal
        className="invoice_item_modal"
        show={show}
        onHide={props.close}
        centered
        size='lg'
    >
            <Modal.Header >
                <Modal.Title>Add Vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>


                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Year
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                value={(getYear())}
                                onChange={e => { setVehicleInfo({ ...vehicleInfo, Year: e.label, Make: "", Model: "" }) }}
                                options={Constants.years} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Make
                            </Form.Label>
                        <Col sm={6}>
                            <CreatableSelect
                                onChange={e => { setVehicleInfo({ ...vehicleInfo, Make: e.label, Model: "" }) }}
                                onCreateOption={handleCreate}
                                options={Constants.makeList}
                                value={vehicleInfo.Make === "" ? "" : getMakes()}
                            />
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>
                            Model
                            </Form.Label>
                        <Col sm={6}>
                            <CreatableSelect
                                onChange={e => { setVehicleInfo({ ...vehicleInfo, Model: e.label }) }}
                                onCreateOption={handleCreate}
                                options={getModelOptions()}
                                value={vehicleInfo.Model === "" ? "" : getModel()}
                            />
                        </Col>
                    </Form.Group>

                  {/*   <Form.Group as={Row}>
                        <Form.Label column sm={3}>Mileage</Form.Label>
                        <Col>
                            <Form.Control type="Mileage" placeholder="Enter Mileage" defaultValue={vehicleInfo.Mileage} onChange={(e) => setVehicleInfo({ ...vehicleInfo, Mileage: e.target.value })} />
                        </Col>
                    </Form.Group> */}

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>License</Form.Label>
                        <Col>
                            <Form.Control type="License" onChange={(e) => { setVehicleInfo({ ...vehicleInfo, LicenseNumber: e.target.value }) }} />
                        </Col>

                        <Form.Label column sm={2}>State</Form.Label>
                        <Col>
                            <Form.Control type="State" onChange={(e) => { setVehicleInfo({ ...vehicleInfo, State: e.target.value }) }} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>VIN</Form.Label>
                        <Col>
                            <Form.Control type="VIN" onChange={(e) => { setVehicleInfo({ ...vehicleInfo, VIN: e.target.value }) }} />
                        </Col>
                    </Form.Group>

                  {/*   <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={3}>
                            Engine
                            </Form.Label>
                        <Col sm={9}>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="V4"
                                name="formHorizontalRadios"
                                id="V4"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Engine: e.target.id })}
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="V6"
                                name="formHorizontalRadios"
                                id="V6"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Engine: e.target.id })}
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="V8"
                                name="formHorizontalRadios"
                                id="V8"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Engine: e.target.id })}
                            />
                        </Col>
                    </Form.Group> */}

             {/*        <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={3}>
                            Transmission
                            </Form.Label>
                        <Col sm={9}>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="AWD"
                                name="formHorizontalRadios2"
                                id="AWD"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Transmission: e.target.id })}
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="FWD"
                                name="formHorizontalRadios2"
                                id="FWD"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Transmission: e.target.id })}

                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label="RWD"
                                name="formHorizontalRadios2"
                                id="RWD"
                                onChange={(e) => setVehicleInfo({ ...vehicleInfo, Transmission: e.target.id })}
                            />
                        </Col>
                    </Form.Group>

 */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.submitAddVehicle.bind(this, vehicleInfo)}>Add & Select Vehicle</Button>
                <Button variant="secondary" onClick={props.close}>Cancel</Button>
            </Modal.Footer>
       </Modal>
    )
}