import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

const style_card = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

const ItemTypes = {
    CARD: 'card',
}

const Card = ({ id, item, moveCard, findCard, section }) => {
    console.log("section:", section);
    const originalIndex = findCard(id).index;
    const [/* { handlerId } */, drop] = useDrop({
        accept: ItemTypes.CARD,
        canDrop: () => false,
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id);
                moveCard(draggedId, overIndex);
            }
        }
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, originalIndex }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1;
    console.log("item:", item);
    return (
        <div
            ref={(node) => drag(drop(node))}
            style={{ ...style_card, opacity }}>

            <Row key={id} >
                {section === "labor" ? 
                <Col>{("00" + item.employeeID).substr(item.employeeID.length - 1)} - {item.description}</Col>
                : 
                <Col>{item.partNumber} - {item.description}</Col>

                }
            </Row>

        </div>
    );
};

export const Container = (props) => {
    const [cards, setCards] = useState(props.labors.map((obj, index) => ({ ...obj, id: index })));
    const [, drop] = useDrop({ accept: ItemTypes.CARD });

    const moveCard = (id, atIndex) => {
        const { card, index } = findCard(id);
        setCards(update(cards, {
            $splice: [
                [index, 1],
                [atIndex, 0, card],
            ],
        }));
    };
    const findCard = (id) => {
        const card = cards.filter((c) => `${c.id}` === id)[0];
        return {
            card,
            index: cards.indexOf(card),
        };
    };

    return (<>
        <div ref={drop} >
            <Row>
                <Col style={{ fontSize: "20px", marginLeft: "-10px", marginBottom:"10px" }}>Labor</Col>
            </Row>
            {cards.map((card) =>
                <Card
                    section={props.section}
                    key={card.id}
                    id={`${card.id}`}
                    item={card}
                    moveCard={moveCard}
                    findCard={findCard}
                />
            )}
            <footer style={{marginTop:"30px"}}>
                <Button variant="primary" onClick={props.submit.bind(this, cards)}>Submit</Button>
                <Button variant="secondary" style={{ marginLeft: "10px" }} onClick={props.close}>Close</Button>

            </footer>
        </div>
    </>);
};
