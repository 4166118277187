import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'

export default function LOF_Modal(props) {
    const { show, employeeList } = props
    const [pads, setPads] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })
    const [rotor, setRotor] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })
    const [employee, setEmployee] = useState({
        employeeID: "",
        description: "LUBE OIL FILTER - CHECK AND FILL ALL FLUIDS AND TIRES. RESET MAINTENANCE LIGHT. ",
        amount: .3, 
        rate: Constants.shop_information.LaborRate,
        total: 15
    })
    //const [partsList, setPartsList] = useState({ parts: [] })
    const [error, setError] = useState("")
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.addOilChange({pads,rotor,employee})
            event.preventDefault()
        }
    }

    function getEmployee(checkNew) {
        if (!checkNew) {
            const currentOption = employeeList.find(option => option.value === employee.employeeID);
            return currentOption
        }
    }

    function submitAddBrakejob(){
        console.log("pads",pads,"rotor:", rotor);

        if(!pads.partNumber){
            setError("You must add brake pads")
            return
        }
        else if(!rotor.partNumber){
            setError("You must add rotors")
            return
        }
    
        props.addBrakejob({pads,rotor,employee})
    }
    
    useEffect(() => {
        inputRef.current.focus();
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Select Brake job Info.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setEmployee({...employee, employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,    
                                        }
                                    })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={3}>
                            Pads
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required

                                value={pads.partNumber}
                                onChange={(e) => setPads({ ...pads, partNumber: e.target.value })} />
                        </Col>
                        <Form.Label column sm={5}>

                        </Form.Label>
                    </Form.Group>
              
                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={3}>
                            Rotors
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required
                                value={rotor.partNumber}
                                onChange={(e) => setRotor({ ...rotor, partNumber: e.target.value })} />
                        </Col>
                        <Form.Label column sm={5}>

                        </Form.Label>
                    </Form.Group>
              
                

                    <br />
                    <Button variant="primary" onClick={()=>submitAddBrakejob()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}