import React from 'react';
import { Modal } from 'react-bootstrap';
//import Print from "../Print.js"
import Print from "../../../global_components/Print.js"
export default function PrintModal(props) {
    const show = props.show
    const info = props.info
    const payments = props.payments
/*     info.Totals.DateOpen = Constants.dateFormatter(info.Totals.DateOpen)
    info.Totals.DateClosed = Constants.dateFormatter(info.Totals.DateClosed) */
    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
        <Modal.Header closeButton>
            <Modal.Title>Would you like to print this workorder now?</Modal.Title>
        </Modal.Header>
        <Modal.Body>       
                <Print searching={false} close={props.close} useEventListener={props.useEventListener} 
                    print={props.print} submit={props.submit} invoiceInfo={info} payments={payments} >
                </Print>         
        </Modal.Body>
    </Modal>
    )

}