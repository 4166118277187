import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import * as Constants from '../../../constants/constants'
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';

export default function PartsModal(props) {
    const { show, list_stock_parts } = props
    const [error, setError] = useState("")
    const [part, setPart] = useState({
        markup:1.95,
        markup_type:1,
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: '',
        distributor: ''
    })
    const [partsList, setPartsList] = useState({ parts: [] })
    const [showDistributor, setShowDistributor] = useState(false)
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            checkForValidation()
            event.preventDefault()
        }
        else if (event.key === "Escape") {
            props.close()
            event.preventDefault()
        }
    }

    async function getParts() {
        try {
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data getParts: ', res_data);
            setPartsList({ parts: res_data.data.inventory })
        } catch (error) {
            console.log("Error - asdb1123a", error)
        }
    }

    function handleChange(newValue, actionMeta) {
        console.group('-- Value Changed -- ');
        console.log(newValue);
        newValue.label = newValue.label.toUpperCase()
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue.__isNew__) {
            setPart({ ...part, partNumber: newValue.label.toUpperCase(), description: "", cost: "", list: "" })
            setShowDistributor(true)
        }
        else {
            setPart({ ...part, _id: newValue._id, total_quantity: newValue.total_quantity, partNumber: newValue.label.toUpperCase(), description: newValue.value.toUpperCase(), cost: newValue.cost, list: newValue.list })
            setShowDistributor(false)
        }
    }

    function handleChange_distributor(newValue, actionMeta) {
        console.group('-- Value Changed Distributor -- ');
        console.log(newValue);
        newValue.label = newValue.label.toUpperCase()
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        if (newValue.__isNew__)
            setPart({ ...part, distributor: newValue.label.toUpperCase() })
        else
            setPart({ ...part, distributor: newValue.code })
    }

    function checkForValidation() {
        if (part.partNumber === "")
            setError("You must enter a part number")
        else if (part.description === "")
            setError("You must enter a part description")
        else if (part.quantity === '')
            setError("You must enter a part quantity")
        else if (part.cost === '')
            setError("You must enter a part cost")
        else if (part.list === '')
            setError("You must enter a part list price")
        else if (part.distributor === '' && !(Math.abs(part.total_quantity) >= 0))
            setError("You must enter a distributor")
        else
            props.addPart(part)
    }

    function selectMarkupType(e){
        console.log("select markup type...",e)
        if(e === "dollar_radio")
            setPart({...part, markup_type:2, markup: 0, list: parseFloat(part.cost)?parseFloat(part.cost):0 + 0})
        else{
            const rounded_list = Constants.round_and_format((part.cost * 1.95).toFixed(2))
            setPart({...part, markup_type:1, markup: 1.95, list: rounded_list})
        }
    }

    function updateMarkup(value){
        if(part.markup_type === 2)
            setPart({...part, markup:value, list: parseFloat(value) + parseFloat(part.cost)})
        else
            setPart({ ...part,markup:value, list: parseFloat(value * part.cost).toFixed(2) })
        //setPart({ ...part,markup:e.target.value, list: parseFloat(e.target.value * part.cost).toFixed(2) })
    }

    function updateCost(value){
        if(part.markup_type === 2)
            setPart({...part, cost:value, list: parseFloat(value) + parseFloat(part.markup)})
        else
            setPart({ ...part, cost:value, list: parseFloat(value * part.markup).toFixed(2) })
        //setPart({ ...part, cost: e.target.value, list: parseFloat(e.target.value * part.markup).toFixed(2) })
    }

    useEffect(() => {
        inputRef.current.focus();
        if (list_stock_parts)
            getParts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Add Part</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}
                <Form /* onSubmit={props.addPart.bind(this, part)} */ >
                    <Form.Row>
                        <Form.Group as={Col} xs={10}>
                            <Form.Label >
                                Part Number
                        </Form.Label>
                            {list_stock_parts ? <CreatableSelect
                                ref={inputRef}
                                formatCreateLabel={(str) => "non-stock item: " + str.toUpperCase()}
                                onChange={handleChange}
                                options={partsList.parts.map((item) => {
                                    return {
                                        _id: item._id,
                                        value: item.Description,
                                        label: item.PartNumber,
                                        cost: item.Cost,
                                        list: item.List,
                                        total_quantity: item.Quantity

                                    }
                                })
                                }
                            />
                                : <Form.Control
                                    required
                                    ref={inputRef}
                                    value={part.partNumber}
                                    onChange={(e) => setPart({ ...part, partNumber: e.target.value.toUpperCase() })}
                                />
                            }


                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label >
                                In-Stock
                        </Form.Label>
                            <Form.Control
                                disabled
                                defaultValue={part.total_quantity && parseFloat(part.total_quantity.toFixed(1))}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={8}>

                            <Form.Label>
                                Part description
                        </Form.Label>

                            <Form.Control
                                required
                                value={part.description}
                                onChange={(e) => setPart({ ...part, description: e.target.value.toUpperCase() })}
                            />


                        </Form.Group>
                        {/*  <Form.Label column sm={5}></Form.Label> */}

                        <Form.Group as={Col}  /* bsPrefix="smallMargin" */ >
                            <Form.Label> Distributor </Form.Label>
                            <CreatableSelect isDisabled={(!showDistributor && list_stock_parts)}
                                formatCreateLabel={(str) => "new distributor: " + str.toUpperCase()}
                                onChange={handleChange_distributor}
                                options={Constants.distributors}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={4} >
                            Quantity
                        </Form.Label>
                        <Col sm={4} >
                            <Form.Control required
                                value={part.quantity}
                                onChange={(e) => setPart({ ...part, quantity: e.target.value })} />
                        </Col>
                        <Form.Label column sm={5} />
                    </Form.Group>

                    <Form.Group as={Row} >
                        <Form.Label column sm="4" >
                            Cost
                        </Form.Label>
                        <Col sm="4">
                            <Form.Control
                                    id="costAmount"
                                    onChange={(e) => updateCost(e.target.value)}
                                    value={part.cost} />                        
                        </Col>
                        <Form.Group sm="3" as={Col} style={{margin:"-31px -15px auto"}}>
                            <Form.Label style={{float:"right"}}>
                                Markup
                            </Form.Label>
                            <Form.Control
                                    id="markupAmount"
                                    style={{textAlign:"right"}}
                                    onChange={(e) => updateMarkup(e.target.value)}
                                    value={part.markup} />  
                        </Form.Group>

                        <Form.Group as={Col} sm="1" style={{margin:"-10px auto auto"}}>
                            <Form.Check 
                                inline 
                                checked={part.markup_type === 2} 
                                label="$" 
                                id="dollar_radio"
                                type="radio"  
                                onChange={(e)=>selectMarkupType(e.target.id)} />
                            <Form.Check 
                                inline 
                                checked={part.markup_type === 1} 
                                label="%" 
                                id="percent_radio"
                                type="radio"  
                                onChange={(e)=>selectMarkupType(e.target.id)}/>
                        </Form.Group>
                        
                        
                          
                        
                        
                    </Form.Group>
                    
                    
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={4} >
                            List
                        </Form.Label>
                        <Col sm={4} >
                            <Form.Control
                                onChange={(e) => setPart({ ...part, list: e.target.value, markup:part.markup_type===1? e.target.value/part.cost :e.target.value-part.cost  })}
                                value={part.list} />
                        </Col>
                        <Form.Label column sm={5} />
                    </Form.Group>

                    <Button variant="primary" onClick={() => checkForValidation()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}