import React, { useState } from 'react';
import { Navbar, Nav} from 'react-bootstrap';
import { useAuth } from "../../authentication/auth.js";
//import logo from "../../photos/lambo-logo-red.png"
import logo from "../../photos/lambo-logo-white.png"

export default function Navigation(props) {

    const { setAuthTokens } = useAuth();
    const { authTokens } = useAuth();
    const [count, setCount] = useState(0);
    function logout() {
        console.log("signing off.")
        setAuthTokens({token:null,permissions:0});
    }


    return (
        authTokens.accessCode >= 2 ?
            <Navbar bg="dark" variant="dark" >
                <Nav  className="mr-auto ">
                    <Navbar.Brand href="/home">
                        <img
                            src={logo}
                            width="30"
                            height="30"
                            className="logo_nav"
                            /* className="d-inline-block align-top" */
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Nav.Link className="navbar__link" href="/home">Home</Nav.Link>
                    <Nav.Link className="navbar__link" href="/jobs">Jobs</Nav.Link>
                    <Nav.Link className="navbar__link" href="/invoices">Invoices</Nav.Link>
                    <Nav.Link className="navbar__link" href="/vendors">Vendors</Nav.Link>
                    <Nav.Link className="navbar__link" href="/inventory">Inventory</Nav.Link>
                    <Nav.Link className="navbar__link" href="/search">Search</Nav.Link>
                    <Nav.Link className="navbar__link" href="/report">Report</Nav.Link>
                </Nav>
                <Nav>
{/*                     <Nav.Link className="navbar__link" href="/customers">Customers</Nav.Link>
                    <Nav.Link className="navbar__link" href="/reset" style={{ color: "Red" }}>Reset</Nav.Link> */}
                    <Nav.Link className="navbar__link" href="/database">Database</Nav.Link>
                    <Nav.Link className="navbar__link" href="/login" onClick={() => logout()}>Logout</Nav.Link>
                </Nav>
            </Navbar>
            :
            authTokens.accessCode > 0 ?
                <Navbar bg="dark" variant="dark" >
                    <Nav  className="mr-auto ">
                    <Navbar.Brand href="/home">
                        <img
                            src={logo}
                            width="30"
                            height="30"
                            className="logo_nav"
                            /* className="d-inline-block align-top" */
                            alt="logo"
                        />
                    </Navbar.Brand>
                        <Nav.Link className="navbar__link" href="/home">Home</Nav.Link>
                        <Nav.Link className="navbar__link" href="/jobs">Jobs</Nav.Link>
                        <Nav.Link className="navbar__link" href="/invoices">Invoices</Nav.Link>
                        <Nav.Link className="navbar__link" href="/vendors">Vendors</Nav.Link>
                        <Nav.Link className="navbar__link" href="/inventory">Inventory</Nav.Link>
                        <Nav.Link className="navbar__link" href="/search">Search</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="navbar__link" href="/login" onClick={() => logout()}>Logout</Nav.Link>
                    </Nav>
                </Navbar>
                :
                <Navbar bg="dark" variant="dark" onClick={()=>setCount(count+1)}>
                    <Nav  className="mr-auto ">
                        {/* <Nav.Link className="navbar__link" href="/home">Home</Nav.Link> */}
                        <Nav.Link className="navbar__link" href="/login">Login</Nav.Link>
                    </Nav>
                </Navbar>


    );
}