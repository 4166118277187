import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import * as Constants from '../../../constants/constants'
import axios from 'axios';

export default function LOF_Modal(props) {
    const { show, employeeList } = props
    const [filter, setFilter] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })
    const [oil, setOil] = useState({
        partNumber: "",
        description: "",
        quantity: '',
        cost: '',
        list: ''
    })
    const [employee, setEmployee] = useState({
        employeeID: "",
        description: "",
        amount: 0, 
        rate: 0,
        total: 0
    })
    const [partsList, setPartsList] = useState({ parts: [] })
    const [error, setError] = useState("")
    const inputRef = useRef();
    props.useEventListener('keydown', myKeyPress);

    function myKeyPress(event) {
        if (event.key === "F10") {
            submitAddLOF()
            event.preventDefault()
        } else if (event.key === "Escape") {
            props.close()
            event.preventDefault()
        }
    }

    async function getParts() {
        try {
            /* setLoading(true) */
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data getParts: ', res_data);
            setPartsList({ parts: res_data.data.inventory })
            /* setLoading(false) */

        } catch (error) {
            console.log("Error - asdb1123a", error)
            // if (error.response)
            //     setError({ show: true, message: error.response })
            // else if (error.request)
            //     setError({ show: true, message: error.request })
            // else
            //     setError({ show: true, message: "Error getting inventory/getInventory, errno: amz123z" })

        }
    }

    function getEmployee(checkNew) {
        if (!checkNew) {
            const currentOption = employeeList.find(option => option.value === employee.employeeID);
            return currentOption
        }
    }

    function submitAddLOF(){
        console.log("filter",filter,"oil:", oil);

        if(!filter.partNumber){
            setError("You must select a filter")
            return
        }
        if(!oil.partNumber){
            setError("You must select an oil")
            return
        }
        if(!oil.quantity){
            setError("You must select a quantity")
            return
        }
        props.addOilChange({filter,oil,employee})
    }

    async function getItemByCode(){
        try {
            const res_data = await axios.post(`${Constants.port}/canned/getItem`,{code:'LOF'});
            console.log('fetching data getItemByCode: ', res_data.data.item);
            const i = res_data.data.item
            setEmployee({...employee, 
                description: i.description,
                amount: i.hours, 
                rate: i.rate,
                total: i.total})
            //setPartsList({ parts: res_data.data.inventory })

        } catch (error) {
            console.log("Error - Unable to getItemByCode:LOF_Modal", error)
        }
    }
    
    useEffect(() => {
        inputRef.current.focus();
        getParts()
        getItemByCode()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal show={show} onHide={props.close} className="invoice_item_modal" >
            <Modal.Header closeButton>
                <Modal.Title>Select Oil Change Info.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger"> {error} </Alert> : null}
                <Form >
                    <Form.Group as={Row} >
                        <Form.Label column sm={3}>
                            Employee
                            </Form.Label>
                        <Col sm={6}>
                            <Select
                                ref={inputRef}
                                value={(getEmployee())}
                                onChange={e => { setEmployee({...employee, employeeID: e.value }) }}
                                options={employeeList.map((item, key) => {
                                    return {
                                        value: item.EmployeeID,
                                        label: item.EmployeeName,    
                                        }
                                    })
                                }
                            />
                        </Col>

                        <Form.Label column sm={3}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Filter
                        </Form.Label>
                        <Col sm={6}>
                            <Select
                                /* value={(getPart())} */
                                onChange={e => { setFilter({ ...filter, partNumber: e.label, description: e.value, quantity: 1, cost: e.cost, list: e.list, _id: e._id }) }}
                                options={partsList.parts.filter((item) => {
                                    return (item.Description).includes("OIL FILTER")
                                    }).map((item)=>{
                                        return {
                                            value: item.Description,
                                            label: item.PartNumber,
                                            cost: item.Cost,
                                            list: item.List,
                                            _id: item._id
                                        }
                                    })
                                        
                                    
                                  /*   .map((item) => {
                                    if((item.Description).includes("OIL FILTER"))
                                        return {
                                            value: item.Description,
                                            label: item.PartNumber,
                                            cost: item.Cost,
                                            list: item.List
                                        }
                                    else
                                    return {}
                                }) */
                                }
                            />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin" >
                        <Form.Label column sm={3}>
                            Oil
                        </Form.Label>
                        <Col sm={6}>
                            <Select
                                /* value={(getPart())} */
                                onChange={e => { setOil({ ...oil, partNumber: e.label, description: e.value, cost: e.cost, list: e.list, _id: e._id }) }}
                                options={partsList.parts.filter((item) => {
                                    return ((item.Description).includes("SYNTHETIC") || (item.Description) === "OIL")  
                                    }).map((item)=>{
                                        return {
                                            value: item.Description,
                                            label: item.PartNumber,
                                            cost: item.Cost,
                                            list: item.List,
                                            _id: item._id
                                        }
                                    })
                                }
                            />
                        </Col>
                        <Form.Label column sm={5}></Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} bsPrefix="smallMargin"  >
                        <Form.Label column sm={3}>
                            Quantity
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control required

                                value={oil.quantity}
                                onChange={(e) => setOil({ ...oil, quantity: e.target.value })} />
                        </Col>
                        <Form.Label column sm={5}>

                        </Form.Label>
                    </Form.Group>

                    <br />
                    <Button variant="primary" onClick={()=>submitAddLOF()}>Submit form</Button>
                    <Button style={{ marginLeft: "10px" }} variant="secondary" onClick={props.close}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )

}