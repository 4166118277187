import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from './../../constants/constants'
import { Card, Button } from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import axios from 'axios';
import Error from '../../global_components/Error.js'
import AddItemModal from "./Modals/AddItemModal.js"
import EditItemModal from "./Modals/EditItemModal.js"
import Loading from '../../global_components/Loading'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

export default function Inventory(props) {
    const columns = [{
        dataField: 'PartNumber',
        text: 'Part Number',
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"} }),
        sort: true
    }, {
        dataField: 'Description',
        text: 'Description',
        filter: textFilter({autocomplete:"off", delay: 10, style:{zoom:"90%"} }),
        sort: true
    }, {
        dataField: 'Quantity',
        text: 'Quantity',
        formatter: quantityFormatter,
        sort: true,
        headerStyle:  { width: "10%"/* , textAlign: 'center' */ }

    }, {
        dataField: 'Cost',
        text: 'Cost',
        formatter: priceFormatter,
        sort: true,
        headerStyle:  { width: "10%"/* , textAlign: 'center' */ }

    }, {
        dataField: 'List',
        text: 'List',
        formatter: priceFormatter,
        sort: true,
        headerStyle:  { width: "10%"/* , textAlign: 'center' */ }

    }];

    const defaultSorted = [{
        dataField: 'Quantity',
        order: 'asc'
      }];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({ show: false, message: "" })
    const [item, setItem] = useState({ item: "" })
    const [parts, setParts] = useState({ list: [] })
    const [modals, setModals] = useState({
        addItem: false,
        editItem: false
    })

    function quantityFormatter(cell, row) {
        return (
            <span>
                { cell ? parseFloat(cell.toFixed(1)) : 0}
            </span>
        );
    }

    function priceFormatter(cell, row) {
        return (
            <span>
                $ { cell ? cell.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}
            </span>
        );
    }
    
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log("ROW EVENT", row)
            setItem({ item: row })
            setModals({ ...modals, editItem: true })
            //props.history.push(`/customers/${row._id}`)
        },
    };

    async function getInventory() {
        try {
            const res_data = await axios.get(`${Constants.port}/inventory/getInventory`);
            console.log('fetching data: ', res_data);
            setParts({ list: res_data.data.inventory })
            setLoading(false)

        } catch (error) {
            console.log("Error - amz123z", error)
            setLoading(false)
        }
    }

    async function submitAddItem(item) {
        console.log("SUBMITTING ADD ITEM, ", item)
        try {
            const res_data = await axios.post(`${Constants.port}/inventory/addItem`, item);
            console.log('fetching data: ', res_data);

        } catch (error) {
            console.log("Error - asnd18z", error)
        }
        setModals({ ...modals, addItem: false })
    }

    async function submitUpdateItem(item) {
        try {
            const res_data = await axios.post(`${Constants.port}/inventory/updateItem`, item);
            console.log('fetching data: ', res_data);

        } catch (error) {
            console.log("Error - ap12i1j", error)
        }
        setModals({ ...modals, editItem: false })
    }

    async function removeItem(item) {
        try {
            console.log("removing item: ", item)
            const res_data = await axios.post(`${Constants.port}/inventory/removeItem`, item);
            console.log('fetching data: ', res_data);

        } catch (error) {
            console.log("Error - ASDz75Z", error)
        }
        setModals({ ...modals, editItem: false })
    }

    useEffect(() => {

        getInventory();
        !modals.addItem && !modals.editItem ? 
        document.getElementById('text-filter-column-PartNumber').focus() : console.log("ASD");
    }, [modals])

    return (
        <div className="cardpadding">
          {!loading ? null : <Loading />}

            <Card>
                <Card.Header style={{ fontSize: "35px", paddingRight: "10px" }}>
                    Inventory
                <Button
                        style={{ marginLeft: "25px", alignSelf: "center" }}
                        onClick={() => setModals({ ...modals, addItem: true })}
                        variant="primary">
                        Add Item
                </Button>
                </Card.Header>
                <Card.Body>
                    <div className="large_inventory_table">
                        <BootstrapTable
                          defaultSorted={ defaultSorted } 

                            bootstrap4
                            keyField='_id'
                            data={parts.list}
                            columns={columns}
                            filter={filterFactory()}
                            rowEvents={rowEvents}
                            striped
                            hover
                            condensed
                        />
                    </div>
                </Card.Body>
            </Card>

            {modals.addItem ? <AddItemModal show={modals.addItem} close={() => setModals({ ...modals, addItem: false })} add={(item) => submitAddItem(item)} /> : null}
            {modals.editItem ? <EditItemModal item={item.item} show={modals.editItem}
                close={() => setModals({ ...modals, editItem: false })}
                update={(item) => submitUpdateItem(item)}
                remove={(item) => removeItem(item)}
            /> : null}
            {error.show ?
                <Error error={error.message} show={error.show} onHide={() => setError({ message: "", show: false })} close={() => setError({ message: "", show: false })} />
                : null}

        </div>
    );
}