import React from 'react';
import { Card, Container, Row, Col, ListGroup } from 'react-bootstrap'

export default function PricingSCreen(props) {

    return (
        <div>
            <br />
            <h1 style={{ textAlign: "center" }}>Find the plan that's right for your shop.</h1>
            <br />
            <Container style={{ textAlign: "center" }}>
                <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Basic - $850.00 /mo</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Included in basic and more:</Card.Subtitle>

                                <ListGroup variant="flush">
                                    <ListGroup.Item>Unlimited Invoices/Estimates/Jobs</ListGroup.Item>
                                    {/* <ListGroup.Item>Reporting</ListGroup.Item> */}
                                    <ListGroup.Item>Shop Workflow</ListGroup.Item>
                                    <ListGroup.Item>Inventory Management</ListGroup.Item>
                                    <ListGroup.Item>Vendor Storage</ListGroup.Item>
                                    <ListGroup.Item>Unlimited users</ListGroup.Item>
                                    <ListGroup.Item>Canned labor </ListGroup.Item>
                                    <ListGroup.Item>Cores & returns tracking</ListGroup.Item>
                                    <ListGroup.Item>Canned-tasks</ListGroup.Item>
                                </ListGroup>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Gold - $1000.00 /mo </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Everything in basic plus:</Card.Subtitle>

                                <ListGroup variant="flush">
                                    <ListGroup.Item>HD reporting/statistics</ListGroup.Item>
                                    <ListGroup.Item>Ability to request moderate-severity updates</ListGroup.Item>

                                </ListGroup>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Platinum - $1350.00 /mo</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Everything in gold plus:</Card.Subtitle>

                                <ListGroup variant="flush">
                                    <ListGroup.Item>Ability to request significant updates</ListGroup.Item>                                    
                                    <ListGroup.Item>High priority support</ListGroup.Item>
                                    <ListGroup.Item>Two-way texting*</ListGroup.Item>
                                    <ListGroup.Item>Photo & video*</ListGroup.Item>
                                    <ListGroup.Item>Online payment processing*</ListGroup.Item>

                                </ListGroup>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row style={{ justifyContent: "center" }}>
                    <Card style={{ background: "#171e2a", color: "white" }}>
                        <Card.Body>
                            <Card.Title>All AIMS-PT2 plans include awesome tools to help you get organized and run your shop </Card.Title>
                            <Row style={{textAlign:"left"}}>
                                <Col>
                                    <ul>
                                        <li>Cloud-based platform</li>
                                        <li>Universal search</li>
                                        <li>Shop Reporting</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Workflow management</li>
                                        <li>Payments</li>
                                        <li>Friendly U.S.-based support team</li>
                                    </ul>

                                </Col>
                                <Col>
                                    <ul>
                                        <li>Unlimited quotes and invoices</li>
                                        <li>Industry-standard security</li>
                                    </ul>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Row>
            </Container>

        </div>
    )
}
