import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Button, Modal, Dropdown, DropdownButton, Container, Row, Spinner } from 'react-bootstrap';
import * as Constants from "../../../constants/constants"
import DateTimePicker from 'react-datetime-picker';

export default function EditJobModal(props) {
    const { show, from, jobInfo } = props
    const [jobToUpdate, setJobToUpdate] = useState(jobInfo)
    const [loading, setLoading] = useState(false)

    function myKeyPress(event) {
        if (event.key === "F10") {
            props.save(jobToUpdate)
            event.preventDefault()
        }
    }
    
    function createWorkorder() {
        setLoading(true)
        props.createWO(jobToUpdate)
    }

    function useEventListener(eventName, handler, element = window) {

        // Create a ref that stores handler
        const savedHandler = useRef();

        // Update ref.current value if handler changes.
        // This allows our effect below to always get latest handler ...
        // ... without us needing to pass it in effect deps array ...
        // ... and potentially cause effect to re-run every render.
        useEffect(() => {
            savedHandler.current = handler;
        }, [handler]);

        useEffect(
            () => {
                // Make sure element supports addEventListener
                // On 
                const isSupported = element && element.addEventListener;
                if (!isSupported) return;

                // Create event listener that calls handler function stored in ref
                const eventListener = event => savedHandler.current(event);

                // Add event listener
                element.addEventListener(eventName, eventListener);

                // Remove event listener on cleanup
                return () => {
                    element.removeEventListener(eventName, eventListener);
                };
            },
            [eventName, element] // Re-run if eventName or element changes
        );
    };

    console.log("test", jobToUpdate, jobInfo);

    useEventListener('keydown', myKeyPress);
    return (
        <Modal show={show} onHide={props.close} size='lg' className="invoice_item_modal">
            <Modal.Header closeButton>
                <Col>
                    <Modal.Title>
                        {jobInfo.Type === "APPOINTMENT" ? "Edit Appointment" : "Edit Job"}
                    </Modal.Title>
                </Col>
                <Col>
                    <Modal.Title>
                        Time In: {Constants.dateTimeFormatter(jobToUpdate.TimeIn)}
                    </Modal.Title>
                </Col>



            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form>
                        <Row>
                            <Col>
                                {jobToUpdate.Type === "APPOINTMENT" ?
                                    <>
                                        <Form.Label>Date</Form.Label>
                                        <br />
                                        <section style={{ marginBottom: "10px" }}>
                                            <DateTimePicker disableClock
                                                onChange={(e) => setJobToUpdate({ ...jobToUpdate, ScheduledDate: e })}
                                                value={jobToUpdate.ScheduledDate ? new Date(jobToUpdate.ScheduledDate) : null}
                                            />
                                        </section>
                                    </>
                                    :
                                    <Form.Row>
                                        <Form.Group as={Col} >
                                            <Form.Label>Status</Form.Label>
                                            <DropdownButton id="dropdown-item-button" title={jobToUpdate.Status}>
                                                {jobToUpdate.Status !== "OPEN" ? <Dropdown.Item onClick={() => setJobToUpdate({ ...jobToUpdate, Status: "OPEN" })}>OPEN</Dropdown.Item> : null}
                                                {jobToUpdate.Status !== "STARTED" ? <Dropdown.Item onClick={() => setJobToUpdate({ ...jobToUpdate, Status: "STARTED" })} >STARTED</Dropdown.Item> : null}
                                                {jobToUpdate.Status !== "NEEDS PARTS" ? <Dropdown.Item onClick={() => setJobToUpdate({ ...jobToUpdate, Status: "NEEDS PARTS" })}>NEEDS PARTS</Dropdown.Item> : null}
                                                {jobToUpdate.Status !== "FINISHED" ? <Dropdown.Item onClick={() => setJobToUpdate({ ...jobToUpdate, Status: "FINISHED" })}>FINISHED</Dropdown.Item> : null}
                                                {jobToUpdate.Status !== "NEEDS APPROVAL" ? <Dropdown.Item onClick={() => setJobToUpdate({ ...jobToUpdate, Status: "NEEDS APPROVAL" })}>NEEDS APPROVAL</Dropdown.Item> : null}

                                            </DropdownButton>
                                        </Form.Group>

                                        <Form.Group as={Col} xs={2} style={{ marginRight: "10px" }}>
                                            <Form.Label>Waiting</Form.Label>
                                            <br />
                                            <input checked={jobToUpdate.Waiting} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Waiting: e.target.checked })} type='checkbox' style={{ height: "25px", width: "20px", margin: "auto" }} />
                                        </Form.Group>

                                        <Form.Group as={Col} xs={3} >
                                            <Form.Label>W.O. #</Form.Label>
                                            <Form.Control autoComplete="new-password" value={jobToUpdate.WorkorderNumber} onChange={(e) => setJobToUpdate({ ...jobToUpdate, WorkorderNumber: e.target.value })} />
                                        </Form.Group>

                                    </Form.Row>
                                }

                                <Form.Row>
                                    <Form.Group as={Col} >
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control autoComplete="new-password" value={jobToUpdate.Lastname} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Lastname: e.target.value })} />
                                    </Form.Group>

                                    <Form.Group as={Col} >
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control autoComplete="new-password" value={jobToUpdate.Firstname} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Firstname: e.target.value })} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control autoComplete="new-password" value={jobToUpdate.Phone} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Phone: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlInput2">
                                    <Form.Label>Vehicle</Form.Label>
                                    <Form.Control autoComplete="new-password" value={jobToUpdate.Vehicle} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Vehicle: e.target.value })} />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control autoComplete="new-password" as="textarea" rows="5" defaultValue={jobInfo.Description} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Description: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col>

                                <Form.Label>Notes</Form.Label>
                                <Form.Control autoComplete="new-password" as="textarea" style={{ height: "88%" }} value={jobToUpdate.Notes} onChange={(e) => setJobToUpdate({ ...jobToUpdate, Notes: e.target.value })} />
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {from === "invoice_screen" || from === "admin_screen" || from === "search_screen" ? null :
                    <Button variant="danger" onClick={props.remove.bind(this, jobToUpdate)} style={{ marginRight: "auto" }}> Remove </Button>}

                <Button variant="secondary" onClick={props.close}> Close </Button>
                {from === "jobscreen" ?
                    <Button variant="dark" onClick={props.moveJob.bind(this, jobToUpdate)} > {jobToUpdate.Type === "APPOINTMENT" ? "Move to Jobs" : "Move to Apts"} </Button>
                    :
                    null}
                <Button variant="primary" onClick={props.save.bind(this, jobToUpdate)} disabled={from === "admin_screen" || from === "search_screen"}> Save Changes </Button>

                {from === "invoice_screen" || from === "admin_screen" ? null :
                    jobToUpdate.WorkorderNumber === -1 ?
                        <Button variant="success" disabled={loading} onClick={from === "search_screen" ? null : createWorkorder}> {loading ?
                            <>
                                Loading {' '}
                                <Spinner size='sm' animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner></>
                            : 'Create WO'} </Button> :
                        <Button variant="info" onClick={props.openWO.bind(this, jobToUpdate)}> Open WO </Button>}
            </Modal.Footer>
        </Modal>
    )
}